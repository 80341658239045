export const PERMISSIONS_RESET_STATE = 'PERMISSIONS_RESET_STATE';
export const PERMISSIONS_CURRENT_ITEM = 'PERMISSIONS_CURRENT_ITEM';

export const PERMISSIONS_REQUEST_LOADING = 'PERMISSIONS_REQUEST_LOADING';
export const PERMISSIONS_REQUEST_OFFLINE = 'PERMISSIONS_REQUEST_OFFLINE';

export const PERMISSIONS_REQUEST_SUCCESS = 'PERMISSIONS_REQUEST_SUCCESS';
export const PERMISSIONS_REQUEST_FAILED = 'PERMISSIONS_REQUEST_FAILED';

export const PERMISSIONS_CURRENT_ACTION = 'PERMISSIONS_CURRENT_ACTION';
export const PERMISSIONS_RESET_ACTION = 'PERMISSIONS_RESET_ACTION';
export const PERMISSIONS_NAME_EXISTS_ACTION = 'PERMISSIONS_NAME_EXISTS_ACTION';
export const PERMISSIONS_RESET_NAME_EXISTS = 'PERMISSIONS_RESET_NAME_EXISTS';
