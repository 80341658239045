
import * as actionTypes from './types';



const INITIAL_KEY_STATE = {
	result: null,
	current: null,
	isLoading: false,
	isSuccess: false,
	error:null,
	isNameExists:false
	
	
};

const INITIAL_STATE = {
	current: {
		result: null,
	},
	list: {
		result: {
			items: [],
			pagination: {
				current: 1,
				pageSize: 5,
				total: 1,
				showSizeChanger: false,
			},
		},
		isLoading: false,
		isSuccess: false,
	},
	filter: {
		result: {
			items: [],
			pagination: {
				current: 1,
				pageSize: 5,
				total: 1,
				showSizeChanger: false,
			},
		},
		isLoading: false,
		isSuccess: false,
	},
	create: INITIAL_KEY_STATE,
	update: INITIAL_KEY_STATE,
	delete: INITIAL_KEY_STATE,
	read: INITIAL_KEY_STATE,
	search: { ...INITIAL_KEY_STATE, result: [] },
	
};

const projectcardsReducer = (state = INITIAL_STATE, action) => {
	const { payload, keyState } = action;
	switch (action.type) {
		case actionTypes.PROJECT_CARDS_RESET_STATE:
			return INITIAL_STATE;
		case actionTypes.PROJECT_CARDS_CURRENT_ITEM:
			return {
				...state,
				current: {
					result: payload,
				},
			};
		case actionTypes.PROJECT_CARDS_REQUEST_LOADING:
			return {
				...state,
				[keyState]: {
					...state[keyState],
					isLoading: true,
					isNameExists:false,
				},
			};
		case actionTypes.PROJECT_CARDS_REQUEST_FAILED:
			return {
				...state,
				[keyState]: {
					...state[keyState],
					isLoading: false,
					isSuccess: false,
					error:payload,
					isNameExists:  payload.code === 409 ? true:false,
				
	
				},
			};
		case actionTypes.PROJECT_CARDS_REQUEST_SUCCESS:
			return {
				...state,
				[keyState]: {
					result: payload,
					isLoading: false,
					isSuccess: true,
					isNameExists:false,
				},
			};
		case actionTypes.PROJECT_CARDS_CURRENT_ACTION:
			return {
				...state,
				[keyState]: {
					...INITIAL_KEY_STATE,
					current: payload,
				},
			};
		case actionTypes.PROJECT_CARDS_RESET_ACTION:
			return {
				...state,
				[keyState]: {
					...INITIAL_STATE[keyState],
				},
			};

			// case actionTypes.PROJECT_CARDS_NAME_EXISTS_ACTION:
			// 	const {error}=payload
			// 	return {
			// 		...state,
			// 		exists: {
			// 			...state.exists,
			// 			isNameExists:true,
			// 			error:error ,
			// 		},
			// 	}
				// case actionTypes.PROJECT_CARDS_RESET_NAME_EXISTS:
				// 	return {
				// 		...state,
				// 		exists: INITIAL_PROJECT_NAME,
				// 	};
				case actionTypes.PROJECT_CARDS_RESET_NAME_EXISTS:
					return {
					  ...state,
					  create: {
						...state.create,
						isNameExists: false,
						error: null,
					  },
					};
		default:
			return state;
	}
};


export default projectcardsReducer;
