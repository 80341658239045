import { crm } from "../redux/crm/actions";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentCRM } from '../redux/crm/selectors';
import { request } from '../request/index';

export default function UploadImage() {
	const dispatch = useDispatch();
	const [issueImage, setIssueImage] = useState('');
	const [files, setFiles] = useState([]);
	const [attachmentList, setAttachmentList] = useState([]);
	const [newAttachmentList, setNewAttachmentList] = useState([]);
	const [attachment, setAttachment] = useState([]);
	const { uploadImage, selectedCheckListItem, isEditIssue } = useSelector(selectCurrentCRM);

	const handleClose = () => {
		dispatch(crm.trigger({ uploadImage: 'close' }))
	}

	useEffect(() => {
		if (isEditIssue) {
			let arr = []
			selectedCheckListItem?.Attachments?.map((attach, index) => {
				if (attach[0] != "")
					arr.push(attach[0])
			})
			setAttachmentList(arr)
		}
	}, [selectedCheckListItem])

	useEffect(() => {
	}, [])

	const handleUpload = async () => {
		let _attach = []
		await Promise.all(newAttachmentList?.map(async (attach, idx) => {
			const file = files[idx]
			const exte = file?.name.substring(file?.name.indexOf(".") + 1);
			const docDetails = {
				extension: exte,
				file_name: file.name,
				mime_type: file.type,
				entity_type: "crm",
				size: file.size,
			};
			const type = file.type
			let data = await request.uploadurl({ entity: 'document', jsonData: docDetails, options: {} })
			_attach.push({ filename: file.name, fileKey: data.filename })
			await request.uploadfileAWSS3({ fileuploadurl: data.uploadLink, file, type })
		}))
		attachmentList.map((obj) => {
			_attach.push({ filename: obj.filename, fileKey: obj.fileKey })
		})
		dispatch(crm.trigger({ activeIssueImageKey: _attach, uploadImage: 'close', isMobileUpload: true }))
	}

	const handleBrowseImagefile = () => {
		document.getElementById('imagefile').click()
	}

	const handleFileChange = (e) => {
		setFiles(e.target.files);
		const chosenFiles = Array.prototype.slice.call(e.target.files)
		let arr = []
		chosenFiles.map((attach) => {
			arr.push({ filename: attach.name, fileKey: '' })
		})
		setNewAttachmentList(arr)
	};

	const downloadAttachment = async (key) => {
		const url = "https://proptech-pdi.s3.amazonaws.com/" + key

		const link = document.createElement('a');
		link.href = url;
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	}

	const handleDeleteAttachment = (obj, idx, type) => {
		if (type == 'uploading') {
			let filteredArray = newAttachmentList.filter(item => item !== obj)
			setNewAttachmentList(filteredArray);
		}
		else {
			let filteredArray = attachmentList.filter(item => item !== obj)
			setAttachmentList(filteredArray);
		}
	}

	return (
		<>
			<div className="absolute fixed w-full mt-20 inset-0 z-50 flex flex-warp justify-center align-middle">
				<div className="w-[300px] my-2 mx-5 max-w-sm">
					<div className="rounded-lg shadow-lg flex flex-col w-full bg-white">
						<div className="flex m-3 items-center justify-between mr-4">
							<div className="flex flex-col items-start justify-start mx-1 mt-3 mb-4">
								<span className="text-xl">Upload Image </span>
							</div>
							<div className="cursor-pointer ">
								<svg onClick={() => handleClose()} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-x-circle"><circle cx="12" cy="12" r="10" /><path d="m15 9-6 6" /><path d="m9 9 6 6" /></svg>
							</div>
						</div>
						<div className="flex flex-col mx-5 justify-center items-center overflow-auto" >
							<div
								className="flex justify-center  h-[170px] w-[280px]  px-4 py-2 transition bg-[#1C49D01A] border-x border-y border-[#171A1FFF] border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none">
								<span className="flex items-center justify-center flex-col space-x-2">
									<svg className="w-[60px] h-[60px] text-[#1C49D0FF]" fill="#1C49D0FF" viewBox="0 0 256 256"><path d="M248,128a87.34,87.34,0,0,1-17.6,52.81,8,8,0,1,1-12.8-9.62A71.34,71.34,0,0,0,232,128a72,72,0,0,0-144,0,8,8,0,0,1-16,0,88,88,0,0,1,3.29-23.88C74.2,104,73.1,104,72,104a48,48,0,0,0,0,96H96a8,8,0,0,1,0,16H72A64,64,0,1,1,81.29,88.68,88,88,0,0,1,248,128Zm-90.34-5.66a8,8,0,0,0-11.32,0l-32,32a8,8,0,0,0,11.32,11.32L144,147.31V208a8,8,0,0,0,16,0V147.31l18.34,18.35a8,8,0,0,0,11.32-11.32Z"></path></svg>
									<span className="font-medium text-[#171A1FFF] mx-2 text-xs">
										Drag & drap your files here
									</span>
									<span className="font-medium text-gray-600 mx-2 text-xs">
										or
									</span>
									<span
										onClick={() => handleBrowseImagefile()}
										className="bg-[#1C49D0FF] my-2  text-[14px] font-[400]  text-white px-2 py-2 rounded text-xs ">
										Browse File
									</span>
								</span>
								{issueImage ? <img
									className="h-24 w-24 py-2"
									src={issueImage} /> : ""}
								<input type="file" id='imagefile' className="hidden" multiple name="file_upload" onChange={(e) => handleFileChange(e)} />
							</div>
							<div className="flex flex-col justify-center item-center w-full mx-5 px-5 h-full">
								<div className="flex flex-row space-x-2 m-4">
									<div className="text-xs font-normal  text-black text-opacity-60 ">Uploading files - {newAttachmentList?.length}</div>
								</div>
								{newAttachmentList?.map((obj, index) => {
									return <div
										key={index}
										className="flex w-full flex-row jusity-center item-center h-full">
										<svg
											xmlns="http://www.w3.org/2000/svg" width="48px" height="48px"
											fill="#1C49D0ff" viewBox="0 0 24 24">
											<path d="M7.782 14.576c-.186 0-.312.018-.377.036v1.193c.077.018.174.023.306.023.485 0 .785-.246.785-.659 0-.371-.258-.593-.714-.593z" /><path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8l-6-6zM9.03 16.105c-.313.293-.774.426-1.313.426-.12 0-.229-.007-.312-.019v1.445h-.906V13.97a7.504 7.504 0 0 1 1.235-.083c.563 0 .966.107 1.235.323.258.204.432.54.432.936s-.131.731-.371.959zm4.302 1.853h-.96l-.863-1.56c-.24-.432-.504-.953-.701-1.427l-.019.006c.024.534.036 1.104.036 1.763v1.218h-.84v-4.042h1.067l.84 1.481c.24.426.479.93.659 1.385h.019a14.746 14.746 0 0 1-.078-1.685v-1.182h.84v4.043zm4.169-.186a4.512 4.512 0 0 1-1.349.228c-.737 0-1.271-.186-1.644-.546-.371-.348-.575-.875-.569-1.469.006-1.344.983-2.111 2.309-2.111.521 0 .924.103 1.121.198l-.191.731c-.222-.096-.498-.174-.941-.174-.762 0-1.338.432-1.338 1.308 0 .833.522 1.325 1.271 1.325.21 0 .378-.024.45-.061v-.846h-.624v-.713h1.505v2.13zM14 9h-1V4l5 5h-4z" /></svg>
										<div className="w-1/2 m-2  pt-1">
											<div className="mb-2 flex w-full items-center justify-between text-xs mr-8">
												<div onClick={() => downloadAttachment(obj?.fileKey)}
													className="cursor-pointer text-gray-600">{obj?.filename.slice(0, 20)}</div>
												{/* <div className="text-gray-600">100%</div> */}
											</div>
											<div className="flex flex-row w-full justify-center item-center">
												<div className="w-full mb-4 flex h-2 overflow-hidden rounded bg-gray-100 text-xs ">
													<div className="bg-green-500 w-full"></div>
												</div>
											</div>
										</div>
										<div onClick={() => handleDeleteAttachment(obj, index, 'uploading')}
											className="m-1 p-2 cursor-pointer rounded  text-black h-7" >
											<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="currentColor"><path d="M17 6H22V8H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V8H2V6H7V3C7 2.44772 7.44772 2 8 2H16C16.5523 2 17 2.44772 17 3V6ZM18 8H6V20H18V8ZM13.4142 13.9997L15.182 15.7675L13.7678 17.1817L12 15.4139L10.2322 17.1817L8.81802 15.7675L10.5858 13.9997L8.81802 12.232L10.2322 10.8178L12 12.5855L13.7678 10.8178L15.182 12.232L13.4142 13.9997ZM9 4V6H15V4H9Z"></path></svg>
										</div>
									</div>
								})}
								{attachmentList?.map((obj, index) => {
									return <div className="flex w-full flex-row jusity-center item-center h-full">
										<svg xmlns="http://www.w3.org/2000/svg" width="48px" height="48px" fill="#1C49D0ff" viewBox="0 0 24 24"><path d="M7.782 14.576c-.186 0-.312.018-.377.036v1.193c.077.018.174.023.306.023.485 0 .785-.246.785-.659 0-.371-.258-.593-.714-.593z" /><path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8l-6-6zM9.03 16.105c-.313.293-.774.426-1.313.426-.12 0-.229-.007-.312-.019v1.445h-.906V13.97a7.504 7.504 0 0 1 1.235-.083c.563 0 .966.107 1.235.323.258.204.432.54.432.936s-.131.731-.371.959zm4.302 1.853h-.96l-.863-1.56c-.24-.432-.504-.953-.701-1.427l-.019.006c.024.534.036 1.104.036 1.763v1.218h-.84v-4.042h1.067l.84 1.481c.24.426.479.93.659 1.385h.019a14.746 14.746 0 0 1-.078-1.685v-1.182h.84v4.043zm4.169-.186a4.512 4.512 0 0 1-1.349.228c-.737 0-1.271-.186-1.644-.546-.371-.348-.575-.875-.569-1.469.006-1.344.983-2.111 2.309-2.111.521 0 .924.103 1.121.198l-.191.731c-.222-.096-.498-.174-.941-.174-.762 0-1.338.432-1.338 1.308 0 .833.522 1.325 1.271 1.325.21 0 .378-.024.45-.061v-.846h-.624v-.713h1.505v2.13zM14 9h-1V4l5 5h-4z" /></svg>
										<div className="w-1/2 m-2  pt-1">
											<div className="mb-2 flex w-full items-center justify-between text-xs mr-8">
												<div className="text-gray-600">{obj.filename.slice(0, 20)}</div>
												<div className="text-gray-600">100%</div>
											</div>
											<div className="flex flex-row w-full justify-center item-center">
												<div className="w-full mb-4 flex h-2 overflow-hidden rounded bg-gray-100 text-xs ">
													<div className="bg-green-500 w-full"></div>
												</div>
											</div>
										</div>
										<div onClick={() => handleDeleteAttachment(obj, index)}
											className="m-1 p-2 cursor-pointer rounded  text-black h-7" >
											<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="currentColor"><path d="M17 6H22V8H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V8H2V6H7V3C7 2.44772 7.44772 2 8 2H16C16.5523 2 17 2.44772 17 3V6ZM18 8H6V20H18V8ZM13.4142 13.9997L15.182 15.7675L13.7678 17.1817L12 15.4139L10.2322 17.1817L8.81802 15.7675L10.5858 13.9997L8.81802 12.232L10.2322 10.8178L12 12.5855L13.7678 10.8178L15.182 12.232L13.4142 13.9997ZM9 4V6H15V4H9Z"></path></svg>
										</div>
									</div>
								})}
							</div>
						</div>
						<div className="flex items-center justify-around px-6 py-4 mx-3 ">
							<button
								className="bg-blue-500 rounded text-white font-bold px-2 text-xs py-1"
								type="button"
								onClick={() => handleUpload()}>
								Upload
							</button>
						</div>
					</div>
				</div>
			</div>
			<div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
		</>
	);
}