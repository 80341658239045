import React, { useState, useEffect } from "react";
import { LiveAudioVisualizer } from "react-audio-visualize";
import { useAudioRecorder } from "react-audio-voice-recorder";

function Audio({
	isRecord,
	setRecord,
	setAudio,
	setShow,
	handleAudio,
}) {
	const {
		startRecording,
		stopRecording,
		recordingBlob,
		recordingTime,
		mediaRecorder,
	} = useAudioRecorder();

	const [audioUrl, setAudioUrl] = useState(null);

	useEffect(() => {
		if (isRecord) {
			startRecording();
		} else {
			stopRecording();
		}

		return () => {
			stopRecording();
		}
	}, [isRecord]);

	useEffect(() => {
		if (recordingBlob) {
			const url = URL.createObjectURL(recordingBlob);
			setAudioUrl(url);
			setAudio(url);
			setShow(false);
			handleAudio(url, recordingBlob);
		}
	}, [recordingBlob]);



	function stop() {
		stopRecording();
		setRecord(false);
		setShow(false);
	}

	function getAUdio() {
		stopRecording();
		// setShow(false)
		// setRecord(false);

		setAudio(recordingBlob);
	}

	const formatRecordingTime = (timeInSeconds) => {
		const minutes = Math.floor(timeInSeconds / 60);
		const seconds = Math.floor(timeInSeconds % 60);
		return `${minutes < 10 ? "0" : ""}${minutes}:${seconds < 10 ? "0" : ""
			}${seconds}`;
	};

	return (
		<div className="p-4">
			{mediaRecorder && audioUrl === null && (
				<div
					style={{ position: "relative" }}
					className="flex flex-col justify-center items-center w-full"
				>
					<div className="text-center">Recording...</div>
					<div className="text-lg font-bold justify-center">
						{formatRecordingTime(recordingTime)}
					</div>
					<div
						className="relative w-[300px] p-2 rounded-lg border border-gray-400"
						style={{ paddingRight: "40px", marginTop: "5px", padding: "10px" }}
					>
						<span
							style={{
								position: "absolute",
								top: "50%",
								left: "10px",
								transform: "translateY(-50%)",
							}}
						>
							<svg
								onClick={stop}
								fill="#ffffff"
								width="25px"
								height="25px"
								viewBox="-20 -20 240.00 240.00"
								xmlns="http://www.w3.org/2000/svg"
								stroke="#ffffff"
							>
								<g id="SVGRepo_bgCarrier" strokeWidth="0">
									<rect
										x="-20"
										y="-20"
										width="240.00"
										height="240.00"
										rx="120"
										fill="#ff0000"
										strokeWidth="0"
									/>
								</g>
								<g
									id="SVGRepo_tracerCarrier"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<g id="SVGRepo_iconCarrier">
									<path d="M114,100l49-49a9.9,9.9,0,0,0-14-14L100,86,51,37A9.9,9.9,0,0,0,37,51l49,49L37,149a9.9,9.9,0,0,0,14,14l49-49,49,49a9.9,9.9,0,0,0,14-14Z" />
								</g>
							</svg>
						</span>
						<div className="px-10">
							<LiveAudioVisualizer
								mediaRecorder={mediaRecorder}
								width={200}
								height={20}
							/>
						</div>

						<span
							style={{
								position: "absolute",
								top: "50%",
								right: "10px",
								transform: "translateY(-50%)",
							}}
						>
							<svg
								onClick={getAUdio}
								width="25px"
								height="25px"
								viewBox="-2.4 -2.4 28.80 28.80"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
								stroke="#fdfcfc"
							>
								<g id="SVGRepo_bgCarrier" strokeWidth="0">
									<rect
										x="-2.4"
										y="-2.4"
										width="28.80"
										height="28.80"
										rx="14.4"
										fill="#2bb673"
										strokeWidth="0"
									/>
								</g>
								<g
									id="SVGRepo_tracerCarrier"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<g id="SVGRepo_iconCarrier">
									<path
										d="M4.89163 13.2687L9.16582 17.5427L18.7085 8"
										stroke="#ffffff"
										strokeWidth="2.5"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</g>
							</svg>
						</span>
					</div>
				</div>
			)}

		</div>
	);
}
export default React.memo(Audio);