export const NUMBER_OF_BEDROOMS_RESET_STATE = 'NUMBER_OF_BEDROOMS_RESET_STATE';
export const NUMBER_OF_BEDROOMS_CURRENT_ITEM = 'NUMBER_OF_BEDROOMS_CURRENT_ITEM';

export const NUMBER_OF_BEDROOMS_REQUEST_LOADING = 'NUMBER_OF_BEDROOMS_REQUEST_LOADING';
export const NUMBER_OF_BEDROOMS_REQUEST_SUCCESS = 'NUMBER_OF_BEDROOMS_REQUEST_SUCCESS';
export const NUMBER_OF_BEDROOMS_REQUEST_FAILED = 'NUMBER_OF_BEDROOMS_REQUEST_FAILED';

export const NUMBER_OF_BEDROOMS_CURRENT_ACTION = 'NUMBER_OF_BEDROOMS_CURRENT_ACTION';
export const NUMBER_OF_BEDROOMS_RESET_ACTION = 'NUMBER_OF_BEDROOMS_RESET_ACTION';

export const NUMBER_OF_BEDROOMS_NAME_EXISTS_ACTION = 'NUMBER_OF_BEDROOMS_NAME_EXISTS_ACTION';
export const NUMBER_OF_BEDROOMS_RESET_NAME_EXISTS = 'NUMBER_OF_BEDROOMS_RESET_NAME_EXISTS';
