import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { request } from '../../request';
import axios from 'axios';
import AddMember from '../../modals/AddMember';
import { crm } from "../../redux/crm/actions";
import { users } from "../../redux/users/actions";
import { projectcards } from '../../redux/projectcards/actions';
import { projectusers } from '../../redux/projectusers/actions';
import { selectListItems } from '../../redux/users/selectors';
import { selectItemsFilter as selectProjectUser } from '../../redux/projectusers/selectors';
import { selectListItems as selectProjectList } from '../../redux/projectcards/selectors';
import { selectCurrentCRM } from '../../redux/crm/selectors';
import storePersist from "../../redux/storePersist";

export default function UserMangement() {
	const _user = storePersist.get('user')
	const dispatch = useDispatch();
	const navigate = useNavigate()
	const [search, setSearch] = useState('')
	const [userId, setUserId] = useState('')
	const [imageUrls, setImageUrls] = useState([])
	const { reloadMember, addMember } = useSelector(selectCurrentCRM)
	const { result } = useSelector(selectListItems);
	const { result: result1 } = useSelector(selectProjectList)
	const { result: result2 } = useSelector(selectProjectUser)
	const { items: userItem } = result;
	const { items: _projectusers } = result2
	const { items: projectitems } = result1;
	const [filteredList, setFilteredList] = useState([])

	const handleSearch = (e) => {
		const searchTerm = e.target.value
		setSearch(searchTerm)
		setFilteredList(userItem.filter((item) => item?.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
			item?.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
			item?.mobileNumber.toString().includes(searchTerm.toLowerCase()) ||
			item?.userId.toLowerCase().includes(searchTerm.toLowerCase()) ||
			item?.roleId?.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
			item?.lastName.toLowerCase().includes(searchTerm.toLowerCase())));
	}

	useEffect(() => {
		dispatch(users.list({ entity: "users", options: { page: 1, limit: 100 } }))
		dispatch(projectcards.list({ entity: "PDI_Project_Cards", options: { page: 1, limit: 200 } }))
		dispatch(projectusers.filter({ entity: 'PDI_Project_Users', jsonData: { params: { isDeleted: false }, page: 1, limit: 200 } }))
	}, [])

	useEffect(() => {
		if (reloadMember) {
			dispatch(users.list({ entity: "users", options: { limit: 100 } })).then(() => {
				dispatch(projectusers.filter({ entity: 'PDI_Project_Users', jsonData: { params: { isDeleted: false }, page: 1, limit: 200 } }))
				dispatch(crm.trigger({ reloadMember: false }))
			})
		}
	}, [reloadMember])

	const handleMember = () => {
		dispatch(crm.trigger({ addMember: 'open', isEditMember: false }))
	}

	const handleEdit = (item) => {
		const assignProject = _projectusers.filter((_item) => _item?.User?._id == item?._id)
		dispatch(crm.trigger({ addMember: 'open', isEditMember: true, activeMemberId: item?._id, memberItem: item, assignProject: assignProject }))
	}

	const getImage = (id) => {
		let url = imageUrls.filter((obj) => obj.id == id)[0]?.url
		return url
	}

	const getImageUrl = async () => {
		let images = []
		await Promise.all(userItem.map(async (item) => {
			const url = "https://proptech-pdi.s3.amazonaws.com/" + item?.profileImage
			images.push({ id: item._id, url: url })
		}))
		setImageUrls(images)
	}

	useEffect(() => {
		setFilteredList(userItem)
		getImageUrl()
	}, [userItem])

	const getProjectName = (id) => {
		const projectName = projectitems?.filter((item) => item._id == id)
		return projectName[0]?.ProjectName
	}

	const handleVerify = async (id) => {
		// const token = storePersist.get('auth')?.current
		// try {
		// 	const response = await axios.post(`https://propcheck.greypath.co/verfication/${id}`, {}, {
		// 		method: 'POST',
		// 		mode: 'no-cors',
		// 		cache: 'no-cache',
		// 		credentials: 'include',
		// 		headers: {
		// 			'Content-Type': 'application/json',
		// 			'Authorization': 'Bearer ' + token
		// 		},
		// 		redirect: 'follow',
		// 		referrerPolicy: 'no-referrer',
		// 	})
		// 	// successHandler(response, {
		// 	// 	notifyOnSuccess: true,
		// 	// 	notifyOnFailed: true,
		// 	// });
		// 	return response.data;
		// } catch (error) {
		// 	return console(error);
		// }
		// // https://propcheck.greypath.co/verfication/${json._id
	}

	const handleReset = () => {

	}

	return (
		<div className="flex flex-col item-center bg-[#F9F9FAFF]" >
			<>
				{addMember == 'open' ? <AddMember /> : ""}
				<header className="flex justify-between h-24 w-full mx-auto px-5 dark:text-white items-center">
					<div className="flex flex-col justify-start w-full" >
						<nav aria-label="breadcrumb" className="w-max" >
							<ol className="flex flex-wrap items-center w-full px-4 py-1 rounded-md bg-blue-gray-50 bg-opacity-60">
								<li
									className="flex items-center font-sans text-sm antialiased font-normal leading-normal transition-colors duration-300 cursor-pointer text-blue-gray-900 hover:text-light-blue-500">
									<h3 className="text-xl font-bold">User Management</h3>
								</li>
							</ol>
						</nav>
						<div className="flex justify-start w-full py-2 mx-3" >
							<button onClick={() => navigate('/inspection-crm/user-management')} className="w-auto mx-1 button  bg-indigo-100 text-blue-600 px-3 rounded  text-xs font-bold"
							> Members
							</button>
							<button
								onClick={() => navigate('/inspection-crm/permissions')}
								className="w-auto button rounded bg-transparent text-black text-opacity-40 px-3 rounded text-xs font-bold"
							> Permission
							</button>
						</div>
					</div>
				</header >
				<div className="flex flex-col  px-3 mx-5 h-[600px] shadow-lg rounded ">
					<div className="flex flex-row items-center  justify-start w-full h-14 my-2  ">
						<form className="mx-3">
							<div className="relative flex w-full flex-wrap items-stretch">
								<span className="z-10 h-full leading-snug font-normal absolute text-center absolute bg-transparent rounded text-base items-center justify-center w-8 pl-3 py-3">
									<svg className="h-4 w-5 text-black border-opacity-40 " viewBox="0 0 24 24" fill="#A9A9A9"><path d="M11 2C15.968 2 20 6.032 20 11C20 15.968 15.968 20 11 20C6.032 20 2 15.968 2 11C2 6.032 6.032 2 11 2ZM11 18C14.8675 18 18 14.8675 18 11C18 7.1325 14.8675 4 11 4C7.1325 4 4 7.1325 4 11C4 14.8675 7.1325 18 11 18ZM19.4853 18.0711L22.3137 20.8995L20.8995 22.3137L18.0711 19.4853L19.4853 18.0711Z"></path></svg>
								</span>
								<input
									type="text"
									placeholder="Search here..."
									value={search}
									onChange={(e) => handleSearch(e)}
									className="border-x border-y px-3 py-2 border-black border-opacity-40 placeholder-black placeholder-opacity-40  text-black text-opacity-60 relative rounded text-sm  w-[300px] pl-10"
								/>
							</div>
						</form>
						<div className="flex justify-end w-36 mx-auto space-x-3 mr-3" >
							<button onClick={() => handleMember()} className="w-auto px-3 button bg-blue-600 text-white rounded ring-1 ring-inset ring-blue-600 text-blue-600 p-2 text-xs font-bold"
							> + Add Member
							</button>
						</div>
					</div>
					<div className="min-h-[450px] w-full overflow-auto mx-3 pr-8">
						<table className="items-center border-collapse w-full ">
							<thead>
								<tr key={'table'}>
									<th key={1}
										className="w-48 align-middle border border-solid py-3 text-xs border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
										User
									</th>
									<th key={2}
										className="w-36 align-middle border border-solid py-3 text-xs  border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
										Role
									</th>
									<th key={3}
										className="w-48 align-middle border border-solid py-3 text-xs  border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
										Email/Mobile
									</th>
									<th key={4}
										className="w-48 align-middle border border-solid py-3 text-xs  border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
										Projects
									</th>
									<th key={5}
										className="w-14 align-middle border border-solid py-3 text-xs  border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ">
										Action
									</th>
								</tr>
							</thead>
							<tbody>
								{filteredList.map((item, idx) => {
									return <tr key={idx} className="even:bg-black even:bg-opacity-5" >
										<td key={1}>
											<span className="flex flex-row w-auto  items-center justify-start align-middle py-3 text-sm whitespace-nowrap text-left" >
												<img
													className="h-9 w-10  rounded-full mx-2"
													src={getImage(item?._id)}
													alt="avatar"
												/>
												<div className="mx-3">{item.firstName} {item.lastName}</div>
											</span>
										</td>
										<td key={2}>
											<span className="w-36  min-w-36 align-middle py-3 text-xs  whitespace-nowrap text-left" >
												{item.roleId?.name}
											</span>
										</td>
										<td key={3}>
											<div className="flex flex-col w-48 min-w-48 justify-start items-start  align-middle space-y-2">
												<span className="text-xs whitespace-nowrap text-left" >
													{item?.email}
												</span>
												<span className="text-xs whitespace-nowrap text-left" >
													{item?.isdCode ? item?.isdCode : ''}	{item?.mobileNumber}
												</span>
											</div>
										</td>
										<td key={4}>
											<span className="w-36 min-w-36 flex flex-wrap align-middle py-1 text-[11px] whitespace-wrap text-left" >
												{_projectusers?.filter((_item) => _item?.User?._id == item?._id).map((obj, i) => {
													return <div key={i} className="w-full flex flex-wrap" >{getProjectName(obj?.ProjectName?._id)}

													</div>
												})}
											</span>
										</td>
										<td key={5} className="w-auto  align-middle item-center cursor-pointer">
											<div className='flex flex-row px-3 justify-between items-center '>
												<svg xmlns="http://www.w3.org/2000/svg" onClick={() => handleEdit(item)} width="14" height="14" viewBox="0 0 24 24"><path d="M19.045 7.401c.378-.378.586-.88.586-1.414s-.208-1.036-.586-1.414l-1.586-1.586c-.378-.378-.88-.586-1.414-.586s-1.036.208-1.413.585L4 13.585V18h4.413L19.045 7.401zm-3-3 1.587 1.585-1.59 1.584-1.586-1.585 1.589-1.584zM6 16v-1.585l7.04-7.018 1.586 1.586L7.587 16H6zm-2 4h16v2H4z" /></svg>

												<div className='flex flex-col justify-center items-start'>
													<span
														onClick={() => handleVerify(item._id)}
														className='text-xs underline text-blue-500 py-1'>Verifiy</span>
													<span
														onClick={() => handleReset()}
														className='text-xs underline text-blue-500 py-1'>Reset</span>
												</div>

											</div>

										</td>
									</tr>
								})}
							</tbody>
						</table>
					</div >
				</div >
			</>
		</div >
	);
}
