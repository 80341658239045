import { createSelector } from 'reselect';

const selectComments = (state) => state.comments;

export const selectCurrentItem = createSelector([selectComments], (comments) => comments.current);

export const selectListItems = createSelector([selectComments], (comments) => comments.list);


export const selectItemById = (itemId) =>
	createSelector(selectListItems, (list) => list.result.items.find((item) => item._id === itemId));

export const selectCreatedItem = createSelector([selectComments], (comments) => comments.create);

export const selectUpdatedItem = createSelector([selectComments], (comments) => comments.update);

export const selectReadItem = createSelector([selectComments], (comments) => comments.read);

export const selectDeletedItem = createSelector([selectComments], (comments) => comments.delete);

export const selectSearchedItems = createSelector([selectComments], (comments) => comments.search);

export const selectItemsCount = createSelector([selectComments], (comments) => comments.count);

export const selectItemsFilter = createSelector([selectComments], (comments) => comments.filter);



