import React, { useState, useRef } from 'react';
import Annotation from 'react-image-annotation';
import {
	PointSelector,
	RectangleSelector,
	OvalSelector
} from 'react-image-annotation/lib/selectors';
import Resizer from "react-image-file-resizer";
import { FaSearchPlus, FaSearchMinus, FaSquare, FaCircle, FaDotCircle, FaHandPointer } from 'react-icons/fa'; // Importing icons
import { request } from "../request";
import { toPng } from 'html-to-image';


const ImageAnnotationComponent = ({ imageViewUrl, setImageView }) => {
	const [type, setType] = useState(RectangleSelector.TYPE);
	const [annotations, setAnnotations] = useState([]);
	const [annotation, setAnnotation] = useState({});
	const imageRef = useRef(null);
	const [zoom, setZoom] = useState(1); // Zoom state
	const onChange = (annotation) => {
		setAnnotation(annotation);
	};

	const handleZoomIn = () => {
		setZoom((prevZoom) => Math.min(prevZoom + 0.1, 3)); // Max zoom level of 3x
	};

	const handleZoomOut = () => {
		setZoom((prevZoom) => Math.max(prevZoom - 0.1, 1)); // Min zoom level of 1x (default size)
	};

	const onSubmit = (annotation) => {
		const { geometry, data } = annotation;
		setAnnotations(annotations.concat({
			geometry,
			data: {
				...data,
				id: Math.random()
			}
		}));

		setAnnotation({});
	};

	const onChangeType = (e) => {
		setAnnotation({});
		setType(e);
	};

	const [allowTouch, setAllowTouch] = useState(true);

	const toggleAllowTouch = () => {
		setAllowTouch(prevAllowTouch => !prevAllowTouch);
	};

	const handleAnnotation = async () => {

		const resizeFile = (file) =>
			new Promise((resolve) => {
				Resizer.imageFileResizer(
					file,
					300,
					300,
					"PNG",
					100,
					0,
					(uri) => {
						resolve(uri);
					},
					"base64"
				);
			});

		const annotationNode = document.getElementById('annotation-container');
		if (annotationNode) {
			try {
				const dataUrl = await toPng(annotationNode, { cacheBust: true });
				const byteString = atob(dataUrl.split(',')[1]);
				const mimeString = dataUrl.split(',')[0].split(':')[1].split(';')[0];
				const ab = new ArrayBuffer(byteString.length);
				const ia = new Uint8Array(ab);

				for (let i = 0; i < byteString.length; i++) {
					ia[i] = byteString.charCodeAt(i);
				}

				const blob = new Blob([ab], { type: mimeString });
				//	const image = await resizeFile(blob);
				const docDetails = {
					extension: "png",
					file_name: "annotation.png",
					mime_type: blob.type,
					entity_type: "annotation",
					size: blob.size,
				};
				const type = blob.type
				let data = await request.uploadurl({ entity: 'document', jsonData: docDetails, options: {} })
				await request.uploadfileAWSS3({ fileuploadurl: data.uploadLink, file: blob, type })
				console.log('fileKey', data.filename)
			} catch (error) {
				console.error('Error generating image:', error);
			}
			finally {
				setImageView(false)
			}
		}
	}

	return (
		<div className="absolute fixed w-auto  h-screen inset-x-0 z-50 bg-black flex flex-col justify-around  ">
			<div className='w-auto mx-3 flex justify-end mt-10 z-30'>
				<svg onClick={() => setImageView(false)} xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="none" stroke="#3b82f6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-x-circle cursor-pointer mx-3"><circle cx="12" cy="12" r="10" /><path d="m15 9-6 6" /><path d="m9 9 6 6" /></svg>
			</div>
			<div className='flex flex-row px-10 justify-around items-start z-30 space-x-3'>
				<button
					onClick={() => onChangeType('RECTANGLE')}
					className="px-4 py-2 bg-blue-500 text-white rounded"
					aria-label="Rectangle"
				>
					<FaSquare size={20} />
				</button>
				<button
					onClick={() => onChangeType('OVAL')}
					className="px-4 py-2 bg-blue-500 text-white rounded"
					aria-label="Oval"
				>
					<FaCircle size={20} />
				</button>
				<button
					onClick={() => onChangeType('POINT')}
					className="px-4 py-2 bg-blue-500 text-white rounded"
					aria-label="Point"
				>
					<FaDotCircle size={20} />
				</button>
				<button
					onClick={() => toggleAllowTouch()}
					className="px-4 py-2 bg-blue-500 text-white rounded"
					aria-label="Touch Interaction"
				>
					<FaHandPointer size={20} />
				</button>
			</div>
			<div ref={imageRef} id="annotation-container" >
				<Annotation
					src={imageViewUrl}
					crossOrigin="Anonymous"
					alt="Two pebbles anthropomorphized holding hands"
					annotations={annotations}
					allowTouch={allowTouch}
					type={type}
					value={annotation}
					onChange={onChange}
					onSubmit={onSubmit}
					style={{
						transform: `scale(${zoom})`,
						transformOrigin: 'center',
						transition: 'transform 0.3s ease-in-out', // Smooth zoom transition
					}}
				/>
			</div>
			<div className='flex flex-row px-10  justify-center items-center z-30 space-x-3'>
				<button
					onClick={handleZoomIn}
					className="px-4 py-2 bg-blue-500 text-white rounded"
					aria-label="Zoom In"
				>
					<FaSearchPlus size={20} />
				</button>
				<button
					onClick={handleZoomOut}
					className="px-4 py-2 bg-blue-500 text-white rounded"
					aria-label="Zoom Out"
				>
					<FaSearchMinus size={20} />
				</button>
			</div>
			<div className='w-auto mx-3 flex justify-end text-xl  z-30'>
				<div
					onClick={() => handleAnnotation()}
					className='w-max px-2 cursor-pointer mx-6 outline outline-blue-500  text-white bg-blue-500 font-bold mb-10 py-1'>Save</div>
			</div>
		</div>
	);
};

export default ImageAnnotationComponent;
