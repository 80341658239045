import { combineReducers } from 'redux';
import { reducer as authReducer } from './auth';
import { reducer as crudReducer } from './crud';
import { reducer as notifyReducer } from './notify';
import * as actionTypes from './auth/types';
import crmReducer from './crm/reducer';
import projectcardsReducer from './projectcards/reducer';
import customerdetailsReducer from './customerdetails/reducer';
import inspectioncardsReducer from './inspectioncards/reducer';
import inspectionstatusReducer from './inspectionstatus/reducer';
import inspectionstageReducer from './inspectionstage/reducer';
import issuestatusReducer from './issuestatus/reducer';
import issuecardsReducer from './issuecards/reducer';
import categorynameReducer from './categoryname/reducer';
import issuepriorityReducer from './issuepriority/reducer';
import projecttypeReducer from './projecttype/reducer';
import projectstatusReducer from './projectstatus/reducer';
import typeofhouseReducer from './typeofhouse/reducer';
import blocktowerReducer from './blocktower/reducer';
import numberofbedroomsReducer from './numberofbedrooms/reducer';
import masterchecklistReducer from './masterchecklist/reducer';
import documentReducer from './documents/reducer';
import usersReducer from './users/reducer';
import rolesReducer from './roles/reducer';
import permissionsReducer from './permissions/reducer';
import masterchecklistitemsReducer from './masterchecklistitems/reducer'
import projectchecklistReducer from './projectchecklist/reducer'
import projectusersReducer from './projectusers/reducer'
import projectaddonReducer from './projectaddon/reducer'
import commentsReducers from './comments/reducer'
import subscribersReducer from './subscribers/reducer';
import issuechangetypeReducer from './issuechangetype/reducer';


const appReducer = combineReducers({
	auth: authReducer,
	notify: notifyReducer,
	crud: crudReducer,
	crm: crmReducer,
	document: documentReducer,
	issuepriority: issuepriorityReducer,
	projecttype: projecttypeReducer,
	issuechangetype: issuechangetypeReducer,
	projectstatus: projectstatusReducer,
	typeofhouse: typeofhouseReducer,
	numberofbedrooms: numberofbedroomsReducer,
	inspectionstatus: inspectionstatusReducer,
	inspectionstage: inspectionstageReducer,
	projectcards: projectcardsReducer,
	projectchecklist: projectchecklistReducer,
	customerdetails: customerdetailsReducer,
	inspectioncards: inspectioncardsReducer,
	issuestatus: issuestatusReducer,
	categoryname: categorynameReducer,
	comments: commentsReducers,
	issuecards: issuecardsReducer,
	roles: rolesReducer,
	masterchecklist: masterchecklistReducer,
	users: usersReducer,
	subscribers: subscribersReducer,
	blocktower: blocktowerReducer,
	permissions: permissionsReducer,
	masterchecklistitems: masterchecklistitemsReducer,
	projectusers: projectusersReducer,
	projectaddon: projectaddonReducer
});

const rootReducer = (state, action) => {
	if (action.type === actionTypes.AUTH_LOGOUT_SUCCESS) {
		state = undefined;
	}
	return appReducer(state, action);
};

export default rootReducer;
