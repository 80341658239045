import React, { useRef, useState, useEffect } from "react";
import { FaTrash } from "react-icons/fa6";
import { FaArrowCircleRight } from "react-icons/fa";
import { MdFlipCameraAndroid } from "react-icons/md";
import { crm } from "../redux/crm/actions";
import { useDispatch } from "react-redux";
const CameraCapture = ({ setImage }) => {
	const videoRef = useRef(null);
	const [stream, setStream] = useState(null);
	const [capturedImages, setCapturedImages] = useState([]);
	const [isCameraOn, setIsCameraOn] = useState(true);
	const [currentImage, setCurrentImage] = useState(null);
	const [usingFrontCamera, setUsingFrontCamera] = useState('user');
	const dispatch = useDispatch();

	useEffect(() => {
		if (isCameraOn) {
			startVideo();
		} else {
			stopVideo();
		}

		return () => {
			stopVideo();
		};
	}, [isCameraOn, usingFrontCamera]);

	const startVideo = async () => {
		try {
			const mediaStream = await navigator.mediaDevices.getUserMedia({
				video: { facingMode: usingFrontCamera },
			});
			videoRef.current.srcObject = mediaStream;
			setStream(mediaStream);
		} catch (error) {
			console.error("Error accessing the camera", error);
		}
	};

	const stopVideo = () => {
		if (stream) {
			stream.getTracks().forEach((track) => track.stop());
			if (videoRef.current) {
				videoRef.current.srcObject = null;
			}
			setStream(null);
		}
	};

	const capturePhoto = () => {
		const canvas = document.createElement("canvas");
		canvas.width = videoRef.current.videoWidth;
		canvas.height = videoRef.current.videoHeight;
		const context = canvas.getContext("2d");
		context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);

		canvas.toBlob(
			(blob) => {

				if (blob) {
					const imageData = URL.createObjectURL(blob);
					const img = new Image();
					img.onload = () => {
						const imageDetails = {
							extension: "jpg",
							file_name: "imageFile",
							mime_type: blob.type,
							size: blob.size,
							entity_type: "image",
							src: blob,
							img: imageData,
						};

						setCapturedImages((prevImages) => [imageDetails, ...prevImages]);
					};
					img.src = imageData;

				}

			},
			"image/jpeg",
			0.95
		);
	};

	const removeImage = (index) => {
		setCapturedImages((prevImages) => prevImages.filter((_, i) => i !== index));
	};

	const toggleCamera = () => {
		stopVideo();
		setUsingFrontCamera(prevCamera => prevCamera === 'user' ? 'environment' : 'user');
	};

	const closeCamera = () => {
		setImage(capturedImages);
		setIsCameraOn(false);
		dispatch(crm.trigger({ isCameraOn: false }));
		stopVideo();
	};

	return (
		<div className="flex flex-col  relative min-h-screen p-6">
			<div className="flex justify-end h-[10%] items-center cursor-pointer" onClick={() => dispatch(crm.trigger({ isCameraOn: false }))}>
				<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-x-circle">
					<circle cx="12" cy="12" r="10" />
					<path d="m15 9-6 6" />
					<path d="m9 9 6 6" />
				</svg>
			</div>
			<div className=" h-[90%]  flex flex-col items-center justify-center">

				<div>
					{isCameraOn && (
						<>
							<div className="relative">
								<video
									ref={videoRef}
									autoPlay
									playsInline
									className="max-w-full h-auto"
								></video>
								<div>
									{videoRef.current && (
										<button
											onClick={toggleCamera}
											className="absolute bottom-0 right-0 p-2 text-white rounded-full z-10"
										>
											<MdFlipCameraAndroid size={"50px"} />
										</button>
									)}
								</div>
							</div>

							<div className="absolute bottom-4 inset-x-0 flex justify-center">
								<button
									className="bg-white rounded-full w-16 h-16 flex justify-center items-center shadow-lg"
									onClick={capturePhoto}
								>
									<div className="bg-red-500 rounded-full w-12 h-12 "></div>
								</button>

								<button
									onClick={closeCamera}
									className="ml-4 bg-white rounded-full w-16 h-16 flex justify-center items- shadow-lg "
								>
									<FaArrowCircleRight size={"65px"} />
								</button>
							</div>
						</>
					)}

					{currentImage && (
						<div className="fixed inset-0 bg-black bg-opacity-80 flex items-center justify-center p-4">
							<img src={currentImage} className="max-w-full max-h-full" alt="currentIMG" />
							<button
								onClick={() => {
									setCurrentImage(null);
									setIsCameraOn(true);
								}}
								className="absolute top-4 right-4 text-white text-xl"
							>
								<svg
									width="50px"
									height="50px"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<g id="SVGRepo_bgCarrier" strokeWidth="0" />

									<g
										id="SVGRepo_tracerCarrier"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>

									<g id="SVGRepo_iconCarrier">
										{" "}
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM8.96963 8.96965C9.26252 8.67676 9.73739 8.67676 10.0303 8.96965L12 10.9393L13.9696 8.96967C14.2625 8.67678 14.7374 8.67678 15.0303 8.96967C15.3232 9.26256 15.3232 9.73744 15.0303 10.0303L13.0606 12L15.0303 13.9696C15.3232 14.2625 15.3232 14.7374 15.0303 15.0303C14.7374 15.3232 14.2625 15.3232 13.9696 15.0303L12 13.0607L10.0303 15.0303C9.73742 15.3232 9.26254 15.3232 8.96965 15.0303C8.67676 14.7374 8.67676 14.2625 8.96965 13.9697L10.9393 12L8.96963 10.0303C8.67673 9.73742 8.67673 9.26254 8.96963 8.96965Z"
											fill="#EF4444"
										/>{" "}
									</g>
								</svg>
							</button>
						</div>
					)}
					<div className="image-scroll-container">
						{currentImage === null &&
							capturedImages.map((img, index) => (
								<div key={index} className="image-item">
									<img
										src={img.img}
										alt={`Captured ${index}`}
										className="w-full h-full object-cover"
										onClick={() => {
											setCurrentImage(img.img);
											setIsCameraOn(false);
										}}
									/>
									<button
										onClick={() => removeImage(index)}
										className="remove-button"
									>
										<FaTrash size={"20px"} color="#EF4444" />
									</button>
								</div>
							))}
					</div>
				</div>
			</div>
		</div>
	);
};

const CameraComponent = ({ setImage }) => {
	useEffect(() => {
	}, []);
	return <CameraCapture setImage={setImage} />;
};

export default React.memo(CameraComponent);
