import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { issuecards } from "../../redux/issuecards/actions";
import { crm } from "../../redux/crm/actions";
import { selectCurrentCRM } from "../../redux/crm/selectors"
import { useNavigate } from 'react-router-dom';
import InfiniteScroll from "react-infinite-scroll-component";
import { Bars } from 'react-loader-spinner';
import { selectListItems as selectStageListItems } from '../../redux/inspectionstage/selectors';
import { selectListItems as selectStatusListItems } from '../../redux/inspectionstatus/selectors';
import { selectItemsFilter } from '../../redux/issuecards/selectors';
import _ from 'lodash'
import Moment from 'moment';
import { request } from '../../request';
import storePersist from "../../redux/storePersist";


export default function Tasks() {
	const dispatch = useDispatch();
	const navigate = useNavigate()
	const [imageUrls, setImageUrls] = useState([])
	const [hasMore, setHasMore] = useState(true);
	const [index, setIndex] = useState(1)
	const [attachmentList, setAttachmentList] = useState([])
	const [updatedItems, setUpdatedItems] = useState([])
	const { activeCategoryName, issueReload, activeCustomerId, clearInfiniteScroll } = useSelector(selectCurrentCRM)
	const { result: stageResult } = useSelector(selectStageListItems)
	const { result: statusResult } = useSelector(selectStatusListItems)
	const { result } = useSelector(selectItemsFilter);
	const { items } = result ? result : []
	const { items: stageitems } = stageResult;
	const { items: statusitems } = statusResult;

	const handleStatusFilter = () => {

	}

	const getImageUrl = async () => {
		let images = []
		await Promise.all(items.map(async (item) => {
			if (item?.Attachments.length > 0) {
				const url = "https://proptech-pdi.s3.amazonaws.com/" + item?.Attachments[0][0]?.fileKey
				images.push({ id: item._id, url: url })
			}
			else {
				images.push({ id: item._id, url: "https://proptech-pdi.s3.amazonaws.com/No_image_available.svg" })
			}
		}))
		setImageUrls((prevItems) => [...prevItems, ...images])
	}

	const getImage = (id) => {
		let url
		if (id != "") {
			url = imageUrls.filter((obj) => obj.id == id)[0]?.url
		}
		else {
			url = "https://proptech-pdi.s3.amazonaws.com/No_image_available.svg"
		}
		return url
	}

	useEffect(() => {
		getImageUrl()
		setUpdatedItems((prevItems) => [...prevItems, ...items]);
		items.length > 0 ? setHasMore(true) : setHasMore(false);
	}, [items])

	useEffect(() => {
		if (clearInfiniteScroll) {
			setUpdatedItems([])
			dispatch(crm.trigger({ clearInfiniteScroll: false }))
		}
	}, [clearInfiniteScroll])

	useEffect(() => {
		if (issueReload) {
			const _user = storePersist.get('user')
			dispatch(issuecards.filter({ entity: 'PDI_Issue_Cards', jsonData: { params: { CRMExecutive: _user?._id, Customer: activeCustomerId }, page: index, limit: 5 } }))
			dispatch(crm.trigger({ issueReload: false }))
		}
	}, [issueReload])

	const handleStageFilter = () => {

	}

	const handleViewIssue = (item) => {
		dispatch(crm.trigger({ selectedCheckListItem: item, clearInfiniteScroll: true }))
		setUpdatedItems([])
		navigate('/mobile/view-issue')
	}

	const fetchMoreData = () => {
		const prevIndex = index
		setIndex(prevIndex + 1);
	};

	useEffect(() => {
		const _user = storePersist.get('user')
		if (_user?.roleId?._id == '65dfe45c6ad846404c6cc572') {
			dispatch(issuecards.filter({ entity: 'PDI_Issue_Cards', jsonData: { params: { CRMExecutive: _user?._id, Customer: activeCustomerId }, page: index, limit: 5 } }))
		}
		else {
			dispatch(issuecards.filter({ entity: 'PDI_Issue_Cards', jsonData: { params: { SiteEngineer: _user?._id, Customer: activeCustomerId }, page: index, limit: 5 } }))
		}
	}, [index])

	return (
		<>
			<div className="max-h-screen w-full bg-[#F9F9FA]">
				<header className="flex fixed top-0 left-0  justify-center w-full h-20 items-center bg-[#3C50E0FF] text-white">
					<div className="flex flex-row text-xl font-bold">
						<button onClick={() => navigate('/mobile/inspection-items')}
							className="absolute fixed left-10 cursor-pointer" >
							<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#ffffff" viewBox="0 0 24 24"><path d="M21 11H6.414l5.293-5.293-1.414-1.414L2.586 12l7.707 7.707 1.414-1.414L6.414 13H21z" /></svg>
						</button>
						Issue List
					</div>
				</header >
				<div className="flex flex-col pt-24 pb-24 px-3 w-screen h-full">
					<div className="flex flex-row justify-between items-center">
						<div className="text-sm">Snag list</div>
						<div className="flex">
							<div className="flex justify-end w-20 mx-1 bg-[#F9F9FAFF]" >
								<div className="border-x border-y border-black border-opacity-40  py-1  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 bg-[#F9F9FAFF]" >
									<select
										className="w-full bg-[#F9F9FAFF] text-xs"
										onChange={(e) => handleStageFilter(e)} >
										<option value={1} key={1}> &nbsp; Status </option>
										{stageitems.map((item, index) => {
											return <option value={item._id} key={index}> {item.Name}</option>
										})}
									</select>
								</div>
							</div>
							<div className="flex justify-end w-20  mx-1" >
								<div className="border-x border-y border-black border-opacity-40   py-1  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" >
									<select
										className="w-full bg-[#F9F9FAFF] text-xs"
										onChange={(e) => handleStatusFilter(e)} >
										<option value={1} key={1}> &nbsp; Filter
											<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M7 11h10v2H7zM4 7h16v2H4zm6 8h4v2h-4z" /></svg>
										</option>
										{statusitems.map((item, index) => {
											return <option value={item._id} key={index}> {item.Name}</option>
										})}
									</select>
								</div>
							</div>
						</div>
					</div>
					<InfiniteScroll
						dataLength={updatedItems.length}
						next={fetchMoreData}
						hasMore={hasMore}
					// loader={<Bars />}
					>
						<div className=" w-full pb-24">
							{updatedItems.length == 0 &&
								<div className="flex flex-col w-screen h-screen justify-start items-center bg-white">
									<div className="text-xl py-2 w-48 font-bold text-center my-20">No Issues Have Been Added</div>
								</div>
							}
							{updatedItems.map((item, index) => {
								return <div key={index} className="bg-white border-x border-y p-2 my-2 rounded border-black">
									<div
										onClick={() => handleViewIssue(item)}
										className="flex flex-row cursor-pointer">
										<div className={`w-2 ${item?.Status?.Name == 'Todo' ? "bg-[#4069E5]" : item?.Status?.Name == 'In Progress' ? "bg-[#8353E2]" :
											"bg-green-400"}`}>
										</div>
										<div className="mx-2 w-2/3">
											<div className="text-sm font-bold py-2">{item?.IssueNumber} - {item?.IssueName}</div>
											<div className="text-xs font-light py-2">{item?.CategoryName?.Name}</div>
											<div className="flex flex-row items-center justify-start space-x-5">
												<div className={`text-[11px] font-semibold  rounded-xl px-2 capitalize ${item?.Status?.Name == 'Todo' ? "bg-[#4069E5] text-white" : item?.Status?.Name == 'In Progress' ? "bg-[#8353E2] text-white" :
													"bg-green-400 text-black"}`}> {item?.Status?.Name}</div>
												<div className="text-xs font-semibold">{Moment(item?.ExpectedCompletionDate).format('DD-MMM-YY')}</div>
											</div>
										</div>
										<div className="w-1/3 flex justify-end items-center">
											<div className="flex flex-row jusity-center item-center h-max" >
												<div className="w-28 p-1 pt-1 cursor-pointer">
													<img src={getImage(item._id)} className="object-scale-down  h-32 w-32 shadow-xl p-1 bg-white rounded " />
												</div>
											</div>
										</div>
									</div>
								</div>
							})}
						</div>
					</InfiniteScroll >
				</div>
				<footer className="flex fixed bottom-0 left-0 flex-row w-full justify-center min-h-20 pb-2 item-center bg-[#3C50E0FF] text-white">
					<button
						onClick={() => navigate('/mobile/project-board')}
						className="w-1/4  flex flex-col justify-center   items-center ">
						<svg xmlns="http://www.w3.org/2000/svg" height="32" width="32" fill="#ffffff" viewBox="0 0 24 24" ><path d="M19 21H5C4.44772 21 4 20.5523 4 20V11L1 11L11.3273 1.6115C11.7087 1.26475 12.2913 1.26475 12.6727 1.6115L23 11L20 11V20C20 20.5523 19.5523 21 19 21ZM13 19H18V9.15745L12 3.7029L6 9.15745V19H11V13H13V19Z"></path></svg>
						<span className="text-xs">Home</span>
					</button>
					<button className="w-1/4  flex flex-col justify-center  items-center ">
						<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#ffffff" viewBox="0 0 256 256"><path d="M216,48H40A16,16,0,0,0,24,64V224a15.85,15.85,0,0,0,9.24,14.5A16.13,16.13,0,0,0,40,240a15.89,15.89,0,0,0,10.25-3.78.69.69,0,0,0,.13-.11L82.5,208H216a16,16,0,0,0,16-16V64A16,16,0,0,0,216,48ZM40,224h0ZM216,192H82.5a16,16,0,0,0-10.3,3.75l-.12.11L40,224V64H216ZM88,112a8,8,0,0,1,8-8h64a8,8,0,0,1,0,16H96A8,8,0,0,1,88,112Zm0,32a8,8,0,0,1,8-8h64a8,8,0,1,1,0,16H96A8,8,0,0,1,88,144Z"></path></svg>
						<span className="text-xs">Chats</span>
					</button>
					<button className="w-1/4  flex  flex-col justify-center items-center ">
						<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#ffffff" viewBox="0 0 24 24"><path d="M19 13.586V10c0-3.217-2.185-5.927-5.145-6.742C13.562 2.52 12.846 2 12 2s-1.562.52-1.855 1.258C7.185 4.074 5 6.783 5 10v3.586l-1.707 1.707A.996.996 0 0 0 3 16v2a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1v-2a.996.996 0 0 0-.293-.707L19 13.586zM19 17H5v-.586l1.707-1.707A.996.996 0 0 0 7 14v-4c0-2.757 2.243-5 5-5s5 2.243 5 5v4c0 .266.105.52.293.707L19 16.414V17zm-7 5a2.98 2.98 0 0 0 2.818-2H9.182A2.98 2.98 0 0 0 12 22z" /></svg>
						<span className="text-xs">Notifications</span>
					</button>
					<button className="w-1/4  flex flex-col justify-center  items-center ">
						<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#ffffff" viewBox="0 0 256 256"><path d="M201.89,54.66A103.43,103.43,0,0,0,128.79,24H128A104,104,0,0,0,24,128v56a24,24,0,0,0,24,24H64a24,24,0,0,0,24-24V144a24,24,0,0,0-24-24H40.36A88.12,88.12,0,0,1,190.54,65.93,87.39,87.39,0,0,1,215.65,120H192a24,24,0,0,0-24,24v40a24,24,0,0,0,24,24h24a24,24,0,0,1-24,24H136a8,8,0,0,0,0,16h56a40,40,0,0,0,40-40V128A103.41,103.41,0,0,0,201.89,54.66ZM64,136a8,8,0,0,1,8,8v40a8,8,0,0,1-8,8H48a8,8,0,0,1-8-8V136Zm128,56a8,8,0,0,1-8-8V144a8,8,0,0,1,8-8h24v56Z"></path></svg>
						<span className="text-xs">Help</span>
					</button>
				</footer>
			</div >
		</>

	);
}
