export const INSPECTION_STATUS_RESET_STATE = 'INSPECTION_STATUS_RESET_STATE';
export const INSPECTION_STATUS_CURRENT_ITEM = 'INSPECTION_STATUS_CURRENT_ITEM';

export const INSPECTION_STATUS_REQUEST_LOADING = 'INSPECTION_STATUS_REQUEST_LOADING';
export const INSPECTION_STATUS_REQUEST_SUCCESS = 'INSPECTION_STATUS_REQUEST_SUCCESS';
export const INSPECTION_STATUS_REQUEST_FAILED = 'INSPECTION_STATUS_REQUEST_FAILED';

export const INSPECTION_STATUS_CURRENT_ACTION = 'INSPECTION_STATUS_CURRENT_ACTION';
export const INSPECTION_STATUS_RESET_ACTION = 'INSPECTION_STATUS_RESET_ACTION';

export const INSPECTION_STATUS_NAME_EXISTS_ACTION = 'INSPECTION_STATUS_NAME_EXISTS_ACTION';
export const INSPECTION_STATUS_RESET_NAME_EXISTS = 'INSPECTION_STATUS_RESET_NAME_EXISTS';
