import { crm } from "../../redux/crm/actions";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash'
import { useNavigate } from 'react-router-dom';
import { selectCurrentCRM } from '../../redux/crm/selectors';
import { selectListItems as selectSetPriorityList } from '../../redux/issuepriority/selectors';
import { selectListItems as selectUserList } from '../../redux/users/selectors';
import { selectItemsFilter as projectUserFiter } from '../../redux/projectusers/selectors';
import { selectListItems as selectIssueList, selectCreatedItem, selectUpdatedItem } from '../../redux/issuecards/selectors';
import { users } from "../../redux/users/actions";
import { issuestatus } from "../../redux/issuestatus/actions";
import { issuepriority } from "../../redux/issuepriority/actions";
import { issuecards } from "../../redux/issuecards/actions";
import UploadImage from "../../modals/UploadImage";
import { useForm } from "react-hook-form";
import { projectusers } from "../../redux/projectusers/actions";
import storePersist from "../../redux/storePersist";
import CameraComponent from "../../modals/CameraCapture";
import { db } from '../../services/db.js'
import { useLiveQuery } from "dexie-react-hooks";

const getOnLineStatus = () =>
	typeof navigator !== 'undefined' && typeof navigator.onLine === 'boolean'
		? navigator.onLine
		: true;

export default function AddIssueMobile() {
	const dbcache = useLiveQuery(() => db.issuecards_table.toArray(), []);
	const { result: projectUserResult } = useSelector(projectUserFiter)
	const { items: projectUser } = projectUserResult
	const dispatch = useDispatch();
	const navigate = useNavigate()
	const [networkStatus, setNetworkStatus] = useState(getOnLineStatus());
	const setOffline = () => setNetworkStatus(false);
	const setOnline = () => setNetworkStatus(true);
	const { register, handleSubmit, clearErrors, formState: { errors }, setValue, getValues } = useForm()
	const [image, setImage] = useState([])
	const { activeIssueImageKey, uploadImage, activeCategoryNameId, projectSiteEngineer, activeChecklistId,
		isCameraOn, isEditIssue, issueItem, selectedCheckListItem, activeIssueId, activeProjectId,
		activeCustomerId, assignCRM, assignSiteEngineer, activeInspectionDescription } = useSelector(selectCurrentCRM);
	const { isSuccess: isCreatedSuccess } = useSelector(selectCreatedItem)
	const { isSuccess: isUpdatedSuccess } = useSelector(selectUpdatedItem)
	const { result: userResult } = useSelector(selectUserList)
	const { items: useritems } = userResult
	const { result: priorityResult } = useSelector(selectSetPriorityList)
	const { items: priorityitems } = priorityResult
	const { result: issueResult } = useSelector(selectIssueList)
	const { items: issueitems } = issueResult
	const [steps, setSteps] = useState({});
	const [userIds, setUserIds] = useState([])

	useEffect(() => {
		window.scrollTo(0, 0);
		window.addEventListener('online', setOnline);
		window.addEventListener('offline', setOffline);
		return () => {
			window.removeEventListener('online', setOnline);
			window.removeEventListener('offline', setOffline);
		};
	}, [])

	useEffect(() => {
		if (networkStatus) {
			db.issuecards_table.count().then((count) => {
				dbcache?.map((obj) => {
					db.issuecards_table.delete(obj.id)
					if (obj.activeid) {
						dispatch(issuecards.resetAction({ actionType: 'update' }))
						dispatch(issuecards.update({
							entity: 'PDI_Issue_Cards', id: obj.activeid, jsonData: obj?.data
						}))
					}
					else {
						dispatch(issuecards.resetAction({ actionType: 'create' }))
						dispatch(issuecards.create({
							entity: 'PDI_Issue_Cards', jsonData: obj?.data
						}))
					}

				})
			})
		}
	}, [networkStatus])

	useEffect(() => {
		if (isCreatedSuccess) {
			setSteps({})
			const user = storePersist.get('user')
			if (user?.roleId?.name == "Site Engineer") {
				navigate('/mobile/tasks')
			} else {
				navigate('/mobile/task-items')
			}
			dispatch(issuecards.resetAction({ actionType: 'create' }))
		}
	}, [isCreatedSuccess])

	useEffect(() => {
		if (isUpdatedSuccess) {
			setSteps({})
			const user = storePersist.get('user')
			if (user?.roleId?.name == "Site Engineer") {
				navigate('/mobile/tasks')
			} else {
				navigate('/mobile/task-items')
			}
			dispatch(issuecards.resetAction({ actionType: 'update' }))
		}
	}, [isUpdatedSuccess])

	const handleCancel = () => {
		navigate('/mobile/project-board')
	}

	useEffect(() => {
		if (projectSiteEngineer.length > 0) {
			setUserIds(projectSiteEngineer)
		}
	}, [projectSiteEngineer])

	useEffect(() => {
		if (networkStatus) {
			dispatch(projectusers.filter({ entity: 'PDI_Project_Users', jsonData: { params: { isDeleted: false, ProjectName: activeProjectId } }, page: 1, limit: 200 }))
		}
	}, [])

	const handleSave = async () => {
		if (isEditIssue) {
			dispatch(issuecards.resetAction({ actionType: 'update' }))
			dispatch(issuecards.update({
				entity: 'PDI_Issue_Cards',
				id: activeIssueId,
				jsonData: {
					entity: {
						"IssueName": steps['IssueName'],
						"CRMExecutive": steps['CRMExecutive'],
						"SiteEngineer": steps['SiteEngineer'],
						"Status": steps['Status'],
						"Priority": steps['Priority'],
						"StartDate": getValues('StartDate'),
						"ExpectedCompletionDate": getValues('ExpectedCompletionDate'),
						"Description": steps['Description'],
						"Comment": steps['Comment'],
						"Project": activeProjectId,
						"Customer": activeCustomerId,
						"CategoryName": issueitems?.CategoryName?._id,
						"ChecklistItem": selectedCheckListItem
					}
				}
			}))
		}
		else {
			dispatch(issuecards.resetAction({ actionType: 'create' }))
			dispatch(issuecards.create({
				entity: 'PDI_Issue_Cards',
				jsonData: {
					entity: {
						"IssueName": steps['IssueName'],
						"CRMExecutive": steps['CRMExecutive'],
						"SiteEngineer": steps['SiteEngineer'],
						"Status": '659cdc96cbe502001aafb6e9',
						"Priority": steps['Priority'],
						"StartDate": new Date(steps['StartDate']),
						"ExpectedCompletionDate": new Date(steps['ExpectedCompletionDate']),
						"Description": steps['Description'],
						"Comment": 'NA',
						"Project": activeProjectId,
						"Customer": activeCustomerId,
						"ImageUrl": [],
						"Attachments": [],
						"CategoryName": activeCategoryNameId,
						"ChecklistItem": activeChecklistId
					}
				}
			}))
		}
	}

	useEffect(() => {
		if (!isEditIssue) {
			setValue('IssueName', activeInspectionDescription)
			setValue('CRMExecutive', assignCRM)
			setValue('SiteEngineer', assignSiteEngineer)
		}
	}, [assignCRM])

	useEffect(() => {
		if (isEditIssue) {
			setValue('IssueNumber', issueItem?.IssueNumber)
			setValue('IssueName', issueItem?.IssueName)
			setValue('CRMExecutive', issueItem?.CRMExecutive?._id)
			setValue('SiteEngineer', issueItem?.SiteEngineer?._id)
			setValue('Description', issueItem?.Description)
			setValue('Status', issueItem?.Status?._id)
			setValue('Priority', issueItem?.Priority?._id)
			setValue('StartDate', issueItem?.StartDate?.slice(0, 10))
			setValue('ExpectedCompletionDate', issueItem?.ExpectedCompletionDate?.slice(0, 10))
		}
	}, [isEditIssue, userIds])

	useEffect(() => {
		const _userIds = []
		Promise.all(projectUser.map((item) => {
			_userIds.push(item.User._id)
		}))
		if (_userIds.length > 0) {
			dispatch(crm.trigger({ projectSiteEngineer: _userIds }))
		}
		setUserIds(_userIds)
	}, [projectUser])

	const handleSteps = (e, key) => {
		setSteps({ ...steps, [key]: e.target.value })
	}

	useEffect(() => {
		if (networkStatus) {
			if (!isEditIssue) {
				dispatch(issuecards.list({ entity: 'PDI_Issue_Cards', options: {} }))
			}
			dispatch(users.list({ entity: 'users', options: {} }))
			dispatch(issuestatus.list({ entity: 'PDI_Issue_Status', options: {} }))
			dispatch(issuepriority.list({ entity: 'PDI_Issue_Priority', options: {} }))
			clearErrors('IssueName')
			clearErrors('CRMExecutive')
			clearErrors('Status')
			clearErrors('StartDate')
			clearErrors('ExpectedCompletionDate')
			clearErrors('Description')
		}
	}, [])

	useEffect(() => {
		if (!isEditIssue) {
			setSteps({ ...steps, ['SiteEngineer']: assignSiteEngineer, ['CRMExecutive']: assignCRM, ['IssueName']: activeInspectionDescription })
		}
	}, [isEditIssue])

	return (
		<>
			<div className="max-h-screen w-screen bg-[#F9F9FA]">
				<header className="flex fixed top-0 left-0  justify-center z-20 w-full h-20 items-center bg-[#3C50E0FF] text-white">
					<div className="flex flex-row text-xl font-bold">
						<button
							onClick={() => navigate(-1)}
							className="absolute fixed left-10 cursor-pointer">
							<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#ffffff" viewBox="0 0 24 24"><path d="M21 11H6.414l5.293-5.293-1.414-1.414L2.586 12l7.707 7.707 1.414-1.414L6.414 13H21z" /></svg>
						</button>
						{isEditIssue ? 'Edit ' : 'Add '} Issue
					</div>
				</header >
				<div className="flex flex-col pt-20 p-3 h-screen pb-20 overflow-auto ">
					<div className="w-full ">
						<form onSubmit={handleSubmit(handleSave)}>
							<div className="px-4 my-q pt-5 space-y-4">
								<div className="flex flex-row space-x-4">
									<div className="relative w-full ">
										<label
											className="block  text-xs font-bold mb-2"
											htmlFor="grid-password">
											Issue Name
										</label>
										<div className="border-0  bg-[#F3F4F6FF] text-xs " >
											<input
												type="text"
												placeholder="Name of Issue"
												id="IssueName"
												name="IssueName"
												{...register('IssueName', { required: true, maxLength: 300 })}
												className="w-full px-3 py-1 bg-[#F3F4F6FF] text-xs"
												onChange={(e) => handleSteps(e, 'IssueName')}
											/>
										</div>
										{errors.IssueName && errors.IssueName.type === "required" && (
											<span role="alert" className="w-full text-red-500 bg-white text-xs my-1">Please fill in the issue name field.</span>
										)}
										{errors.IssueName && errors.IssueName.type === "maxLength" && (
											<span role="alert" className="w-full text-red-500 bg-white text-xs my-1">Max length exceeded</span>
										)}
									</div>
								</div>
								<div className="flex flex-row space-x-4">
									<div className="relative w-full">
										<label
											className="block text-xs font-bold mb-2"
											htmlFor="grid-password" >
											QC/Engineer
										</label>
										<div className="px-3 border-0  bg-[#F3F4F6FF] text-xs " >
											<select
												className="w-full  py-1 bg-[#F3F4F6FF] text-xs"
												id="SiteEngineer"
												name="SiteEngineer"
												{...register('SiteEngineer', { required: true })}
												onChange={(e) => handleSteps(e, 'SiteEngineer')}
											>
												<option value="">--Select--</option>
												{useritems?.filter(item1 => userIds?.some(item2 => item2 == item1._id))?.filter((obj) => obj?.roleId?._id == '65dfe4766ad846404c6cc573')?.map((item, index) => {
													return <option value={item._id} key={index}> {item.userId}</option>
												})}
											</select>
										</div>
										{errors.SiteEngineer && errors.SiteEngineer.type === "required" && (
											<span role="alert" className="w-full text-red-500 bg-white text-xs my-1">Please select QC/Engineer.</span>
										)}
									</div>
								</div>
								<div className="flex flex-row space-x-4">
									<div className="relative w-full">
										<label
											className="block text-xs font-bold mb-2"
											htmlFor="grid-password">
											Set Priority
										</label>
										<div className="px-3 border-0  bg-[#F3F4F6FF] text-xs " >
											<select
												className="w-full py-1 bg-[#F3F4F6FF] text-xs"
												id="Priority"
												name="Priority"
												{...register('Priority', { required: true })}
												onChange={(e) => handleSteps(e, 'Priority')}
											>
												<option value="" >--Select--</option>
												{priorityitems.map((item, index) => {
													return <option value={item._id} key={index}> {item.Name}</option>
												})}
											</select>
										</div>
										{errors.Priority && errors.Priority.type === "required" && (
											<span role="alert" className="w-full text-red-500 bg-white text-xs my-1">Please select priority field.</span>
										)}
									</div>
								</div>
								<div className="text-md font-bold border-t-2 pt-2 border-black border-opacity-10"> Timeline</div>
								<div className="flex flex-row space-x-4">
									<div className="relative w-full">
										<label
											className="block text-xs font-bold mb-2"
											htmlFor="grid-password" >
											Start Date
										</label>
										<div className="px-3 border-0  bg-[#F3F4F6FF] text-xs " >
											<input
												type="date"
												id="StartDate"
												name="StartDate"
												{...register('StartDate', { required: true })}
												placeholder=""
												className="w-full py-1 bg-[#F3F4F6FF] text-xs"
												onChange={(e) => handleSteps(e, 'StartDate')}
											/>
										</div>
										{errors.StartDate && errors.StartDate.type === "required" && (
											<span role="alert" className="w-full text-red-500 bg-white text-xs my-1">Please select start date field.</span>
										)}
									</div>
								</div>
								<div className="flex flex-row space-x-4">
									<div className="relative w-full">
										<label
											className="block text-xs font-bold mb-2"
											htmlFor="grid-password">
											Expected Completion Date
										</label>
										<div className="px-3 border-0  bg-[#F3F4F6FF] text-xs" >
											<input
												type="date"
												id="ExpectedCompletionDate"
												name="ExpectedCompletionDate"
												{...register('ExpectedCompletionDate', { required: true })}
												className="w-full py-1 bg-[#F3F4F6FF] text-xs"
												onChange={(e) => handleSteps(e, 'ExpectedCompletionDate')}
											/>
										</div>
										{errors.ExpectedCompletionDate && errors.ExpectedCompletionDate.type === "required" && (
											<span role="alert" className="w-full text-red-500 bg-white text-xs my-1">Please fill expected completion date.</span>
										)}
									</div>
								</div>
								<div className="text-md font-bold border-t-2 pt-2 border-black border-opacity-10"> Other Relevant Details</div>
								<div className="flex flex-row space-x-2">
									<div className="relative w-full mb-1">
										<label
											htmlFor="formFile"
											className="block uppercase text-xs font-bold mb-2" >
											Description
										</label>
										<div className="border-0  bg-[#F3F4F6FF] text-xs" >
											<textarea
												type="text"
												placeholder="detailed description of issue"
												id="Description"
												name="Description"
												{...register('Description', { required: true })}
												className="w-full px-3 py-1 bg-[#F3F4F6FF] text-xs"
												onChange={(e) => handleSteps(e, 'Description')}
											/>
										</div>
										{errors.Description && errors.Description.type === "required" && (
											<span role="alert" className="w-full text-red-500 bg-white text-xs my-1">Please fill in the description field.</span>
										)}
										{errors.Description && errors.Description.type === "maxLength" && (
											<span role="alert" className="w-full text-red-500 bg-white text-xs my-1">Max length exceeded</span>
										)}
									</div>
								</div>
							</div>
							<div className="flex flex-row item-baseline justify-center py-2">
								<button
									className="items-center text-blue-600 outline px-2 py-1 mr-4 rounded text-xs font-bold"
									onClick={() => {
										handleCancel()
									}}
								> Cancel
								</button>
								<button
									type="submit"
									className="bg-blue-500 text-white rounded  px-2 text-xs py-1"
								>
									{isEditIssue ? 'Update' : 'Add'}
								</button>
							</div>
						</form>
					</div>
				</div>
				<footer className="flex fixed bottom-0 left-0 flex-row w-full justify-center min-h-20 pb-2 item-center bg-[#3C50E0FF] text-white">
					<button
						onClick={() => navigate('/mobile/project-board')}
						className="w-1/4  flex flex-col justify-center   items-center ">
						<svg xmlns="http://www.w3.org/2000/svg" height="32" width="32" fill="#ffffff" viewBox="0 0 24 24" ><path d="M19 21H5C4.44772 21 4 20.5523 4 20V11L1 11L11.3273 1.6115C11.7087 1.26475 12.2913 1.26475 12.6727 1.6115L23 11L20 11V20C20 20.5523 19.5523 21 19 21ZM13 19H18V9.15745L12 3.7029L6 9.15745V19H11V13H13V19Z"></path></svg>						<span className="text-xs">Home</span>
					</button>
					<button className="w-1/4  flex flex-col justify-center  items-center ">
						<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#ffffff" viewBox="0 0 256 256"><path d="M216,48H40A16,16,0,0,0,24,64V224a15.85,15.85,0,0,0,9.24,14.5A16.13,16.13,0,0,0,40,240a15.89,15.89,0,0,0,10.25-3.78.69.69,0,0,0,.13-.11L82.5,208H216a16,16,0,0,0,16-16V64A16,16,0,0,0,216,48ZM40,224h0ZM216,192H82.5a16,16,0,0,0-10.3,3.75l-.12.11L40,224V64H216ZM88,112a8,8,0,0,1,8-8h64a8,8,0,0,1,0,16H96A8,8,0,0,1,88,112Zm0,32a8,8,0,0,1,8-8h64a8,8,0,1,1,0,16H96A8,8,0,0,1,88,144Z"></path></svg>
						<span className="text-xs">Chats</span>
					</button>
					<button className="w-1/4  flex  flex-col justify-center items-center ">
						<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#ffffff" viewBox="0 0 24 24"><path d="M19 13.586V10c0-3.217-2.185-5.927-5.145-6.742C13.562 2.52 12.846 2 12 2s-1.562.52-1.855 1.258C7.185 4.074 5 6.783 5 10v3.586l-1.707 1.707A.996.996 0 0 0 3 16v2a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1v-2a.996.996 0 0 0-.293-.707L19 13.586zM19 17H5v-.586l1.707-1.707A.996.996 0 0 0 7 14v-4c0-2.757 2.243-5 5-5s5 2.243 5 5v4c0 .266.105.52.293.707L19 16.414V17zm-7 5a2.98 2.98 0 0 0 2.818-2H9.182A2.98 2.98 0 0 0 12 22z" /></svg>
						<span className="text-xs">Notifications</span>
					</button>
					<button className="w-1/4  flex flex-col justify-center  items-center ">
						<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#ffffff" viewBox="0 0 256 256"><path d="M201.89,54.66A103.43,103.43,0,0,0,128.79,24H128A104,104,0,0,0,24,128v56a24,24,0,0,0,24,24H64a24,24,0,0,0,24-24V144a24,24,0,0,0-24-24H40.36A88.12,88.12,0,0,1,190.54,65.93,87.39,87.39,0,0,1,215.65,120H192a24,24,0,0,0-24,24v40a24,24,0,0,0,24,24h24a24,24,0,0,1-24,24H136a8,8,0,0,0,0,16h56a40,40,0,0,0,40-40V128A103.41,103.41,0,0,0,201.89,54.66ZM64,136a8,8,0,0,1,8,8v40a8,8,0,0,1-8,8H48a8,8,0,0,1-8-8V136Zm128,56a8,8,0,0,1-8-8V144a8,8,0,0,1,8-8h24v56Z"></path></svg>
						<span className="text-xs">Help</span>
					</button>
				</footer>
			</div>
		</>
	);
}

