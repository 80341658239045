import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash'
// import { Bars } from 'react-loader-spinner';
import Confirmation from "../../modals/Confirmation.js";
import AddTask from "../../modals/AddTask.js";
import { request } from '../../request';
import { selectCurrentCRM } from "../../redux/crm/selectors"
import { selectItemsFilter as selectListItems, selectUpdatedItem } from '../../redux/projectchecklist/selectors.js';
import { selectItemsFilter } from '../../redux/issuecards/selectors.js';
import { selectItemsFilter as selectAddonFilter } from '../../redux/projectaddon/selectors.js';
import { projectaddon } from '../../redux/projectaddon/actions.js';
import { issuestatus } from "../../redux/issuestatus/actions"
import { projectchecklist } from "../../redux/projectchecklist/actions.js";
import { issuecards } from "../../redux/issuecards/actions";
import { crm } from "../../redux/crm/actions";
import { db } from '../../services/db.js'
import { useLiveQuery } from "dexie-react-hooks";

const getOnLineStatus = () =>
	typeof navigator !== 'undefined' && typeof navigator.onLine === 'boolean'
		? navigator.onLine
		: true;

export default function TaskItems() {
	const dbcache = useLiveQuery(() => db.projectchecklist_table.toArray(), []);
	const dbcache1 = useLiveQuery(() => db.issuecards_table.toArray(), []);
	const dispatch = useDispatch();
	const navigate = useNavigate()
	const [url, setUrl] = useState('')
	const [loader, setLoader] = useState('')
	const { isSuccess } = useSelector(selectUpdatedItem)
	const [networkStatus, setNetworkStatus] = useState(getOnLineStatus());
	const setOffline = () => setNetworkStatus(false);
	const setOnline = () => setNetworkStatus(true);
	const [filteredList, setFilteredList] = useState([])
	const { issueReload, addProjectTask, activeProjectId, reloadProjectTask, activeCategoryName, activeCustomerId, reloadIssueCreated } = useSelector(selectCurrentCRM)
	const { result, isLoading } = useSelector(selectListItems);
	const { items } = result ? result : [];
	const { result: issueResult } = useSelector(selectItemsFilter);
	const { items: issueitems } = issueResult;
	const { result: addonChecklist } = useSelector(selectAddonFilter)
	const { items: addonItems } = addonChecklist

	useEffect(() => {
		window.scrollTo(0, 0);
		window.addEventListener('online', setOnline);
		window.addEventListener('offline', setOffline);
		return () => {
			window.removeEventListener('online', setOnline);
			window.removeEventListener('offline', setOffline);
		};
	}, [])

	useEffect(() => {
		getImage(activeCategoryName?.IconName)
	}, [activeCategoryName])

	useEffect(() => {
		setFilteredList(items[0]?.Checklist)
	}, [items])

	useEffect(() => {
		if (reloadIssueCreated) {
			// if (isAddonTask) {
			// 	dispatch(projectchecklist.update({
			// 		entity: 'PDI_Project_Addon_Checklist', id: activeAddonId, jsonData:
			// 		{
			// 			entity: {
			// 				Status: activeAddonStatus,
			// 				IssueDetails: createdResult?._id
			// 			}
			// 		}
			// 	})).then(() => {
			// 		dispatch(crm.trigger({ reloadWebIssueCreated: false, reloadProjectTask: true, isAddonTask: false }))
			// 	})
			// }
			// else {
			// 	let list = []
			// 	items[0]?.Checklist?.map((obj) => {
			// 		if (obj?.Item?._id == activeChecklistId) {
			// 			list.push({
			// 				Item: obj?.Item?._id,
			// 				Status: obj?.Status,
			// 				IssueDetails: createdResult?._id
			// 			})
			// 		}
			// 		else {
			// 			list.push({
			// 				Item: obj?.Item?._id,
			// 				IssueDetails: obj?.IssueDetails ? obj?.IssueDetails : null,
			// 				Status: obj?.Status
			// 			})
			// 		}
			// 	})
			// 	dispatch(projectchecklist.update({
			// 		entity: 'PDI_Project_Checklist', id: items[0]._id, jsonData:
			// 		{
			// 			entity: {
			// 				UnitDetails: items[0].UnitDetails,
			// 				Checklist: list
			// 			}
			// 		}
			// 	})).then(() => {
			dispatch(crm.trigger({ reloadIssueCreated: false, reloadProjectTask: true }))
			// 	})
			// }
		}
	}, [reloadIssueCreated])

	useEffect(() => {
		db.issuecards_table.count().then((count) => {
			dbcache1?.map((obj) => {
				const refid = obj?.data?.entity?.ChecklistItem
				dbcache.map((obj1) => {
					obj1.data.entity.Checklist.map((checklist) => {
						if (checklist.Item == refid) {
							console.log('checklist', checklist)
						}
					})

				})
			})
		})
	}, [dbcache1])

	useEffect(() => {
		if (networkStatus) {
			dispatch(projectchecklist.filter({ entity: 'PDI_Project_Checklist', jsonData: { params: { UnitDetails: activeCustomerId }, page: 1, limit: 200 } }))
			dispatch(issuecards.filter({ entity: 'PDI_Issue_Cards', jsonData: { params: { Customer: activeCustomerId }, page: 1, limit: 200 } }))
			dispatch(projectaddon.filter({
				entity: "PDI_Project_Addon_Checklist",
				jsonData: {
					params: {
						ProjectName: activeProjectId,
						CategoryName: activeCategoryName?._id,
						UnitDetails: activeCustomerId
					}, page: 1, limit: 200
				}
			}))
			dispatch(issuestatus.list({ entity: 'PDI_Issue_Status', options: {} }))
		}
	}, [])

	useEffect(() => {
		if (issueReload) {
			dispatch(issuecards.filter({ entity: 'PDI_Issue_Cards', jsonData: { params: { Customer: activeCustomerId }, page: 1, limit: 200 } }))
			dispatch(crm.trigger({ issueReload: false }))
		}
	}, [issueReload])

	useEffect(() => {
		if (isSuccess) {
			dispatch(crm.trigger({ reloadProjectTask: true }))
		}
	}, [isSuccess])

	useEffect(() => {
		if (networkStatus) {
			db.issuecards_table.count().then((count) => {
				dbcache1?.map((obj) => {
					db.issuecards_table.delete(obj.id)
					if (obj.activeid) {
						dispatch(issuecards.resetAction({ actionType: 'update' }))
						dispatch(issuecards.update({
							entity: 'PDI_Issue_Cards', id: obj.activeid, jsonData: obj?.data
						}))
					}
					else {
						dispatch(issuecards.resetAction({ actionType: 'create' }))
						dispatch(issuecards.create({
							entity: 'PDI_Issue_Cards', jsonData: obj?.data
						}))
					}
				})
			})
			db.projectchecklist_table.count().then((count) => {
				dbcache?.map((obj) => {
					db.projectchecklist_table.delete(obj.id)
					dispatch(projectchecklist.resetAction({ actionType: 'update' }))
					dispatch(projectchecklist.update({
						entity: 'PDI_Project_Checklist', id: obj.activeid, jsonData: obj?.data
					}))
				})
			})
		}

	}, [networkStatus])

	useEffect(() => {
		if (reloadProjectTask && networkStatus) {
			dispatch(projectchecklist.filter({ entity: 'PDI_Project_Checklist', jsonData: { params: { UnitDetails: activeCustomerId }, page: 1, limit: 200 } }))
			dispatch(projectaddon.filter({
				entity: "PDI_Project_Addon_Checklist",
				jsonData: {
					params: {
						ProjectName: activeProjectId,
						UnitDetails: activeCustomerId
					}, page: 1, limit: 200
				}
			}))
			dispatch(crm.trigger({ reloadProjectTask: false }))
		}
	}, [reloadProjectTask])

	// useEffect(() => {

	// }, [filteredList])

	const handleStatus = async (status, id) => {
		let list = []
		let list1 = []
		setLoader(true)
		await filteredList.map((_item) => {
			if (_item?.Item?._id == id) {
				// if (_item?.IssueDetails?._id) {
				// 	dispatch(issuecards.update({
				// 		entity: 'PDI_Issue_Cards',
				// 		id: _item?.IssueDetails?._id,
				// 		jsonData: {
				// 			entity: {
				// 				"IsReopened": true,
				// 				"Status": "659cdc8dcbe502001aafb6dd"
				// 			}
				// 		}
				// 	})).then(() => {
				// 		dispatch(issuecards.filter({ entity: 'PDI_Issue_Cards', jsonData: { params: { Customer: activeCustomerId }, page: 1, limit: 200 } }))
				// 	})
				// }
				list.push({ Item: id, Status: status, IssueDetails: _item?.IssueDetails?._id })
				list1.push({ Item: _item.Item, Status: status, IssueDetails: _item?.IssueDetails?._id })
			}
			else {
				list.push({ Item: _item?.Item?._id, Status: _item?.Status, IssueDetails: _item?.IssueDetails?._id })
				list1.push({ Item: _item?.Item, Status: _item?.Status, IssueDetails: _item?.IssueDetails?._id })

			}
		})
		dispatch(projectchecklist.resetAction({ actionType: 'update' }))
		dispatch(projectchecklist.update({ entity: 'PDI_Project_Checklist', id: items[0]?._id, jsonData: { entity: { Checklist: list } } })).then(() => {
			if (networkStatus) {
				dispatch(crm.trigger({ reloadProjectTask: true }))
			}
			else {
				setFilteredList(list1)
			}
			setLoader(false)
		})
	}

	const handleAddTask = (category) => {
		dispatch(crm.trigger({ addProjectTask: 'open', isAddTaskByWeb: false }))
	}

	const handleAddonIssue = (obj) => {
		dispatch(crm.trigger({
			activeChecklistId: obj?._id,
			activeInspectionDescription: obj?.ChecklistItem,
			activeCategoryNameId: obj?.CategoryName?._id,
			// activeCategoryName: obj?.CategoryName?.Name,
			isEditIssue: false,
			isAddonTask: true,
			activeAddonStatus: obj?.Status,
			activeAddonId: obj?._id
		}))
		navigate('/mobile/add-issue')
	}

	const handleAddonStatus = async (status, id) => {
		await addonItems.map((_item) => {
			if (_item?._id == id) {
				dispatch(projectaddon.update({ entity: 'PDI_Project_Addon_Checklist', id: id, jsonData: { entity: { Status: status, IssueDetails: null } } })).then(() => {
					dispatch(crm.trigger({ reloadProjectTask: true }))
				})
			}
		})
	}

	const handleViewIssue = (id) => {
		const data = issueitems.filter((item) => item._id == id)
		dispatch(crm.trigger({ selectedCheckListItem: data[0] }))
		navigate('/mobile/view-issue')
	}

	const handleAddIssue = (item) => {
		dispatch(crm.trigger({ activeChecklistId: item?._id, activeInspectionDescription: item?.Description, activeCategoryNameId: item?.CategoryName?._id, isEditIssue: false }))
		navigate('/mobile/add-issue')
	}

	const getImage = async (key) => {
		if (networkStatus) {
			const url = "https://proptech-pdi.s3.amazonaws.com/" + key
			setUrl(url)
		}
	}

	const handleNavigation = () => {
		navigate('/mobile/category-list')
	}

	return (
		<>
			{/* {isLoading || loader ? <div className="opacity-25 fixed inset-0 z-40 bg-black">
				<div className="flex justify-center  items-center h-full w-full"><Bars
					height="80"
					width="80"
					color="#4fa94d"
					ariaLabel="bars-loading"
					wrapperStyle={{}}
					wrapperClass=""
					visible={true}
				/></div>
			</div> : ""} */}
			<div className="max-h-screen w-full bg-[#F9F9FA]">
				<header className="flex fixed top-0 left-0  justify-center w-full h-20 items-center bg-[#3C50E0FF] text-white">

					<div className="flex flex-row text-xl font-bold">
						<button
							onClick={() => handleNavigation()}
							className="absolute fixed left-10 cursor-pointer">
							<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#ffffff" viewBox="0 0 24 24"><path d="M21 11H6.414l5.293-5.293-1.414-1.414L2.586 12l7.707 7.707 1.414-1.414L6.414 13H21z" /></svg>
						</button>
						Task Details
					</div>
				</header >
				{addProjectTask == 'open' ? <AddTask /> : ''}
				<Confirmation />
				<div className="flex flex-row justify-between items-center mt-24 px-3 w-screen">
					<div className="flex flex-row  justify-start items-center m-2 px-4 py-2">
						<img
							className="h-10 w-10"
							src={url}
							alt="image-text"
						/>
						<div className="mx-3 text-xl font-bold">{activeCategoryName?.Name}</div>
					</div>
					<button
						onClick={() => handleAddTask(activeCategoryName?._id)}
						className="px-5 bg-blue-500 text-white font-bold rounded  px-2 text-xs py-1 mx-3">
						Add Task
					</button>
				</div>
				<div className=" w-full overflow-auto   pb-24">
					{filteredList?.filter((obj) => obj?.Item?.CategoryName?._id == activeCategoryName?._id).map((item, index) => {
						return <div key={index} className="bg-white border-x border-y p-2 m-1 my-2 mx-6 px-2 text-sm font-bold rounded-xl  border-black">
							<div className="mx-2">{item?.Item?.Description}</div>
							<div className="flex flex-col item-center justify-center m-1">
								<div className="flex flex-row item-center justify-center m-1 space-x-2" >
									<button
										className={`w-32 flex shadow justify-center items-center p-3 px-2 rounded text-sm ${item?.Status ? "bg-[#6B9BD2] text-white" : "bg-[#4069E5] text-white"}`}>
										<svg fill="#ffffff" height="12" width="12" className='mr-2'
											version="1.1" id="Layer_1"
											viewBox="0 0 455 455">
											<path d="M332.229,90.04l14.238-27.159l-26.57-13.93L305.67,76.087c-19.618-8.465-40.875-13.849-63.17-15.523V30h48.269V0H164.231v30
	H212.5v30.563c-22.295,1.674-43.553,7.059-63.171,15.523L135.103,48.95l-26.57,13.93l14.239,27.16
	C67.055,124.958,30,186.897,30,257.5C30,366.576,118.424,455,227.5,455S425,366.576,425,257.5
	C425,186.896,387.944,124.958,332.229,90.04z M355,272.5H212.5V130h30v112.5H355V272.5z"/>
										</svg>
										Not Done
									</button>
									<button
										onClick={() => handleStatus('Passed', item?.Item?._id)}
										className={`w-32 flex shadow justify-center items-center p-3 px-2 rounded text-sm  ${item?.Status == 'Passed' ? "bg-[#17A948] text-white" : "bg-[#EEFDF3] text-[#17A948]"}`}>
										<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" className="mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24"><path d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8z" /><path d="M9.999 13.587 7.7 11.292l-1.412 1.416 3.713 3.705 6.706-6.706-1.414-1.414z" /></svg>
										Passed
									</button>
									<button
										onClick={() => handleStatus('Failed', item?.Item?._id)}
										className={`w-32 flex shadow justify-center items-center p-3 px-2 rounded text-sm  ${item?.Status == 'Failed' ? "bg-[#E05858] text-white" : "bg-[#FDF2F2] text-[#E05858]"}`}>
										<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" className="pr-1" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" ><circle cx="12" cy="12" r="10" /><path d="m15 9-6 6" /><path d="m9 9 6 6" /></svg>
										Failed
									</button>
								</div>
								<div>
									{item?.Status ?
										<div className="flex flex-row item-center justify-start m-1 w-[150px]">
											{item?.Status != 'Passed' ?
												<>
													{item?.IssueDetails?._id ? <button
														onClick={() => handleViewIssue(item?.IssueDetails?._id)}
														className={"w-2/3 px-2 py-1 rounded text-xs  border-x border-y border-[#3C50E0FF] text-[#3C50E0FF] "}>
														View {item?.IssueDetails?._id && item?.Status == 'Passed' ? 'History' : "Issue"}
													</button> :
														<button
															onClick={() => handleAddIssue(item?.Item)}
															className="w-2/3 px-2 py-1 rounded text-xs border-x border-y border-[#3C50E0FF] text-[#3C50E0FF]">
															Add Issue
														</button>}
												</> : item?.Status == 'Passed' && item?.IssueDetails?._id ? <button
													onClick={() => handleViewIssue(item?.IssueDetails?._id)}
													className={"w-2/3 px-2 py-1 rounded text-xs  border-x border-y border-[#3C50E0FF] text-[#3C50E0FF] "}>
													View {item?.IssueDetails?._id && item?.Status == 'Passed' ? 'History' : "Issue"}
												</button> : ''}
										</div> :
										<div className="flex flex-row item-center justify-center m-1 w-[150px]">
										</div>}
								</div>
							</div>
						</div>
					})}
				</div>
				{addonItems.length > 0 ? <div className="flex justify-start items-center w-full -mt-20 px-8 py-3  text-xl font-bold">Add On</div> : ""}
				<div className="w-full overflow-auto h-auto pb-24">
					{addonItems?.map((item, index) => {
						return <div key={index} className="bg-white border-x border-y p-2 m-1 my-2 mx-6 px-2 text-sm font-bold rounded-xl  border-black">
							<div className="mx-2">{item?.ChecklistItem}</div>
							<div className="flex flex-col item-center justify-center m-1">
								<div className="flex flex-row item-center justify-start my-2 space-x-2" >
									<button
										className={`w-32 flex shadow justify-center items-center p-3 px-2 rounded text-sm ${item.Status != 'Not Done' ? "bg-[#6B9BD2] text-white" : "bg-[#4069E5] text-white"}`}>
										<svg fill="#ffffff" height="12" width="12" className='mr-2'
											version="1.1" id="Layer_1"
											viewBox="0 0 455 455">
											<path d="M332.229,90.04l14.238-27.159l-26.57-13.93L305.67,76.087c-19.618-8.465-40.875-13.849-63.17-15.523V30h48.269V0H164.231v30
	H212.5v30.563c-22.295,1.674-43.553,7.059-63.171,15.523L135.103,48.95l-26.57,13.93l14.239,27.16
	C67.055,124.958,30,186.897,30,257.5C30,366.576,118.424,455,227.5,455S425,366.576,425,257.5
	C425,186.896,387.944,124.958,332.229,90.04z M355,272.5H212.5V130h30v112.5H355V272.5z"/>
										</svg>
										Not Done
									</button>
									<button
										onClick={() => handleAddonStatus('Passed', item?._id)}
										className={`w-32 flex shadow justify-center items-center p-3 px-2 rounded text-sm  ${item?.Status == 'Passed' ? "bg-[#17A948] text-white" : "bg-[#EEFDF3] text-[#17A948]"}`}>
										<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" className="mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24"><path d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8z" /><path d="M9.999 13.587 7.7 11.292l-1.412 1.416 3.713 3.705 6.706-6.706-1.414-1.414z" /></svg>
										Passed
									</button>
									<button
										onClick={() => handleAddonStatus('Failed', item?._id)}
										className={`w-32 flex shadow justify-center items-center p-3 px-2 rounded text-sm  ${item?.Status == 'Failed' ? "bg-[#E05858] text-white" : "bg-[#FDF2F2] text-[#E05858]"}`}>
										<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" className="pr-1" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" ><circle cx="12" cy="12" r="10" /><path d="m15 9-6 6" /><path d="m9 9 6 6" /></svg>
										Failed
									</button>
								</div>
								<div>
									{item?.Status ?
										<div className="flex flex-row item-center justify-start m-1 w-[150px]">
											{item?.Status != 'Passed' ?
												<>
													{item?.IssueDetails ? <button
														onClick={() => handleViewIssue(item?.IssueDetails?._id)}
														className={"w-2/3 px-2 py-1 rounded text-xs  border-x border-y border-[#3C50E0FF] text-[#3C50E0FF] "}>
														View {item?.IssueDetails && item?.Status == 'Passed' ? 'History' : "Issue"}
													</button> :
														<button
															onClick={() => handleAddonIssue(item)}
															className="w-2/3 px-2 py-1 rounded text-xs border-x border-y border-[#3C50E0FF] text-[#3C50E0FF]">
															Add Issue
														</button>}
												</> : item?.Status == 'Passed' && item?.IssueDetails ? <button
													onClick={() => handleViewIssue(item?.IssueDetails?._id)}
													className={"w-2/3 px-2 py-1 rounded text-xs  border-x border-y border-[#3C50E0FF] text-[#3C50E0FF] "}>
													View {item?.IssueDetails && item?.Status == 'Passed' ? 'History' : "Issue"}
												</button> : ''}
										</div> :
										<div className="flex flex-row item-center justify-center m-1 w-[150px]">
										</div>}
								</div>
							</div>
						</div>
					})}
				</div>
				<footer className="flex fixed bottom-0 left-0 flex-row w-full justify-center min-h-20 pb-2 item-center bg-[#3C50E0FF] text-white">
					<button
						onClick={() => navigate('/mobile/project-board')}
						className="w-1/4  flex flex-col justify-center   items-center ">
						<svg xmlns="http://www.w3.org/2000/svg" height="32" width="32" fill="#ffffff" viewBox="0 0 24 24" ><path d="M19 21H5C4.44772 21 4 20.5523 4 20V11L1 11L11.3273 1.6115C11.7087 1.26475 12.2913 1.26475 12.6727 1.6115L23 11L20 11V20C20 20.5523 19.5523 21 19 21ZM13 19H18V9.15745L12 3.7029L6 9.15745V19H11V13H13V19Z"></path></svg>
						<span className="text-xs">Home</span>
					</button>
					<button
						onClick={() => navigate('/mobile/chat')}
						className="w-1/4  flex flex-col justify-center  items-center ">
						<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#ffffff" viewBox="0 0 256 256"><path d="M216,48H40A16,16,0,0,0,24,64V224a15.85,15.85,0,0,0,9.24,14.5A16.13,16.13,0,0,0,40,240a15.89,15.89,0,0,0,10.25-3.78.69.69,0,0,0,.13-.11L82.5,208H216a16,16,0,0,0,16-16V64A16,16,0,0,0,216,48ZM40,224h0ZM216,192H82.5a16,16,0,0,0-10.3,3.75l-.12.11L40,224V64H216ZM88,112a8,8,0,0,1,8-8h64a8,8,0,0,1,0,16H96A8,8,0,0,1,88,112Zm0,32a8,8,0,0,1,8-8h64a8,8,0,1,1,0,16H96A8,8,0,0,1,88,144Z"></path></svg>
						<span className="text-xs">Chats</span>
					</button>
					<button
						onClick={() => navigate('/mobile/notification')}
						className="w-1/4  flex  flex-col justify-center items-center ">
						<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#ffffff" viewBox="0 0 24 24"><path d="M19 13.586V10c0-3.217-2.185-5.927-5.145-6.742C13.562 2.52 12.846 2 12 2s-1.562.52-1.855 1.258C7.185 4.074 5 6.783 5 10v3.586l-1.707 1.707A.996.996 0 0 0 3 16v2a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1v-2a.996.996 0 0 0-.293-.707L19 13.586zM19 17H5v-.586l1.707-1.707A.996.996 0 0 0 7 14v-4c0-2.757 2.243-5 5-5s5 2.243 5 5v4c0 .266.105.52.293.707L19 16.414V17zm-7 5a2.98 2.98 0 0 0 2.818-2H9.182A2.98 2.98 0 0 0 12 22z" /></svg>
						<span className="text-xs">Notifications</span>
					</button>
					<button
						onClick={() => navigate('/mobile/help-center')}
						className="w-1/4  flex flex-col justify-center  items-center ">
						<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#ffffff" viewBox="0 0 256 256"><path d="M201.89,54.66A103.43,103.43,0,0,0,128.79,24H128A104,104,0,0,0,24,128v56a24,24,0,0,0,24,24H64a24,24,0,0,0,24-24V144a24,24,0,0,0-24-24H40.36A88.12,88.12,0,0,1,190.54,65.93,87.39,87.39,0,0,1,215.65,120H192a24,24,0,0,0-24,24v40a24,24,0,0,0,24,24h24a24,24,0,0,1-24,24H136a8,8,0,0,0,0,16h56a40,40,0,0,0,40-40V128A103.41,103.41,0,0,0,201.89,54.66ZM64,136a8,8,0,0,1,8,8v40a8,8,0,0,1-8,8H48a8,8,0,0,1-8-8V136Zm128,56a8,8,0,0,1-8-8V144a8,8,0,0,1,8-8h24v56Z"></path></svg>
						<span className="text-xs">Help</span>
					</button>
				</footer>
			</div >
		</>

	);
}
