import { createSelector } from 'reselect';

const selectNumberOfBedrooms = (state) => state.numberofbedrooms;

export const selectCurrentItem = createSelector([selectNumberOfBedrooms], (numberofbedrooms) => numberofbedrooms.current);

export const selectListItems = createSelector([selectNumberOfBedrooms], (numberofbedrooms) => numberofbedrooms.list);


export const selectItemById = (itemId) =>
	createSelector(selectListItems, (list) => list.result.items.find((item) => item._id === itemId));

export const selectCreatedItem = createSelector([selectNumberOfBedrooms], (numberofbedrooms) => numberofbedrooms.create);

export const selectUpdatedItem = createSelector([selectNumberOfBedrooms], (numberofbedrooms) => numberofbedrooms.update);

export const selectReadItem = createSelector([selectNumberOfBedrooms], (numberofbedrooms) => numberofbedrooms.read);

export const selectDeletedItem = createSelector([selectNumberOfBedrooms], (numberofbedrooms) => numberofbedrooms.delete);

export const selectSearchedItems = createSelector([selectNumberOfBedrooms], (numberofbedrooms) => numberofbedrooms.search);

export const selectItemsCount = createSelector([selectNumberOfBedrooms], (numberofbedrooms) => numberofbedrooms.count);

export const selectItemsFilter = createSelector([selectNumberOfBedrooms], (numberofbedrooms) => numberofbedrooms.filter);



