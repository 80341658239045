export const TYPE_OF_HOUSE_RESET_STATE = 'TYPE_OF_HOUSE_RESET_STATE';
export const TYPE_OF_HOUSE_CURRENT_ITEM = 'TYPE_OF_HOUSE_CURRENT_ITEM';

export const TYPE_OF_HOUSE_REQUEST_LOADING = 'TYPE_OF_HOUSE_REQUEST_LOADING';
export const TYPE_OF_HOUSE_REQUEST_SUCCESS = 'TYPE_OF_HOUSE_REQUEST_SUCCESS';
export const TYPE_OF_HOUSE_REQUEST_FAILED = 'TYPE_OF_HOUSE_REQUEST_FAILED';

export const TYPE_OF_HOUSE_CURRENT_ACTION = 'TYPE_OF_HOUSE_CURRENT_ACTION';
export const TYPE_OF_HOUSE_RESET_ACTION = 'TYPE_OF_HOUSE_RESET_ACTION';

export const TYPE_OF_HOUSE_NAME_EXISTS_ACTION = 'TYPE_OF_HOUSE_NAME_EXISTS_ACTION';
export const TYPE_OF_HOUSE_RESET_NAME_EXISTS = 'TYPE_OF_HOUSE_RESET_NAME_EXISTS';
