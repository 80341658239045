import Dexie from 'dexie';

export const db = new Dexie('PropCheck');
db.version(1).stores({
	notifications: '++id, title, message' // Primary key and indexed props
});


db.version(1).stores({
	permission_table: '++id, collection, data' // Primary key and indexed props
});

db.version(1).stores({
	projectchecklist_table: '++id, collection, data, activeid' // Primary key and indexed props
});

db.version(1).stores({
	issuecards_table: '++id, collection, data, activeid' // Primary key and indexed props
});

db.transaction("rw", db.title, db.message, () => {
	db.notifications.count().then((count) => {
		if (count === 0) {
			db.notifications.bulkPut([
				{
					title: "Tachipirina",
					message: '10',

				},
				{
					title: "Cardioaspirin",
					message: '30',
				}
			]);
		}
	});
});

// db.transaction("rw", db.status, db.item, () => {
// 	db.snaglists.count().then((count) => {
// 		if (count === 0) {
// 			db.snaglists.bulkPut([
// 				{
// 					status: "Passed",
// 					item: '10',

// 				},
// 				{
// 					title: "Failed",
// 					item: '30',
// 				}
// 			]);
// 		}
// 	});
// });