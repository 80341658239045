import { crm } from "../redux/crm/actions";
import React, { useEffect, useState, useRef, useCallback } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentCRM } from '../redux/crm/selectors';
import { selectListItems as selectIssueStatus } from '../redux/issuestatus/selectors';
import { selectItemsFilter, selectCreatedItem } from '../redux/comments/selectors';
import { issuecards } from "../redux/issuecards/actions";
import { request } from "../request";
import { useNavigate } from "react-router-dom";
import { comments } from "../redux/comments/actions";
import storePersist from "../redux/storePersist";
import { subscriber } from "../redux/subscribers/actions";
import { selectItemsFilter as selectIssueFilter } from '../redux/issuecards/selectors';
import axios from "axios";
import ImageAnnotationComponent from "./ImageAnnotationComponent";
import Annotation from 'react-image-annotation';
import {
	PointSelector,
	RectangleSelector,
	OvalSelector
} from 'react-image-annotation/lib/selectors';
import { FaSearchPlus, FaSearchMinus, FaSquare, FaCircle, FaDotCircle, FaHandPointer } from 'react-icons/fa'; // Importing icons


export default function ViewIssue() {
	const dispatch = useDispatch();
	const commentRef = useRef();
	const navigate = useNavigate()
	const [activeTab, setActiveTab] = useState('capture');
	const [message, setMessage] = useState('');
	const [height, setHeight] = useState(50);
	const [audioUrl, setAudioUrl] = useState([]);
	const [commentImage, setCommentImage] = useState([]);
	const expandedHeight = window.innerHeight - 300;
	const { viewIssue, selectedCheckListItem, commentAdded, activeIssueId } = useSelector(selectCurrentCRM);
	const { result: issueStatus } = useSelector(selectIssueStatus);
	const { result: commentResult } = useSelector(selectItemsFilter);
	const { result: result1 } = useSelector(selectIssueFilter);
	const { items: selectedItems } = result1
	const { items: commentItems } = commentResult
	const { items: issuestatusitems } = issueStatus
	const [attachmentList, setAttachmentList] = useState([])
	const [imageUrl, setImageUrl] = useState([])
	const [attachmentList1, setAttachmentList1] = useState([])
	const [imageUrl1, setImageUrl1] = useState([])
	const [profileUrl, setProfileUrl] = useState([])
	const [activeProfileUrl, setActiveProfileUrl] = useState('')
	const [imageView, setImageView] = useState([])
	const [imageView1, setImageView1] = useState([])
	const { result: createdComment } = useSelector(selectCreatedItem)
	const [issue, setIssue] = useState({})

	const handleInputChange = useCallback((event) => {
		setMessage(event.target.value);
		if (height !== expandedHeight) {
			event.target.style.height = 'auto';
			event.target.style.height = `${event.target.scrollHeight}px`;
		}
	}, [height, expandedHeight]);

	useEffect(() => {
		console.log(selectedItems[0])
	}, [selectedItems])

	useEffect(() => {
		let _issue = selectedCheckListItem
		dispatch(crm.trigger({ activeIssueId: selectedCheckListItem._id }))
		setIssue(_issue)
	}, [selectedCheckListItem])

	useEffect(() => {
		window.scrollTo(0, 0);
		if (viewIssue == 'open') {
			let _issue = selectedCheckListItem
			setIssue(_issue)
			let arr = []
			let imgUrl = []
			Promise.all(selectedCheckListItem?.Attachments?.map(async (attach, index) => {
				arr.push(attach[0])
				let data = "https://proptech-pdi.s3.amazonaws.com/" + attach[0].fileKey
				imgUrl.push(data)
			})).then(() => {
				setImageUrl(imgUrl)
				setAttachmentList(arr)
			})
			let arr1 = []
			let imgUrl1 = []
			Promise.all(selectedCheckListItem?.ImageUrl?.map(async (attach, index) => {
				arr1.push(attach[0])
				let data = "https://proptech-pdi.s3.amazonaws.com/" + attach[0].fileKey
				imgUrl1.push(data)
			})).then(() => {
				setImageUrl1(imgUrl1)
				setAttachmentList1(arr1)
			})
			dispatch(comments.filter({ entity: 'PDI_Comment', jsonData: { params: { "IssueId": selectedCheckListItem._id }, page: 1, limit: 200 } }))
		}
	}, [viewIssue])

	const handleDelete = async (obj) => {
		let temp = []
		temp = attachmentList?.filter(attachment => attachment.fileKey !== obj?.fileKey);
		setAttachmentList(temp)
		console.log(temp)
		dispatch(issuecards.update({
			entity: 'PDI_Issue_Cards',
			id: activeIssueId,
			jsonData: {
				entity: {
					"Attachments": [...temp],
				}
			}
		}))
		//.then(() => {
		//	dispatch(crm.trigger({ activeIssueImageKey: [] }))
		//	navigate('/inspection-crm/project-checklist')
		//	})
		const token = storePersist.get('auth').current
		try {
			const response = await axios.delete('https://api.greypath.co/api/v1/document/deleteObject?key=' + obj?.fileKey, {
				method: 'DELETE',
				mode: 'no-cors',
				cache: 'no-cache',
				credentials: 'include',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + token,
				},
				// redirect: 'follow',
				referrerPolicy: 'no-referrer',
			});
			return response.data;
		} catch (error) {
			return console.log(error);
		}
		finally {
			clearCache()
			// dispatch(crm.trigger({ viewIssue: 'close', reloadProjectTask: true }))
		}
	}

	const clearCache = async () => {
		if ('serviceWorker' in navigator && navigator.serviceWorker.controller) {
			// Send a message to the service worker to clear all cache
			navigator.serviceWorker.controller.postMessage({ type: 'CLEAR_ALL_DYNAMIC_CACHE' });
			alert('Cache clearing message sent to the Service Worker!');
		} else {
			alert('Service Worker is not available.');
		}
	};

	const handleDelete1 = async (obj) => {
		let temp = []
		temp = attachmentList1.filter(attachment => attachment.fileKey !== obj?.fileKey);
		setAttachmentList1(temp)
		console.log(temp)
		dispatch(issuecards.update({
			entity: 'PDI_Issue_Cards',
			id: activeIssueId,
			jsonData: {
				entity: {
					"ImageUrl": [...temp],
				}
			}
		}))
		// 	.then(() => {
		// 	dispatch(crm.trigger({ activeIssueImageKey: [] }))
		// 	navigate('/inspection-crm/project-checklist')
		// })
		const token = storePersist.get('auth').current
		try {
			const response = await axios.delete('https://api.greypath.co/api/v1/document/deleteObject?key=' + obj?.fileKey, {
				method: 'DELETE',
				mode: 'no-cors',
				cache: 'no-cache',
				credentials: 'include',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + token,
				},
				// redirect: 'follow',
				referrerPolicy: 'no-referrer',
			});
			return response.data;
		} catch (error) {
			return console.log(error);
		}
		finally {
			clearCache()
			// dispatch(crm.trigger({ viewIssue: 'close', reloadProjectTask: true }))
		}
	}

	const handleStatus = (e, item) => {
		dispatch(issuecards.update({ entity: 'PDI_Issue_Cards', id: item._id, jsonData: { entity: { Status: e.target.value } } })).then(() => {
			dispatch(issuecards.filter({ entity: 'PDI_Issue_Cards', jsonData: { params: { _id: item._id } } }))
		})
	}

	const handleClose = () => {
		dispatch(crm.trigger({ viewIssue: 'close' }))
	}

	const handleEditIssue = (issue) => {
		dispatch(crm.trigger({ activeIssueId: issue._id, issueItem: issue, isEditIssue: true, viewIssue: 'close' }))
		navigate('/inspection-crm/add-issue')
	}

	const [type, setType] = useState(RectangleSelector.TYPE);
	const [annotations, setAnnotations] = useState([]);
	const [annotation, setAnnotation] = useState({});
	const [zoom, setZoom] = useState(1); // Zoom state

	const onChange = (annotation) => {
		setAnnotation(annotation);
	};

	const handleZoomIn = () => {
		setZoom((prevZoom) => Math.min(prevZoom + 0.1, 3)); // Max zoom level of 3x
	};

	const handleZoomOut = () => {
		setZoom((prevZoom) => Math.max(prevZoom - 0.1, 1)); // Min zoom level of 1x (default size)
	};

	const onSubmit = (annotation) => {
		const { geometry, data } = annotation;
		setAnnotations(annotations.concat({
			geometry,
			data: {
				...data,
				id: Math.random()
			}
		}));

		setAnnotation({});
	};

	const onChangeType = (e) => {
		setAnnotation({});
		setType(e);
	};

	const [allowTouch, setAllowTouch] = useState(true);

	const toggleAllowTouch = () => {
		setAllowTouch(prevAllowTouch => !prevAllowTouch);
	};


	useEffect(() => {
		getDownloadUrl(commentItems);
		getAudioUrl().then(urls => {
			setAudioUrl(urls);

		}).catch(error => {
			console.error('Error fetching audio URLs:', error);
		});
		getCommentImgUrl().then(urls => {
			setCommentImage(urls);
		}).catch(error => {
			console.error('Error fetching Image URLs:', error);
		});
	}, [commentItems])


	const getAudioUrl = async () => {
		let audiodata = commentItems[0]?.replies.map(item => {
			if (item.audioFileKey) {
				return axios.post('https://api.greypath.co/api/v2/document/downloadurl', { key: item.audioFileKey })
					.then(response => response.data)
					.catch(err => {
						console.log(err);
						return null;
					});
			} else {
				return Promise.resolve(null);
			}
		});
		return Promise.all(audiodata);
	}

	const getCommentImgUrl = async () => {
		let ImgData = commentItems[0]?.replies.map(item => {
			if (item.imageFileKey) {
				return "https://proptech-pdi.s3.amazonaws.com/" + item.imageFileKey

			} else {
				return Promise.resolve(null);
			}
		});
		return Promise.all(ImgData);
	}

	useEffect(() => {
		//dispatch(comments.filter({ entity: 'PDI_Comment', jsonData: { params: { "IssueId": selectedCheckListItem._id }, page: 1, limit: 200 } }))
	}, [])

	useEffect(() => {
		if (commentAdded) {
			if (createdComment && createdComment?._id) {
				dispatch(crm.trigger({ commentAdded: false }))
				dispatch(comments.update({ entity: 'PDI_Comment', id: commentItems[0]._id, jsonData: { entity: { replies: [createdComment?._id] } } })).then(() => {
					dispatch(comments.filter({ entity: 'PDI_Comment', jsonData: { params: { "IssueId": issue?._id }, page: 1, limit: 200 } }))
				})

			}
		}
	}, [createdComment])

	const log = () => {
		const myArray = message.split(' ')
		const startsWithAt = myArray.filter((arr) => arr.startsWith("@"));
		let users = []
		startsWithAt.map((obj) => {
			users.push(obj.replace('@', ''))
		})
		const textWithoutAt = myArray.filter((arr) => !arr.startsWith("@"));
		let newText = textWithoutAt.join(" ");
		if (newText) {
			dispatch(subscriber.send({ entity: 'PDI_Push_Subscriber', jsonData: { "entity": { "users": users, "title": 'Issue Comments Added', "body": newText } } }))
			dispatch(comments.create({ entity: 'PDI_Comment', jsonData: { entity: { replies: [], likes: [], content: newText, IssueId: null } } }))
			dispatch(crm.trigger({ commentAdded: true }))
		}
		setMessage('')
	};

	const getDownloadUrl = async (key) => {
		let data = "https://proptech-pdi.s3.amazonaws.com/" + commentItems[0]?.userId?.profileImage

		setProfileUrl([...profileUrl, profileUrl[0] = data])
		if (commentItems.length > 0) {
			Promise.all(commentItems[0]?.replies?.map(async (item, idx) => {
				let data = "https://proptech-pdi.s3.amazonaws.com/" + item?.userId?.profileImage

				setProfileUrl([...profileUrl, profileUrl[idx + 1] = data])
			}))
		}
		const user = storePersist.get('user')
		let data1 = "https://proptech-pdi.s3.amazonaws.com/" + user?.profileImage
		setActiveProfileUrl(data1)
	}

	return (
		<>
			{(imageView != "") &&
				<div className="absolute fixed w-full  inset-x-0 inset-y-0 z-50 flex flex-row justify-center items-center ">
					<div className="w-auto  h-auto inset-x-0 z-50 bg-black flex flex-col justify-around  ">
						<div className='w-auto mx-3 flex justify-end mt-10 z-30 '>
							<svg onClick={() => setImageView(false)} xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="none" stroke="#3b82f6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-x-circle cursor-pointer mx-3"><circle cx="12" cy="12" r="10" /><path d="m15 9-6 6" /><path d="m9 9 6 6" /></svg>
						</div>
						<div className='flex flex-row px-10 py-3 justify-center items-center z-30 w-auto space-x-3'>
							<button
								onClick={() => onChangeType('RECTANGLE')}
								className="px-4 py-2 bg-blue-500 text-white rounded"
								aria-label="Rectangle"
							>
								<FaSquare size={20} />
							</button>
							<button
								onClick={() => onChangeType('OVAL')}
								className="px-4 py-2 bg-blue-500 text-white rounded"
								aria-label="Oval"
							>
								<FaCircle size={20} />
							</button>
							<button
								onClick={() => onChangeType('POINT')}
								className="px-4 py-2 bg-blue-500 text-white rounded"
								aria-label="Point"
							>
								<FaDotCircle size={20} />
							</button>
							<button
								onClick={() => toggleAllowTouch()}
								className="px-4 py-2 bg-blue-500 text-white rounded"
								aria-label="Touch Interaction"
							>
								<FaHandPointer size={20} />
							</button>
						</div>
						<Annotation
							src={imageView}
							alt="Two pebbles anthropomorphized holding hands"
							annotations={annotations}
							allowTouch={allowTouch}
							type={type}
							value={annotation}
							onChange={onChange}
							onSubmit={onSubmit}
							style={{
								width: 400,
								height: 400,
								transform: `scale(${zoom})`,
								transformOrigin: 'center',
								transition: 'transform 0.3s ease-in-out', // Smooth zoom transition
							}}
						/>
						<div className='flex flex-row px-10  py-3 z-30 justify-center items-center w-auto  space-x-3'>
							<button
								onClick={handleZoomIn}
								className="px-4 py-2 bg-blue-500 text-white rounded"
								aria-label="Zoom In"
							>
								<FaSearchPlus size={20} />
							</button>
							<button
								onClick={handleZoomOut}
								className="px-4 py-2 bg-blue-500 text-white rounded"
								aria-label="Zoom Out"
							>
								<FaSearchMinus size={20} />
							</button>
						</div>
						<div className='w-auto mx-3 flex justify-end text-xl  z-30'>
							<div className='w-max px-2  mx-6 outline outline-blue-500  text-white bg-blue-500 font-bold mb-10 py-1'>Save</div>
						</div>
					</div>
				</div>}
			{(imageView1 != "") &&
				<div className="absolute fixed w-full inset-x-0 inset-y-0 z-50 flex flex-row justify-center items-center "><div className="w-auto  h-auto inset-x-0 z-50 bg-black flex flex-col justify-around  ">
					<div className='w-auto mx-3 flex justify-end mt-10 z-30'>
						<svg onClick={() => setImageView1(false)} xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="none" stroke="#3b82f6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-x-circle cursor-pointer mx-3"><circle cx="12" cy="12" r="10" /><path d="m15 9-6 6" /><path d="m9 9 6 6" /></svg>
					</div>
					<div className='flex flex-row px-10 py-3 justify-center items-center z-30 w-auto space-x-3'>
						<button
							onClick={() => onChangeType('RECTANGLE')}
							className="px-4 py-2 bg-blue-500 text-white rounded"
							aria-label="Rectangle"
						>
							<FaSquare size={20} />
						</button>
						<button
							onClick={() => onChangeType('OVAL')}
							className="px-4 py-2 bg-blue-500 text-white rounded"
							aria-label="Oval"
						>
							<FaCircle size={20} />
						</button>
						<button
							onClick={() => onChangeType('POINT')}
							className="px-4 py-2 bg-blue-500 text-white rounded"
							aria-label="Point"
						>
							<FaDotCircle size={20} />
						</button>
						<button
							onClick={() => toggleAllowTouch()}
							className="px-4 py-2 bg-blue-500 text-white rounded"
							aria-label="Touch Interaction"
						>
							<FaHandPointer size={20} />
						</button>
					</div>
					<Annotation
						src={imageView1}
						alt="Two pebbles anthropomorphized holding hands"
						annotations={annotations}
						allowTouch={allowTouch}
						type={type}
						value={annotation}
						onChange={onChange}
						onSubmit={onSubmit}
						style={{
							width: 400,
							height: 400,
							transform: `scale(${zoom})`,
							transformOrigin: 'center',
							transition: 'transform 0.3s ease-in-out', // Smooth zoom transition
						}}
					/>
					<div className='flex flex-row px-10  py-3 z-30 justify-center items-center w-auto  space-x-3'>
						<button
							onClick={handleZoomIn}
							className="px-4 py-2 bg-blue-500 text-white rounded"
							aria-label="Zoom In"
						>
							<FaSearchPlus size={20} />
						</button>
						<button
							onClick={handleZoomOut}
							className="px-4 py-2 bg-blue-500 text-white rounded"
							aria-label="Zoom Out"
						>
							<FaSearchMinus size={20} />
						</button>
					</div>
					<div className='w-auto mx-3 flex justify-end text-xl  z-30'>
						<div className='w-max px-2  mx-6 outline outline-blue-500  text-white bg-blue-500 font-bold mb-10 py-1'>Save</div>
					</div>
				</div>
				</div>}
			{(imageView == "" && imageView1 == "") && <div className="absolute fixed w-full 
			 inset-x-0 inset-y-0 z-50 flex flex-row justify-center items-start top-10 ">
				<div className="rounded-lg shadow-lg flex flex-col h-[650px] w-[650px] overflow-y-auto  bg-white">
					<div className="flex items-center justify-between mx-4 py-2 my-2">
						<div className="text-[10px] font-bold ">Project Name /Task ID - {issue?.IssueNumber} </div>
						<div className="ml-auto px-2 cursor-pointer" onClick={() => handleEditIssue(issue)}>
							<svg className="w-4 h-4" fill="none" c stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" width="24"
								xmlns="http://www.w3.org/2000/svg">
								<path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" />
								<path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" />
							</svg>
						</div>
						<svg onClick={() => handleClose()} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-x-circle cursor-pointer mx-3"><circle cx="12" cy="12" r="10" /><path d="m15 9-6 6" /><path d="m9 9 6 6" /></svg>
					</div>
					<div className="px-2 flex flex-wrap justify-center align-middle  border-b-2 border-black border-opacity-10 w-auto" >
						<div className="flex flex-col item-center justify-between  mx-2 my-2 space-x-4 w-auto">
							<span className="text-black text-opacity-80 text-xl font-bold  ">{issue?.IssueName}</span>
							<div className="flex flex-row justify-start items-center">
								<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#000000" viewBox="0 0 256 256"><path d="M128,96a32,32,0,1,0,32,32A32,32,0,0,0,128,96Zm0,48a16,16,0,1,1,16-16A16,16,0,0,1,128,144ZM48,96a32,32,0,1,0,32,32A32,32,0,0,0,48,96Zm0,48a16,16,0,1,1,16-16A16,16,0,0,1,48,144ZM208,96a32,32,0,1,0,32,32A32,32,0,0,0,208,96Zm0,48a16,16,0,1,1,16-16A16,16,0,0,1,208,144Z"></path></svg>
								<div className="text-black text-opacity-80  text-xs  w-[150px] font-normal my-2 px-1 rounded-xl">Category Name</div>
								<div className="text-xs font-normal my-2  rounded-xl w-max px-2 bg-[#1DD75BFF] text-[#171A1FFF]">{issue?.CategoryName?.Name}</div>
							</div>
							<div className="flex flex-row justify-start items-center">
								<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#000000" viewBox="0 0 256 256"><path d="M248,56a8,8,0,0,1-8,8H192v40a8,8,0,0,1-8,8H136v40a8,8,0,0,1-8,8H80v40a8,8,0,0,1-8,8H16a8,8,0,0,1,0-16H64V152a8,8,0,0,1,8-8h48V104a8,8,0,0,1,8-8h48V56a8,8,0,0,1,8-8h56A8,8,0,0,1,248,56Z"></path></svg>
								<div className="text-black text-opacity-80  text-xs w-[150px] font-normal my-2 px-1 rounded-x">Status</div>
								<div className="text-xs font-normal my-2  rounded-xl  w-max  text-[#171A1FFF]">
									<select
										name="IssueStatus"
										className="w-full py-1 bg-black bg-opacity-0 text-xs"
										onChange={(e) => handleStatus(e, issue)}
										value={issue?.Status?._id}>
										{issuestatusitems.map((item, index) => {
											return <option value={item?._id} key={index}> {item?.Name}
											</option>
										})}
									</select>
								</div>
							</div>
							<div className="flex flex-row justify-start items-center w-64">
								<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-trending-up"><polyline points="22 7 13.5 15.5 8.5 10.5 2 17" /><polyline points="16 7 22 7 22 13" /></svg>
								<div className="text-black  text-opacity-80  text-xs w-[150px] font-normal my-2 px-1 rounded-xl">Priority</div>
								<div className="text-xs font-normal my-2  rounded-xl w-max px-2 bg-[#4069E5FF] text-[#FFFFFF]">{issue?.Priority?.Name} </div>
							</div>
							<div className="flex flex-row justify-start items-center w-64">
								<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-user"><path d="M19 21v-2a4 4 0 0 0-4-4H9a4 4 0 0 0-4 4v2" /><circle cx="12" cy="7" r="4" /></svg>
								<div className="text-black text-opacity-80  text-xs  w-[150px] font-normal my-2 px-1 rounded-x">CRM Executive
								</div>
								<div className="text-xs font-normal my-2  rounded-xl px-2 w-max text-black">{issue?.CRMExecutive?.userId}
								</div>
							</div>
							<div className="flex flex-row justify-start items-center">
								<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-user"><path d="M19 21v-2a4 4 0 0 0-4-4H9a4 4 0 0 0-4 4v2" /><circle cx="12" cy="7" r="4" /></svg>
								<div className="text-black text-opacity-80  text-xs  w-[150px] font-normal my-2 px-1 rounded-xl">QC/Engineer
								</div>
								<div className="text-xs font-normal my-2  rounded-xl  px-2  text-black">{issue?.SiteEngineer?.userId}
								</div>
							</div>
							<div className="flex flex-row justify-start items-center">
								<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-calendar"><path d="M8 2v4" /><path d="M16 2v4" /><rect width="18" height="18" x="3" y="4" rx="2" /><path d="M3 10h18" /></svg>
								<div className="text-black text-opacity-80  text-xs  w-[150px] font-normal my-2 px-1 rounded-xl">Start Date
								</div>
								<div className="text-xs font-normal my-2  rounded-xl w-max px-2  text-black">{issue?.StartDate?.slice(0, 10)}
								</div>
							</div>
							<div className="flex flex-row justify-start items-center">
								<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-calendar"><path d="M8 2v4" /><path d="M16 2v4" /><rect width="18" height="18" x="3" y="4" rx="2" /><path d="M3 10h18" /></svg>
								<div className="text-black text-opacity-80  text-xs  w-[150px] font-normal my-2 px-1 rounded-xl">Expected Finshed Date
								</div>
								<div className="text-xs font-normal my-2  rounded-xl w-max px-2  text-black">{issue?.ExpectedCompletionDate?.slice(0, 10)}
								</div>
							</div>
							<div className="flex flex-row justify-start items-center">
								<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-calendar"><path d="M8 2v4" /><path d="M16 2v4" /><rect width="18" height="18" x="3" y="4" rx="2" /><path d="M3 10h18" /></svg>
								<div className="text-black text-opacity-80  text-xs  w-[150px] font-normal my-2 px-1 rounded-xl">Actual Finshed Date
								</div>
								<div className="text-xs font-normal my-2  rounded-xl w-max px-2  text-black">{issue?.ActualCompletionDate?.slice(0, 10)}
								</div>
							</div>
						</div>
					</div>
					<div className="mx-10">
						<div className="flex justify-around border-b border-gray-300">
							<button
								className={`py-2 px-4 text-sm font-medium ${activeTab === 'capture' ? 'text-blue-500 border-b-2 border-blue-500' : 'text-gray-600'
									}`}
								onClick={() => setActiveTab('capture')}
							>
								Capture Issue Image <span className="text-xs text-red-500 border-x px-2 border-red-500 rounded-full -mt-3">{attachmentList.length}</span>
							</button>
							<button
								className={`py-2 px-4 text-sm font-medium ${activeTab === 'postfix' ? 'text-blue-500 border-b-2 border-blue-500' : 'text-gray-600'
									}`}
								onClick={() => setActiveTab('postfix')}
							>

								Post-Fix Image <span className="text-xs text-red-500 border-x px-2 border-red-500 rounded-full -mt-3">{attachmentList1.length}</span>
							</button>
						</div>
						<div className="flex justify-center items-center mt-4">
							{activeTab === 'capture' && (
								<div className="w-[550px] overflow-auto">
									<div className=" flex flex-row space-x-2 w-max py-3">
										{attachmentList?.map((obj, index) => {
											return <div
												key={index}

												className="w-44 flex flex-row justify-start items-start m-2 mx-4 pt-1 cursor-pointer"
											>
												<img
													onClick={() => setImageView(imageUrl[index])}
													src={imageUrl[index]} className="object-scale-down  h-32 w-32 shadow-xl p-1 bg-white rounded " />
												<svg xmlns="http://www.w3.org/2000/svg" onClick={() => handleDelete(obj)} className="w-8 h-7 cursor-pointer  -mb-3  p-1 rounded  bg-red-600 text-white" viewBox="0 0 24 24" fill="currentColor"><path d="M17 6H22V8H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V8H2V6H7V3C7 2.44772 7.44772 2 8 2H16C16.5523 2 17 2.44772 17 3V6ZM18 8H6V20H18V8ZM13.4142 13.9997L15.182 15.7675L13.7678 17.1817L12 15.4139L10.2322 17.1817L8.81802 15.7675L10.5858 13.9997L8.81802 12.232L10.2322 10.8178L12 12.5855L13.7678 10.8178L15.182 12.232L13.4142 13.9997ZM9 4V6H15V4H9Z"></path></svg>
											</div>
										})}
									</div>
								</div>
							)}
							{activeTab === 'postfix' && (
								<div className="w-[550px] overflow-auto">
									<div className=" flex flex-row space-x-2 w-max py-3">
										{attachmentList1?.map((obj, index) => {
											return <div key={index} className="flex w-full flex-row jusity-center item-center h-full" >
												<div className="w-44 flex flex-row justify-start items-start m-2 mx-4 pt-1 cursor-pointer">
													<img
														onClick={() => setImageView1(imageUrl1[index])}
														src={imageUrl1[index]} className="object-scale-down  h-32 w-32 shadow-xl p-1 bg-white rounded " />
													<svg xmlns="http://www.w3.org/2000/svg" onClick={() => handleDelete1(obj)} className="w-8 h-7 cursor-pointer  -mb-3  p-1 rounded  bg-red-600 text-white" viewBox="0 0 24 24" fill="currentColor"><path d="M17 6H22V8H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V8H2V6H7V3C7 2.44772 7.44772 2 8 2H16C16.5523 2 17 2.44772 17 3V6ZM18 8H6V20H18V8ZM13.4142 13.9997L15.182 15.7675L13.7678 17.1817L12 15.4139L10.2322 17.1817L8.81802 15.7675L10.5858 13.9997L8.81802 12.232L10.2322 10.8178L12 12.5855L13.7678 10.8178L15.182 12.232L13.4142 13.9997ZM9 4V6H15V4H9Z"></path></svg>

												</div>
											</div>
										})}
									</div>
								</div>
							)}
						</div>
					</div>
					<div className="px-2 flex flex-wrap justify-center align-middle  border-b-2 border-black border-opacity-10" >
						<div className="w-full flex flex-col item-center justify-between mx-2 my-4">
							<span className="text-grey-600 text-xs font-bold">Description</span>
							<span className="text-grey-600 text-xs font-normal">{issue?.Description}</span>
						</div>
					</div>
					<div className="flex flex-col  w-full justify-center  items-center space-y-3 py-5 pb-[100px]">
						{commentItems[0]?.replies?.map((comment, index) => {
							return <><div ref={commentRef} className="flex  flex-row-reverse p-4 rounded-[16px] shadow-lg justify-end  w-4/5 m-2 ">


								<div className="flex flex-col">
									<div className="break-keep flex-shrink-2 mr-2" dangerouslySetInnerHTML={{ __html: comment?.content }} />

									{audioUrl[index] && (
										<audio controls className="w-[200px] mt-2 my-2">
											<source src={audioUrl[index]} type="audio/mpeg" />
											Your browser does not support the audio element.
										</audio>
									)}
									{commentImage[index] != null && (<img className="rounded-[8px]" src={commentImage[index]} alt="commentImage" />)}
								</div>
								<img
									className="h-7 w-7 mx-2 rounded-full"
									src={profileUrl[index + 1] ? profileUrl[index + 1] : ""}
									alt="avatar"
								/>
							</div>
							</>
						})
						}
					</div >
					<div className="flex flex-col w-full justify-center items-center space-y-3 py-5">
						<div className="flex flex-col h-max w-4/5">
							<div className="flex flex-row h-auto pb-10 justify-center items-center space-x-3">
								<img
									className="h-7 w-7 rounded-full"
									src={activeProfileUrl}
									alt="avatar"
								/>
								<textarea
									className={`w-60 resize-none px-4 py-2 border   border-gray-200 focus:outline-none transition-all duration-300`}
									style={{ height: `${height}px` }}
									placeholder="Type your message here..."
									value={message}
									onChange={handleInputChange}
								></textarea>
								<button
									onClick={log}
									className="outline text-xs bg-blue-500 text-white px-2 p-1 rounded">Send</button>
							</div>
						</div>
					</div>
				</div>
			</div>}
		</>
	);
}