export const ISSUE_PRIORITY_RESET_STATE = 'ISSUE_PRIORITY_RESET_STATE';
export const ISSUE_PRIORITY_CURRENT_ITEM = 'ISSUE_PRIORITY_CURRENT_ITEM';

export const ISSUE_PRIORITY_REQUEST_LOADING = 'ISSUE_PRIORITY_REQUEST_LOADING';
export const ISSUE_PRIORITY_REQUEST_SUCCESS = 'ISSUE_PRIORITY_REQUEST_SUCCESS';
export const ISSUE_PRIORITY_REQUEST_FAILED = 'ISSUE_PRIORITY_REQUEST_FAILED';

export const ISSUE_PRIORITY_CURRENT_ACTION = 'ISSUE_PRIORITY_CURRENT_ACTION';
export const ISSUE_PRIORITY_RESET_ACTION = 'ISSUE_PRIORITY_RESET_ACTION';

export const ISSUE_PRIORITY_NAME_EXISTS_ACTION = 'ISSUE_PRIORITY_NAME_EXISTS_ACTION';
export const ISSUE_PRIORITY_RESET_NAME_EXISTS = 'ISSUE_PRIORITY_RESET_NAME_EXISTS';
