import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EmptyBoard from './EmptyBoard';
import InspectionHome from './InspectionHome';
import { selectItemsFilter } from '../../redux/inspectioncards/selectors.js';
import { inspectioncards } from "../../redux/inspectioncards/actions";
import { selectCurrentCRM } from '../../redux/crm/selectors';
import { crm } from '../../redux/crm/actions'

export default function InspectionBoard() {
	const dispatch = useDispatch()
	const { result } = useSelector(selectItemsFilter);
	const { reloadCustomerDetails, activeProjectId } = useSelector(selectCurrentCRM)
	const { items } = result ? result : [];

	useEffect(() => {
		if (reloadCustomerDetails) {
			dispatch(inspectioncards.filter({ entity: 'PDI_Customer_Details', jsonData: { params: { project: activeProjectId }, page: 1, limit: 200 } }))
			dispatch(crm.trigger({ reloadCustomerDetails: false }))
		}
	}, [reloadCustomerDetails])

	useEffect(() => {
		dispatch(inspectioncards.filter({ entity: 'PDI_Customer_Details', jsonData: { params: { project: activeProjectId }, page: 1, limit: 200 } }))
	}, [])

	return (
		<>
			{items.length > 0 ?
				<>
					<InspectionHome items={items} />
				</>
				:
				<>
					<EmptyBoard type='add' />
				</>
			}

		</>)
}
