import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Confirmation from "../../modals/Confirmation";
import { useNavigate } from 'react-router-dom';
import { masterchecklist } from '../../redux/masterchecklist/actions';
import { selectListItems } from '../../redux/masterchecklist/selectors';
import { crm } from "../../redux/crm/actions";
import AddChecklist from '../../modals/AddChecklist';
import storePersist from "../../redux/storePersist";

export default function Settings() {
	const dispatch = useDispatch();
	const navigate = useNavigate()
	const { result } = useSelector(selectListItems);
	const { items } = result;
	const _user = storePersist.get('user')

	const handleGenerateChecklist = () => {
		navigate('/inspection-crm/generate-checklist')
		dispatch(crm.trigger({ activeMasterChecklistId: '', masterChecklistItem: {}, isEditMasterChecklist: false }))
	}

	const handleDelete = (id) => {
		dispatch(masterchecklist.delete({ entity: 'PDI_Master_Checklist', id: id })).then(() => {
			dispatch(masterchecklist.list({
				entity: 'PDI_Master_Checklist'
			}))
		})
	}

	const handleView = (obj) => {
		dispatch(crm.trigger({ activeMasterChecklist: obj }))
		navigate('/inspection-crm/master-checklist')
	}

	useEffect(() => {
		dispatch(masterchecklist.list({
			entity: 'PDI_Master_Checklist'
		}))
	}, [])

	return (
		<div className="flex flex-col item-center bg-[#F9F9FAFF]">
			<AddChecklist />
			<header className="flex justify-between h-24 w-full mx-auto px-5 items-center">
				<div className="flex flex-col justify-start w-full" >
					<nav aria-label="breadcrumb" className="w-max" >
						<ol className="flex flex-wrap items-center w-full px-4 py-1 rounded-md">
							<li
								className="flex items-center font-sans text-sm antialiased font-normal leading-normal transition-colors duration-300 cursor-pointer text-blue-gray-900 hover:text-light-blue-500">
								<h3 className="text-xl font-bold">Settings</h3>
							</li>
						</ol>
					</nav>
					<div className="flex justify-start w-full py-2 pr-10 pl-3" >
						<button onClick={() => navigate('/inspection-crm/settings')} className="w-auto mx-1 button   text-blue-600 px-3 rounded  text-xs font-bold"
						> Snag List
						</button>
						<button
							onClick={() => navigate('/inspection-crm/category')}
							className="w-auto button rounded  text-black text-opacity-40 px-3 rounded text-xs font-bold"
						> Cateogry
						</button>
						{_user?.roleId?._id == "5f616169b7b6a1367e1bfdb9" ? <button
							onClick={() => navigate('/inspection-crm/master-settings')}
							className="w-auto button rounded  text-black text-opacity-40 px-3 rounded text-xs font-bold"
						> Master Settings
						</button> : <></>}
					</div>
				</div>
				{_user?.roleId?._id == "5f616169b7b6a1367e1bfdb9" ? <button
					onClick={() => handleGenerateChecklist()}
					className="w-[200px] px-3 button ml-auto text-white rounded ring-1 ring-inset ring-blue-600 bg-[#3C50E0FF] p-2 text-xs font-bold"
				> Generate Snag List
				</button> : <></>}
			</header >
			<Confirmation />
			<div className="flex flex-col  px-3 mx-5 h-[600px]  bg-[#F9F9FAFF]">
				<div className="flex flex-wrap">
					{items.map((obj, index) => {
						return <div key={index} className="m-4 flex flex-row justify-around items-center space-x-4 text-xs">
							<div className="p-4 rounded shadow-xl bg-white">
								<div className="flex flex-col">
									<div className="flex flex-row justify-around items-center py-2 text-xl font-bold px-1 h-12">
										<div className="w-48">
											{obj.Name}
										</div>
										<div className="w-6 cursor-pointer" onClick={() => handleDelete(obj._id)}>
											<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="currentColor"><path d="M17 6H22V8H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V8H2V6H7V3C7 2.44772 7.44772 2 8 2H16C16.5523 2 17 2.44772 17 3V6ZM18 8H6V20H18V8ZM13.4142 13.9997L15.182 15.7675L13.7678 17.1817L12 15.4139L10.2322 17.1817L8.81802 15.7675L10.5858 13.9997L8.81802 12.232L10.2322 10.8178L12 12.5855L13.7678 10.8178L15.182 12.232L13.4142 13.9997ZM9 4V6H15V4H9Z"></path></svg>
										</div>
									</div>
									<div className="flex flex-row justify-between item-center space-x-2 border-b border-black border-opacity-30 p-3">
										<span className="px-2  text-center  text-[9px]  font-semibold border-x border-y border-black rounded-xl">
											{obj.ProjectType.Name}
										</span>
										<span className="px-2 text-center  text-[9px]  font-semibold border-x border-y border-black rounded-xl">
											{obj.TypeOfHouse.Name}
										</span>
										<span className="px-2  text-center  text-[9px]  font-semibold border-x border-y border-black rounded-xl">
											{obj.NumberOfBedrooms.Name}
										</span>
									</div>
									<div className="flex flex-row justify-end item-center m-4">
										<button
											onClick={() => handleView(obj)} className="w-auto px-3   bg-[#3C50E0FF] text-white p-2 text-xs ">
											View
										</button>
									</div>
								</div>
							</div>
						</div>
					})}
				</div>

				{/* <Editor
					apiKey='hhf1tnksyjbk21l1yrf26m6e136b224ayt9dwbv4ytzmsjp8'
					onInit={(_evt, editor) => editorRef.current = editor}
					initialValue="<p>This is the initial content of the editor.</p>"
					init={{
						height: 100,
						menubar: false,
						plugins: [
							'advlist', 'autolink', 'lists', 'link', 'charmap', 'preview',
							'fullscreen',
							'insertdatetime'
						],
						toolbar: 'undo redo | blocks | ' +
							'bold italic forecolor | alignleft aligncenter ' +
							'alignright alignjustify | bullist numlist outdent indent | ' +
							'removeformat ',
						content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
					}}
				/> */}
			</div>
		</div>
	);
}
