import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'tailwind-toast'
import _ from 'lodash'
import {
	Accordion,
	AccordionHeader,
	AccordionBody,
} from "@material-tailwind/react";
import { useNavigate } from 'react-router-dom';
import EditMasterChecklist from '../../modals/EditMasterChecklist';
import AddMasterChecklist from '../../modals/AddMasterChecklist';
import Confirmation from "../../modals/Confirmation"
import { crm } from "../../redux/crm/actions";
import { masterchecklistitems } from "../../redux/masterchecklistitems/actions.js";
import AddChecklist from '../../modals/AddChecklist';
import { selectCurrentCRM } from "../../redux/crm/selectors"
import { selectItemsFilter } from '../../redux/masterchecklistitems/selectors.js';
import storePersist from "../../redux/storePersist";


export default function MasterChecklist() {
	const dispatch = useDispatch();
	const navigate = useNavigate()
	const [open, setOpen] = React.useState(1);
	const handleOpen = (value) => setOpen(open === value ? 0 : value);

	const { activeMasterChecklist, reloadMasterChecklist } = useSelector(selectCurrentCRM)
	const { result } = useSelector(selectItemsFilter);
	const { items } = result;
	const [categoryName, setCategoryName] = useState([]);
	const [checkList, setCheckList] = useState([]);
	const _user = storePersist.get('user')

	useEffect(() => {
		dispatch(masterchecklistitems.filter({ entity: 'PDI_Master_Checklist_Items', jsonData: { params: { MasterChecklist: activeMasterChecklist?._id }, page: 1, limit: 250 } }))
	}, [])

	useEffect(() => {
		if (reloadMasterChecklist) {
			dispatch(masterchecklistitems.filter({ entity: 'PDI_Master_Checklist_Items', jsonData: { params: { MasterChecklist: activeMasterChecklist?._id }, page: 1, limit: 250 } }))
			dispatch(crm.trigger({ reloadMasterChecklist: false }))
		}
	}, [reloadMasterChecklist])

	useEffect(() => {
		let _categoryName = []
		let checkList = []

		items?.map((item, index) => {
			_categoryName.push(item?.CategoryName?.Name)
		})
		let uniqueCategoryList = [...new Set(_categoryName)]
		uniqueCategoryList?.map((obj) => {
			checkList.push(items.filter((item) => item.CategoryName?.Name == obj && item.MasterChecklist?._id == activeMasterChecklist?._id)) //&& item.ProjectType.Name == checklistProjectType
		})
		setCheckList(checkList)
		setCategoryName(uniqueCategoryList)
	}, [items])

	const handleEditMasterChecklist = (id, description, item) => {
		dispatch(crm.trigger({ editMasterChecklist: 'open', activeMasterTaskItem: description, activeCategory: item, activeMasterTaskId: id }))
	}

	const handleAddMasterChecklist = () => {
		toast().warning("WIP : ", "Sync with Project Checklist Pending").with({
			shape: "pill",
			duration: 2000,
			speed: 1000,
			positionX: 'end',
			icon: 'fa fa-check-circle',
			positionY: 'top',
			color: 'bg-red-50',
			fontColor: 'red',
			fontTone: 'bg-blue-100'
		}).show()
		// dispatch(crm.trigger({ addMasterChecklist: 'open' }))
	}

	return (
		<>
			<div className="flex flex-col item-center bg-[#F9F9FAFF]">
				<header className="flex justify-between h-24 w-full mx-auto px-5 items-center">
					<div className="flex flex-col justify-start w-full" >
						<nav aria-label="breadcrumb" className="w-max" >
							<ol className="flex flex-wrap items-center w-full px-4 py-1 rounded-md">
								<li
									className="flex items-center font-sans text-sm antialiased font-normal leading-normal transition-colors duration-300 cursor-pointer text-blue-gray-900 hover:text-light-blue-500">
									<h3 className="text-xl font-bold">Settings</h3>
								</li>
							</ol>
						</nav>
						<div className="flex justify-start w-full py-2 pr-10 pl-3" >
							<button onClick={() => navigate('/inspection-crm/settings')} className="w-auto mx-1 button   text-blue-600 px-3 rounded  text-xs font-bold"
							> Checklist
							</button>
							<button
								onClick={() => navigate('/inspection-crm/category')}
								className="w-auto button rounded  text-black text-opacity-40 px-3 rounded text-xs font-bold"
							> Cateogry
							</button>
							{_user?.roleId?._id == "5f616169b7b6a1367e1bfdb9" ? <button
								onClick={() => navigate('/inspection-crm/master-settings')}
								className="w-auto button rounded  text-black text-opacity-40 px-3 rounded text-xs font-bold"
							> Master Settings
							</button> : <></>}

						</div>
					</div>
				</header >
				<header className="flex justify-between h-20 w-full mx-auto px-5 items-center">
					<div className="flex justify-start w-full" >
						<nav aria-label="breadcrumb" className="w-max" >
							<ol className="flex flex-wrap items-center w-full px-4 py-2 rounded-md bg-blue-gray-50 bg-opacity-60">
								<li className="flex items-center font-sans text-sm antialiased font-normal leading-normal transition-colors duration-300 cursor-pointer text-blue-gray-900 hover:text-light-blue-500">
									<h3 className="text-xl font-bold">{activeMasterChecklist.Name}</h3>
								</li>
							</ol>
						</nav>
					</div>
					{/* <div className="flex justify-end w-full " >
						<button className="flex flex-row space-x-1 justify-center item-center rounded p-1 px-2 text-[11px] text-[#3C50E0] font-bold border-x border-y border-[#3C50E0]"
							onClick={() => {
								handleAddMasterChecklist()
							}} >
							<span>
								<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-plus"><path d="M5 12h14" /><path d="M12 5v14" /></svg>
							</span>  Add
						</button>
					</div> */}
				</header >
				<>
					<Confirmation />
					<AddChecklist />
					<EditMasterChecklist />
					<AddMasterChecklist />
					<div className="flex flex-col  px-3 mx-5 h-[600px]">
						{categoryName?.map((item, index) => {
							return <>
								<Accordion
									className="p-1"
									open={open == (index + 1)}>
									{checkList[index].length > 0 ? <AccordionHeader onClick={() => handleOpen(index + 1)} className="bg-white border-x border-y border-indigo-200 h-8">
										<div className="flex flex-row items-center justify-between px-10 dark:bg-blue-600" >
											<label className="text-sm dark:text-white text-black font-bold">
												{item}
											</label>
											<svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
												<path d="M7 10L12 15L17 10" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
											</svg>
											<div className="text-xs text-black font-bold text-opacity-50">&#40;{checkList[index].length} Items &#41;</div>
										</div>
									</AccordionHeader> : ""}
									<AccordionBody className="py-0 mt-1 -mb-1 ">
										{checkList[index].map((obj, idx) => {
											return <div className="flex flex-row item-center justify-center w-[95%] bg-white bg-opacity-50  h-8 px-10 border-x border-y w-[1300px] mx-4 my-1  border-black border-opacity-30 items-center  texrt-slate-200 text-sm dark:bg-blue-900/5">
												<div className="mr-auto text-xs font-bold text-black font-bold text-opacity-50	 w-[600px]">{obj.Description}</div>
												<button className="flex flex-row space-x-1 justify-center item-center rounded p-1 px-2 text-[11px] text-[#3C50E0] font-bold"
													onClick={() => {
														handleEditMasterChecklist(obj._id, obj.Description, obj.CategoryName._id)
													}} >
													<span>
														<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24"><path d="M19.045 7.401c.378-.378.586-.88.586-1.414s-.208-1.036-.586-1.414l-1.586-1.586c-.378-.378-.88-.586-1.414-.586s-1.036.208-1.413.585L4 13.585V18h4.413L19.045 7.401zm-3-3 1.587 1.585-1.59 1.584-1.586-1.585 1.589-1.584zM6 16v-1.585l7.04-7.018 1.586 1.586L7.587 16H6zm-2 4h16v2H4z" />
														</svg>
													</span>  Edit
												</button>

											</div>
										})}
									</AccordionBody>
								</Accordion ></>
						})}
					</div>
				</>
			</div>
		</>
	);
}
