
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Confirmation from "../../modals/Confirmation";
import { roles } from './../../redux/roles/actions'
import { selectListItems } from '../../redux/roles/selectors';
import { selectListItems as selectPermissionList } from '../../redux/permissions/selectors';
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux';
import { permissions } from '../../redux/permissions/actions';
export default function Permissions() {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const [search, setSearch] = useState('')
	const { result } = useSelector(selectListItems)
	const { items } = result
	const { result: permissionsResult } = useSelector(selectPermissionList)
	const { items: permissionsItems } = permissionsResult
	const [roleItems, setRoleItems] = useState([])
	const [permissionItems, setPermissionItems] = useState([])
	const [isSelectedArr, setIsSelectedArr] = useState([]);
	let displayIndex = -1

	const handlePermission = (role, _id) => {
		let _isSelectedArr = []
		isSelectedArr.map((item, index) => {
			const objKey = Object.keys(item)[1]
			const ObjValue = Object.values(item)[1]
			if (objKey == _id && item.role == role) {
				_isSelectedArr.push({ role: role, [objKey]: !ObjValue })
			}
			else {
				_isSelectedArr.push({ role: item.role, [objKey]: ObjValue })
			}
		})
		setIsSelectedArr(_isSelectedArr)
		const roleId = roleItems.find((object) => object?.name == role)?._id
		const permissionfilter = _isSelectedArr.filter((obj) => obj.role == role)
		let permissionIds = []
		permissionfilter.map((object) => {
			const objectKey = Object.keys(object)[1]
			const objectValue = Object.values(object)[1]
			if (objectValue) {
				permissionIds.push(objectKey)
			}
		})
		dispatch(roles.update({
			entity: 'roles', id: roleId, jsonData: {
				entity: {
					"permissions": permissionIds
				}
			}
		})).then(() => {
			dispatch(roles.list({ entity: 'roles' }))
		})
	}

	useEffect(() => {
		const _permissions = _.orderBy(permissionsItems, [item => item.displayIndex], ['asc']);
		setPermissionItems(_permissions)
		const _isSelectedArr = []
		roleItems?.map((obj) => {
			_permissions.map((item) => {
				const isPermission = obj?.permissions.filter((_permission) => _permission == item?._id)
				_isSelectedArr.push({ role: obj?.name, [item?._id]: isPermission?.length > 0 ? true : false })
			})
		})
		setIsSelectedArr(_isSelectedArr)
	}, [permissionsItems])

	useEffect(() => {
		const _roleItems = _.orderBy(items, [item => item.displayIndex], ['asc']);
		setRoleItems(_roleItems)
	}, [items])

	useEffect(() => {
		dispatch(roles.list({ entity: 'roles' }))
		dispatch(permissions.list({ entity: 'permissions' }))
	}, [])
	return <div className="h-full">
		<header className="flex justify-between h-24 w-full mx-auto px-5 dark:text-white items-center">
			<div className="flex flex-col justify-start w-full" >
				<nav aria-label="breadcrumb" className="w-max" >
					<ol className="flex flex-wrap items-center w-full px-4 py-1 rounded-md bg-blue-gray-50 bg-opacity-60">
						<li
							className="flex items-center font-sans text-sm antialiased font-normal leading-normal transition-colors duration-300 cursor-pointer text-blue-gray-900 hover:text-light-blue-500">
							<h3 className="text-xl font-bold">User Management</h3>
						</li>
					</ol>
				</nav>
				<div className="flex justify-start w-full py-2 mx-3" >
					<button onClick={() => navigate('/inspection-crm/user-management')} className="w-auto mx-1 button  bg-transparent   text-black  text-opacity-40  px-3 rounded  text-xs font-bold"
					> Members
					</button>
					<button onClick={() => navigate('/inspection-crm/permissions')} className="w-auto button rounded  bg-indigo-100 text-[#4069E5FF]   px-3 rounded text-xs font-bold"
					> Permission
					</button>
				</div>
			</div>
		</header>
		<Confirmation />
		<div className="flex flex-col  px-3 mx-5 h-full shadow-lg rounded bg-white dark:bg-[#2b2c37]">
			<div className="flex flex-wrap  px-4 py-2 bg-whtie overflow-auto">
				{roleItems?.map((item, index) => {
					return <div key={index} className="text-xs bg-white m-2 py-4 rounded shadow-xl w-[350px]">
						<div className="text-md font-bold mx-4 mt-4 mb-1">{item?.name}</div>
						<div className="felx flex-row justify-center item-center  space-y-1 mx-3 w-[350px] ">
							<div className="flex flex-row justify-center item-center border-b border-black border-opacity-40 py-2 px-2 mx-5" >
								<div className="w-[200px]">Capability</div>
								<div className="w-[100px]">Permission</div>
							</div>
							{permissionItems.map((permission, idx) => {
								displayIndex = displayIndex + 1
								return <div key={idx} className="flex flex-row space-x-10 border-black border-opacity-40 px-2 mx-5" >
									<div className="w-[200px] capitalize pt-1">{permission?.displayText}</div>
									<div className="w-[100px] text-left">
										<label for="Toggle3" className="inline-flex items-center p-1 rounded-md cursor-pointer dark:text-gray-800">
											<span
												onClick={(e) => handlePermission(item?.name, permission?._id)}
												className={`px-1 py-1 w-10 text-center text-xs font-semibold rounded-l ${isSelectedArr?.length > 0 && isSelectedArr[displayIndex]['role'] == item?.name && isSelectedArr[displayIndex][permission?._id] ? "bg-[#4069E5FF] text-white" : "bg-gray-300 text-black"}`} >
												Yes
											</span>
											<span
												onClick={(e) => handlePermission(item?.name, permission?._id)}
												className={`px-1 py-1 w-10 text-center text-xs font-semibold rounded-r ${isSelectedArr?.length > 0 && isSelectedArr[displayIndex]['role'] == item?.name && isSelectedArr[displayIndex][permission?._id] ? "bg-gray-300 text-black" : "bg-[#4069E5FF] text-white"}`} > No</span>
										</label>
									</div>
								</div>

							})}
						</div>
					</div >
				})}
			</div >
		</div>
	</div >
}