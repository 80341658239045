import { createSelector } from 'reselect';

const selectRoles = (state) => state.roles;

export const selectCurrentItem = createSelector([selectRoles], (roles) => roles.current);

export const selectListItems = createSelector([selectRoles], (roles) => roles.list);

export const selectItemById = (itemId) =>
	createSelector(selectListItems, (list) => list.result.items.find((item) => item._id === itemId));

export const selectCreatedItem = createSelector([selectRoles], (roles) => roles.create);

export const selectUpdatedItem = createSelector([selectRoles], (roles) => roles.update);

export const selectReadItem = createSelector([selectRoles], (roles) => roles.read);

export const selectDeletedItem = createSelector([selectRoles], (roles) => roles.delete);

export const selectSearchedItems = createSelector([selectRoles], (roles) => roles.search);

export const selectItemsCount = createSelector([selectRoles], (roles) => roles.count);

export const selectItemsFilter = createSelector([selectRoles], (roles) => roles.filter);