import { crm } from "../../redux/crm/actions";
import React, { useEffect, useState, useRef, useCallback } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentCRM } from '../../redux/crm/selectors';
import { selectListItems, selectUpdatedItem, selectItemsFilter as selectedIssue } from '../../redux/issuecards/selectors';
import { issuecards } from "../../redux/issuecards/actions";
import { selectListItems as selectListStatus } from '../../redux/issuestatus/selectors';
import { selectItemsFilter, selectCreatedItem } from '../../redux/comments/selectors';
import UploadImage from "../../modals/UploadImage";
import { request } from "../../request";
import { useNavigate } from "react-router-dom";
import { comments } from "../../redux/comments/actions";
import storePersist from "../../redux/storePersist";
import { issuestatus } from "../../redux/issuestatus/actions"
import { subscriber } from "../../redux/subscribers/actions";
import { useForm } from "react-hook-form";
import Moment from 'moment';
import axios from "axios";
import Comment from "./Comment";
import CameraComponent from "../../modals/CameraCapture";
import ImageAnnotationComponent from "../../modals/ImageAnnotationComponent";
import { selectItemsFilter as projectUserFiter } from '../../redux/projectusers/selectors';
import { projectusers } from "../../redux/projectusers/actions";

import IssueChangeType from "../../modals/IssueChangeType";
import { selectListItems as selectUserList } from '../../redux/users/selectors';
import { users } from "../../redux/users/actions";


export default function ViewIssueMobile() {
	const dispatch = useDispatch();
	const navigate = useNavigate()
	const [changeType, setChangeType] = useState(false)
	const [activeTab, setActiveTab] = useState('capture');
	const [attachmentList, setAttachmentList] = useState([])
	const [attachmentList1, setAttachmentList1] = useState([])
	const [imageUrl1, setImageUrl1] = useState([])
	const [profileUrl, setProfileUrl] = useState([])
	const [activeProfileUrl, setActiveProfileUrl] = useState('')
	const [commentValue, setCommentValue] = useState('')
	const { result: commentResult } = useSelector(selectItemsFilter);
	const { items: commentItems } = commentResult
	const { result: issueResult } = useSelector(selectedIssue)
	const [userIds, setUserIds] = useState([])
	const { result: userResult } = useSelector(selectUserList)
	const { items: useritems } = userResult
	const { items: issueItem } = issueResult
	const { result: updateResult } = useSelector(selectUpdatedItem);
	const [imageUrl, setImageUrl] = useState([])
	const { viewIssue, selectedCheckListItem, uploadImage, isPostFixIssue, activeProjectId, issueChangeType, commentAdded, isIssueCameraImage, activeIssueId, activeIssueImageKey, isCameraOn } = useSelector(selectCurrentCRM);
	const { result: issueStatus } = useSelector(selectListStatus);
	const { items: issuestatusitems } = issueStatus
	const { result: replies } = useSelector(selectCreatedItem)
	const [imageView, setImageView] = useState(false)
	const [imageView1, setImageView1] = useState(false)
	const [imageViewUrl, setImageViewUrl] = useState('')
	const [issue, setIssue] = useState({})
	const [status, setStatus] = useState('')
	const [qcEngineer, setQCEngineer] = useState('')
	const [audioUrl, setAudioUrl] = useState([]);
	const [commentImage, setCommentImage] = useState([]);
	const [isEdiable, setIsEditable] = useState(false)
	const [message, setMessage] = useState('')
	const commentRef = useRef();
	const [image, setImage] = useState([])
	const [fromWhom, setFromWhom] = useState('')
	const [towhom, setToWhom] = useState('')
	const [isLoading, setIsLoading] = useState(false);
	const { register, handleSubmit, clearErrors, formState: { errors }, setValue, getValues } = useForm()
	const { result: projectUserResult } = useSelector(projectUserFiter)
	const { items: projectUser } = projectUserResult
	const _user = storePersist.get('user')

	useEffect(() => {
		const _userIds = []
		Promise.all(projectUser.map((item) => {
			_userIds.push(item.User._id)
		}))
		if (_userIds.length > 0) {
			dispatch(crm.trigger({ projectSiteEngineer: _userIds }))
		}
		setUserIds(_userIds)
	}, [projectUser])

	const downloadAttachment = async (key) => {
		const url = "https://proptech-pdi.s3.amazonaws.com/" + key
		setImageViewUrl(url)
		setImageView(true)
	}

	const handleUpload = (type) => {
		dispatch(crm.trigger({ uploadImage: 'open', activeIssueId: issue?._id, isPostFixIssue: type }))
	}

	const reset = (initial) => {
		initial();
	}

	const uploadAudio = async (audioData) => {
		try {
			const response = await postData('https://api.greypath.co/api/v1/document/uploadurl', audioData);
			if (response.status === 200) {
				const uploadLinkResponse = await axios.put(response.data.uploadLink, audioData);
				if (uploadLinkResponse.status === 200) {
					return response.data.filename;
				}
			}
		} catch (error) {
			console.log("Error uploading audio", error);
			throw error;
		}
	};

	const uploadImgURl = async (imageDetails) => {
		try {
			if (imageDetails[0].src) {
				let { src, img, ...imgData } = imageDetails[0];
				const response = await request.uploadurl({ entity: 'document', jsonData: imgData });
				if (response.filename) {
					const res = await request.uploadfileAWSS3({ fileuploadurl: response.uploadLink, file: src, type: imgData.mime_type });
					if (res.status === 200) {
						return response.filename;
					}
				}
			}
		} catch (error) {
			console.log("Error uploading image", error);
			throw error;
		}
	};

	const log = async (text, audio, resetAudio) => {
		if (text === "" && audio === null && image.length === 0) {
			setIsLoading(false);
		} else {
			setIsLoading(true);
			try {
				setMessage(text);
				let audioFileKey = null;
				let imageFileKey = null;
				if (audio) {
					audioFileKey = await uploadAudio(audio);
				}
				if (image.length > 0) {
					imageFileKey = await uploadImgURl(image);
				}
				createComment(text, audioFileKey, imageFileKey, resetAudio);
			} catch (error) {
				console.log("Error in log function", error);
			}
		};
	}

	const createComment = (text, audioFileKey, imageFileKey, resetAudio) => {
		const myArray = text.split(' ')
		const startsWithAt = myArray.filter((arr) => arr.startsWith("@"));
		let users = []
		startsWithAt.map((obj) => {
			users.push(obj.replace('@', ''))
		})
		const textWithoutAt = myArray.filter((arr) => !arr.startsWith("@"));
		let newText = textWithoutAt.join(" ");
		console.log(startsWithAt, textWithoutAt, newText)
		if (newText || audioFileKey || imageFileKey) {
			dispatch(subscriber.send({ entity: 'PDI_Push_Subscriber', jsonData: { "entity": { "users": users, "title": 'Issue Comments Added', "body": newText } } }))
			const commentData = {
				entity: {
					replies: [],
					likes: [],
					content: newText,
					IssueId: null,
					audioFileKey: audioFileKey || null,
					imageFileKey: imageFileKey || null
				}
			};

			dispatch(comments.create({ entity: 'PDI_Comment', jsonData: commentData }));
			dispatch(crm.trigger({ commentAdded: true }));
		}
		setIsLoading(false);
		setImage([]);
		resetAudio();
	};

	const postData = async (url, audioData) => {
		const request = {
			extension: "mp3",
			file_name: "audioFile",
			mime_type: "audio/mpeg",
			size: audioData.size,
			entity_type: "audio"
		};

		const token = storePersist.get('auth')?.current
		const options = {
			mode: 'no-cors',
			cache: 'no-cache',
			credentials: 'include',
			headers: {
				'content-type': 'application/json',
				'Authorization': 'Bearer ' + token
			},
			redirect: 'follow',
			referrerPolicy: 'no-referrer',
		}
		try {
			const response = await axios.post(url, request, options);
			return response;
		} catch (error) {
			console.log("Error posting data", error);
			throw error;
		}
	};

	useEffect(() => {
		if (commentAdded) {
			dispatch(crm.trigger({ commentAdded: false, }))
			dispatch(comments.update({ entity: 'PDI_Comment', id: commentItems[0]?._id, jsonData: { entity: { replies: [replies?._id] } } })).then(() => {
				dispatch(comments.filter({ entity: 'PDI_Comment', jsonData: { params: { "IssueId": selectedCheckListItem?._id }, page: 1, limit: 200 } }))
			})
			setCommentValue('')
		}
	}, [replies])

	useEffect(() => {
		getDownloadUrl(commentItems);
		getAudioUrl().then(urls => {
			setAudioUrl(urls);

		}).catch(error => {
			console.error('Error fetching audio URLs:', error);
		});
		getCommentImgUrl().then(urls => {
			setCommentImage(urls);
		}).catch(error => {
			console.error('Error fetching Image URLs:', error);
		});
	}, [commentItems])

	const getAudioUrl = async () => {
		let audiodata = commentItems[0]?.replies?.map(item => {
			if (item?.audioFileKey) {
				return axios.post('https://api.greypath.co/api/v2/document/downloadurl', { key: item.audioFileKey })
					.then(response => response.data)
					.catch(err => {
						console.log(err);
						return null;
					});
			} else {
				return Promise.resolve(null);
			}
		});
		return Promise.all(audiodata);
	}

	const getCommentImgUrl = async () => {
		let ImgData = commentItems[0]?.replies.map(item => {
			if (item.imageFileKey) {
				return "https://proptech-pdi.s3.amazonaws.com/" + item.imageFileKey
				// .then(response => response)
				// .catch(err => {
				// 	console.log(err);
				// 	return null;
				// });
			} else {
				return Promise.resolve(null);
			}
		});
		return Promise.all(ImgData);
	}

	const getDownloadUrl = async (key) => {
		let data = "https://proptech-pdi.s3.amazonaws.com/" + key[0]?.userId?.profileImage
		setProfileUrl([...profileUrl, profileUrl[0] = data])
		if (key[0]?.replies.length > 0) {
			Promise.all(key[0]?.replies?.map(async (item, idx) => {
				let data = "https://proptech-pdi.s3.amazonaws.com/" + item?.userId?.profileImage
				setProfileUrl([...profileUrl, profileUrl[idx + 1] = data])
			}))
		}
		const user = storePersist.get('user')
		let data1 = "https://proptech-pdi.s3.amazonaws.com/" + user?.profileImage
		setActiveProfileUrl(data1)
	}

	const uploadCameraImg = async (imageDetails) => {
		let _attach = []
		await Promise.all(imageDetails?.map(async (attach, idx) => {
			let { src, img, ...imgData } = attach;
			const response = await request.uploadurl({ entity: 'document', jsonData: imgData });
			if (response.filename) {
				_attach.push({ filename: imgData.file_name, fileKey: response.filename })
				const res = await request.uploadfileAWSS3({ fileuploadurl: response.uploadLink, file: src, type: imgData.mime_type });
			}
		}))
		return _attach
	}

	useEffect(() => {
		if (image.length > 0 && isIssueCameraImage) {
			uploadCameraImg(image).then((cameraImg) => {
				if (isPostFixIssue) {
					dispatch(issuecards.update({
						entity: 'PDI_Issue_Cards',
						id: issue?._id,
						jsonData: {
							entity: {
								"ImageUrl": [...attachmentList1, ...cameraImg]
							}
						}
					})).then(() => {
						dispatch(crm.trigger({ reloadIssueCreated: true, isIssueCameraImage: false, isPostFixIssue: false }))
						const user = storePersist.get('user')
						if (user?.roleId?._id == "65dfe4766ad846404c6cc573") {
							navigate('/mobile/tasks')
						} else {
							navigate('/mobile/task-items')
						}
					})
				}
				else {
					dispatch(issuecards.update({
						entity: 'PDI_Issue_Cards',
						id: issue?._id,
						jsonData: {
							entity: {
								"Attachments": [...attachmentList, ...cameraImg]
							}
						}
					})).then(() => {
						dispatch(crm.trigger({ reloadIssueCreated: true, isIssueCameraImage: false }))
						const user = storePersist.get('user')
						if (user?.roleId?._id == "65dfe4766ad846404c6cc573") {
							navigate('/mobile/tasks')
						} else {
							navigate('/mobile/task-items')
						}
					})
				}
			}).then(() => {
				setImage([])
			})
		}
	}, [image])

	const handleCamera = (type) => {
		dispatch(crm.trigger({ isCameraOn: true, isIssueCameraImage: true, isPostFixIssue: type }))
	}

	const handleNavigate = () => {
		const _user = storePersist.get('user')
		if (_user?.roleId?._id == "65dfe45c6ad846404c6cc572") {
			navigate('/mobile/task-items')
		} else {
			navigate('/mobile/tasks')
		}
	}

	const handleEngineer = (e, item) => {
		setValue('SiteEngineer', e.target.value)
		setQCEngineer(e.target.value)
		setToWhom(e.target.value)
		dispatch(issuecards.update({ entity: 'PDI_Issue_Cards', id: item._id, jsonData: { entity: { SiteEngineer: e.target.value } } }))
		dispatch(crm.trigger({ issueChangeType: true }))
	}

	useEffect(() => {
	}, [status])

	const handleStatus = (e, item) => {
		setStatus(e.target.value)
		if (e.target.value == '659cdc77cbe502001aafb6c7') {
			dispatch(issuecards.update({ entity: 'PDI_Issue_Cards', id: item._id, jsonData: { entity: { Status: e.target.value, ActualCompletionDate: Date.now() } } })).then(() => {
			})
		}
		else {
			dispatch(issuecards.update({ entity: 'PDI_Issue_Cards', id: item._id, jsonData: { entity: { Status: e.target.value } } })).then(() => {
				navigate('/mobile/view-issue')
			})
		}
	}

	useEffect(() => {
		dispatch(comments.filter({ entity: 'PDI_Comment', jsonData: { params: { "IssueId": selectedCheckListItem?._id }, page: 1, limit: 200 } }))
		dispatch(issuestatus.list({ entity: 'PDI_Issue_Status', options: {} }))
		dispatch(projectusers.filter({ entity: 'PDI_Project_Users', jsonData: { params: { isDeleted: false, ProjectName: activeProjectId } }, page: 1, limit: 200 }))
		dispatch(users.list({ entity: 'users', options: {} }))
		const user = storePersist.get('user')
		if (user?.roleId?._id == '65dfe4766ad846404c6cc573') {
			setIsEditable(false)
		}
		else {
			setIsEditable(true)
		}
	}, [])

	useEffect(() => {
		setQCEngineer(selectedCheckListItem?.SiteEngineer?._id)
		setFromWhom(selectedCheckListItem?.SiteEngineer?._id)
		setIssue(selectedCheckListItem)
		setValue('SiteEngineer', selectedCheckListItem?.SiteEngineer?._id)
		dispatch(crm.trigger({ activeIssueId: selectedCheckListItem?._id }))
		setStatus(selectedCheckListItem?.Status?._id)
		let arr = []
		let imgUrl = []
		if (selectedCheckListItem?.Attachments.length > 0) {
			Promise.all(selectedCheckListItem?.Attachments?.map(async (attach, index) => {
				if (attach[0]) {
					arr.push(attach[0])
					let data = "https://proptech-pdi.s3.amazonaws.com/" + attach[0].fileKey
					imgUrl.push(data)
				}
			})).then(() => {
				setImageUrl(imgUrl)
				setAttachmentList(arr)
			})
		}
		let arr1 = []
		let imgUrl1 = []
		if (selectedCheckListItem?.ImageUrl?.length > 0) {
			Promise.all(selectedCheckListItem?.ImageUrl?.map(async (attach, index) => {
				if (attach[0]) {
					arr1.push(attach[0])
					let data = "https://proptech-pdi.s3.amazonaws.com/" + attach[0].fileKey

					imgUrl1.push(data)
				}
			})).then(() => {
				setImageUrl1(imgUrl1)
				setAttachmentList1(arr1)
			})
		}
	}, [selectedCheckListItem])

	const handleEditIssue = (issue) => {
		dispatch(crm.trigger({ activeIssueId: issue?._id, issueItem: issue, isEditIssue: true }))
		navigate('/mobile/add-issue')
	}

	useEffect(() => {
		if (activeIssueImageKey.length > 0) {
			if (isPostFixIssue) {
				dispatch(issuecards.update({
					entity: 'PDI_Issue_Cards',
					id: activeIssueId,
					jsonData: {
						entity: {
							"ImageUrl": [...activeIssueImageKey, ...attachmentList1],
						}
					}
				})).then(() => {
					dispatch(crm.trigger({ activeIssueImageKey: [], isPostFixIssue: false }))
					const user = storePersist.get('user')
					if (user?.roleId?._id == "65dfe4766ad846404c6cc573") {
						navigate('/mobile/tasks')
					} else {
						navigate('/mobile/task-items')
					}
				})
			}
			else {
				dispatch(issuecards.update({
					entity: 'PDI_Issue_Cards',
					id: activeIssueId,
					jsonData: {
						entity: {
							"Attachments": [...activeIssueImageKey, ...attachmentList],
						}
					}
				})).then(() => {
					dispatch(crm.trigger({ activeIssueImageKey: [] }))
					const user = storePersist.get('user')
					if (user?.roleId?._id == "65dfe4766ad846404c6cc573") {
						navigate('/mobile/tasks')
					} else {
						navigate('/mobile/task-items')
					}
				})
			}

		}
	}, [activeIssueImageKey])


	const handleDelete = async (obj) => {
		let temp = []
		temp = attachmentList.filter(attachment => attachment.fileKey !== obj?.fileKey);
		dispatch(issuecards.update({
			entity: 'PDI_Issue_Cards',
			id: activeIssueId,
			jsonData: {
				entity: {
					"Attachments": [...temp],
				}
			}
		})).then(() => {
			dispatch(crm.trigger({ activeIssueImageKey: [] }))
			const user = storePersist.get('user')
			if (user?.roleId?._id == "65dfe4766ad846404c6cc573") {
				navigate('/mobile/tasks')
			} else {
				navigate('/mobile/task-items')
			}
		})
		const token = storePersist.get('auth').current
		try {
			const response = await axios.delete('https://api.greypath.co/api/v1/document/deleteObject?key=' + obj?.fileKey, {
				method: 'DELETE',
				mode: 'no-cors',
				cache: 'no-cache',
				credentials: 'include',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + token,
				},
				// redirect: 'follow',
				referrerPolicy: 'no-referrer',
			});
			return response.data;
		} catch (error) {
			return console.log(error);
		}
	}

	const handleDelete1 = async (obj) => {
		let temp = []
		temp = attachmentList1.filter(attachment => attachment.fileKey !== obj?.fileKey);
		dispatch(issuecards.update({
			entity: 'PDI_Issue_Cards',
			id: activeIssueId,
			jsonData: {
				entity: {
					"ImageUrl": [...temp],
				}
			}
		})).then(() => {
			dispatch(crm.trigger({ activeIssueImageKey: [] }))
			const user = storePersist.get('user')
			if (user?.roleId?._id == "65dfe4766ad846404c6cc573") {
				navigate('/mobile/tasks')
			} else {
				navigate('/mobile/task-items')
			}
		})
		const token = storePersist.get('auth').current
		try {
			const response = await axios.delete('https://api.greypath.co/api/v1/document/deleteObject?key=' + obj?.fileKey, {
				method: 'DELETE',
				mode: 'no-cors',
				cache: 'no-cache',
				credentials: 'include',
				headers: {
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + token,
				},
				// redirect: 'follow',
				referrerPolicy: 'no-referrer',
			});
			return response.data;
		} catch (error) {
			return console.log(error);
		}
	}

	useEffect(() => {
		console.log(imageView)
	}, [imageView])

	return (
		<>
			{issueChangeType ? <IssueChangeType fromWhom={fromWhom} toWhom={towhom} /> : ""}
			{uploadImage == 'open' ? <UploadImage /> : ""}
			{isCameraOn ? <CameraComponent setImage={setImage} /> : ""}
			<div style={{ display: `${isCameraOn ? `none` : `block`}` }} className="max-h-screen max-w-screen  bg-[#F9F9FA]">
				<header className="flex fixed top-0 left-0  justify-center w-screen h-20 items-center bg-[#3C50E0FF] text-white z-10">
					<div className="flex flex-row text-xl font-bold">
						<button
							onClick={() => handleNavigate()}
							className="absolute fixed left-10 cursor-pointer">
							<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#ffffff" viewBox="0 0 24 24"><path d="M21 11H6.414l5.293-5.293-1.414-1.414L2.586 12l7.707 7.707 1.414-1.414L6.414 13H21z" /></svg>
						</button>View Issue
					</div>
				</header >
				{imageView && <ImageAnnotationComponent setImageView={setImageView} imageViewUrl={imageView} />}
				{imageView1 && <ImageAnnotationComponent setImageView={setImageView1} imageViewUrl={imageView1} />}
				<div className="scroll-smooth pb-10">
					<div className="flex flex-col mt-24 px-3 w-screen ">
						<div className="flex flex-row items-center justify-between px-2">
							<div className="text-md py-2 font-semibold">Task ID - {issue?.IssueNumber} </div>
							{isEdiable ? <div className="pl-auto px-2 cursor-pointer" onClick={() => handleEditIssue(issue)}>
								<svg className="w-4 h-4" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" width="24"
									xmlns="http://www.w3.org/2000/svg">
									<path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" />
									<path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" />
								</svg>
							</div> : <></>}
						</div>
						<div className="flex flex-row items-center justify-between px-2 text-black text-opacity-80 text-xl font-bold">{issue?.IssueName}</div>
						<table className="w-max flex justify-start space-y-3">
							<tbody className="px-2">
								<tr className="h-10">
									<td className="p-1">
										<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#000000" viewBox="0 0 256 256"><path d="M128,96a32,32,0,1,0,32,32A32,32,0,0,0,128,96Zm0,48a16,16,0,1,1,16-16A16,16,0,0,1,128,144ZM48,96a32,32,0,1,0,32,32A32,32,0,0,0,48,96Zm0,48a16,16,0,1,1,16-16A16,16,0,0,1,48,144ZM208,96a32,32,0,1,0,32,32A32,32,0,0,0,208,96Zm0,48a16,16,0,1,1,16-16A16,16,0,0,1,208,144Z"></path></svg>
									</td>
									<td className="w-1/2 text-black text-opacity-80  text-xs   font-normal my-2 px-1 rounded-xl">
										Category Name
									</td>
									<td className="w-1/2 text-black text-opacity-80  text-xs  w-[150px] font-normal my-2 px-1 rounded-x">
										{issue?.CategoryName?.Name}
									</td>
								</tr>
								<tr className="h-10">
									<td>
										<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-trending-up"><polyline points="22 7 13.5 15.5 8.5 10.5 2 17" /><polyline points="16 7 22 7 22 13" /></svg>
									</td>
									<td className="text-black text-opacity-80  text-xs   font-normal my-2 px-1 rounded-xl">
										Priority
									</td>
									<td className="text-xs font-normal my-2  rounded-xl  px-2">
										<span className="bg-[#4069E5FF] p-1 text-[#FFFFFF]">{issue?.Priority?.Name}</span>
									</td>
								</tr>
								<tr className="h-10">
									<td>
										<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#000000" viewBox="0 0 256 256"><path d="M248,56a8,8,0,0,1-8,8H192v40a8,8,0,0,1-8,8H136v40a8,8,0,0,1-8,8H80v40a8,8,0,0,1-8,8H16a8,8,0,0,1,0-16H64V152a8,8,0,0,1,8-8h48V104a8,8,0,0,1,8-8h48V56a8,8,0,0,1,8-8h56A8,8,0,0,1,248,56Z"></path></svg>
									</td>
									<td className="text-black text-opacity-80  text-xs   font-normal my-2 px-1 rounded-xl">
										Status
									</td>
									<td className="text-xs font-normal my-2  rounded-xl  w-max">
										<select
											name="IssueStatus"
											className="w-full py-1 text-black bg-opacity-0 text-xs"
											onChange={(e) => handleStatus(e, issue)}
											value={status}>
											<option value="" key={0}> --Select--
											</option>
											{issuestatusitems?.map((item, index) => {
												return <option value={item?._id} key={index}> {item?.Name}
												</option>
											})}
										</select>
									</td>
								</tr>
								<tr className="h-10">
									<td>
										<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-user"><path d="M19 21v-2a4 4 0 0 0-4-4H9a4 4 0 0 0-4 4v2" /><circle cx="12" cy="7" r="4" /></svg>
									</td>
									<td className="text-black text-opacity-80  text-xs   font-normal my-2 px-1 rounded-xl">
										CRM Executive
									</td>
									<td className="text-black text-opacity-80  text-xs  w-[150px] font-normal my-2 px-1 rounded-x">
										{issue?.CRMExecutive?.userId}
									</td>
								</tr>
								<tr className="h-10">
									<td>
										<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-user"><path d="M19 21v-2a4 4 0 0 0-4-4H9a4 4 0 0 0-4 4v2" /><circle cx="12" cy="7" r="4" /></svg>
									</td>
									<td className="text-black text-opacity-80  text-xs   font-normal my-2 px-1 rounded-xl">
										QC/Engineer
									</td>
									{_user?.roleId?._id == "65dfe45c6ad846404c6cc572" ?
										<td className="text-xs font-normal my-2  rounded-xl  w-max">
											<select
												className="w-full py-1 text-black bg-opacity-0 text-xs capitalize"
												id="SiteEngineer"
												name="SiteEngineer"
												{...register('SiteEngineer', { required: true })}
												value={qcEngineer}
												onChange={(e) => handleEngineer(e, issue)}
											>
												<option value="">--Select--</option>
												{useritems?.filter(item1 => userIds?.some(item2 => item2 == item1._id))?.filter((obj) => obj?.roleId?._id == '65dfe4766ad846404c6cc573')?.map((item, index) => {
													return <option value={item._id} key={index}> {item.userId}</option>
												})}
											</select>
										</td> : <td className="text-black text-opacity-80  text-xs  w-[150px] font-normal my-2 px-1 rounded-x">
											{issue?.SiteEngineer?.userId}
										</td>}
								</tr>
								<tr className="h-10">
									<td>
										<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-calendar"><path d="M8 2v4" /><path d="M16 2v4" /><rect width="18" height="18" x="3" y="4" rx="2" /><path d="M3 10h18" /></svg>
									</td>
									<td className="text-black text-opacity-80  text-xs   font-normal my-2 px-1 rounded-xl">
										Expected  Date
									</td>
									<td className="text-black text-opacity-80  text-xs  w-[150px] font-normal my-2 px-1 rounded-x">
										{Moment(issue?.ExpectedCompletionDate)?.format('DD-MMM-YY')}
									</td>
								</tr>
								<tr className="h-10">
									<td>
										<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-calendar"><path d="M8 2v4" /><path d="M16 2v4" /><rect width="18" height="18" x="3" y="4" rx="2" /><path d="M3 10h18" /></svg>
									</td>
									<td className="text-black text-opacity-80  text-xs   font-normal my-2 px-1 rounded-xl">
										Actual Finish Date
									</td>
									<td className="text-black text-opacity-80  text-xs  w-[150px] font-normal my-2 px-1 rounded-x">
										{Moment(issue?.ActualCompletionDate).format('DD-MMM-YY')}
									</td>
								</tr>
							</tbody>
						</table>
						<div className="h-max pb-5">
							<div className="flex justify-around border-b border-gray-300">
								<button
									className={`py-2 px-2 text-xs font-medium ${activeTab === 'capture' ? 'text-blue-500 border-b-2 border-blue-500' : 'text-gray-600'
										}`}
									onClick={() => setActiveTab('capture')}
								>
									Capture Issue Image <span className="text-xs text-red-500 border-x px-2 border-red-500 rounded-full -mt-3">{attachmentList.length}</span>
								</button>
								<button
									className={`py-2 px-2 text-xs font-medium ${activeTab === 'postfix' ? 'text-blue-500 border-b-2 border-blue-500' : 'text-gray-600'
										}`}
									onClick={() => setActiveTab('postfix')}
								>

									Post-Fix Image <span className="text-xs text-red-500 border-x px-2 border-red-500 rounded-full -mt-3">{attachmentList1.length}</span>
								</button>
							</div>
							<div className="mt-4 w-[390px] max-w-[390px]">
								{activeTab === 'capture' && (
									<div className="flex flex-col w-[390px] ">
										<div className="flex flex-row item-center justify-end w-[350px] max-w-[350px] py-3">
											<button
												className="mr-2 flex flex-row spaxce-x-1 font-bold text-xs items-center text-white bg-[#635fc7] px-2 py-1 mx-3  rounded"
												disabled={isLoading ? true : false}
												onClick={() => handleCamera(false)} >
												<svg className="mr-2" width="12px" height="12px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
													<g id="SVGRepo_bgCarrier" strokeWidth="0" />
													<g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
													<g id="SVGRepo_iconCarrier"> <circle cx="12" cy="13" r="3" stroke="#fff" strokeWidth="1.5" /> <path d="M9.77778 21H14.2222C17.3433 21 18.9038 21 20.0248 20.2646C20.51 19.9462 20.9267 19.5371 21.251 19.0607C22 17.9601 22 16.4279 22 13.3636C22 10.2994 22 8.76721 21.251 7.6666C20.9267 7.19014 20.51 6.78104 20.0248 6.46268C19.3044 5.99013 18.4027 5.82123 17.022 5.76086C16.3631 5.76086 15.7959 5.27068 15.6667 4.63636C15.4728 3.68489 14.6219 3 13.6337 3H10.3663C9.37805 3 8.52715 3.68489 8.33333 4.63636C8.20412 5.27068 7.63685 5.76086 6.978 5.76086C5.59733 5.82123 4.69555 5.99013 3.97524 6.46268C3.48995 6.78104 3.07328 7.19014 2.74902 7.6666C2 8.76721 2 10.2994 2 13.3636C2 16.4279 2 17.9601 2.74902 19.0607C3.07328 19.5371 3.48995 19.9462 3.97524 20.2646C5.09624 21 6.65675 21 9.77778 21Z" stroke="#fff" strokeWidth="1.5" /> <path d="M19 10H18" stroke="#fff" strokeWidth="2" strokeLinecap="round" /> </g>
												</svg>
												Camera
											</button>
											<button
												className="flex flex-row items-center text-white text-xs bg-[#635fc7] px-2 py-1 mx-3 rounded text-xs font-bold"
												onClick={() => handleUpload(false)}>
												<svg className="mr-2" width="10px" height="10px" version="1.1" id="Layer_1" fill="#fff" viewBox="0 0 280.067 280.067">
													<g>
														<path d="M149.823,257.142c-31.398,30.698-81.882,30.576-113.105-0.429
		c-31.214-30.987-31.337-81.129-0.42-112.308l-0.026-0.018L149.841,31.615l14.203-14.098c23.522-23.356,61.65-23.356,85.172,0
		s23.522,61.221,0,84.586l-125.19,123.02l-0.044-0.035c-15.428,14.771-40.018,14.666-55.262-0.394
		c-15.244-15.069-15.34-39.361-0.394-54.588l-0.044-0.053l13.94-13.756l69.701-68.843l13.931,13.774l-83.632,82.599
		c-7.701,7.596-7.701,19.926,0,27.53s20.188,7.604,27.88,0L235.02,87.987l-0.035-0.026l0.473-0.403
		c15.682-15.568,15.682-40.823,0-56.39s-41.094-15.568-56.776,0l-0.42,0.473l-0.026-0.018l-14.194,14.089L50.466,158.485
		c-23.522,23.356-23.522,61.221,0,84.577s61.659,23.356,85.163,0l99.375-98.675l14.194-14.089l14.194,14.089l-14.194,14.098
		l-99.357,98.675C149.841,257.159,149.823,257.142,149.823,257.142z"/>
													</g>
												</svg>
												Upload
											</button>
										</div>
										<div className="w-[350px] overflow-auto">
											<div className=" flex flex-row space-x-2   w-max py-3">
												{isIssueCameraImage && image?.map((obj, index) => {
													return <div key={index} className="flex flex-row jusity-center item-center h-full">
														<svg
															xmlns="http://www.w3.org/2000/svg" width="48px" height="48px"
															fill="#1C49D0ff" viewBox="0 0 24 24">
															<path d="M7.782 14.576c-.186 0-.312.018-.377.036v1.193c.077.018.174.023.306.023.485 0 .785-.246.785-.659 0-.371-.258-.593-.714-.593z" /><path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8l-6-6zM9.03 16.105c-.313.293-.774.426-1.313.426-.12 0-.229-.007-.312-.019v1.445h-.906V13.97a7.504 7.504 0 0 1 1.235-.083c.563 0 .966.107 1.235.323.258.204.432.54.432.936s-.131.731-.371.959zm4.302 1.853h-.96l-.863-1.56c-.24-.432-.504-.953-.701-1.427l-.019.006c.024.534.036 1.104.036 1.763v1.218h-.84v-4.042h1.067l.84 1.481c.24.426.479.93.659 1.385h.019a14.746 14.746 0 0 1-.078-1.685v-1.182h.84v4.043zm4.169-.186a4.512 4.512 0 0 1-1.349.228c-.737 0-1.271-.186-1.644-.546-.371-.348-.575-.875-.569-1.469.006-1.344.983-2.111 2.309-2.111.521 0 .924.103 1.121.198l-.191.731c-.222-.096-.498-.174-.941-.174-.762 0-1.338.432-1.338 1.308 0 .833.522 1.325 1.271 1.325.21 0 .378-.024.45-.061v-.846h-.624v-.713h1.505v2.13zM14 9h-1V4l5 5h-4z" /></svg>
														<div className="w-1/2 m-2  pt-1">
															<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M17 6H22V8H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V8H2V6H7V3C7 2.44772 7.44772 2 8 2H16C16.5523 2 17 2.44772 17 3V6ZM18 8H6V20H18V8ZM13.4142 13.9997L15.182 15.7675L13.7678 17.1817L12 15.4139L10.2322 17.1817L8.81802 15.7675L10.5858 13.9997L8.81802 12.232L10.2322 10.8178L12 12.5855L13.7678 10.8178L15.182 12.232L13.4142 13.9997ZM9 4V6H15V4H9Z"></path></svg>
															<img src={obj?.img} width={"100"} alt='captured' />
														</div>
													</div>
												})}
												{attachmentList?.map((obj, index) => {
													return <div
														key={index}
														className="w-44 flex flex-row justify-start items-start m-2 mx-4 pt-1 cursor-pointer"
													>
														<img src={imageUrl[index]} onClick={() => setImageView(imageUrl[index])} className="object-scale-down  h-36 w-36 shadow-xl p-1 bg-white rounded " />
														<svg xmlns="http://www.w3.org/2000/svg" onClick={() => handleDelete(obj)} className="w-8 h-7 cursor-pointer  -mb-3  p-1 rounded  bg-red-600 text-white" viewBox="0 0 24 24" fill="currentColor"><path d="M17 6H22V8H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V8H2V6H7V3C7 2.44772 7.44772 2 8 2H16C16.5523 2 17 2.44772 17 3V6ZM18 8H6V20H18V8ZM13.4142 13.9997L15.182 15.7675L13.7678 17.1817L12 15.4139L10.2322 17.1817L8.81802 15.7675L10.5858 13.9997L8.81802 12.232L10.2322 10.8178L12 12.5855L13.7678 10.8178L15.182 12.232L13.4142 13.9997ZM9 4V6H15V4H9Z"></path></svg>

													</div>
												})}
											</div>
										</div>
									</div>
								)}
								{activeTab === 'postfix' && (
									<div className="flex flex-col">
										<div className="flex flex-row item-center justify-end py-3">
											<button
												className="mr-2 flex flex-row spaxce-x-1 font-bold text-xs items-center text-white bg-[#635fc7] px-2 py-1 mx-3  rounded"
												disabled={isLoading ? true : false}
												onClick={() => handleCamera(true)} >
												<svg className="mr-2" width="12px" height="12px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
													<g id="SVGRepo_bgCarrier" strokeWidth="0" />
													<g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
													<g id="SVGRepo_iconCarrier"> <circle cx="12" cy="13" r="3" stroke="#fff" strokeWidth="1.5" /> <path d="M9.77778 21H14.2222C17.3433 21 18.9038 21 20.0248 20.2646C20.51 19.9462 20.9267 19.5371 21.251 19.0607C22 17.9601 22 16.4279 22 13.3636C22 10.2994 22 8.76721 21.251 7.6666C20.9267 7.19014 20.51 6.78104 20.0248 6.46268C19.3044 5.99013 18.4027 5.82123 17.022 5.76086C16.3631 5.76086 15.7959 5.27068 15.6667 4.63636C15.4728 3.68489 14.6219 3 13.6337 3H10.3663C9.37805 3 8.52715 3.68489 8.33333 4.63636C8.20412 5.27068 7.63685 5.76086 6.978 5.76086C5.59733 5.82123 4.69555 5.99013 3.97524 6.46268C3.48995 6.78104 3.07328 7.19014 2.74902 7.6666C2 8.76721 2 10.2994 2 13.3636C2 16.4279 2 17.9601 2.74902 19.0607C3.07328 19.5371 3.48995 19.9462 3.97524 20.2646C5.09624 21 6.65675 21 9.77778 21Z" stroke="#fff" strokeWidth="1.5" /> <path d="M19 10H18" stroke="#fff" strokeWidth="2" strokeLinecap="round" /> </g>
												</svg>
												Camera
											</button>
											<button
												className="flex flex-row items-center text-white text-xs bg-[#635fc7] px-2 py-1 mx-3 rounded text-xs font-bold"
												onClick={() => handleUpload(true)}>
												<svg className="mr-2" width="10px" height="10px" version="1.1" id="Layer_1" fill="#fff" viewBox="0 0 280.067 280.067">
													<g>
														<path d="M149.823,257.142c-31.398,30.698-81.882,30.576-113.105-0.429
		c-31.214-30.987-31.337-81.129-0.42-112.308l-0.026-0.018L149.841,31.615l14.203-14.098c23.522-23.356,61.65-23.356,85.172,0
		s23.522,61.221,0,84.586l-125.19,123.02l-0.044-0.035c-15.428,14.771-40.018,14.666-55.262-0.394
		c-15.244-15.069-15.34-39.361-0.394-54.588l-0.044-0.053l13.94-13.756l69.701-68.843l13.931,13.774l-83.632,82.599
		c-7.701,7.596-7.701,19.926,0,27.53s20.188,7.604,27.88,0L235.02,87.987l-0.035-0.026l0.473-0.403
		c15.682-15.568,15.682-40.823,0-56.39s-41.094-15.568-56.776,0l-0.42,0.473l-0.026-0.018l-14.194,14.089L50.466,158.485
		c-23.522,23.356-23.522,61.221,0,84.577s61.659,23.356,85.163,0l99.375-98.675l14.194-14.089l14.194,14.089l-14.194,14.098
		l-99.357,98.675C149.841,257.159,149.823,257.142,149.823,257.142z"/>
													</g>
												</svg>
												Upload
											</button>
										</div>
										<div className="w-[350px] overflow-auto">
											<div className=" flex flex-row space-x-2 w-max py-3">
												{isIssueCameraImage && image?.map((obj, index) => {
													return <div
														key={index}
														className="flex w-full flex-row jusity-center item-center h-full">
														<svg
															xmlns="http://www.w3.org/2000/svg" width="48px" height="48px"
															fill="#1C49D0ff" viewBox="0 0 24 24">
															<path d="M7.782 14.576c-.186 0-.312.018-.377.036v1.193c.077.018.174.023.306.023.485 0 .785-.246.785-.659 0-.371-.258-.593-.714-.593z" /><path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8l-6-6zM9.03 16.105c-.313.293-.774.426-1.313.426-.12 0-.229-.007-.312-.019v1.445h-.906V13.97a7.504 7.504 0 0 1 1.235-.083c.563 0 .966.107 1.235.323.258.204.432.54.432.936s-.131.731-.371.959zm4.302 1.853h-.96l-.863-1.56c-.24-.432-.504-.953-.701-1.427l-.019.006c.024.534.036 1.104.036 1.763v1.218h-.84v-4.042h1.067l.84 1.481c.24.426.479.93.659 1.385h.019a14.746 14.746 0 0 1-.078-1.685v-1.182h.84v4.043zm4.169-.186a4.512 4.512 0 0 1-1.349.228c-.737 0-1.271-.186-1.644-.546-.371-.348-.575-.875-.569-1.469.006-1.344.983-2.111 2.309-2.111.521 0 .924.103 1.121.198l-.191.731c-.222-.096-.498-.174-.941-.174-.762 0-1.338.432-1.338 1.308 0 .833.522 1.325 1.271 1.325.21 0 .378-.024.45-.061v-.846h-.624v-.713h1.505v2.13zM14 9h-1V4l5 5h-4z" /></svg>
														<div className="w-1/2 m-2  pt-1">
															<img src={obj?.img} width={"100"} alt='captured' />
														</div>
													</div>
												})}
												{attachmentList1?.map((obj, index) => {
													return <div
														key={index}
														className="w-44 flex flex-row justify-start items-start m-2 mx-4 pt-1 cursor-pointer"
														onClick={() => setImageView1(imageUrl1[index])}>
														<img src={imageUrl1[index]} className="object-scale-down  h-32 w-32 shadow-xl p-1 bg-white rounded " />
														<svg xmlns="http://www.w3.org/2000/svg" onClick={() => handleDelete1(obj)} className="w-8 h-7 cursor-pointer  -mb-3  p-1 rounded  bg-red-600 text-white" viewBox="0 0 24 24" fill="currentColor"><path d="M17 6H22V8H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V8H2V6H7V3C7 2.44772 7.44772 2 8 2H16C16.5523 2 17 2.44772 17 3V6ZM18 8H6V20H18V8ZM13.4142 13.9997L15.182 15.7675L13.7678 17.1817L12 15.4139L10.2322 17.1817L8.81802 15.7675L10.5858 13.9997L8.81802 12.232L10.2322 10.8178L12 12.5855L13.7678 10.8178L15.182 12.232L13.4142 13.9997ZM9 4V6H15V4H9Z"></path></svg>
													</div>
												})}
											</div>
										</div>
									</div>
								)}
							</div>
						</div>
						<div className="px-2 flex flex-wrap justify-center align-middle  border-b-2 border-black border-opacity-10" >
							<div className="w-full flex flex-col item-center justify-between mx-2 my-4">
								<span className="text-grey-600 text-xs font-bold">Description</span>
								<span className="text-grey-600 text-xs font-normal">{issue?.Description}</span>
							</div>
						</div>
					</div>
					<div className="flex flex-col  w-full justify-center  items-center space-y-3 py-5 pb-[100px]">
						{commentItems[0]?.replies?.map((comment, index) => {
							return <div key={index} ref={commentRef} className="flex  flex-row-reverse p-4 rounded-[16px] shadow-lg justify-end  w-4/5 m-2 ">
								<div className="flex flex-col">
									<div className="break-keep flex-shrink-2 mr-2" dangerouslySetInnerHTML={{ __html: comment?.content }} />

									{audioUrl[index] && (
										<audio controls className="w-[200px] mt-2 my-2">
											<source src={audioUrl[index]} type="audio/mpeg" />
											Your browser does not support the audio element.
										</audio>
									)}
									{commentImage[index] != null && (<img className="rounded-[8px]" src={commentImage[index]} alt="commentImage" />)}
								</div>
								<img
									className="h-7 w-7 mx-2 rounded-full"
									src={profileUrl[index + 1] ? profileUrl[index + 1] : ""}
									alt="avatar"
								/>
							</div>
						})
						}
					</div >
				</div>
			</div >
			<Comment reset={reset} message={message} setIsLoading={setIsLoading} isLoading={isLoading} setImage={setImage} setmessage={setMessage} images={image} saveComment={log}></Comment>
		</>
	);
}