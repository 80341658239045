import { crm } from "../redux/crm/actions";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentCRM } from '../redux/crm/selectors';
import { selectListItems } from '../redux/categoryname/selectors';
import { categoryname } from "../redux/categoryname/actions";
import { inspectionchecklist } from "../redux/inspectionchecklist/actions.js";

export default function EditMasterChecklist() {
	const dispatch = useDispatch();
	const { editMasterChecklist, activeMasterTaskItem, activeCategory, activeMasterTaskId } = useSelector(selectCurrentCRM);
	const { result } = useSelector(selectListItems);
	const { items } = result
	const [description, setDescription] = useState('')
	const [category, setCategory] = useState('')

	const handleCancel = () => {
		dispatch(crm.trigger({ editMasterChecklist: 'close' }))
	}

	const handleUpdate = () => {
		dispatch(inspectionchecklist.update({ entity: 'PDI_Master_Checklist_Items', id: activeMasterTaskId, jsonData: { entity: { Description: description, CategoryName: category } } })).then(() => {
			dispatch(crm.trigger({ editMasterChecklist: 'close', reloadMasterChecklist: true }))
		})
	}

	useEffect(() => {
		setDescription(activeMasterTaskItem)
		setCategory(activeCategory)
	}, [activeMasterTaskItem])

	useEffect(() => {
		dispatch(categoryname.list({ entity: 'PDI_Category_Name' }))
	}, [])

	return (
		<>
			{editMasterChecklist == 'open' ? (
				<>
					<div className="absolute fixed inset-0 mt-20 z-50 flex  justify-center align-middle w-screen">
						<div className="w-[500px] p-4">
							<div className="rounded-lg shadow-lg flex flex-col bg-white px-3 py-2 ">
								<div className="flex items-start justify-between px-4 py-1 mt-5">
									<h3 className="text-sm font-bold text-[#323842FF]">
										Task Edit
									</h3>
								</div>
								<div className="flex flex-wrap justify-center align-middle" >
									<div className="w-full flex flex-col item-center justify-between  px-4 py-2">
										<span className="text-[#323842FF] text-xs font-bold my-1">Select/Add Category</span>
										<div className="px-3 border-0  bg-[#F3F4F6FF] text-xs " >
											<select className="w-full border-0 py-1 bg-black bg-opacity-0 text-xs"
												onChange={(e) => setCategory(e.target.value)}
												value={category}>
												{items.map((item, index) => {
													return <option value={item._id} key={index}> {item.Name}</option>
												})}
											</select>
										</div>
									</div>
								</div>
								<div className="flex flex-wrap justify-center align-middle" >
									<div className="w-full flex flex-col item-center justify-between  px-4 py-2">
										<span className="text-[#323842FF] text-xs font-bold my-1">Task Details</span>
										<div className="px-3 border-0  bg-[#F3F4F6FF] text-xs " >
											<input
												type="text"
												placeholder="Name of Task"
												name={"description"}
												className="w-full py-1 bg-[#F3F4F6FF] text-xs  "
												onChange={(e) => setDescription(e.target.value)}
												value={description}
											/>
										</div>
									</div>
								</div>
								<div className="flex items-center justify-center px-6 py-4 mx-3 space-x-3">
									<button
										className="font-semibold  px-2 text-xs rounded border-x border-y  border-[#1C49D0FF] text-[#1C49D0FF] py-1"
										type="button"
										onClick={() => handleCancel()}
									>
										Cancel
									</button>
									<button
										className=" bg-[#1C49D0FF] text-white  font-bold px-2 rounded text-xs py-1"
										type="button"
										onClick={() => handleUpdate()}
									>
										Update
									</button>
								</div>
							</div>
						</div>
					</div>
					<div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
				</>
			) : null}
		</>
	);
}