import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentCRM } from "../../redux/crm/selectors"
import Confirmation from "../../modals/Confirmation.js";
import { useNavigate } from 'react-router-dom';
import { crm } from "../../redux/crm/actions";
import { request } from '../../request/index';
import axios from 'axios';
import storePersist from './../../redux/storePersist';

import { projectchecklist } from '../../redux/projectchecklist/actions';

export default function ImportCustomer() {
	const navigate = useNavigate()
	const dispatch = useDispatch();
	const [isActiveBtn, setIsActiveBtn] = useState(false)
	const { activeBoardName, activeProjectId } = useSelector(selectCurrentCRM)
	const [file, setFile] = useState(null);

	const handleImportCustomer = () => {
		document.getElementById('excelfile').click()
	}

	const handleFileChange = async (e) => {
		if (e.target.files) {
			//const _file = e.target.files[0]
			setFile(e.target.files[0]);
			dispatch(crm.trigger({
				activeDocumentId: '',
				activeDocumentFile: '',
				activeUploadLink: '',
				activeDocumentFilename: '',
				activeDownloadedUrl: ''
			}))


		}
	}

	const upload = async () => {
		if (file) {
			setIsActiveBtn(true)
			const exte = file.name.substring(file.name.indexOf(".") + 1);
			const docDetails = {
				extension: exte,
				file_name: file.name,
				mime_type: file.type,
				entity_type: "excel",
				size: file.size,
			};
			let data = await request.uploadurl({ entity: 'document', jsonData: docDetails, options: {} })
			dispatch(crm.trigger({
				activeDocumentId: data.document_id,
				activeDocumentFileKey: data.filename,
				activeUploadLink: data.uploadLink,
				activeDocumentFilename: file.name
			}))
			const type = file.type
			await request.uploadfileAWSS3({ fileuploadurl: data.uploadLink, file, type }).then(async () => {
				await request.importExcel({ key: data.filename, collectionName: 'PDI_Customer_Details', projectId: activeProjectId })
				const fileInput = document.getElementById('excelfile')
				fileInput.value = null
			})
			setIsActiveBtn(false)
			navigate('/inspection-crm/inspection-items')
		}
	}

	const handleProject = () => {
		navigate('/inspection-crm/project-boards')
	}

	const handleDownload = () => {
		const token = storePersist.get('auth')?.current

		axios({
			url: `https://api.greypath.co/api/v1/document/templateCustomerDetails`,
			method: "GET",
			headers: {
				'content-type': 'application/json',
				'Authorization': 'Bearer ' + token
			},
			responseType: "blob"
		}).then(response => {
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement("a");
			link.href = url;
			link.setAttribute(
				"download",
				`customer-template.xlsx`
			);
			document.body.appendChild(link);
			link.click();

			// Clean up and remove the link
			link.parentNode.removeChild(link);
		});


	}

	useEffect(() => {
		dispatch(projectchecklist.list({ entity: 'PDI_Project_Checklist' }))
	}, [])

	return (
		<div className="flex flex-col item-center">
			<header className="flex justify-between h-20 w-full mx-auto px-5 dark:text-white items-center">
				<div className="flex justify-start w-full" >
					<nav aria-label="breadcrumb" className="w-max" >
						<ol className="flex flex-wrap items-center w-full px-4 py-2 rounded-md bg-blue-gray-50 bg-opacity-60">
							<li
								className="flex items-center font-sans text-sm antialiased font-normal leading-normal transition-colors duration-300 cursor-pointer text-blue-gray-900 hover:text-light-blue-500">
								<h3 className="text-xl font-bold" onClick={() => handleProject()} >Projects</h3>
								<span
									className="mx-2 font-bold text-xl  antialiased font-normal leading-normal pointer-events-none select-none text-blue-gray-500 text-xl">
									<svg fill="#000000" height="12px" width="12px" version="1.1" id="Layer_1"
										viewBox="0 0 330 330">
										<path id="XMLID_222_" d="M250.606,154.389l-150-149.996c-5.857-5.858-15.355-5.858-21.213,0.001
	c-5.857,5.858-5.857,15.355,0.001,21.213l139.393,139.39L79.393,304.394c-5.857,5.858-5.857,15.355,0.001,21.213
	C82.322,328.536,86.161,330,90,330s7.678-1.464,10.607-4.394l149.999-150.004c2.814-2.813,4.394-6.628,4.394-10.606
	C255,161.018,253.42,157.202,250.606,154.389z"/>
									</svg>
								</span>
							</li>
							<li
								className="flex items-center font-sans text-sm antialiased font-normal leading-normal transition-colors duration-300 cursor-pointer text-blue-gray-900 hover:text-light-blue-500">
								<div onClick={() => navigate('/inspection-crm/inspection-items')} className=" text-[#787C83FF]  text-md font-bold">
									<span>
										{activeBoardName}
									</span>
								</div>
								<span
									className="mx-2 font-bold text-xl  antialiased font-normal leading-normal pointer-events-none select-none text-blue-gray-500 text-xl">
									<svg fill="#000000" height="12px" width="12px" version="1.1" id="Layer_1"
										viewBox="0 0 330 330">
										<path id="XMLID_222_" d="M250.606,154.389l-150-149.996c-5.857-5.858-15.355-5.858-21.213,0.001
	c-5.857,5.858-5.857,15.355,0.001,21.213l139.393,139.39L79.393,304.394c-5.857,5.858-5.857,15.355,0.001,21.213
	C82.322,328.536,86.161,330,90,330s7.678-1.464,10.607-4.394l149.999-150.004c2.814-2.813,4.394-6.628,4.394-10.606
	C255,161.018,253.42,157.202,250.606,154.389z"/>
									</svg>
								</span>
							</li>
							<li
								className="flex items-center font-sans text-sm antialiased font-normal leading-normal transition-colors duration-300 cursor-pointer text-blue-gray-900 hover:text-light-blue-500">
								<a href="#" className="opacity-60 text-md font-bold">
									Customer Details
								</a>
								<span
									className="mx-2 font-bold text-xl  antialiased font-normal leading-normal pointer-events-none select-none text-blue-gray-500 text-xl">
									<svg fill="#000000" height="12px" width="12px" version="1.1" id="Layer_1"
										viewBox="0 0 330 330">
										<path id="XMLID_222_" d="M250.606,154.389l-150-149.996c-5.857-5.858-15.355-5.858-21.213,0.001
	c-5.857,5.858-5.857,15.355,0.001,21.213l139.393,139.39L79.393,304.394c-5.857,5.858-5.857,15.355,0.001,21.213
	C82.322,328.536,86.161,330,90,330s7.678-1.464,10.607-4.394l149.999-150.004c2.814-2.813,4.394-6.628,4.394-10.606
	C255,161.018,253.42,157.202,250.606,154.389z"/>
									</svg>
								</span>
							</li>
							<li
								className="flex items-center font-sans text-sm antialiased font-normal leading-normal transition-colors duration-300 cursor-pointer text-blue-gray-900 hover:text-light-blue-500">
								<a href='#' disabled={isActiveBtn}
									className="opacity-60 text-md font-bold">
									Import Customer
								</a>
							</li>
						</ol>
					</nav>
				</div>
			</header>
			<Confirmation />
			<div className="flex flex-col bg-blue-gray-50 h-screen ">
				<div className="flex flex-col items-center justify-around mt-10 pt-24">
					<span className="text-3xl font-bold m-3 w-96 text-center">Upload Customer Details</span>
					<span className="text-xs font-bold w-96 text-center">
						To proceed with planning your inspection delivery please upload the customer and inpsection details for the projects
					</span>
					<div className="flex justify-around my-4 " >
						<button
							onClick={() => handleImportCustomer()}

							className="
						w-auto px-3 m-3 button bg-blue-600 text-white rounded ring-1 ring-inset ring-blue-600
						text-blue-600 p-2 text-xs font-bold">
							Browse
						</button>
						<button
							onClick={() => upload()}
							disabled={isActiveBtn}
							className="
						w-auto px-3 m-3 button bg-blue-600 text-white rounded ring-1 ring-inset ring-blue-600
						text-blue-600 p-2 text-xs font-bold">
							+ Import Customer
						</button>
						<input type="file" id='excelfile' className="hidden" name="file_upload" onChange={(e) => handleFileChange(e)} />
						<button
							onClick={() => handleDownload()}
							className="
						w-auto px-3 m-3 button bg-blue-600 text-white rounded ring-1 ring-inset ring-blue-600
						text-blue-600 p-2 text-xs font-bold" >
							+ Download Format
						</button>
					</div>
				</div>
			</div>
		</div >
	);
}
