import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import storePersist from "../../redux/storePersist";
import { request } from "../../request";
import MyAccount from "../../modals/MyAccount";
import { selectItemsFilter } from '../../redux/inspectioncards/selectors';
import { selectListItems as selectStageListItems } from '../../redux/inspectionstage/selectors';
import { selectListItems as selectStatusListItems } from '../../redux/inspectionstatus/selectors';
import { selectCurrentCRM } from "../../redux/crm/selectors";
import { inspectioncards } from "../../redux/inspectioncards/actions";
import { inspectionstage } from "../../redux/inspectionstage/actions";
import { inspectionstatus } from "../../redux/inspectionstatus/actions"
import { projectusers } from "../../redux/projectusers/actions";
import { crm } from "../../redux/crm/actions.js";

const getOnLineStatus = () =>
	typeof navigator !== 'undefined' && typeof navigator.onLine === 'boolean'
		? navigator.onLine
		: true;


function InspectionItemsMobile() {
	const navigate = useNavigate()
	const dispatch = useDispatch();
	const [url, setUrl] = useState('')

	const [cardImage, setCardImage] = useState('')
	const [user, setUser] = useState({})

	const [search, setSearch] = useState('')
	const [filteredList, setFilteredList] = useState([])
	const { result } = useSelector(selectItemsFilter);
	const { activeProjectId } = useSelector(selectCurrentCRM)
	const [networkStatus, setNetworkStatus] = useState(getOnLineStatus());
	const setOffline = () => setNetworkStatus(false);
	const setOnline = () => setNetworkStatus(true);
	const { result: stageResult } = useSelector(selectStageListItems)
	const { result: statusResult } = useSelector(selectStatusListItems)
	const { items: stageitems } = stageResult;
	const { items: statusitems } = statusResult;
	const { items } = result ? result : []

	useEffect(() => {
		window.scrollTo(0, 0);
		window.addEventListener('online', setOnline);
		window.addEventListener('offline', setOffline);
		return () => {
			window.removeEventListener('online', setOnline);
			window.removeEventListener('offline', setOffline);
		};
	}, [])

	const getUrl = async (user) => {
		const image_url = "https://proptech-pdi.s3.amazonaws.com/" + user?.profileImage
		setUrl(image_url)
	}

	const handleSearch = (e) => {
		const searchTerm = e.target.value
		setSearch(searchTerm)
		setFilteredList(items.filter((item) => item?.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
			item?.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
			item?.lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
			item?.Block.toLowerCase().includes(searchTerm.toLowerCase()) ||
			item?.NumberOfBedrooms?.Name.toLowerCase().includes(searchTerm.toLowerCase()) ||
			item?.UnitDetails.toLowerCase().includes(searchTerm.toLowerCase())));
	}

	const handleStatusFilter = (e) => {
		setFilteredList(items.filter((item) => item.Status._id == e.target.value))
	}

	const handleStageFilter = (e) => {
		setFilteredList(items.filter((item) => item.Stage._id == e.target.value))
	}

	const getImage = async () => {
		const url = "https://proptech-pdi.s3.amazonaws.com/" + 'a76ddbb0-b309-4d0f-a6b2-0a4c64b8ebd3.svg'
		setCardImage(url)
	}

	useEffect(() => {
		getImage()
		setFilteredList(items)
	}, [items])

	useEffect(() => {
		dispatch(inspectionstage.list({ entity: 'PDI_Inspection_Stage', options: {} }))
		dispatch(inspectionstatus.list({ entity: 'PDI_Inspection_Status', options: {} }))
		const _user = storePersist.get('user')
		setUser(_user)
		getUrl(_user)
		if (_user?.roleId?._id == '65dfe45c6ad846404c6cc572') {
			dispatch(inspectioncards.filter({ entity: 'PDI_Customer_Details', jsonData: { params: { AssignCRM: _user?._id, project: activeProjectId }, page: 1, limit: 200 } }))
		}
		if (activeProjectId && networkStatus) {
			dispatch(projectusers.filter({ entity: 'PDI_Project_Users', jsonData: { params: { isDeleted: false, ProjectName: activeProjectId } }, page: 1, limit: 200 }))
		}
	}, [activeProjectId])

	useEffect(() => {
		if (networkStatus) {
			dispatch(inspectionstage.list({ entity: 'PDI_Inspection_Stage', options: {} }))
			dispatch(inspectionstatus.list({ entity: 'PDI_Inspection_Status', options: {} }))
			const _user = storePersist.get('user')
			if (_user?.roleId?.name == 'CRM Executive') {
				dispatch(inspectioncards.filter({ entity: 'PDI_Customer_Details', jsonData: { params: { AssignCRM: _user?._id, project: activeProjectId }, page: 1, limit: 200 } }))
			}
			else {
				dispatch(inspectioncards.filter({ entity: 'PDI_Customer_Details', jsonData: { params: { AssignSiteEngineer: _user?._id, project: activeProjectId }, page: 1, limit: 200 } }))
			}
		}
	}, [])

	const handleProjectChecklist = (obj) => {
		const _user = storePersist.get('user')
		if (_user?.roleId?._id == '65dfe45c6ad846404c6cc572') {
			navigate("/mobile/category-list")
			dispatch(crm.trigger({ activeCustomerId: obj._id, activeCustomer: obj?.UnitDetails + '_' + obj?.Block + '_' + obj?.firstName + obj?.lastName, assignCRM: obj?.AssignCRM._id, assignSiteEngineer: obj.AssignSiteEngineer._id, clearInfiniteScroll: true }))
		}
		else {
			navigate("/mobile/tasks")
			dispatch(crm.trigger({ activeCustomerId: obj._id, activeCustomer: obj?.UnitDetails + '_' + obj?.Block + '_' + obj?.firstName + obj?.lastName, assignCRM: obj?.AssignCRM._id, assignSiteEngineer: obj.AssignSiteEngineer._id, clearInfiniteScroll: true }))
		}
	}

	return (
		<>
			<MyAccount />
			<div className="max-h-screen w-full bg-[#F9F9FA]">
				<header className="flex  absolute z-30 fixed top-0 left-0  justify-center w-full h-20 items-center bg-[#3C50E0FF] text-white">

					<div className="flex flex-row text-xl font-bold">
						<button
							onClick={() => navigate('/mobile/project-board')}
							className="absolute fixed left-10  cursor-pointer">
							<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#ffffff" viewBox="0 0 24 24"><path d="M21 11H6.414l5.293-5.293-1.414-1.414L2.586 12l7.707 7.707 1.414-1.414L6.414 13H21z" /></svg>
						</button>
						Unit Details
					</div>
				</header >
				<div className="flex flex-col pt-20 px-3 w-screen h-full overflow-auto">
					<form className="m-3">
						<div className="relative flex w-full flex-wrap items-stretch">
							<span className="z-10 h-full leading-snug font-normal absolute text-center absolute rounded text-base items-center justify-center w-8 pl-3 py-2">
								<svg className="h-4 w-5 text-black border-opacity-40 " viewBox="0 0 24 24" fill="#A9A9A9"><path d="M11 2C15.968 2 20 6.032 20 11C20 15.968 15.968 20 11 20C6.032 20 2 15.968 2 11C2 6.032 6.032 2 11 2ZM11 18C14.8675 18 18 14.8675 18 11C18 7.1325 14.8675 4 11 4C7.1325 4 4 7.1325 4 11C4 14.8675 7.1325 18 11 18ZM19.4853 18.0711L22.3137 20.8995L20.8995 22.3137L18.0711 19.4853L19.4853 18.0711Z"></path></svg>
							</span>
							<input
								type="text"
								placeholder="Search here..."
								value={search}
								onChange={(e) => handleSearch(e)}
								className="border-x border-y px-3 py-1 border-black border-opacity-40 text-black text-opacity-60 relative rounded text-sm shadow outline-none focus:outline-none focus:ring w-[300px] pl-10 bg-[#F9F9FAFF]"
							/>
						</div>
					</form>
					<div className="flex flex-row justify-between items-center m-2">
						<div className="text-sm">Units</div>
						<div className="flex">
							<div className="flex justify-end w-24 mx-1 bg-[#F9F9FAFF]" >
								<div className="border-x border-y  px-2 border-black border-opacity-40  py-1  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 bg-[#F9F9FAFF]" >
									<select
										className="w-full bg-[#F9F9FAFF] text-xs"
										onChange={(e) => handleStageFilter(e)} >
										<option value={1} key={1}> --Stage-- </option>
										{stageitems.map((item, index) => {
											return <option value={item._id} key={index}> {item.Name}</option>
										})}
									</select>
								</div>
							</div>
							<div className="flex justify-end w-24  mx-1 " >
								<div className="border-x border-y px-2 border-black border-opacity-40   py-1  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" >
									<select
										className="w-full bg-[#F9F9FAFF] text-xs"
										onChange={(e) => handleStatusFilter(e)} >
										<option value={1} key={1}> --Status--
											<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M7 11h10v2H7zM4 7h16v2H4zm6 8h4v2h-4z" /></svg>
										</option>
										{statusitems.map((item, index) => {
											return <option value={item._id} key={index}> {item.Name}</option>
										})}
									</select>
								</div>
							</div>
						</div>
					</div>
					<div className="w-full pb-20 ">
						{filteredList.map((item, index) => {
							return <div
								onClick={() => handleProjectChecklist(item)}
								key={index}
								className="cursor-pointer bg-white shadow-xl p-3 pr-0 text-sm font-bold m-2 rounded ">
								<div className="flex flex-row justify-between items-center w-full">
									<div className="flex flex-col item-center justify-center">
										<div className="text-sm">
											{item?.project?.ProjectName}
										</div>
										<div className="text-sm font-bold">
											{item?.UnitDetails}, {item?.NumberOfBedrooms?.Name}, {item?.Block},
										</div>
										<div className="text-xs font-normal p-1" >
											{item?.firstName} {item?.lastName},  {item?.mobile}
										</div>
										<div className="flex flex-row">
											<div className="text-xs font-light p-1">
												{item?.Stage?.Name == "PDI Stage" ? <span className="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">
													{item?.Stage?.Name}</span> :
													<span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/10">{item?.Stage?.Name}</span>
												}
											</div>
											<div className="text-xs font-light p-1">
												{item?.Status?.Name == "Not Started" ? <span className="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">
													{item?.Status?.Name}</span> : item?.Status?.Name == "In Progress" ?
													<span className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">{item?.Status?.Name}</span> :
													<span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/10">{item?.Status?.Name}</span>
												}
											</div>
										</div>
									</div>
									<div className="flex flex-row items-center justify-center mr-3">
										<img
											className="h-20 w-20"
											src={cardImage}
											alt="image-text"
										/>
									</div>
								</div>
							</div>
						})}
					</div>
				</div>
				<footer className="flex fixed bottom-0 left-0 flex-row w-full justify-center min-h-20 pb-2 item-center bg-[#3C50E0FF] text-white">
					<button
						onClick={() => navigate('/mobile/project-board')}
						className="w-1/4  flex flex-col justify-center   items-center ">
						<svg xmlns="http://www.w3.org/2000/svg" height="32" width="32" fill="#ffffff" viewBox="0 0 24 24" ><path d="M19 21H5C4.44772 21 4 20.5523 4 20V11L1 11L11.3273 1.6115C11.7087 1.26475 12.2913 1.26475 12.6727 1.6115L23 11L20 11V20C20 20.5523 19.5523 21 19 21ZM13 19H18V9.15745L12 3.7029L6 9.15745V19H11V13H13V19Z"></path></svg>
						<span className="text-xs">Home</span>
					</button>
					<button
						onClick={() => navigate('/mobile/chat')}
						className="w-1/4  flex flex-col justify-center  items-center ">
						<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#ffffff" viewBox="0 0 256 256"><path d="M216,48H40A16,16,0,0,0,24,64V224a15.85,15.85,0,0,0,9.24,14.5A16.13,16.13,0,0,0,40,240a15.89,15.89,0,0,0,10.25-3.78.69.69,0,0,0,.13-.11L82.5,208H216a16,16,0,0,0,16-16V64A16,16,0,0,0,216,48ZM40,224h0ZM216,192H82.5a16,16,0,0,0-10.3,3.75l-.12.11L40,224V64H216ZM88,112a8,8,0,0,1,8-8h64a8,8,0,0,1,0,16H96A8,8,0,0,1,88,112Zm0,32a8,8,0,0,1,8-8h64a8,8,0,1,1,0,16H96A8,8,0,0,1,88,144Z"></path></svg>
						<span className="text-xs">Chats</span>
					</button>
					<button
						onClick={() => navigate('/mobile/notification')}
						className="w-1/4  flex  flex-col justify-center items-center ">
						<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#ffffff" viewBox="0 0 24 24"><path d="M19 13.586V10c0-3.217-2.185-5.927-5.145-6.742C13.562 2.52 12.846 2 12 2s-1.562.52-1.855 1.258C7.185 4.074 5 6.783 5 10v3.586l-1.707 1.707A.996.996 0 0 0 3 16v2a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1v-2a.996.996 0 0 0-.293-.707L19 13.586zM19 17H5v-.586l1.707-1.707A.996.996 0 0 0 7 14v-4c0-2.757 2.243-5 5-5s5 2.243 5 5v4c0 .266.105.52.293.707L19 16.414V17zm-7 5a2.98 2.98 0 0 0 2.818-2H9.182A2.98 2.98 0 0 0 12 22z" /></svg>
						<span className="text-xs">Notifications</span>
					</button>
					<button
						onClick={() => navigate('/mobile/help-center')}
						className="w-1/4  flex flex-col justify-center  items-center ">
						<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#ffffff" viewBox="0 0 256 256"><path d="M201.89,54.66A103.43,103.43,0,0,0,128.79,24H128A104,104,0,0,0,24,128v56a24,24,0,0,0,24,24H64a24,24,0,0,0,24-24V144a24,24,0,0,0-24-24H40.36A88.12,88.12,0,0,1,190.54,65.93,87.39,87.39,0,0,1,215.65,120H192a24,24,0,0,0-24,24v40a24,24,0,0,0,24,24h24a24,24,0,0,1-24,24H136a8,8,0,0,0,0,16h56a40,40,0,0,0,40-40V128A103.41,103.41,0,0,0,201.89,54.66ZM64,136a8,8,0,0,1,8,8v40a8,8,0,0,1-8,8H48a8,8,0,0,1-8-8V136Zm128,56a8,8,0,0,1-8-8V144a8,8,0,0,1,8-8h24v56Z"></path></svg>
						<span className="text-xs">Help</span>
					</button>
				</footer>
			</div >
		</>
	);
}

export default InspectionItemsMobile;
