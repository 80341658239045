import { crm } from "../redux/crm/actions";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectListItems as selectIssueChangeTypeList, selectCreatedItem } from '../redux/issuechangetype/selectors';
import { issuechangetype } from '../redux/issuechangetype/actions';
import { selectCurrentCRM } from '../redux/crm/selectors';
import { issuecards } from "../redux/issuecards/actions";


export default function IssueChangeType({ fromWhom, toWhom }) {
	const dispatch = useDispatch();
	const navigate = useNavigate()
	const [selectedReason, setSelectedReason] = useState('');
	const [otherReason, setOtherReason] = useState('');
	const { isSuccess, result } = useSelector(selectCreatedItem)
	const { activeIssueId } = useSelector(selectCurrentCRM)
	const { result: issueChangeTypeResult } = useSelector(selectIssueChangeTypeList)
	const { items } = issueChangeTypeResult;

	useEffect(() => {
		dispatch(issuechangetype.list({ entity: 'PDI_Issue_Change_Type' }))
	}, [])

	const handleReasonChange = (e) => {
		console.log(e)
		setSelectedReason(e);
	};

	useEffect(() => {
		if (isSuccess) {
			dispatch(issuecards.update({ entity: 'PDI_Issue_Cards', id: activeIssueId, jsonData: { entity: { IssueLogs: result._id } } }))
			dispatch(issuechangetype.resetAction({ actionType: 'create' }))
			dispatch(crm.trigger({ issueChangeType: false }))
			console.log(result)
		}
	}, [isSuccess])

	const handleOtherReasonChange = (e) => {
		setOtherReason(e.target.value);
	};

	const handleSave = () => {
		dispatch(issuechangetype.create({
			entity: "PDI_Propcheck_Logs", jsonData: {
				"issue": activeIssueId,
				"log_type": toWhom,
				"log_message": otherReason != "" ? otherReason : selectedReason,
				"request_type": fromWhom
			}
		}))
	}
	return (
		<>
			<div className="absolute fixed w-full max-h-max mt-24 inset-y-0 inset-x-0 z-50 flex flex-warp justify-center align-middle">
				<div className="flex justify-center items-center p-4">
					<div className="bg-white px-6 rounded-lg min-h-96  py-10  shadow-lg max-w-md w-full">
						<h2 className="text-2xl font-bold mb-4 text-gray-800">Reasons For Re-Assigning</h2>
						<p className="mb-4 text-gray-600"></p>
						{items?.map((obj) => {
							return <div className="mb-4">
								<label className="block mb-2 text-gray-700">
									<input
										type="radio"
										value={obj.Name}
										checked={selectedReason === obj?.Name}
										onChange={(e) => handleReasonChange(e.target.value)}
										className="mr-2"
									/>
									{obj?.Name}
								</label>
							</div>
						})}

						{selectedReason === 'Others' && (
							<div className="mb-4">
								<label className="block mb-2 text-gray-700" htmlFor="otherReason">
									Please specify:
								</label>
								<textarea
									id="otherReason"
									value={otherReason}
									onChange={handleOtherReasonChange}
									className="w-full px-3 py-2 border border-gray-300 rounded-md"
									placeholder="Your reason"
								/>
							</div>
						)}

						<button
							onClick={() => handleSave()}
							type="button"
							className="w-full bg-red-500 text-white py-2 px-4 rounded-md hover:bg-red-600 transition-colors"
						>
							Submit
						</button>
					</div>
				</div>
			</div >
			<div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
		</>
	);
}