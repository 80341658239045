import React, { useState, useRef, useEffect, useCallback } from 'react';

import Audio from '../../modals/Audio';
import { FaPlay, FaPause } from 'react-icons/fa';
import { FaTrash } from "react-icons/fa6";
import ConfirmAlert from '../../modals/ConfirmAlert';
import { formatRecordingTime } from '../../utils//utils';

import { crm } from '../../redux/crm/actions';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentCRM } from '../../redux/crm/selectors';



const Comment = ({ setmessage, images, setImage, saveComment, isLoading, setIsLoading }) => {
	const [message, setMessage] = useState('');
	const [height, setHeight] = useState(50);
	const [audio, setAudio] = useState(null);
	const [blob, setBlob] = useState(null);
	const [isrec, setrec] = useState(false)
	const initialY = useRef(null);
	const [show, setShow] = useState(false);

	const dispatch = useDispatch();
	const { isCameraOn, isIssueCameraImage } = useSelector(selectCurrentCRM);

	const expandedHeight = window.innerHeight - 300;
	//audio
	const audioRef = useRef(null);
	const [isPlaying, setIsPlaying] = useState(false);


	const [showConfirm, setShowConfirm] = useState(false);


	const [currentTime, setCurrentTime] = useState(0);


	useEffect(() => {
		if (audio) {
			const audioData = audioRef.current;
			const handleTimeUpdate = () => {
				setCurrentTime(audioData.currentTime);
			};
			audioData.addEventListener('loadedmetadata', handleTimeUpdate);
			audioData.addEventListener('timeupdate', handleTimeUpdate);

			return () => {
				audioData.removeEventListener('loadedmetadata', handleTimeUpdate);
				audioData.removeEventListener('timeupdate', handleTimeUpdate);


			};
		}
	}, [audio]);

	const handleCamera = () => {

		dispatch(crm.trigger({ isCameraOn: true }))
	}

	const handleAudio = useCallback((url, blob) => {
		setAudio(url);
		setBlob(blob);
		setmessage(message);
	}, [message, setmessage]);

	const handleAudioEnded = useCallback(() => {
		setIsPlaying(false);
		audioRef.current.currentTime = 0;
	}, []);

	const togglePlayPause = useCallback(() => {
		if (isPlaying) {
			audioRef.current.pause();
		} else {
			audioRef.current.play();
		}
		setIsPlaying(!isPlaying);
	}, [isPlaying]);

	const handleTouchStart = useCallback((e) => {
		initialY.current = e.touches[0].clientY;
	}, []);

	const handleTouchMove = useCallback((e) => {
		if (initialY.current !== null) {
			const currentY = e.touches[0].clientY;
			const diffY = initialY.current - currentY;

			if (diffY > 20) {
				setHeight(expandedHeight);
				initialY.current = null;
			} else if (diffY < -20) {
				setHeight(50);
				initialY.current = null;
			}
		}
	}, [expandedHeight]);

	const handleInputChange = useCallback((event) => {
		setMessage(event.target.value);
		if (height !== expandedHeight) {
			event.target.style.height = 'auto';
			event.target.style.height = `${event.target.scrollHeight}px`;
		}
	}, [height, expandedHeight]);

	const handleDelete = useCallback((id) => {

		setAudio(null);

		setShowConfirm(false);
	}, []);

	const reset = () => {
		setAudio(null);
		setMessage("")
		setBlob(null);


	}

	return (
		<>

			{<div className={`relative z-20`} style={{ display: `${isCameraOn ? `none` : `block`}` }}>


				<div className={`comment fixed ${show ? `bottom-0` : `bottom-[56px]`} left-0 right-0 flex flex-col border bg-white border-gray-100`}>
					<div className="flex-1">
						<div className="flex justify-center items-center">
							<div className="w-12 h-1 bg-gray-400 rounded-full mb-2"></div>
						</div>

						{show ? <Audio isRecord={isrec} setRecord={setrec} setAudio={setAudio} setShow={setShow} handleAudio={handleAudio} /> : <textarea
							className={`w-full resize-none px-4 py-2 border ${audio ? `border-b-0` : ""}  border-gray-200 focus:outline-none transition-all duration-300`}
							style={{ height: `${height}px` }}
							placeholder="Type your message here..."
							value={message}
							onChange={handleInputChange}
							onTouchStart={handleTouchStart}
							onTouchMove={handleTouchMove}
						></textarea>}

						{audio && !show && (
							<div className='flex my-2' >

								<div className="max-w-md mx-auto bg-white shadow-lg rounded-lg overflow-hidden">
									<audio onEnded={handleAudioEnded} ref={audioRef} src={audio} />
									<div className="p-4">
										<div className="flex ">
											<button
												onClick={togglePlayPause}
												className="text-2xl text-blue-500 focus:outline-none"
											>
												{isPlaying ? <FaPause /> : <FaPlay />}

											</button>

											<div className='mx-2'>{isPlaying && formatRecordingTime(currentTime)}</div>

											<div className='mx-2'><FaTrash onClick={() => setShowConfirm(true)} size={"20px"} color='#EF4444' /></div>
										</div>
									</div>

								</div>

							</div>

						)}
						{(!isIssueCameraImage && images?.length > 0 && !show) && <div className='flex justify-center relative'>
							<img src={images?.[0]?.img} width={"200"} alt='captured' />  </div>}
						{showConfirm && (
							<ConfirmAlert
								message="Are you sure you want to delete this item?"
								onConfirm={handleDelete}
								onCancel={() => setShowConfirm(false)}
							/>
						)}


					</div>
					{!isLoading ? !show && <div className={`fixed bottom-0 bg-blue-500 icon-tab flex justify-between items-center px-4 py-4 w-full`}>
						<button className="mr-4"
							disabled={isLoading ? true : false}
							onClick={handleCamera} >
							<svg width="30px" height="30px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">

								<g id="SVGRepo_bgCarrier" strokeWidth="0" />

								<g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />

								<g id="SVGRepo_iconCarrier"> <circle cx="12" cy="13" r="3" stroke="#fff" strokeWidth="1.5" /> <path d="M9.77778 21H14.2222C17.3433 21 18.9038 21 20.0248 20.2646C20.51 19.9462 20.9267 19.5371 21.251 19.0607C22 17.9601 22 16.4279 22 13.3636C22 10.2994 22 8.76721 21.251 7.6666C20.9267 7.19014 20.51 6.78104 20.0248 6.46268C19.3044 5.99013 18.4027 5.82123 17.022 5.76086C16.3631 5.76086 15.7959 5.27068 15.6667 4.63636C15.4728 3.68489 14.6219 3 13.6337 3H10.3663C9.37805 3 8.52715 3.68489 8.33333 4.63636C8.20412 5.27068 7.63685 5.76086 6.978 5.76086C5.59733 5.82123 4.69555 5.99013 3.97524 6.46268C3.48995 6.78104 3.07328 7.19014 2.74902 7.6666C2 8.76721 2 10.2994 2 13.3636C2 16.4279 2 17.9601 2.74902 19.0607C3.07328 19.5371 3.48995 19.9462 3.97524 20.2646C5.09624 21 6.65675 21 9.77778 21Z" stroke="#fff" strokeWidth="1.5" /> <path d="M19 10H18" stroke="#fff" strokeWidth="2" strokeLinecap="round" /> </g>

							</svg>
						</button>
						<button
							onClick={() => { setShow(true); setrec(true) }}
							disabled={isLoading ? true : false}
						>
							<svg
								fill="#fff"
								height="30px"
								width="30px"
								version="1.1"
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 512 512"
								xlinkHref="http://www.w3.org/1999/xlink"
								enableBackground="new 0 0 512 512"
							>
								<g>
									<g>
										<path
											d="m439.5,236c0-11.3-9.1-20.4-20.4-20.4s-20.4,9.1-20.4,20.4c0,70-64,126.9-142.7,126.9-78.7,0-142.7-56.9-142.7-126.9 0-11.3-9.1-20.4-20.4-20.4s-20.4,9.1-20.4,20.4c0,86.2 71.5,157.4 163.1,166.7v57.5h-23.6c-11.3,0-20.4,9.1-20.4,20.4 0,11.3 9.1,20.4 20.4,20.4h88c11.3,0 20.4-9.1 20.4-20.4 0-11.3-9.1-20.4-20.4-20.4h-23.6v-57.5c91.6-9.3 163.1-80.5 163.1-166.7z"
										/>
										<path
											d="m256,323.5c51,0 92.3-41.3 92.3-92.3v-127.9c0-51-41.3-92.3-92.3-92.3s-92.3,41.3-92.3,92.3v127.9c0,51 41.3,92.3 92.3,92.3zm-52.3-220.2c0-28.8 23.5-52.3 52.3-52.3s52.3,23.5 52.3,52.3v127.9c0,28.8-23.5,52.3-52.3,52.3s-52.3-23.5-52.3-52.3v-127.9z"
										/>
									</g>
								</g>
							</svg>
						</button>
						<button
							onClick={() => { saveComment(message, blob, reset); }}
							disabled={isLoading ? true : false}
						>
							<svg stroke="#fff"
								strokeWidth="1"
								strokeLinecap="round"
								strokeLinejoin="round" width="30px" fill="none" height="30px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
								<path d="M11.5003 12H5.41872M5.24634 12.7972L4.24158 15.7986C3.69128 17.4424 3.41613 18.2643 3.61359 18.7704C3.78506 19.21 4.15335 19.5432 4.6078 19.6701C5.13111 19.8161 5.92151 19.4604 7.50231 18.7491L17.6367 14.1886C19.1797 13.4942 19.9512 13.1471 20.1896 12.6648C20.3968 12.2458 20.3968 11.7541 20.1896 11.3351C19.9512 10.8529 19.1797 10.5057 17.6367 9.81135L7.48483 5.24303C5.90879 4.53382 5.12078 4.17921 4.59799 4.32468C4.14397 4.45101 3.77572 4.78336 3.60365 5.22209C3.40551 5.72728 3.67772 6.54741 4.22215 8.18767L5.24829 11.2793C5.34179 11.561 5.38855 11.7019 5.407 11.8459C5.42338 11.9738 5.42321 12.1032 5.40651 12.231C5.38768 12.375 5.34057 12.5157 5.24634 12.7972Z" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
							</svg>

						</button>

					</div> : <div className={`animate-pulse fixed bottom-0 bg-blue-500 text-white font-bold icon-tab flex justify-center items-center px-4 py-4 w-full`}> Processing...</div>}
				</div>
			</div >}
		</>
	);
};

export default React.memo(Comment);
