import { createSelector } from 'reselect';

const selectSubscribers = (state) => state.subscribers;

export const selectCurrentItem = createSelector([selectSubscribers], (subscribers) => subscribers.current);

export const selectListItems = createSelector([selectSubscribers], (subscribers) => subscribers.list);


export const selectItemById = (itemId) =>
	createSelector(selectListItems, (list) => list.result.items.find((item) => item._id === itemId));

export const selectCreatedItem = createSelector([selectSubscribers], (subscribers) => subscribers.create);

export const selectUpdatedItem = createSelector([selectSubscribers], (subscribers) => subscribers.update);

export const selectReadItem = createSelector([selectSubscribers], (subscribers) => subscribers.read);

export const selectDeletedItem = createSelector([selectSubscribers], (subscribers) => subscribers.delete);

export const selectSearchedItems = createSelector([selectSubscribers], (subscribers) => subscribers.search);

export const selectItemsCount = createSelector([selectSubscribers], (subscribers) => subscribers.count);

export const selectItemsFilter = createSelector([selectSubscribers], (subscribers) => subscribers.filter);



