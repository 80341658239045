export const PROJECT_TYPE_RESET_STATE = 'PROJECT_TYPE_RESET_STATE';
export const PROJECT_TYPE_CURRENT_ITEM = 'PROJECT_TYPE_CURRENT_ITEM';

export const PROJECT_TYPE_REQUEST_LOADING = 'PROJECT_TYPE_REQUEST_LOADING';
export const PROJECT_TYPE_REQUEST_SUCCESS = 'PROJECT_TYPE_REQUEST_SUCCESS';
export const PROJECT_TYPE_REQUEST_FAILED = 'PROJECT_TYPE_REQUEST_FAILED';

export const PROJECT_TYPE_CURRENT_ACTION = 'PROJECT_TYPE_CURRENT_ACTION';
export const PROJECT_TYPE_RESET_ACTION = 'PROJECT_TYPE_RESET_ACTION';
export const PROJECT_TYPE_NAME_EXISTS_ACTION = 'PROJECT_TYPE_NAME_EXISTS_ACTION';
export const PROJECT_TYPE_RESET_NAME_EXISTS = 'PROJECT_TYPE_RESET_NAME_EXISTS';
