import { createSelector } from 'reselect';

const selectProjectAddon = (state) => state.projectaddon;

export const selectCurrentItem = createSelector([selectProjectAddon], (projectaddon) => projectaddon.current);

export const selectListItems = createSelector([selectProjectAddon], (projectaddon) => projectaddon.list);


export const selectItemById = (itemId) =>
	createSelector(selectListItems, (list) => list.result.items.find((item) => item._id === itemId));

export const selectCreatedItem = createSelector([selectProjectAddon], (projectaddon) => projectaddon.create);

export const selectUpdatedItem = createSelector([selectProjectAddon], (projectaddon) => projectaddon.update);

export const selectReadItem = createSelector([selectProjectAddon], (projectaddon) => projectaddon.read);

export const selectDeletedItem = createSelector([selectProjectAddon], (projectaddon) => projectaddon.delete);

export const selectSearchedItems = createSelector([selectProjectAddon], (projectaddon) => projectaddon.search);

export const selectItemsCount = createSelector([selectProjectAddon], (projectaddon) => projectaddon.count);

export const selectItemsFilter = createSelector([selectProjectAddon], (projectaddon) => projectaddon.filter);



