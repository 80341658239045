import { createSelector } from 'reselect';

const selectProjectChecklist = (state) => state.projectchecklist;

export const selectCurrentItem = createSelector([selectProjectChecklist], (projectchecklist) => projectchecklist.current);

export const selectListItems = createSelector([selectProjectChecklist], (projectchecklist) => projectchecklist.list);

export const selectItemById = (itemId) =>
	createSelector(selectListItems, (list) => list.result.items.find((item) => item._id === itemId));

export const selectCreatedItem = createSelector([selectProjectChecklist], (projectchecklist) => projectchecklist.create);

export const selectUpdatedItem = createSelector([selectProjectChecklist], (projectchecklist) => projectchecklist.update);

export const selectReadItem = createSelector([selectProjectChecklist], (projectchecklist) => projectchecklist.read);

export const selectDeletedItem = createSelector([selectProjectChecklist], (projectchecklist) => projectchecklist.delete);

export const selectSearchedItems = createSelector([selectProjectChecklist], (projectchecklist) => projectchecklist.search);

export const selectItemsCount = createSelector([selectProjectChecklist], (projectchecklist) => projectchecklist.count);

export const selectItemsFilter = createSelector([selectProjectChecklist], (projectchecklist) => projectchecklist.filter);

export const selectItemsFilterByIssue = createSelector([selectProjectChecklist], (projectchecklist) => projectchecklist.filterbyissue);