import { crm } from "../redux/crm/actions";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentCRM } from '../redux/crm/selectors';
import { projecttype } from '../redux/projecttype/actions';
import { useForm } from "react-hook-form";
import { selectCreatedItem, selectUpdatedItem } from "../redux/projecttype/selectors";

export default function AddProjectType() {
	const dispatch = useDispatch();
	const { register, handleSubmit, clearErrors, formState: { errors }, setValue } = useForm();

	const { addProjectType, isProjectTypeEdit, projectTypeItem, activeProjectTypeId } = useSelector(selectCurrentCRM);
	const [name, setName] = useState('');
	const { isSuccess: isSuccessCreated, error, isNameExists } = useSelector(selectCreatedItem);
	const { isSuccess: isSuccessUpdated } = useSelector(selectUpdatedItem)

	useEffect(() => {
		if (addProjectType == 'open') {
			clearErrors("name")
			if (isNameExists) {
				dispatch(projecttype.resetName());
			}
		}
	}, [addProjectType])

	const handleSave = () => {
		if (!isProjectTypeEdit) {
			dispatch(projecttype.resetAction({ actionType: 'create' }))
			dispatch(projecttype.create({ entity: 'PDI_Project_Configuration', jsonData: { entity: { Name: name } } }))
		}
		else {
			dispatch(projecttype.resetAction({ actionType: 'update' }))
			dispatch(projecttype.update({ entity: 'PDI_Project_Configuration', id: activeProjectTypeId, jsonData: { entity: { Name: name } } }))
		}
	}

	useEffect(() => {
		if (isSuccessCreated) {
			dispatch(crm.trigger({ reloadProjectType: true, addProjectType: 'close' }))
		}
	}, [isSuccessCreated])

	useEffect(() => {
		if (isSuccessUpdated) {
			dispatch(crm.trigger({ reloadProjectType: true, addProjectType: 'close', isProjectTypeEdit: false }))
		}
	}, [isSuccessUpdated])

	useEffect(() => {
		if (isProjectTypeEdit) {
			setName(projectTypeItem)
			setValue('name', projectTypeItem)
		}
		else {
			setName('')
			setValue('name', '')
		}
	}, [projectTypeItem])

	const handleCancel = () => {
		dispatch(crm.trigger({ addProjectType: 'close' }))
	}

	return (
		<>
			{addProjectType == 'open' ? (
				<>
					<div className="absolute fixed w-full left-[50px] top-[250px] z-50 flex flex-warp justify-center align-middle w-[350px] h-[215px]">
						<div className="w-auto my-2 mx-auto max-w-sm">
							<div className="rounded-lg shadow-lg flex flex-col w-full bg-white">
								<h3 className="text-xs font-bold pt-3 pb-1 px-5">
									{isProjectTypeEdit ? 'Edit ' : 'Add '} Project Type
								</h3>
								<form onSubmit={handleSubmit(handleSave)} >
									<div className="flex flex-col justify-start align-middle px-5 py-3" >
										<span className="text-blue-600 text-xs font-bold py-1">Project Type</span>
										<input
											id="name"
											aria-invalid={errors.name ? "true" : "false"}
											{...register('name', { required: true, maxLength: 30 })}
											type="text"
											name={"name"}
											className="border-0 px-3 py-1 bg-black bg-opacity-10  text-xs w-full"
											onChange={(e) => setName(e.target.value)}
										/>
										{errors.name && errors.name.type === "required" && (
											<span role="alert" className="w-full text-red-500 bg-white text-xs my-1">This is required</span>
										)}
										{errors.name && errors.name.type === "maxLength" && (
											<span role="alert" className="w-full text-red-500 bg-white text-xs my-1">Max length exceeded</span>
										)}
										{isNameExists && (
											<span role="alert" className="w-full text-red-500 bg-white text-xs my-1">{error?.message}</span>
										)}
									</div>
									<div className="flex items-center justify-around px-6 my-4 mx-3 ">
										<button
											className="text-blue-500 bg-transparent rounded font-bold  px-2 text-xs outline py-1"
											type="button"
											onClick={() => handleCancel()}
										>
											Cancel
										</button>
										<input type="submit"
											className="bg-blue-500 text-white rounded  px-2 text-xs py-1 mx-3"
											value={isProjectTypeEdit ? 'Update' : 'Save'}
										/>
									</div>
								</form>
							</div>
						</div>
					</div>
					<div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
				</>
			) : null}
		</>
	);
}