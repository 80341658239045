export const ISSUE_CARDS_RESET_STATE = 'ISSUE_CARDS_RESET_STATE';
export const ISSUE_CARDS_CURRENT_ITEM = 'ISSUE_CARDS_CURRENT_ITEM';

export const ISSUE_CARDS_REQUEST_LOADING = 'ISSUE_CARDS_REQUEST_LOADING';
export const ISSUE_CARDS_REQUEST_SUCCESS = 'ISSUE_CARDS_REQUEST_SUCCESS';
export const ISSUE_CARDS_REQUEST_FAILED = 'ISSUE_CARDS_REQUEST_FAILED';

export const ISSUE_CARDS_CURRENT_ACTION = 'ISSUE_CARDS_CURRENT_ACTION';
export const ISSUE_CARDS_RESET_ACTION = 'ISSUE_CARDS_RESET_ACTION';
export const ISSUE_CARDS_REQUEST_OFFLINE = 'ISSUE_CARDS_REQUEST_OFFLINE';


