export const ISSUE_CHANGE_TYPE_RESET_STATE = 'ISSUE_CHANGE_TYPE_RESET_STATE';
export const ISSUE_CHANGE_TYPE_CURRENT_ITEM = 'ISSUE_CHANGE_TYPE_CURRENT_ITEM';

export const ISSUE_CHANGE_TYPE_REQUEST_LOADING = 'ISSUE_CHANGE_TYPE_REQUEST_LOADING';
export const ISSUE_CHANGE_TYPE_REQUEST_SUCCESS = 'ISSUE_CHANGE_TYPE_REQUEST_SUCCESS';
export const ISSUE_CHANGE_TYPE_REQUEST_FAILED = 'ISSUE_CHANGE_TYPE_REQUEST_FAILED';

export const ISSUE_CHANGE_TYPE_CURRENT_ACTION = 'ISSUE_CHANGE_TYPE_CURRENT_ACTION';
export const ISSUE_CHANGE_TYPE_RESET_ACTION = 'ISSUE_CHANGE_TYPE_RESET_ACTION';


export const ISSUE_CHANGE_TYPE_NAME_EXISTS_ACTION = 'ISSUE_CHANGE_TYPE_NAME_EXISTS_ACTION';
export const ISSUE_CHANGE_TYPE_RESET_NAME_EXISTS = 'ISSUE_CHANGE_TYPE_RESET_NAME_EXISTS';