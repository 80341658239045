import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function ProjectHome() {
	const naviage = useNavigate()
	return (
		<div className="flex flex-col item-center">
			<header className="flex justify-between h-24 w-full mx-auto px-5 dark:text-white items-center">
				<div className="flex flex-col justify-start w-full" >
					<nav aria-label="breadcrumb" className="w-max" >
						<ol className="flex flex-wrap items-center w-full px-4 py-1 rounded-md bg-blue-gray-50 bg-opacity-60">
							<li
								className="flex items-center font-sans text-sm antialiased font-normal leading-normal transition-colors duration-300 cursor-pointer text-blue-gray-900 hover:text-light-blue-500">
								<h3 className="text-xl font-bold">Projects</h3>
							</li>
						</ol>
					</nav>
				</div>
			</header >
			<div className="flex flex-col bg-blue-gray-50 h-screen ">
				<div className="flex flex-col items-center justify-around mt-10 pt-24">
					<span className="text-3xl font-bold m-3 w-96 text-center">Add Project Details</span>
					<span className="text-xs font-bold w-96 text-center">
						To intiate the project, kindly provide the necessary details by uploading project-releated information
					</span>
					<div className="flex justify-center my-4" >
						<button
							onClick={() => naviage('/inspection-crm/add-project')}
							className="w-auto px-3 button bg-blue-600 text-white rounded ring-1 ring-inset ring-blue-600 text-blue-600 p-2 text-xs font-bold"
						>
							Add Project
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}
