import { crm } from "../redux/crm/actions";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentCRM } from '../redux/crm/selectors';
import { selectListItems } from '../redux/projectcards/selectors';
import { projectcards } from "../redux/projectcards/actions";

export default function AddChecklist() {
	const dispatch = useDispatch();
	const [project, setProject] = useState('')
	const { addChecklist } = useSelector(selectCurrentCRM);
	const { result } = useSelector(selectListItems);
	const { items } = result
	const handleCancel = () => {
		dispatch(crm.trigger({ addChecklist: 'close' }))
	}

	const handleAdd = () => {

	}

	useEffect(() => {
		dispatch(projectcards.list({ entity: 'PDI_Project_Cards', options: { limit: 30 } }))
	}, [addChecklist])
	return (
		<>
			{addChecklist == 'open' ? (
				<>
					<div className="absolute fixed  inset-0 mt-20 z-50 flex flex-warp justify-center align-middle px-10 py-5">
						<div className="w-auto my-4  mx-auto max-w-sm">
							<div className="rounded-lg shadow-lg flex flex-col w-full bg-white">
								<h3 className="text-sm font-bold pt-4 pb-1 px-5">
									Add Checklist
								</h3>
								<div className="flex flex-col justify-start align-middle px-5 py-2" >
									<span className="text-blue-600 text-xs font-bold py-2">Project Name</span>
									<div className="px-3 border-0  bg-black bg-opacity-10 text-xs " >
										<select
											className="w-full border-0 py-1 bg-black bg-opacity-0 text-xs"
											onChange={(e) => setProject(e.targer.value)}
											defaultValue={project}
										>
											{items.map((item, index) => {
												return <option value={item._id} key={index}> {item.ProjectName}</option>
											})}
										</select>
									</div>
								</div>
								<div className="flex items-center justify-center space-x-3 w-[180px] my-4 mx-3 ">
									<button
										className="text-blue-500 bg-transparent rounded font-bold  px-2 text-xs outline py-1"
										type="button"
										onClick={() => handleCancel()}
									>
										Cancel
									</button>
									<button
										className="bg-blue-500 text-white rounded font-bold px-2 text-xs py-1"
										type="button"
										onClick={() => handleAdd()}
									>
										Add
									</button>
								</div>
							</div>
						</div>
					</div>
					<div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
				</>
			) : null}
		</>
	);
}