import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { selectListItems } from '../../redux/categoryname/selectors';
import { selectCurrentCRM } from "../../redux/crm/selectors"
import { projectcards } from '../../redux/projectcards/actions'
import storePersist from '../../redux/storePersist';
import { categoryname } from "../../redux/categoryname/actions";


const SortableTable = ({ data }) => {
	const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
	const sortedData = React.useMemo(() => {
		let sortableItems = [...data];
		if (sortConfig.key !== null) {
			sortableItems.sort((a, b) => {
				if (a[sortConfig.key] < b[sortConfig.key]) {
					return sortConfig.direction === 'ascending' ? -1 : 1;
				}
				if (a[sortConfig.key] > b[sortConfig.key]) {
					return sortConfig.direction === 'ascending' ? 1 : -1;
				}
				return 0;
			});
		}
		return sortableItems;
	}, [data, sortConfig]);

	return (
		<table className="min-w-full bg-white ring-black ring-1 rounded">
			<thead>
				<tr className='bg-gray-200'>
					<th className={`py-2 font-normal px-4 text-left`} >
						<span className='flex flex-row justify-start items-center '>S.I No
						</span>
					</th>
					<th
						className={`py-2 font-normal px-4  text-left`}
					>
						<span className='flex flex-row justify-start items-center '>Unit Details
						</span>
					</th>
					<th
						className={`py-2 font-normal px-4  text-left`}
					>
						<span className='flex flex-row justify-start items-center '>Issue Name
						</span>
					</th>
					<th
						className={`py-2 font-normal px-4  text-left `}
					>
						<span className='flex flex-row justify-start items-center '>Reporter(CRM Executive)
						</span>
					</th>
					<th
						className={`py-2 font-normal px-4  text-left`}
					>
						<span className='flex flex-row justify-start items-center '>
							Resolver(QC/Engineer)

						</span>
					</th>
					<th className={"py-2 px-4 font-normal text-left "}>
						Time to Resolve
					</th>
					<th className={"py-2 px-4 font-normal text-left "}>
						Was it Reopened
					</th>
					<th className={"py-2 px-4 font-normal text-left "}>
						Time to Resolve
					</th>
				</tr>

			</thead>
			<tbody>
				{sortedData.map((item, index) => (
					<tr key={index} className="border-b">
						<td className="py-2 px-4 text-sm">
							{index + 1}
						</td>
						<td className="py-2 px-4 text-sm">
							{item?.Customer?.UnitDetails}
						</td>
						<td className="py-2 px-4 text-sm">
							{item?.IssueName}
						</td>
						<td className="py-2 px-4 text-sm">
							{item?.CRMExecutive?.firstName + ' ' + item?.CRMExecutive?.lastName}
						</td>
						<td className="py-2 px-4 text-sm">
							{item?.SiteEngineer?.firstName + ' ' + item?.SiteEngineer?.lastName}
						</td>
						<td className="py-2 px-4  text-sm text-blue-500">
							{item?.duration}
						</td>
						<td className="py-2 px-4  text-sm text-blue-500">
							{item?.IsReopened ? 'Yes' : 'No'}
						</td>
						<td className="py-2 px-4  text-sm text-blue-500">
							{item?.reopenDuration}
						</td>
					</tr>
				))}
			</tbody>
		</table >
	);
};

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
	const pages = [];

	for (let i = 1; i <= totalPages; i++) {
		pages.push(i);
	}

	return (
		<nav aria-label="Page navigation">
			<ul className="inline-flex items-center -space-x-px">
				<li>
					<button
						onClick={() => onPageChange(currentPage - 1)}
						disabled={currentPage === 1}
						className="px-3 py-2 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700"
					>
						Previous
					</button>
				</li>
				{pages.map((page) => (
					<li key={page}>
						<button
							onClick={() => onPageChange(page)}
							className={`px-3 py-2 leading-tight ${currentPage === page
								? 'text-blue-600 bg-blue-50 border-blue-300'
								: 'text-gray-500 bg-white border-gray-300 hover:bg-gray-100 hover:text-gray-700'
								} border`}
						>
							{page}
						</button>
					</li>
				))}
				<li>
					<button
						onClick={() => onPageChange(currentPage + 1)}
						disabled={currentPage === totalPages}
						className="px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700"
					>
						Next
					</button>
				</li>
			</ul>
		</nav>
	);
};

function QualityMetrics() {
	const [qualityReport, setQualityReport] = useState([])
	const { result: categoryResult } = useSelector(selectListItems);
	const { activeDashboardProject } = useSelector(selectCurrentCRM);
	const { items: categoryItems } = categoryResult
	const dispatch = useDispatch()
	const [pages, setPages] = useState(1)
	const [currentPage, setCurrentPage] = useState(1)
	const [activeProject, setActiveProject] = useState('')
	const [activeCategory, setActiveCategory] = useState('')
	const { qualityMetricsCategory } = useSelector(selectCurrentCRM);

	useEffect(() => {
		dispatch(projectcards.list({ entity: 'PDI_Project_Cards', jsonData: { page: 1, limit: 200 } }))
		dispatch(categoryname.list({ entity: 'PDI_Category_Name', options: {} }))
		setActiveProject(activeDashboardProject)
	}, [])

	useEffect(() => {
		if (categoryItems.length > 0 && qualityMetricsCategory != "") {
			const data = categoryItems.filter((obj) => obj.Name == qualityMetricsCategory)
			if (data.length > 0) {
				setActiveCategory(data[0]?._id)
			}
		}
	}, [qualityMetricsCategory, categoryItems])

	const getQualityMetric = async (id, category, curr) => {
		const token = storePersist.get('auth')?.current
		let response
		try {
			response = await axios.post('https://api.greypath.co/api/v1/PDI_Issue_Cards/filter', {
				"params": {
					"Project": id,
					"CategoryName": category,

				},
				page: curr,
				// limit: 200
			},
				{
					method: 'POST',
					mode: 'no-cors',
					cache: 'no-cache',
					credentials: 'include',
					headers: {
						'Content-Type': 'application/json',
						'Authorization': 'Bearer ' + token
					},
					redirect: 'follow',
					referrerPolicy: 'no-referrer',
				})
		} catch (error) {
			return JSON.stringify(error);
		}
		finally {
			if (response && response?.data && response?.data?.result) {

				setQualityReport(response?.data?.result)
				setPages(response?.data?.pagination.pages)
			}
		}
	}

	// useEffect(() => {
	// 	if (items.length > 0) {
	// 		setActiveProject(items[0]._id)

	// 	}
	// }, [items])

	const handlePageChange = (page) => {
		setCurrentPage(page);
		getQualityMetric(activeProject, activeCategory, page)
	};

	useEffect(() => {
		if (activeProject != '' && activeCategory != "") {
			getQualityMetric(activeProject, activeCategory)
		}
	}, [activeProject, activeCategory])

	return (
		<div className="min-h-screen bg-gray-100 p-6">
			<div className='flex flex-row justify-between items-center px-1 py-1'>
				<div className="text-3xl font-bold ">Quality Metrics</div>
				<div className="border-x border-y border-black border-opacity-40 pr-5 pl-2  py-1  rounded text-sm shadow focus:outline-none focus:ring w-max ease-linear transition-all duration-150" >
					<select
						className="w-max bg-gray-100 text-lg"
						onChange={(e) => setActiveCategory(e.target.value)}
						value={activeCategory}>
						{categoryItems.map((item, index) => {
							return <option value={item._id} key={index}> {item?.Name}</option>
						})}
					</select>
				</div>
			</div>
			<div className="container mx-auto my-8 space-y-3">

				{pages > 0 ? <Pagination
					currentPage={currentPage}
					totalPages={pages}
					onPageChange={(e) => handlePageChange(e)}
				/> : <div className="text-center py-4 w-full border-x border-y border-gray-600">
					<p className="text-gray-500 text-3xl">No Data Found</p>
				</div>}
				{qualityReport.length > 0 ? <SortableTable data={qualityReport} /> : <></>}

			</div>
		</div>
	);
}

export default QualityMetrics;
