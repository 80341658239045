import { crm } from "../redux/crm/actions";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentCRM } from '../redux/crm/selectors';
import { selectListItems } from '../redux/inspectionstatus/selectors';
import { selectItemsFilter as projectUserFiter } from '../redux/projectusers/selectors';
import { selectListItems as selectUserListItems } from '../redux/users/selectors';
import { selectListItems as selectStageListItems } from '../redux/inspectionstage/selectors';
import { inspectionstatus } from "../redux/inspectionstatus/actions";
import { inspectionstage } from "../redux/inspectionstage/actions";
import { users } from "../redux/users/actions";
import { inspectioncards } from "../redux/inspectioncards/actions";
import { projectusers } from "../redux/projectusers/actions";

export default function AssignUser() {
	const dispatch = useDispatch();
	const { assignUser, selectedCustomer, activeProjectId, activeAssignCRM, activeSiteEngineer, activeStage, activeStatus } = useSelector(selectCurrentCRM);
	const { result } = useSelector(selectListItems)
	const { result: result1 } = useSelector(selectUserListItems)
	const { result: result2 } = useSelector(selectStageListItems)
	const { result: result3 } = useSelector(projectUserFiter)
	const { items: statusitems } = result
	const { items: useritems } = result1
	const { items: stageitems } = result2
	const { items: projectUser } = result3
	const [steps, setSteps] = useState({});
	const [selectedCount, setSelectedCount] = useState(0)
	const [userIds, setUserIds] = useState([])
	const handleCancel = () => {
		dispatch(crm.trigger({ assignUser: 'close' }))
	}

	const handleSteps = (e, key) => {
		setSteps({ ...steps, [key]: e.target.value })
	}

	const handleAssign = async () => {
		await Promise.all(selectedCustomer.map((item, index) => {
			const id = Object.keys(item)[0]
			if (item[id]) {
				dispatch(inspectioncards.update({
					entity: 'PDI_Customer_Details', id: id, jsonData: {
						entity:
							steps
					}
				})).then(() => {
					dispatch(crm.trigger({ reloadCustomerDetails: true, assignUser: 'close' }))
				})
			}
		}))

	}

	useEffect(() => {
		let count = []
		selectedCustomer?.map((item) => {
			if (Object.values(item)[0]) {
				count.push(item)
			}
		})
		setSelectedCount(count?.length)
	}, [selectedCustomer])
	useEffect(() => {
		const _userIds = []
		Promise.all(projectUser.map((item) => {
			_userIds.push(item.User._id)
		}))
		setUserIds(_userIds)
	}, [projectUser])

	useEffect(() => {
		dispatch(inspectionstatus.list({ entity: 'PDI_Inspection_Status', options: {} }))
		dispatch(inspectionstage.list({ entity: 'PDI_Inspection_Stage', options: {} }))
		dispatch(users.list({ entity: 'users', options: {} }))
		dispatch(projectusers.filter({ entity: 'PDI_Project_Users', jsonData: { params: { isDeleted: false, ProjectName: activeProjectId } }, page: 1, limit: 200 }))
		setSteps({})
	}, [])
	useEffect(() => {
		if (assignUser == 'open') {
			setSteps({ ...steps, ['Stage']: activeStage, ['Status']: activeStatus, ['AssignCRM']: activeAssignCRM, ['AssignSiteEngineer']: activeSiteEngineer })
		}
	}, [assignUser])

	return (
		<>
			{assignUser == 'open' ? (
				<>
					<div className="absolute fixed w-full mt-10 inset-0 z-50 flex flex-warp justify-center align-middle">
						<div className="w-full my-2 mx-auto max-w-sm">
							<div className="rounded-lg shadow-lg flex flex-col w-full bg-white px-3">
								<div className="flex flex-col items-start justify-start my-5 mx-1 ">
									<span className="text-xl">Assign </span>
									<span className="text-xs">{selectedCount} Unit have been selected </span>
								</div>
								<div className="flex flex-row space-x-4 my-2">
									<div className=" w-1/2">
										<label
											className="block text-xs font-bold mb-2"
											htmlFor="grid-password" >
											CRM Executive
										</label>
										<div className="px-3 border-0  bg-black bg-opacity-10  text-xs shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" >
											<select
												className="w-full py-1 bg-black bg-opacity-0 text-xs"
												onChange={(e) => handleSteps(e, 'AssignCRM')}
												value={steps['AssignCRM']}
											>
												<option value="1" key={1}>--Select--</option>
												{useritems?.filter(item1 => userIds?.some(item2 => item2 == item1._id))?.filter((obj) => obj?.roleId?._id == '65dfe45c6ad846404c6cc572')?.map((item, index) => {
													return <option value={item._id} key={index}> {item.userId}</option>
												})}
											</select>
										</div>
									</div>
									<div className=" w-1/2">
										<label
											className="block text-xs font-bold mb-2"
											htmlFor="grid-password" >
											QC/Engineer
										</label>
										<div className="px-3 border-0  bg-black bg-opacity-10  text-xs shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" >
											<select
												className="w-full py-1 bg-black bg-opacity-0 text-xs"
												onChange={(e) => handleSteps(e, 'AssignSiteEngineer')}
												value={steps['AssignSiteEngineer']}
											>
												<option value="1" key={1}>--Select--</option>
												{useritems?.filter(item1 => userIds?.some(item2 => item2 == item1._id))?.filter((obj) => obj?.roleId?._id == '65dfe4766ad846404c6cc573')?.map((item, index) => {
													return <option value={item._id} key={index}> {item.userId}</option>
												})}
											</select>
										</div>
									</div>
								</div>
								<div className="flex flex-row space-x-4 my-2">
									<div className=" w-1/2">
										<label
											className="block text-xs font-bold mb-2"
											htmlFor="grid-password" >
											Stage
										</label>
										<div className="px-3 border-0  bg-black bg-opacity-10  text-xs shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" >
											<select
												className="w-full py-1 bg-black bg-opacity-0 text-xs"
												onChange={(e) => handleSteps(e, 'Stage')}
												value={steps['Stage']}
											>
												<option value="1" key={1}>--Select--</option>
												{stageitems.map((item, index) => {
													return <option value={item._id} key={index}> {item.Name}</option>
												})}
											</select>
										</div>
									</div>
									<div className="w-1/2">
										<label
											className="block text-xs font-bold mb-2"
											htmlFor="grid-password" >
											Status
										</label>
										<div className="px-3 border-0  bg-black bg-opacity-10  text-xs shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" >
											<select
												className="w-full py-1 bg-black bg-opacity-0 text-xs"
												onChange={(e) => handleSteps(e, 'Status')}
												value={steps['Status']}
											>
												<option value="1" key={1}>--Select--</option>
												{statusitems.map((item, index) => {
													return <option value={item._id} key={index}> {item.Name}</option>
												})}
											</select>
										</div>
									</div>
								</div>
								<div className="flex items-center justify-center px-6 py-4 mx-3 space-x-3">
									<button
										className="rounded border-x border-y border-blue-300 text-blue-500 font-bold px-2 text-xs py-1"
										type="button"
										onClick={() => handleCancel()}
									>
										Cancel
									</button>
									<button
										className="rounded bg-blue-600 text-white font-bold px-2 text-xs py-1"
										type="button"
										onClick={() => handleAssign()}
									>
										Update
									</button>
								</div>
							</div>
						</div>
					</div>
					<div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
				</>
			) : null}
		</>
	);
}