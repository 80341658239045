export const ISSUE_STATUS_RESET_STATE = 'ISSUE_STATUS_RESET_STATE';
export const ISSUE_STATUS_CURRENT_ITEM = 'ISSUE_STATUS_CURRENT_ITEM';

export const ISSUE_STATUS_REQUEST_LOADING = 'ISSUE_STATUS_REQUEST_LOADING';
export const ISSUE_STATUS_REQUEST_SUCCESS = 'ISSUE_STATUS_REQUEST_SUCCESS';
export const ISSUE_STATUS_REQUEST_FAILED = 'ISSUE_STATUS_REQUEST_FAILED';

export const ISSUE_STATUS_CURRENT_ACTION = 'ISSUE_STATUS_CURRENT_ACTION';
export const ISSUE_STATUS_RESET_ACTION = 'ISSUE_STATUS_RESET_ACTION';


export const ISSUE_STATUS_NAME_EXISTS_ACTION = 'ISSUE_STATUS_NAME_EXISTS_ACTION';
export const ISSUE_STATUS_RESET_NAME_EXISTS = 'ISSUE_STATUS_RESET_NAME_EXISTS';