import { crm } from "../redux/crm/actions";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentCRM } from '../redux/crm/selectors';
import { issuechangetype } from "../redux/issuechangetype/actions";
import { useForm } from "react-hook-form";
import { selectCreatedItem } from "../redux/issuechangetype/selectors";

export default function AddIssueChangeType() {
	const dispatch = useDispatch();
	const { addIssueChangeType, isIssueChangeTypeEdit, issueChangeTypeItem, activeIssueChangeTypeId } = useSelector(selectCurrentCRM);
	const { isSuccess, error, isNameExists } = useSelector(selectCreatedItem);
	const [name, setName] = useState('')
	const { register, handleSubmit, clearErrors, formState: { errors }, setValue } = useForm();


	const handleSave = () => {
		if (!isIssueChangeTypeEdit) {
			dispatch(issuechangetype.resetAction({ actionType: 'create' }))
			dispatch(issuechangetype.create({
				entity: 'PDI_Issue_Change_Type', jsonData: {
					entity:
					{
						Name: name
					}
				}
			}))
		}
		else {
			dispatch(issuechangetype.update({
				entity: 'PDI_Issue_Change_Type', id: activeIssueChangeTypeId, jsonData:
				{
					entity: {
						Name: name
					}
				}
			})).then(() => {
				dispatch(crm.trigger({ reloadIssueChangeType: true, addIssueChangeType: 'close', isIssueChangeTypeEdit: false }))
			})
		}
	}

	useEffect(() => {
		dispatch(crm.trigger({ reloadIssueChangeType: true, addIssueChangeType: 'close' }))
	}, [isSuccess])
	useEffect(() => {
		if (addIssueChangeType == 'open') {
			if (isNameExists) {
				dispatch(issuechangetype.resetName());
			}
			clearErrors("name")
		}
	}, [addIssueChangeType])

	useEffect(() => {
		if (isIssueChangeTypeEdit) {
			setValue('name', issueChangeTypeItem.Name)
			setName(issueChangeTypeItem.Name)
		}
		else {
			setValue('name', '')
			setName('')
		}
	}, [issueChangeTypeItem])

	const handleCancel = () => {
		dispatch(crm.trigger({ addIssueChangeType: 'close' }))
	}

	return (
		<>
			{addIssueChangeType == 'open' ? (
				<>
					<div className="absolute fixed w-full inset-x-0 inset-y-auto z-50 flex flex-warp justify-center align-middle w-[350px] h-[215px]">
						<div className="w-[300px] my-2 p-3 mx-auto ">
							<div className="rounded-lg shadow-lg flex flex-col w-full bg-white">
								<h3 className="text-xs font-bold pt-3 pb-1 px-5">
									{isIssueChangeTypeEdit ? 'Edit ' : 'Add '} Issue Change Type
								</h3>
								<form onSubmit={handleSubmit(handleSave)} >
									<div className="flex flex-col justify-start align-middle px-5 py-3" >
										<span className="text-blue-600 text-xs font-bold py-1">
											Issue Change Type
											<sup className="text-red-500">*</sup></span>
										<input
											id="name"
											aria-invalid={errors.name ? "true" : "false"}
											{...register('name', { required: true, maxLength: 50 })}
											type="text"
											name={"name"}
											className="border-0 px-3 py-1 bg-black bg-opacity-10  text-xs w-full"
											onChange={(e) => setName(e.target.value)}
										/>
										{errors.name && errors.name.type === "required" && (
											<span role="alert" className="w-full text-red-500 bg-white text-xs my-1">This is required</span>
										)}
										{errors.name && errors.name.type === "maxLength" && (
											<span role="alert" className="w-full text-red-500 bg-white text-xs my-1">Max length exceeded</span>
										)}

										{isNameExists && (
											<span role="alert" className="w-full text-red-500 bg-white text-xs my-1">{error?.message}</span>
										)}
									</div>
									<div className="flex items-center justify-around px-6 my-4 mx-3 ">
										<button
											className="text-blue-500 bg-transparent rounded font-bold  px-2 text-xs outline py-1"
											type="button"
											onClick={() => handleCancel()}
										>
											Cancel
										</button>
										<input type="submit"
											className="bg-blue-500 text-white rounded  px-2 text-xs py-1 mx-3"
											value={isIssueChangeTypeEdit ? 'Update' : 'Save'}
										/>
									</div>
								</form>
							</div>
						</div>
					</div>
					<div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
				</>
			) : null}
		</>
	);
}