import * as actionTypes from './types';

const INITIAL_KEY_STATE = {
	result: null,
	current: null,
	isLoading: false,
	isSuccess: false,
	isNameExists: false,
    error: null,
};

const INITIAL_STATE = {
	current: {
		result: null,
	},
	list: {
		result: {
			items: [],
			pagination: {
				current: 1,
				pageSize: 5,
				total: 1,
				showSizeChanger: false,
			},
		},
		isLoading: false,
		isSuccess: false,
	},
	create: INITIAL_KEY_STATE,
	update: INITIAL_KEY_STATE,
	delete: INITIAL_KEY_STATE,
	read: INITIAL_KEY_STATE,
	search: { ...INITIAL_KEY_STATE, result: [] },
};

const projectstatusReducer = (state = INITIAL_STATE, action) => {
	const { payload, keyState } = action;
	switch (action.type) {
		case actionTypes.PROJECT_STATUS_RESET_STATE:
			return INITIAL_STATE;
		case actionTypes.PROJECT_STATUS_CURRENT_ITEM:
			return {
				...state,
				current: {
					result: payload,
				},
			};
		case actionTypes.PROJECT_STATUS_REQUEST_LOADING:
			return {
				...state,
				[keyState]: {
					...state[keyState],
					isLoading: true,
				},
			};
		case actionTypes.PROJECT_STATUS_REQUEST_FAILED:
			return {
				...state,
				[keyState]: {
					...state[keyState],
					isLoading: false,
					isSuccess: false,
				},
			};
		case actionTypes.PROJECT_STATUS_REQUEST_SUCCESS:
			return {
				...state,
				[keyState]: {
					result: payload,
					isLoading: false,
					isSuccess: true,
				},
			};
		case actionTypes.PROJECT_STATUS_CURRENT_ACTION:
			return {
				...state,
				[keyState]: {
					...INITIAL_KEY_STATE,
					current: payload,
				},
			};
		case actionTypes.PROJECT_STATUS_RESET_ACTION:
			return {
				...state,
				[keyState]: {
					...INITIAL_STATE[keyState],
				},
			};
			case actionTypes.PROJECT_STATUS_NAME_EXISTS_ACTION:
					return {
					  ...state,
					  create: {
						...state.create,
						isNameExists: true,
						isLoading: false,
					    isSuccess: false,
						error: payload,
					  },};
					  case actionTypes.PROJECT_STATUS_RESET_NAME_EXISTS:
					return {
					  ...state,
					  create: {
						...state.create,
						isNameExists: false,
						error: null,
					  },};
		default:
			return state;
	}
};

export default projectstatusReducer;
