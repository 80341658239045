import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import _ from 'lodash'
import EmptyBoard from "./EmptyBoard";
import { crm } from "../../redux/crm/actions";
import { selectCurrentCRM } from '../../redux/crm/selectors';
import { inspectioncards } from '../../redux/inspectioncards/actions'
import { inspectionstatus } from '../../redux/inspectionstatus/actions'
import { inspectionstage } from '../../redux/inspectionstage/actions'
import { selectItemsFilter } from '../../redux/inspectioncards/selectors';
import { selectItemsFilter as selectIssueFilter } from '../../redux/issuecards/selectors';
import { Bars } from 'react-loader-spinner';
import { selectListItems as selectStatusItems } from '../../redux/inspectionstatus/selectors';
import { selectListItems as selectStageItems } from '../../redux/inspectionstage/selectors';
import AddCustomer from "../../modals/AddCustomer";
import AssignUser from '../../modals/AssignUser';
import { request } from '../../request';
import { toast } from 'tailwind-toast'
import { issuecards } from "../../redux/issuecards/actions";
import { customerdetails } from "../../redux/customerdetails/actions";


function InspectionHome() {
	const dispatch = useDispatch();
	const navigate = useNavigate()
	const [siteEngineerimageUrls, setSiteEngineerImageUrls] = useState([])
	const [crmImageUrls, setCrmImageUrls] = useState([])
	const columns = ['Not Started', 'In Progress', 'Completed']
	const { activeBoardName, reloadCustomerDetails, activeProjectId, addCustomer } = useSelector(selectCurrentCRM)
	const { result } = useSelector(selectItemsFilter);
	const { result: result1 } = useSelector(selectIssueFilter)
	const { result: statusResult } = useSelector(selectStatusItems);
	const { result: stageResult } = useSelector(selectStageItems);
	const { items } = result ? result : [];
	const { items: issueItems } = result1 ? result1 : [];
	const { items: statusitems } = statusResult;
	const { items: stageitems } = stageResult;
	const [search, setSearch] = useState('')
	const [issueList, setIssueList] = useState([])
	const [filteredList, setFilteredList] = useState([])
	const [loader, setLoader] = useState('')
	const [data, setData] = useState('')
	const [toStage, setToStage] = useState({})

	const handleSearch = (e) => {
		const searchTerm = e.target.value
		setSearch(searchTerm)
		setFilteredList(items.filter((item) => item?.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
			item?.lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
			item?.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
			item?.Block.toLowerCase().includes(searchTerm.toLowerCase()) ||
			item?.NumberOfBedrooms?.Name.toLowerCase().includes(searchTerm.toLowerCase()) ||
			item?.UnitDetails.toLowerCase().includes(searchTerm.toLowerCase())));
	}

	const getImage = (id) => {
		let url = crmImageUrls.filter((obj) => obj.id == id)[0]?.url
		return url
	}

	const getImage1 = (id) => {
		let url = siteEngineerimageUrls.filter((obj) => obj.id == id)[0]?.url
		return url
	}

	const getImageUrl = async () => {
		let images = []
		let images1 = []
		await Promise.all(items.map(async (item) => {
			const url = "https://proptech-pdi.s3.amazonaws.com/" + item?.AssignCRM?.profileImage
			images.push({ id: item._id, url: url })
		}))
		await Promise.all(items.map(async (item) => {
			const url = "https://proptech-pdi.s3.amazonaws.com/" + item?.AssignSiteEngineer?.profileImage
			images1.push({ id: item._id, url: url })
		}))
		setCrmImageUrls(images)
		setSiteEngineerImageUrls(images1)
	}

	useEffect(() => {
		setIssueList(issueItems)
	}, [issueItems])

	const getIssueCount = (id) => {
		let issuecount = []
		issuecount = issueList.filter((item) => item.Customer._id == id)
		return issuecount?.length
	}

	const getIssueCompletedCount = (id) => {
		let issueCompletedCount = []
		issueCompletedCount = issueList.filter((item) => item.Customer._id == id && item?.Status?.Name == 'Finished')
		return issueCompletedCount?.length
	}

	const getAttachementCount = (id) => {
		let attachmentCount = 0
		let attachments = issueList.filter((item) => item.Customer._id == id)
		attachments.map((att) => {
			attachmentCount = attachmentCount + att.Attachments?.length
		})
		return attachmentCount
	}
	useEffect(() => {
		setFilteredList(items)
		getImageUrl()
	}, [items]);

	const handleStageFilter = (e) => {
		setFilteredList({})
		const id = e.target.value
		const filterItems = items.filter((item) => (item?.project?._id == activeProjectId && item?.Stage?._id == id))
		setFilteredList(filterItems);
	}

	const handleStatusFilter = (e) => {
		setFilteredList({})
		const id = e.target.value
		const filterItems = items.filter((item) => (item?.project?._id == activeProjectId && item?.Status?._id == id))
		setFilteredList(filterItems);
	}

	const handleKandbanBoard = () => {
		navigate('/inspection-crm/inspection-boards')
		dispatch(crm.trigger({ inspectionReload: true }))
	}

	const handleSyncCRM = () => {
		toast().warning("Hold : ", "Analysis is going on").with({
			shape: "pill",
			duration: 2000,
			speed: 1000,
			positionX: 'end',
			icon: 'fa fa-check-circle',
			positionY: 'top',
			color: 'bg-red-50',
			fontColor: 'red',
			fontTone: 'bg-blue-100'
		}).show()
	}

	const handleImportCustomer = () => {
		toast().warning("WIP : ", "Bulk Inpection card creation Pending").with({
			shape: "pill",
			duration: 2000,
			speed: 1000,
			positionX: 'end',
			icon: 'fa fa-check-circle',
			positionY: 'top',
			color: 'bg-red-50',
			fontColor: 'red',
			fontTone: 'bg-blue-100'
		}).show()
		//navigate('/inspection-crm/import-customer')
	}

	const handleOnDrop = (e, col) => {
		setToStage(col)
		//setLoader(true)

		// const { prevColIndex, taskIndex } = JSON.parse(
		// 	e.dataTransfer.getData("text")
		// );
		// if (colIndex !== prevColIndex) {
		// 	dispatch(
		// 		boardsSlice.actions.dragTask({ colIndex, prevColIndex, taskIndex })
		// 	);
		// }
	};

	useEffect(() => {
		if (data != '' && toStage != {}) {
			let board_status = ''
			if (toStage == 'In Progress') {
				board_status = '659b7d1ba5c872001ab13db2'
			}
			else if (toStage == 'Completed') {
				board_status = '659b7d15a5c872001ab13da6'
			}
			else if (toStage == 'Not Started') {
				board_status = '659b7d22a5c872001ab13dbe'
			}
			if (board_status != '') {
				dispatch(customerdetails.update({
					entity: 'PDI_Customer_Details', id: data?._id, jsonData: {
						entity: {
							"Status": board_status
						}
					}
				})).then(() => {
					setData('')
					setLoader(false)
					setToStage({})
					dispatch(crm.trigger({ reloadCustomerDetails: true }))
				})
			}

		}
	}, [data, toStage])

	const handleOnDrag = (item) => {
		setData(item)
		// e.dataTransfer.setData(
		// 	"text",
		// 	JSON.stringify({ taskIndex, prevColIndex: colIndex })
		// );
	};

	const handleOnDragOver = (e) => {
		e.preventDefault();
	};

	const handleProject = () => {
		navigate('/inspection-crm/project-boards')
	}

	useEffect(() => {
		if (reloadCustomerDetails) {
			dispatch(inspectioncards.filter({ entity: 'PDI_Customer_Details', jsonData: { params: { project: activeProjectId }, offset: 200, limit: 200 } }))
			dispatch(crm.trigger({ reloadCustomerDetails: false }))
		}
	}, [reloadCustomerDetails])

	const handleAddCustomer = () => {
		dispatch(crm.trigger({ addCustomer: 'open', isEditCustomerDetails: false }))
	}

	useEffect(() => {
		dispatch(inspectioncards.filter({ entity: 'PDI_Customer_Details', jsonData: { params: { project: activeProjectId }, page: 1, limit: 200 } }))
		dispatch(inspectionstatus.list({ entity: 'PDI_Inspection_Status', options: { page: 1, limit: 5 } }))
		dispatch(inspectionstage.list({ entity: 'PDI_Inspection_Stage', options: { page: 1, limit: 5 } }))
		dispatch(issuecards.filter({ entity: 'PDI_Issue_Cards', jsonData: { params: { Project: activeProjectId }, page: 1, limit: 200 } }))

	}, [])

	const handleInspectionList = () => {
		navigate('/inspection-crm/inspection-items')
	}

	const handleProjectChecklist = (obj) => {
		navigate("/inspection-crm/project-checklist")
		dispatch(crm.trigger({ activeCustomerId: obj._id, activeCustomer: obj?.UnitDetails + '_' + obj?.Block + '_' + obj?.firstName + obj?.lastName, assignCRM: obj?.AssignCRM._id, assignSiteEngineer: obj.AssignSiteEngineer._id }))
	}

	const handleCustomerEdit = (item) => {
		dispatch(crm.trigger({ addCustomer: 'open', activeCustomerItem: item, activeCustomerId: item._id, isEditCustomerDetails: true }))
	}

	return (
		<>
			{loader ? <div className="opacity-25 fixed inset-0 z-40 bg-black">
				<div className="flex justify-center  items-center h-full w-full"><Bars
					height="80"
					width="80"
					color="#4fa94d"
					ariaLabel="bars-loading"
					wrapperStyle={{}}
					wrapperClass=""
					visible={true}
				/></div>
			</div> : ""}
			{addCustomer == 'open' ? <AddCustomer /> : ""}
			<div className="flex flex-col item-center bg-[#F9F9FAFF]">
				<header className="flex justify-between h-20 w-full mx-auto px-5 dark:text-white items-center">
					<div className="flex justify-start w-full" >
						<nav aria-label="breadcrumb" className="w-max" >
							<ol className="flex flex-wrap items-center w-full px-4 py-2 rounded-md">
								<li
									className="flex items-center ">
									<h3 className="text-xl font-bold text-[#787C83FF] " onClick={() => handleProject()}>Projects</h3>
									<span
										className="mx-2 ">
										<svg fill="#787C83FF" height="12px" width="12px" version="1.1" id="Layer_1"
											viewBox="0 0 330 330">
											<path id="XMLID_222_" d="M250.606,154.389l-150-149.996c-5.857-5.858-15.355-5.858-21.213,0.001
	c-5.857,5.858-5.857,15.355,0.001,21.213l139.393,139.39L79.393,304.394c-5.857,5.858-5.857,15.355,0.001,21.213
	C82.322,328.536,86.161,330,90,330s7.678-1.464,10.607-4.394l149.999-150.004c2.814-2.813,4.394-6.628,4.394-10.606
	C255,161.018,253.42,157.202,250.606,154.389z"/>
										</svg>
									</span>
								</li>
								<li
									className="flex items-center cursor-pointer">
									<div onClick={() => navigate('/inspection-crm/inspection-items')} className=" text-[#787C83FF]  text-md font-bold">
										<span>
											{activeBoardName}
										</span>
									</div>
									<span
										className="mx-2 ">
										<svg fill="#787C83FF" height="12px" width="12px" version="1.1" id="Layer_1"
											viewBox="0 0 330 330">
											<path id="XMLID_222_" d="M250.606,154.389l-150-149.996c-5.857-5.858-15.355-5.858-21.213,0.001
	c-5.857,5.858-5.857,15.355,0.001,21.213l139.393,139.39L79.393,304.394c-5.857,5.858-5.857,15.355,0.001,21.213
	C82.322,328.536,86.161,330,90,330s7.678-1.464,10.607-4.394l149.999-150.004c2.814-2.813,4.394-6.628,4.394-10.606
	C255,161.018,253.42,157.202,250.606,154.389z"/>
										</svg>
									</span>
								</li>
								<li
									className="flex items-center" onClick={() => navigate('/inspection-crm/inspection-items')}>
									<a href="#" className="text-[#787C83FF] text-md font-bold">
										Customer Details
									</a>
									<span
										className="mx-2 font-bold text-xl">
									</span>
								</li>
							</ol>
						</nav>
					</div>
					<div className="flex justify-end w-full" >
						<button className="w-auto mx-1 px-3 rounded p-2 text-[11px] font-semibold border-x border-y border-[#3C50E0FF] text-[#3C50E0FF]"
							onClick={() => {
								handleAddCustomer()
							}} > + Add Customer
						</button>
						<button className="w-auto mx-1 px-3 rounded p-2 text-[11px] font-semibold border-x border-y border-[#3C50E0FF] text-[#3C50E0FF]"
							onClick={() => {
								handleSyncCRM()
							}} > + Sync CRM
						</button>
						<button className="w-auto mx-1 px-3 rounded bg-[#3C50E0FF] text-white py-2 text-[11px] font-semibold"
							onClick={() => {
								handleImportCustomer()
							}} > + Import Customer
						</button>
					</div>
				</header >
				<div>
					<div className="flex flex-col  px-3 mx-5 h-[600px]  dark:bg-[#2b2c37]">
						<div className="flex flex-row items-center justify-start w-full h-14 my-2   ">
							<form className="mx-3">
								<div className="relative flex w-full flex-wrap items-stretch">
									<span className="z-10 h-full leading-snug font-normal absolute text-center text-blueGray-300 absolute rounded text-base items-center justify-center w-8 pl-3 py-2">
										<svg className="h-4 w-5 text-black border-opacity-40 " viewBox="0 0 24 24" fill="#A9A9A9"><path d="M11 2C15.968 2 20 6.032 20 11C20 15.968 15.968 20 11 20C6.032 20 2 15.968 2 11C2 6.032 6.032 2 11 2ZM11 18C14.8675 18 18 14.8675 18 11C18 7.1325 14.8675 4 11 4C7.1325 4 4 7.1325 4 11C4 14.8675 7.1325 18 11 18ZM19.4853 18.0711L22.3137 20.8995L20.8995 22.3137L18.0711 19.4853L19.4853 18.0711Z"></path></svg>
									</span>
									<input
										type="text"
										placeholder="Search here..."
										value={search}
										onChange={(e) => handleSearch(e)}
										className="border-x border-y px-3 py-1 border-black border-opacity-40 text-grey-600 relative rounded text-sm shadow outline-none focus:outline-none focus:ring w-[300px] pl-10 bg-[#F9F9FAFF]"
									/>
								</div>
							</form>
							<div className="flex justify-end w-36 mx-3 space-x-3 bg-[#F9F9FAFF]" >
								<div className="border-x border-y border-black border-opacity-40 pr-5 pl-2  py-1  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 bg-[#F9F9FAFF]" >
									<select
										className="w-full bg-[#F9F9FAFF] text-xs"
										onChange={(e) => handleStageFilter(e)} >
										<option value={1} key={1}>--Select --</option>
										{stageitems.map((item, index) => {
											return <option value={item._id} key={index}> {item.Name}</option>
										})}
									</select>
								</div>
							</div>
							<div className="flex justify-end w-36  mx-3 space-x-3" >
								<div className="border-x border-y border-black border-opacity-40 pr-5 pl-2  py-1  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" >
									<select
										className="w-full bg-[#F9F9FAFF] text-xs"
										onChange={(e) => handleStatusFilter(e)} >
										<option value={1} key={1}>--Select --</option>
										{statusitems.map((item, index) => {
											return <option value={item._id} key={index}> {item.Name}</option>
										})}
									</select>
								</div>
							</div>
							<div className="flex justify-end mx-auto space-x-3 mr-0 w-48">
								<label for="Toggle3" className="inline-flex items-center p-2 rounded-md cursor-pointer dark:text-gray-800">
									<input id="Toggle3" type="checkbox" className="hidden peer" />
									<span
										onClick={() => handleInspectionList()}
										className="px-4 py-2 w-20 text-center text-sm font-semibold rounded-l-md bg-gray-300 peer-checked:bg-blue-600 text-black peer-checked:text-white ">List</span>
									<span
										onClick={() => {
											handleKandbanBoard()
										}}
										className="px-4 py-2 w-20 text-center  text-sm  font-semibold rounded-r-md bg-blue-600  peer-checked:bg-gray-300 text-white peer-checked:text-black ">Kanban</span>
								</label>
							</div>
						</div>
						<AssignUser />
						<div className="flex flex-row">
							{columns.length > 0 ? (
								<>
									{columns.map((col, index) => (
										<div
											key={index}
											onDrop={(e) => handleOnDrop(e, col)}
											onDragOver={(e) => handleOnDragOver(e)}
											className="scrollbar-hide shadow-lg bg-[#EEEFF2FF] rounded p-5  mx-auto pt-[20px] min-w-[280px]">
											<div className=" font-semibold flex  items-center text-black">
												<div className="text-xl font-bold rounded-full w-4 h-4" />
												{col}  &#40;{filteredList.filter((item) => item.Status?.Name == col)?.length}&#41;
											</div>
											{filteredList.map((item, idx) => {
												if (item.Status?.Name == col) {
													return <div key={idx} className="bg-white">
														<div
															draggable
															onDragStart={() => handleOnDrag(item)}
															className=" w-[280px] first:my-5 rounded shadow-xl px-5 py-3 hover:text-[#635fc7]"
														>
															<div className="h-40 items-center">
																<div className="text-xs truncate .. font-bold mb-1  cursor-pointer" onClick={() => handleProjectChecklist(item)}>
																	{item?.UnitDetails}  - {item?.Block}
																	{item?.NumberOfBedrooms?.Name}  - {item?.ProjectType?.Name}
																</div>
																<div className="flex flex-row justify-between">
																	<div className="text-xs my-1">
																		{item?.firstName} {item?.lastName} - {item.mobile}
																	</div>
																	<div onClick={() => handleCustomerEdit(item)}>
																		<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"><path d="M19.045 7.401c.378-.378.586-.88.586-1.414s-.208-1.036-.586-1.414l-1.586-1.586c-.378-.378-.88-.586-1.414-.586s-1.036.208-1.413.585L4 13.585V18h4.413L19.045 7.401zm-3-3 1.587 1.585-1.59 1.584-1.586-1.585 1.589-1.584zM6 16v-1.585l7.04-7.018 1.586 1.586L7.587 16H6zm-2 4h16v2H4z" /></svg>
																	</div>
																</div>
																<div className="flex flex-row my-2">
																	<div>
																		<button className="inline-flex items-center mx-2 rounded px-2 py-1 text-[10px] bg-[#EBFDFFFF] font-semibold" >
																			{item.Stage.Name}
																		</button>
																	</div>
																	<div>
																		{item?.Status.Name == 'Not Started' ?
																			<button className="inline-flex items-center mx-2 rounded px-2 py-1 text-[10px] font-semibold bg-[#FEF9EEFF]" >
																				{item?.Status?.Name}
																			</button> :
																			item?.Status.Name == 'In Progress' ? <button className="inline-flex items-center mx-2 rounded px-2 py-1 text-[10px] font-semibold bg-red-100" >
																				{item?.Status.Name}
																			</button> : <button className="inline-flex items-center mx-2 rounded px-2 py-1 text-[10px] font-semibold bg-green-100" >
																				{item?.Status.Name}
																			</button>
																		}
																	</div>
																</div>
																<div className="flex flex-row my-2" onClick={() => navigate('/inspection-crm/issues-management')}>
																	<button className="inline-flex items-center mx-2 rounded bg-blue-100 px-2 py-1 text-[10px] font-semibold text-blue-500" >
																		{getIssueCompletedCount(item?._id)} out of {getIssueCount(item?._id)} Issue Completed
																	</button>
																</div>
																<div className="flex flex-row items-center">
																	<div className="flex flex-row items-center justify-around"
																		onClick={() => handleCustomerEdit(item)}>
																		<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#000000" viewBox="0 0 256 256"><path d="M88,104a8,8,0,0,1,8-8h64a8,8,0,0,1,0,16H96A8,8,0,0,1,88,104Zm8,40h64a8,8,0,0,0,0-16H96a8,8,0,0,0,0,16ZM232,56V184a16,16,0,0,1-16,16H156.53l-14.84,24.29a16,16,0,0,1-27.41-.06L99.47,200H40a16,16,0,0,1-16-16V56A16,16,0,0,1,40,40H216A16,16,0,0,1,232,56Zm-16,0H40V184H99.47a16.08,16.08,0,0,1,13.7,7.73L128,216l14.82-24.32A16.07,16.07,0,0,1,156.53,184H216Z"></path></svg>
																		<span>{getIssueCount(item?._id)}</span>
																	</div>
																	<div className="flex flex-row items-center justify-around mx-2"
																		onClick={() => handleCustomerEdit(item)}>
																		<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-paperclip"><path d="m21.44 11.05-9.19 9.19a6 6 0 0 1-8.49-8.49l8.57-8.57A4 4 0 1 1 18 8.84l-8.59 8.57a2 2 0 0 1-2.83-2.83l8.49-8.48" /></svg>
																		<span>{getAttachementCount(item?._id)}</span>
																	</div>
																	<div className="flex items-center justify-end w-full -mr-4 ">
																		<img
																			className="h-8 w-8 mt-3 rounded-full"
																			src={getImage(item._id)}
																			alt="image-text"
																		/>
																	</div>
																	<div className="flex items-center justify-end w-12 ">
																		<img
																			className="h-8 w-8 mt-3 rounded-full"
																			src={getImage1(item._id)}
																			alt="image-text"
																		/>
																	</div>
																</div>
															</div>
														</div>
													</div>
												}
											})}
										</div>
									))}
								</>
							) : (
								<>
									<EmptyBoard type="edit" />
								</>
							)
							}
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
export default InspectionHome;
