import { createSelector } from 'reselect';

const selectProjectType = (state) => state.projecttype;

export const selectCurrentItem = createSelector([selectProjectType], (projecttype) => projecttype.current);

export const selectListItems = createSelector([selectProjectType], (projecttype) => projecttype.list);


export const selectItemById = (itemId) =>
	createSelector(selectListItems, (list) => list.result.items.find((item) => item._id === itemId));

export const selectCreatedItem = createSelector([selectProjectType], (projecttype) => projecttype.create);

export const selectUpdatedItem = createSelector([selectProjectType], (projecttype) => projecttype.update);

export const selectReadItem = createSelector([selectProjectType], (projecttype) => projecttype.read);

export const selectDeletedItem = createSelector([selectProjectType], (projecttype) => projecttype.delete);

export const selectSearchedItems = createSelector([selectProjectType], (projecttype) => projecttype.search);

export const selectItemsCount = createSelector([selectProjectType], (projecttype) => projecttype.count);

export const selectItemsFilter = createSelector([selectProjectType], (projecttype) => projecttype.filter);



