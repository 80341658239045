import { initializeApp } from 'firebase/app';
import { getToken, getMessaging, onMessage } from 'firebase/messaging';

const firebaseConfig = {
	apiKey: "AIzaSyArINnf0MP29-xfK7Ai6Y43hnM5gtu6avE",
	authDomain: "propcheck-28e73.firebaseapp.com",
	projectId: "propcheck-28e73",
	storageBucket: "propcheck-28e73.appspot.com",
	messagingSenderId: "623443874051",
	appId: "1:623443874051:web:04241801cfcd34af619936",
	measurementId: "G-GYM40SXYBN"
};

const firebaseApp = initializeApp(firebaseConfig);
export const messaging = getMessaging(firebaseApp);


export const getOrRegisterServiceWorker = () => {
	if ('serviceWorker' in navigator) {
		return window.navigator.serviceWorker
			.getRegistration('/firebase-push-notification-scope')
			.then((serviceWorker) => {
				if (serviceWorker) return serviceWorker;
				return window.navigator.serviceWorker.register('/firebase-messaging-sw.js', {
					scope: '/firebase-push-notification-scope',
				});
			});
	}
	throw new Error('The browser doesn`t support service worker.');
};

export const getFirebaseToken = () => {
	return getOrRegisterServiceWorker()
		.then((serviceWorkerRegistration) => {
			return getToken(messaging, {
				vapidKey: "BKw5IiAF8L8hZpf6OqSBGpa_HWbWzydqdT8u1nZJyIPFMvyHV5Tgt7ODDuHHBjX395A_L4rToalpBs3kMnFrJ1c",
				serviceWorkerRegistration
			})
		}).catch((err) => {
			console.log(err)
		})
}





