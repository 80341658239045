import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentCRM } from '../redux/crm/selectors';
import { selectListItems } from '../redux/roles/selectors';
import { selectListItems as selectProjectItems } from '../redux/projectcards/selectors';
import { selectCreatedItem, selectUpdatedItem } from '../redux/users/selectors';
import { MultiSelect } from "react-multi-select-component";
import { roles } from '../redux/roles/actions'
import { projectcards } from "../redux/projectcards/actions";
import { projectusers } from "../redux/projectusers/actions";
import { request } from "../request";
import { users } from "../redux/users/actions";
import { crm } from "../redux/crm/actions";
import { Bars } from 'react-loader-spinner';


export default function AddMember() {
	const dispatch = useDispatch();
	const [profile, setProfile] = useState('');
	const [file, setFile] = useState();
	const { register, clearErrors, handleSubmit, formState: { errors }, setValue } = useForm();
	const { addMember, isEditMember, memberItem, activeMemberId, activeDocumentFileKey, createdMember, updateMember, assignProject } = useSelector(selectCurrentCRM);
	const [selectedProject, setSelectedProject] = useState([]);
	const [projectOptions, setProjectOptions] = useState([]);
	const { result } = useSelector(selectListItems);
	const { result: resp, isLoading } = useSelector(selectCreatedItem)
	const { result: updateresp } = useSelector(selectUpdatedItem)
	const { result: projectResult } = useSelector(selectProjectItems);
	const { items } = result
	const { items: projectItems } = projectResult
	const [steps, setSteps] = useState({});
	const [url, setUrl] = useState('')
	const [loader, setLoader] = useState('')

	useEffect(() => {
		if (addMember == 'open') {
			clearErrors("firstName")
			clearErrors("lastName")
			clearErrors("mobileNumber")
			clearErrors("email")
			clearErrors("userId")
			clearErrors("isdCode")
		}
	}, [addMember])

	const handleCancel = () => {
		dispatch(crm.trigger({ addMember: 'close' }))
	}

	const handleChange = (e) => {
		setProfile(URL.createObjectURL(e.target.files[0]));
		setFile(e.target.files[0])
	}

	const handleSteps = (e, key) => {
		setSteps({ ...steps, [key]: e.target.value })
	}

	useEffect(() => {
		if (createdMember) {
			setLoader(true)
			Promise.all(selectedProject?.map((selectedItem) => {
				dispatch(projectusers.create({
					entity: 'PDI_Project_Users', jsonData: {
						entity: {
							ProjectName: selectedItem?.value,
							User: isEditMember ? activeMemberId : resp?._id
						}
					}
				}))
			}))
			setLoader(false)
			dispatch(crm.trigger({ reloadMember: true, addMember: 'close', createdMember: false }))
		}
	}, [resp, createdMember])

	useEffect(() => {
		if (updateMember) {
			Promise.all(assignProject?.map((project) => {
				dispatch(projectusers.delete({
					entity: 'PDI_Project_Users', id: project?._id
				}))
			}))
			Promise.all(selectedProject?.map((selectedItem) => {
				dispatch(projectusers?.create({
					entity: 'PDI_Project_Users', jsonData: {
						entity: {
							ProjectName: selectedItem?.value,
							User: isEditMember ? activeMemberId : updateresp?._id
						}
					}
				}))
			}))
			dispatch(crm.trigger({ reloadMember: true, addMember: 'close', updateMember: false }))
		}
	}, [updateresp, updateMember])

	const handleCreate = async () => {
		if (file) {
			const exte = file?.name.substring(file?.name.indexOf(".") + 1);
			const docDetails = {
				extension: exte,
				file_name: file?.name,
				mime_type: file?.type,
				entity_type: "crm",
				size: file?.size,
			};
			let data = await request.uploadurl({ entity: 'document', jsonData: docDetails, options: {} })
			const type = file?.type
			await request.uploadfileAWSS3({ fileuploadurl: data?.uploadLink, file, type })
			if (isEditMember) {
				dispatch(users.update({
					entity: 'users', id: activeMemberId, jsonData: {
						entity: {
							"firstName": steps['firstName'],
							"lastName": steps['lastName'],
							"userId": steps['userId'],
							"email": steps['email'],
							"mobileNumber": steps['mobileNumber'],
							"profileImage": data?.filename,
							"isdCode": steps['isdCode'],
							"roleId": steps['roleId'],
						}
					}
				})).then(() => {
					dispatch(crm.trigger({ updateMember: true }))
				})
			}
			else {
				dispatch(users.create({
					entity: 'users', jsonData: {
						entity: {
							"firstName": steps['firstName'],
							"lastName": steps['lastName'],
							"userId": steps['userId'],
							"email": steps['email'],
							"mobileNumber": steps['mobileNumber'],
							"profileImage": data?.filename,
							"password": 'Spark@123$',
							"isdCode": steps['isdCode'],
							"roleId": steps['roleId'],
						}
					}
				})).then(() => {
					dispatch(crm.trigger({ createdMember: true }))
				})
			}
		} else {
			if (isEditMember) {
				dispatch(users.update({
					entity: 'users', id: activeMemberId, jsonData: {
						entity: {
							"firstName": steps['firstName'],
							"lastName": steps['lastName'],
							"userId": steps['userId'],
							"email": steps['email'],
							"mobileNumber": steps['mobileNumber'],
							"profileImage": steps['profileImage'],
							"roleId": steps['roleId'],
							"isdCode": steps['isdCode']
						}
					}
				})).then(() => {
					dispatch(crm.trigger({ updateMember: true }))
				})
			}
		}
	}

	const getImageUrl = async (key) => {
		const data = "https://proptech-pdi.s3.amazonaws.com/" + key
		setUrl(data)
	}

	const handleDelete = () => {
		setUrl('')
	}

	useEffect(() => {
		if (isEditMember) {
			let ids = []
			assignProject.map((project) => {
				const data = projectItems.filter((item) => item._id == project.ProjectName._id)
				ids.push({ label: data[0]?.ProjectName, value: data[0]?._id })
			})
			setSelectedProject(ids)
			setSteps(memberItem)
			setValue('firstName', memberItem?.firstName)
			setValue('lastName', memberItem?.lastName)
			setValue('email', memberItem?.email)
			setValue('mobileNumber', memberItem?.mobileNumber)
			setValue('userId', memberItem?.userId)
			setValue('isdCode', memberItem?.isdCode)
			let fileKey = memberItem?.profileImage
			getImageUrl(fileKey)
			setProfile('')
		}
		else {
			setProfile('')
			setSteps({})
			setFile(null)
			setUrl('')
			setValue('firstName', '')
			setValue('lastName', '')
			setValue('email', '')
			setValue('mobileNumber', '')
			setValue('userId', '')
			setValue('isdCode', '')
		}
	}, [isEditMember])

	useEffect(() => {
		let _projectOptions = []
		projectItems.map((item) => {
			_projectOptions.push({ label: item?.ProjectName, value: item?._id })
		})
		setProjectOptions(_projectOptions)
	}, [projectItems])

	useEffect(() => {
		dispatch(roles.list({ entity: 'roles', options: {} }))
		dispatch(projectcards.list({ entity: 'PDI_Project_Cards', options: {} }))
	}, [])

	return (
		<>
			{isLoading || loader ? <div className="opacity-25 fixed inset-0 z-40 bg-black">
				<div className="flex justify-center  items-center h-full w-full"><Bars
					height="80"
					width="80"
					color="#4fa94d"
					ariaLabel="bars-loading"
					wrapperStyle={{}}
					wrapperClass=""
					visible={true}
				/></div>
			</div> : ""}
			<div className="absolute fixed  inset-x-0 top-5  z-50 flex flex-warp justify-center items-center">
				<div className="w-[600px] my-2 mx-auto">
					<div className="rounded-lg shadow-lg flex flex-col w-full bg-white w-64">
						<h3 className="text-sm font-bold pt-4 pb-1 px-5">
							{isEditMember ? 'Edit ' : 'Add '} Member
						</h3>
						<form onSubmit={handleSubmit(handleCreate)} >
							<div className="w-full felx items-center justify-center my-2">
								<div className="w-full flex items-center justify-center p-3">
									<div className="w-1/2 mx-3 flex flex-col items-start justify-center " >
										<label
											className="block text-xs font-bold mb-1"
											htmlFor="name">
											First Name <sup className="text-red-500">*</sup>
										</label>
										<div className="w-full  bg-white text-xs h-6" >
											<input
												id="firstName"
												aria-invalid={errors.firstName ? "true" : "false"}
												{...register('firstName', { required: true, maxLength: 30 })}
												type="text"
												placeholder="First Name"
												className="w-full px-3 py-1 bg-[#F3F4F6FF] text-xs"
												onChange={(e) => handleSteps(e, 'firstName')}
											/>
											{errors.firstName && errors.firstName.type === "required" && (
												<span role="alert" className="w-full text-red-500 bg-white px-3">This is required</span>
											)}
											{errors.firstName && errors.firstName.type === "maxLength" && (
												<span role="alert" className="w-full text-red-500 bg-white px-3">Max length exceeded</span>
											)}
										</div>
									</div>
									<div className="w-1/2 mx-3 flex flex-col items-start justify-center " >
										<label
											className="block text-xs font-bold mb-1"
											htmlFor="grid-password">
											Last Name<sup className="text-red-500">*</sup>
										</label>
										<div className="w-full  bg-white  text-xs h-6" >
											<input
												id="lastName"
												aria-invalid={errors.lastName ? "true" : "false"}
												{...register('lastName', { required: true, maxLength: 30 })}
												type="text"
												placeholder="Last Name"
												className="w-full px-3 py-1 bg-[#F3F4F6FF] text-xs"
												onChange={(e) => handleSteps(e, 'lastName')}
											/>
											{errors.lastName && errors.lastName.type === "required" && (
												<span role="alert" className="w-full text-red-500 bg-white px-3">This is required</span>
											)}
											{errors.lastName && errors.lastName.type === "maxLength" && (
												<span role="alert" className="w-full text-red-500 bg-white px-3">Max length exceeded</span>
											)}
										</div>
									</div>
								</div>
								<div className="w-full flex items-center justify-center p-3">
									<div className="w-1/2 mx-3 flex flex-col items-start justify-center " >
										<label
											className="block text-xs font-bold mb-1"
											htmlFor="email">
											Email<sup className="text-red-500">*</sup>
										</label>
										<div className="w-full bg-white text-xs h-6" >
											<input
												id="email"
												type="text"
												aria-invalid={errors.email ? "true" : "false"}
												placeholder="Email"
												{...register('email', {
													required: true,
													pattern:
														/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
												})}
												className="w-full px-3 py-1 bg-[#F3F4F6FF] text-xs"
												onChange={(e) => handleSteps(e, 'email')}
											/>
											{errors.email && errors.email.type === "required" && (
												<span role="alert" className="w-full text-red-500 bg-white px-3">This is required</span>
											)}
											{errors.email && errors.email.type === "pattern" && (
												<span role="alert" className="w-full text-red-500 bg-white px-3">Please enter valid email id</span>
											)}
										</div>
									</div>
									<div className="w-1/2 mx-3 flex flex-col items-start justify-center " >
										<label
											className="block text-xs font-bold mb-1"
											htmlFor="mobileNumber">
											Mobile<sup className="text-red-500">*</sup>
										</label>
										<div className="w-full flex flex-row bg-white text-xs h-6" >
											<select
												className="block text-xs  px-3 py-1 bg-[#F3F4F6FF]"
												{...register('isdCode', {
													required: true,
												})}
												onChange={(e) => handleSteps(e, 'isdCode')}
											>
												<option key={0} value="" >Select</option>
												<option key={1} value="+91" > +91 </option>
												<option key={2} value="+001" > +001 </option>

											</select>

											<input
												id="mobileNumber"
												aria-invalid={errors.mobileNumber ? "true" : "false"}
												type="text"
												placeholder="Mobile Number"
												name={"mobileNumber"}
												{...register('mobileNumber', {
													required: true,
													maxLength: 10,
													minLength: 10,
													pattern: /^[0-9+-]+$/
												})}
												className="w-full px-3 py-1 bg-[#F3F4F6FF] text-xs"
												onChange={(e) => handleSteps(e, 'mobileNumber')}
											/>

										</div>
										{errors.isdCode && errors.isdCode.type === "required" && (
											<span role="alert" className="w-full text-red-500 bg-white text-xs  px-3">This is required</span>
										)}
										{errors.mobileNumber && errors.mobileNumber.type === "required" && (
											<span role="alert" className="w-full text-red-500 bg-white text-xs px-3">This is required</span>
										)}
										{errors.mobileNumber && errors.mobileNumber.type === "maxLength" && (
											<span role="alert" className="w-full text-red-500 bg-white text-xs px-3">Please enter valid mobile number</span>
										)}
										{errors.mobileNumber && errors.mobileNumber.type === "minLength" && (
											<span role="alert" className="w-full text-red-500 bg-white text-xs px-3">Please enter valid mobile number</span>
										)}
										{errors.mobileNumber && errors.mobileNumber.type === "pattern" && (
											<span role="alert" className="w-full text-red-500 bg-white text-xs -bottom-0px-3">Please enter valid mobile number</span>
										)}
									</div>

								</div>
								<div className="w-full flex items-center justify-center p-3">
									<div className="w-1/2 mx-3 flex flex-col items-start justify-center " >
										<label
											className="block text-xs font-bold mb-1"
											htmlFor="userId">
											User Id<sup className="text-red-500">*</sup>
										</label>
										<div className="w-full bg-black bg-opacity-10  text-xs" >
											<input
												id="userId"
												aria-invalid={errors.userId ? "true" : "false"}
												{...register('userId', {
													required: true, maxLength: 20
												})}
												type="text"
												placeholder="userId"
												name={"userId"}
												className="w-full px-3 py-1 bg-[#F3F4F6FF] text-xs"
												onChange={(e) => handleSteps(e, 'userId')}
											/>
											{errors.userId && errors.userId.type === "required" && (
												<span role="alert" className="w-full text-red-500 bg-white px-3">This is required</span>
											)}
											{errors.userId && errors.userId.type === "maxLength" && (
												<span role="alert" className="w-full text-red-500 bg-white px-3">Max length exceeded</span>
											)}
										</div>
									</div>
									<div className="w-1/2 flex flex-col items-start justify-center my-2 mx-3" >
										<label
											className="block text-xs font-bold mb-1"
											htmlFor="roleId">
											Role
										</label>
										<div className="w-full px-3 bg-[#F3F4F6FF] text-xs" >
											<select
												className="w-full  py-1 bg-[#F3F4F6FF] text-xs"
												onChange={(e) => handleSteps(e, 'roleId')}
												value={isEditMember ? steps['roleId']?._id : steps['roleId']}
											>
												<option value={1} key={1}>--Select--</option>
												{items.map((item, index) => {
													return <option value={item._id} key={index}> {item.name}</option>
												})}
											</select>
										</div>
									</div>
								</div>
								<div className="w-full flex items-start justify-start px-3">
									{!url ? <div className="w-1/2 ml-3 flex flex-col items-start justify-center" >
										<label
											className="block text-xs font-bold"
											htmlFor="grid-password">
											Image
										</label>
										<div className="w-full bg-transparent  text-xs " >
											<div className="flex flex-row ">
												<input type="file" id="file-input" name="ImageStyle" onChange={(e) => handleChange(e)} />
											</div>
											{profile ? <img
												className="h-24 w-24 py-2"
												src={profile} /> : ""}
										</div>
									</div> :
										<div className="w-1/2 mx-3 flex flex-col justify-center items-start" >
											<div className="w-full flex flex-col item-center justify-between">
												<div className="w-full flex flex-row items-center justify-start my-2">
													<div className="text-grey-600 text-sm font-bold">
														Profile Image
													</div>
													<div>
														<svg onClick={() => handleDelete()} width="15" height="15" className="mx-2" viewBox="0 0 24 24" fill="currentColor"><path d="M17 6H22V8H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V8H2V6H7V3C7 2.44772 7.44772 2 8 2H16C16.5523 2 17 2.44772 17 3V6ZM18 8H6V20H18V8ZM13.4142 13.9997L15.182 15.7675L13.7678 17.1817L12 15.4139L10.2322 17.1817L8.81802 15.7675L10.5858 13.9997L8.81802 12.232L10.2322 10.8178L12 12.5855L13.7678 10.8178L15.182 12.232L13.4142 13.9997ZM9 4V6H15V4H9Z"></path></svg>
													</div>
												</div>
												<img
													className="h-24 w-24"
													src={url}
													alt="avatar"
												/>
											</div>
										</div>}
								</div>
								<div className="w-full flex items-center justify-center p-3">
									<div className="w-full mx-3 flex flex-col items-start justify-center" >
										<label
											className="block text-xs font-bold mb-2 "
											htmlFor="grid-password">
											Projects
										</label>
										<div className="w-full  border-0 bg-[#F3F4F6FF] text-xs" >
											<MultiSelect
												options={projectOptions}
												className="w-full border-0  bg-[#F3F4F6FF] text-xs "
												value={selectedProject}
												onChange={setSelectedProject}
												labelledBy="Select"
											/>
										</div>
									</div>
								</div>
								<div className="flex items-center justify-center space-x-3 m-3">
									<button
										className="text-blue-500 border-x border-y border-blue-500 rounded  px-2 text-xs  py-1"
										type="button"
										onClick={() => handleCancel()} >
										Cancel
									</button>
									<input type="submit"
										className="bg-blue-500 text-white rounded  px-2 text-xs py-1"
										value={isEditMember ? 'Update' : 'Create'}
									/>
								</div>
							</div>
						</form>
					</div>

				</div>
			</div>
			<div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
		</>
	);
}