import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { crm } from "../../redux/crm/actions";
import { useNavigate } from 'react-router-dom';
import _ from 'lodash'
import { Bars } from 'react-loader-spinner';
import AddTask from "../../modals/AddTask";
import ViewIssue from "../../modals/ViewIssue";
import { selectCurrentCRM } from "../../redux/crm/selectors"
import { selectItemsFilter as selectProjectItemsFilter, selectUpdatedItem } from '../../redux/projectchecklist/selectors.js';
import { selectItemsFilter as selectProjectAddon } from '../../redux/projectaddon/selectors.js';
import { selectItemsFilter, selectCreatedItem } from '../../redux/issuecards/selectors.js';
import { selectListItems as selectCategoryList } from '../../redux/categoryname/selectors.js';
import { } from '../../redux/issuecards/selectors.js';
import { inspectionstage } from "../../redux/inspectionstage/actions";
import { inspectionstatus } from "../../redux/inspectionstatus/actions";
import { issuecards } from "../../redux/issuecards/actions";
import { projectchecklist } from '../../redux/projectchecklist/actions';
import { categoryname } from '../../redux/categoryname/actions';
import { projectaddon } from '../../redux/projectaddon/actions';
import { issuestatus } from "../../redux/issuestatus/actions"
import Moment from 'moment';
import { useLiveQuery } from "dexie-react-hooks";
import { db } from '../../services/db.js'
const XLSX = require('xlsx');


const getOnLineStatus = () =>
	typeof navigator !== 'undefined' && typeof navigator.onLine === 'boolean'
		? navigator.onLine
		: true;


export default function ProjectChecklist() {
	const dispatch = useDispatch();
	const navigate = useNavigate()
	const [loader, setLoader] = useState('')
	const dbcache = useLiveQuery(() => db.projectchecklist_table.toArray(), []);
	const { activeBoardName, viewIssue, isAddonTask, activeCustomer, activeAddonId, activeAddonStatus, reloadProjectAddonTask, addProjectTask, reloadWebIssueCreated, activeChecklistId, reloadProjectTask, isSideBarOpen, activeProjectId, activeCustomerId } = useSelector(selectCurrentCRM)
	const { result, isLoading } = useSelector(selectProjectItemsFilter);
	const { result: addonResult } = useSelector(selectProjectAddon);
	const [status, setStatus] = useState(getOnLineStatus());
	const { items: addonItems } = addonResult
	const { items } = result ? result : []
	const { result: result1 } = useSelector(selectCategoryList);
	const { items: categoryitems } = result1 ? result1 : [];
	const { result: issueResult } = useSelector(selectItemsFilter);
	const { items: issueitems } = issueResult ? issueResult : [];
	const [sortedItems, setSortedItems] = useState([])
	const [activeCategory, setActiveCategory] = useState('All Tasks')
	const [activeStatus, setActiveStatus] = useState('select')
	const [activeIssue, setActiveIssue] = useState('select')
	const [search, setSearch] = useState('')
	const [filteredCategory, setFilterCategory] = useState([])
	const [filteredList, setFilteredList] = useState([])
	const [filterAddon, setFilterAddon] = useState([])
	const setOffline = () => setStatus(false);
	const setOnline = () => setStatus(true);

	useEffect(() => {
		console.log(filteredList.length)
		let uniqueObjects = {};
		filteredList.forEach(obj => {
			uniqueObjects[obj.Item?.CategoryName?.Name] = obj;
		});
		let output = Object.values(uniqueObjects);
		console.log(output)
		let category = []
		output.map((obj) => {
			category.push(obj.Item?.CategoryName?.Name)
		})
		setFilterCategory(category)
	}, [filteredList])
	useEffect(() => {
		dispatch(issuestatus.list({ entity: 'PDI_Issue_Status', options: {} }))
		dispatch(issuecards.filter({ entity: 'PDI_Issue_Cards', jsonData: { params: { Customer: activeCustomerId }, page: 1, limit: 200 } }))
		dispatch(inspectionstage.list({ entity: 'PDI_Inspection_Stage', options: {} }))
		dispatch(inspectionstatus.list({ entity: 'PDI_Inspection_Status', options: {} }))
		dispatch(categoryname.list({ entity: 'PDI_Category_Name', options: {} }))
		dispatch(projectchecklist.filter({ entity: 'PDI_Project_Checklist', jsonData: { params: { UnitDetails: activeCustomerId }, page: 1, limit: 200 } }))
		dispatch(projectaddon.filter({
			entity: "PDI_Project_Addon_Checklist",
			jsonData: {
				params: {
					ProjectName: activeProjectId,
					UnitDetails: activeCustomerId
				}, page: 1, limit: 200
			}
		}))
	}, [])

	useEffect(() => {
		window.scrollTo(0, 0);
		window.addEventListener('online', setOnline);
		window.addEventListener('offline', setOffline);
		return () => {
			window.removeEventListener('online', setOnline);
			window.removeEventListener('offline', setOffline);
		};
	}, [])

	useEffect(() => {
		if (status) {
			db.projectchecklist_table.count().then((count) => {
				dbcache?.map((obj) => {
					db.projectchecklist_table.delete(obj.id)
					dispatch(projectchecklist.update({
						entity: 'PDI_Project_Checklist', id: obj.activeid, jsonData: obj?.data
					}))
				})
			})
		}
	}, [status])

	useEffect(() => {
		if (reloadProjectTask) {
			dispatch(projectchecklist.filter({ entity: 'PDI_Project_Checklist', jsonData: { params: { UnitDetails: activeCustomerId } }, page: 1, limit: 200 }))
			dispatch(projectaddon.filter({
				entity: "PDI_Project_Addon_Checklist",
				jsonData: {
					params: {
						ProjectName: activeProjectId,
						UnitDetails: activeCustomerId
					}, page: 1, limit: 200
				}
			}))
			dispatch(crm.trigger({ reloadProjectTask: false }))
		}
	}, [reloadProjectTask])

	useEffect(() => {
		setFilteredList(items[0]?.Checklist)
		setSortedItems(items[0]?.Checklist)
	}, [items])

	useEffect(() => {
		setFilterAddon(addonItems)
	}, [addonItems])

	const handleStatus = async (status, id) => {
		setActiveCategory('All Tasks')
		setActiveStatus('select')
		setActiveIssue('select')
		let list = []
		setLoader(true)
		await sortedItems.map((_item) => {
			if (_item?.Item?._id == id) {
				list.push({ Item: id, Status: status, IssueDetails: _item?.IssueDetails?._id })
			}
			else {
				list.push({ Item: _item?.Item?._id, Status: _item?.Status, IssueDetails: _item?.IssueDetails?._id })
			}
		})
		dispatch(projectchecklist.update({ entity: 'PDI_Project_Checklist', id: items[0]?._id, jsonData: { entity: { Checklist: list } } })).then(() => {
			dispatch(crm.trigger({ reloadProjectTask: true }))
			setLoader(false)
		})
	}

	useEffect(() => {
		setFilterAddon(filterAddon)
	}, [filterAddon])

	const handleAddonStatus = async (status, id) => {
		await addonItems.map((_item) => {
			if (_item?._id == id) {
				dispatch(projectaddon.update({ entity: 'PDI_Project_Addon_Checklist', id: id, jsonData: { entity: { Status: status, IssueDetails: null } } })).then(() => {
					dispatch(crm.trigger({ reloadProjectTask: true }))
				})
			}
		})
	}

	const handleSearch = (e) => {
		const searchTerm = e.target.value
		setSearch(searchTerm)
		if (searchTerm == "") {
			setFilteredList(sortedItems)
		}
		setFilteredList(sortedItems.filter((data) => data?.Item?.CategoryName?.Name.toLowerCase().includes(searchTerm.toLowerCase()) ||
			data?.Item?.Description.toLowerCase().includes(searchTerm.toLowerCase()) ||
			data?.Item?.Status?.toLowerCase().includes(searchTerm.toLowerCase())));
	}

	const handleViewIssue = (id) => {
		const data = issueitems.filter((item) => item._id == id)
		console.log('data[0]', data[0])
		dispatch(crm.trigger({ viewIssue: 'open', selectedCheckListItem: data[0] }))
	}

	const handleProject = () => {
		navigate('/inspection-crm/project-boards')
	}

	const handleCustomer = () => {
		navigate('/inspection-crm/inspection-boards')
	}

	const handleAddIssue = (obj) => {
		dispatch(crm.trigger({
			activeChecklistId: obj?.Item?._id,
			activeInspectionDescription: obj?.Item?.Description,
			activeCategoryNameId: obj?.Item?.CategoryName?._id,
			activeCategoryName: obj?.Item?.CategoryName?.Name,
			isEditIssue: false,
			isAddonTask: false
		}))
		navigate('/inspection-crm/add-issue')
	}

	const handleAddAddonIssue = (obj) => {
		dispatch(crm.trigger({
			activeChecklistId: obj?._id,
			activeInspectionDescription: obj?.ChecklistItem,
			activeCategoryNameId: obj?.CategoryName?._id,
			activeCategoryName: obj?.CategoryName?.Name,
			isEditIssue: false,
			isAddonTask: true,
			activeAddonStatus: obj?.Status,
			activeAddonId: obj?._id
		}))
		navigate('/inspection-crm/add-issue')
	}

	useEffect(() => {
		if (reloadProjectTask) {
			dispatch(projectaddon.filter({
				entity: "PDI_Project_Addon_Checklist",
				jsonData: {
					params: {
						ProjectName: activeProjectId,
						UnitDetails: activeCustomerId
					}, page: 1, limit: 200
				}
			}))
		}
		dispatch(crm.trigger({ reloadProjectTask: false }))
	}, [reloadProjectTask])

	useEffect(() => {
		if (reloadWebIssueCreated) {
			if (isAddonTask) {
				dispatch(crm.trigger({ reloadWebIssueCreated: false, reloadProjectTask: true, isAddonTask: false }))
			}
			else {
				dispatch(crm.trigger({ reloadWebIssueCreated: false, reloadProjectTask: true }))
			}
		}
	}, [reloadWebIssueCreated])

	const handleAddTask = () => {
		dispatch(crm.trigger({ addProjectTask: 'open', isAddTaskByWeb: true }))
	}

	// const handleDownload = () => {
	// 	const jsonData = [];
	// 	filteredList.map((RowItem, index) => {
	// 		jsonData.push(
	// 			{
	// 				"Category Name": RowItem.Item?.CategoryName?.Name,
	// 				"Task Name": RowItem.Item?.Description.replace(/,/g, ''),
	// 				"Status": RowItem.Status == "Passed" ? "Passed" : RowItem.Status == "Failed" ? "Failed" : "Not Done",
	// 				"Action": RowItem.IssueDetails ? RowItem.IssueDetails._id : ""
	// 			})
	// 	})
	// 	const ws = XLSX.utils.json_to_sheet(jsonData);
	// 	ws['!cols'] = [
	// 		{ wch: 15 },
	// 		{ wch: 80 },
	// 		{ wch: 10 },
	// 		{ wch: 25 }
	// 	];
	// 	const wb = XLSX.utils.book_new();
	// 	XLSX.utils.book_append_sheet(wb, ws, activeCustomer);
	// 	XLSX.writeFile(wb, "Project_Checklist_SunTower.xlsx");
	// }

	const getCount = (type) => {
		let list = []
		list = filteredList?.filter((item) => item?.Status == type)
		let list1 = []
		list1 = addonItems?.filter((item) => item?.Status == type)
		return list?.length + list1.length
	}

	const handlefilter = (e, type) => {
		if (type == 'task') {
			setActiveCategory(e.target.value)
		}
		else if (type == 'status') {
			setActiveStatus(e.target.value)
		}
		else if (type == 'issue') {
			setActiveIssue(e.target.value)
		}
	}

	useEffect(() => {
		if (activeCategory == 'All Tasks' && activeIssue == 'View Issue' && activeStatus == 'Failed') {
			const sortedList = sortedItems.filter(data => data?.Status == 'Failed' && data?.IssueDetails);
			setFilteredList(sortedList)
			const filterAddon = addonItems.filter(data => data?.Status == 'Failed' && data?.IssueDetails);
			setFilterAddon(filterAddon)
		}
		else if (activeCategory == 'All Tasks' && activeIssue == 'View Issue' && activeStatus == 'select') {
			const sortedList = sortedItems.filter(data => data?.Status == 'Failed' && data?.IssueDetails);
			setFilteredList(sortedList)
			const filterAddon = addonItems.filter(data => data?.Status == 'Failed' && data?.IssueDetails);
			setFilterAddon(filterAddon)
		}
		else if (activeCategory == 'All Tasks' && activeIssue == 'View History' && activeStatus == 'Passed') {
			const sortedList = sortedItems.filter(data => data?.Status == 'Passed' && data?.IssueDetails);
			setFilteredList(sortedList)
			const filterAddon = addonItems.filter(data => data?.Status == 'Passed' && data?.IssueDetails);
			setFilterAddon(filterAddon)
		}
		else if (activeCategory == 'All Tasks' && activeIssue == 'View History' && activeStatus == 'select') {
			const sortedList = sortedItems.filter(data => data?.Status == 'Passed' && data?.IssueDetails);
			setFilteredList(sortedList)
			const filterAddon = addonItems.filter(data => data?.Status == 'Passed' && data?.IssueDetails);
			setFilterAddon(filterAddon)
		}
		else if (activeCategory == 'All Tasks' && activeIssue == 'select' && activeStatus == 'select') {
			const sortedList = sortedItems;
			setFilteredList(sortedList)
			const filterAddon = addonItems;
			setFilterAddon(filterAddon)
		}
		else if (activeCategory == 'All Tasks' && activeIssue == 'select' && activeStatus == 'Passed') {
			const sortedList = sortedItems;
			setFilteredList(sortedList.filter(data => data?.Status == 'Passed'))
			const filterAddon = addonItems;
			setFilterAddon(filterAddon.filter(data => data?.Status == 'Passed'))
		}
		else if (activeCategory == 'All Tasks' && activeIssue == 'select' && activeStatus == 'Failed') {
			const sortedList = sortedItems;
			setFilteredList(sortedList.filter(data => data?.Status == 'Failed'))
			const filterAddon = addonItems;
			setFilterAddon(filterAddon.filter(data => data?.Status == 'Failed'))
		}
		else if (activeCategory == 'All Tasks' && activeIssue == 'select' && activeStatus == 'Not Done') {
			const sortedList = sortedItems;
			setFilteredList(sortedList.filter(data => data?.Status != 'Passed' && data?.Status != 'Failed'))
			const filterAddon = addonItems;
			setFilterAddon(filterAddon.filter(data => data?.Status == 'Passed' && data?.Status != 'Failed'))
		}
		else if (activeCategory == 'All Tasks' && activeIssue == 'Add Issue' && activeStatus == 'Failed') {
			const sortedList = sortedItems.filter(data => data?.Status == 'Failed' && !data?.IssueDetails);
			setFilteredList(sortedList)
			const filterAddon = addonItems.filter(data => data?.Status == 'Failed' && !data?.IssueDetails);
			setFilterAddon(filterAddon)
		}
		else if (activeCategory == 'All Tasks' && activeIssue == 'Add Issue' && activeStatus == 'select') {
			const sortedList = sortedItems.filter(data => data?.Status == 'Failed' && !data?.IssueDetails);
			setFilteredList(sortedList)
			const filterAddon = addonItems.filter(data => data?.Status == 'Failed' && !data?.IssueDetails);
			setFilterAddon(filterAddon)
		}

		else if (activeCategory != 'All Tasks' && activeIssue == 'View Issue' && activeStatus == 'Failed') {
			const sortedList = sortedItems.filter(data => data?.Item?.CategoryName?.Name == activeCategory && data?.Status == 'Failed' && data?.IssueDetails);
			setFilteredList(sortedList)
			const filterAddon = addonItems.filter(data => data?.CategoryName?.Name == activeCategory && data?.Status == 'Failed' && data?.IssueDetails);
			setFilterAddon(filterAddon)
		}
		else if (activeCategory != 'All Tasks' && activeIssue == 'View Issue' && activeStatus == 'select') {
			const sortedList = sortedItems.filter(data => data?.Item?.CategoryName?.Name == activeCategory && data?.Status == 'Failed' && data?.IssueDetails);
			setFilteredList(sortedList)
			const filterAddon = addonItems.filter(data => data?.CategoryName?.Name == activeCategory && data?.Status == 'Failed' && data?.IssueDetails);
			setFilterAddon(filterAddon)
		}
		else if (activeCategory != 'All Tasks' && activeIssue == 'View History' && activeStatus == 'Passed') {
			const sortedList = sortedItems.filter(data => data?.Item?.CategoryName?.Name == activeCategory && data?.Status == 'Passed' && data?.IssueDetails);
			setFilteredList(sortedList)
			const filterAddon = addonItems.filter(data => data?.CategoryName?.Name == activeCategory && data?.Status == 'Passed' && data?.IssueDetails);
			setFilterAddon(filterAddon)
		}
		else if (activeCategory != 'All Tasks' && activeIssue == 'View History' && activeStatus == 'select') {
			const sortedList = sortedItems.filter(data => data?.Item?.CategoryName?.Name == activeCategory && data?.Status == 'Passed' && data?.IssueDetails);
			setFilteredList(sortedList)
			const filterAddon = addonItems.filter(data => data?.CategoryName?.Name == activeCategory && data?.Status == 'Passed' && data?.IssueDetails);
			setFilterAddon(filterAddon)
		}
		else if (activeCategory != 'All Tasks' && activeIssue == 'Add Issue' && activeStatus == 'Failed') {
			const sortedList = sortedItems.filter(data => data?.Item?.CategoryName?.Name == activeCategory && data?.Status == 'Failed' && !data?.IssueDetails);
			setFilteredList(sortedList)
			const filterAddon = addonItems.filter(data => data?.CategoryName?.Name == activeCategory && data?.Status == 'Failed' && !data?.IssueDetails);
			setFilterAddon(filterAddon)
		}
		else if (activeCategory != 'All Tasks' && activeIssue == 'Add Issue' && activeStatus == 'select') {
			const sortedList = sortedItems.filter(data => data?.Item?.CategoryName?.Name && data?.Status == 'Failed' && !data?.IssueDetails);
			setFilteredList(sortedList)
			const filterAddon = addonItems.filter(data => data?.CategoryName?.Name && data?.Status == 'Failed' && !data?.IssueDetails);
			setFilterAddon(filterAddon)
		}
		else if (activeCategory != 'All Tasks' && activeIssue == 'select' && activeStatus == 'select') {
			const sortedList = sortedItems.filter(data => data?.Item?.CategoryName?.Name == activeCategory);
			setFilteredList(sortedList)
			const filterAddon = addonItems.filter(data => data?.CategoryName?.Name == activeCategory);
			setFilterAddon(filterAddon)
		}
		else if (activeCategory != 'All Tasks' && activeIssue == 'select' && activeStatus == 'Passed') {
			const sortedList = sortedItems;
			setFilteredList(sortedList.filter(data => data?.Item?.CategoryName?.Name == activeCategory && data?.Status == 'Passed'))
			const filterAddon = addonItems;
			setFilterAddon(filterAddon.filter(data => data?.CategoryName?.Name == activeCategory && data?.Status == 'Passed'))
		}
		else if (activeCategory != 'All Tasks' && activeIssue == 'select' && activeStatus == 'Failed') {
			const sortedList = sortedItems;
			setFilteredList(sortedList.filter(data => data?.Item?.CategoryName?.Name == activeCategory && data?.Status == 'Failed'))
			const filterAddon = addonItems;
			setFilterAddon(filterAddon.filter(data => data?.CategoryName?.Name == activeCategory && data?.Status == 'Failed'))
		}
		else if (activeCategory != 'All Tasks' && activeIssue == 'select' && activeStatus == 'Not Done') {
			const sortedList = sortedItems;
			setFilteredList(sortedList.filter(data => data?.Item?.CategoryName?.Name == activeCategory && data?.Status != 'Passed' && data?.Status != 'Failed'))
			const filterAddon = addonItems;
			setFilterAddon(filterAddon.filter(data => data?.CategoryName?.Name == activeCategory && data?.Status == 'Passed' && data?.Status != 'Failed'))
		}
	}, [activeCategory, activeIssue, activeStatus])

	return (
		<>
			{isLoading || loader ? <div className="opacity-25 fixed inset-0 z-40 bg-black">
				<div className="flex justify-center  items-center h-full w-full"><Bars
					height="80"
					width="80"
					color="#4fa94d"
					ariaLabel="bars-loading"
					wrapperStyle={{}}
					wrapperClass=""
					visible={true}
				/></div>
			</div> : ""}
			{addProjectTask == 'open' ? <AddTask filteredCategory={filteredCategory} /> : ''}
			{viewIssue == 'open' ? <ViewIssue /> : ""}
			<div className="flex flex-col item-center bg-[#F9F9FAFF]">
				<header className="flex justify-between h-20 w-full mx-auto px-5 items-center">
					<div className="flex justify-start w-full" >
						<nav aria-label="breadcrumb" className="w-max" >
							<ol className="flex flex-wrap items-center w-full px-4 py-2 rounded-md">
								<li
									className="flex items-center ">
									<h3 className="text-xl font-bold text-[#787C83FF] " onClick={() => handleProject()}>Projects</h3>
									<span
										className="mx-2 ">
										<svg fill="#787C83FF" height="12px" width="12px" version="1.1" id="Layer_1"
											viewBox="0 0 330 330">
											<path id="XMLID_222_" d="M250.606,154.389l-150-149.996c-5.857-5.858-15.355-5.858-21.213,0.001
	c-5.857,5.858-5.857,15.355,0.001,21.213l139.393,139.39L79.393,304.394c-5.857,5.858-5.857,15.355,0.001,21.213
	C82.322,328.536,86.161,330,90,330s7.678-1.464,10.607-4.394l149.999-150.004c2.814-2.813,4.394-6.628,4.394-10.606
	C255,161.018,253.42,157.202,250.606,154.389z"/>
										</svg>
									</span>
								</li>
								<li
									className="flex items-center ">
									<div
										onClick={() => navigate('/inspection-crm/inspection-items')}
										className="text-[#787C83FF]  text-md font-bold cursor-pointer">
										<span>
											{activeBoardName}
										</span>
									</div>
									<span
										className="mx-2 ">
										<svg fill="#787C83FF" height="12px" width="12px" version="1.1" id="Layer_1"
											viewBox="0 0 330 330">
											<path id="XMLID_222_" d="M250.606,154.389l-150-149.996c-5.857-5.858-15.355-5.858-21.213,0.001
	c-5.857,5.858-5.857,15.355,0.001,21.213l139.393,139.39L79.393,304.394c-5.857,5.858-5.857,15.355,0.001,21.213
	C82.322,328.536,86.161,330,90,330s7.678-1.464,10.607-4.394l149.999-150.004c2.814-2.813,4.394-6.628,4.394-10.606
	C255,161.018,253.42,157.202,250.606,154.389z"/>
										</svg>
									</span>
								</li>
								<li
									className="flex items-center cursor-pointer">
									<span onClick={() => handleCustomer()} className="text-[#787C83FF] text-md font-bold">
										{activeCustomer}
									</span>
									<span
										className="mx-2 ">
										<svg fill="#787C83FF" height="12px" width="12px" version="1.1" id="Layer_1"
											viewBox="0 0 330 330">
											<path id="XMLID_222_" d="M250.606,154.389l-150-149.996c-5.857-5.858-15.355-5.858-21.213,0.001
	c-5.857,5.858-5.857,15.355,0.001,21.213l139.393,139.39L79.393,304.394c-5.857,5.858-5.857,15.355,0.001,21.213
	C82.322,328.536,86.161,330,90,330s7.678-1.464,10.607-4.394l149.999-150.004c2.814-2.813,4.394-6.628,4.394-10.606
	C255,161.018,253.42,157.202,250.606,154.389z"/>
										</svg>
									</span>
								</li>
								<li
									className="flex items-center ">
									<a href="#" className="text-[#787C83FF] text-md font-bold">
										Checklist
									</a>
								</li>
							</ol>
						</nav>
					</div>
					<div className="flex justify-end w-full mx-5" >
						{/* <button className="w-auto mx-1 px-3 rounded p-2 text-[11px] font-semibold bg-[#3C50E0FF] text-white"
							onClick={() => {
								handleDownload()
							}}
						>
							Download
						</button> */}
						<button className="w-auto mx-1 px-3 rounded p-2 text-[11px] font-semibold bg-[#3C50E0FF] text-white"
							onClick={() => {
								handleAddTask()
							}}
						>
							+ Add Task
						</button>
					</div>
				</header >
				<div className="flex flex-col  px-3 mx-5 h-max py-2 ">
					<div className="flex flex-row items-end justify-start w-full h-14 my-2   ">
						<form className="mx-3">
							<div className="relative flex w-full flex-wrap items-stretch">
								<span className="z-10 h-full leading-snug font-normal absolute text-center text-black absolute rounded text-base items-center justify-center w-8 pl-3 py-2">
									<svg className="h-4 w-5 text-black border-opacity-40 " viewBox="0 0 24 24" fill="#A9A9A9"><path d="M11 2C15.968 2 20 6.032 20 11C20 15.968 15.968 20 11 20C6.032 20 2 15.968 2 11C2 6.032 6.032 2 11 2ZM11 18C14.8675 18 18 14.8675 18 11C18 7.1325 14.8675 4 11 4C7.1325 4 4 7.1325 4 11C4 14.8675 7.1325 18 11 18ZM19.4853 18.0711L22.3137 20.8995L20.8995 22.3137L18.0711 19.4853L19.4853 18.0711Z"></path></svg>
								</span>
								<input
									type="text"
									placeholder="Search here..."
									value={search}
									onChange={(e) => handleSearch(e)}
									className="border-x border-y px-3 py-1 border-black border-opacity-40 text-grey-600 relative rounded text-sm shadow outline-none focus:outline-none focus:ring w-[300px] pl-10 bg-[#F9F9FAFF]"
								/>
							</div>
						</form>
						<div className="flex flex-col justify-start mx-3" >
							<div className='text-xs my-1 italic'>Filter By Category</div>
							<div className="border-x border-y border-black border-opacity-40 pr-5 pl-2  py-1  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 bg-[#F9F9FAFF]" >
								<select
									className="w-full bg-[#F9F9FAFF] text-xs"
									onChange={(e) => handlefilter(e, 'task')}
									value={activeCategory}
								>
									<option value={'All Tasks'} key={1}>All Tasks</option>
									{filteredCategory.map((item, index) => {
										return <option value={item} key={index}> {item}</option>
									})}
								</select>
							</div>
						</div>
						<div className="flex flex-col justify-start mx-3 " >
							<div className='text-xs my-1 italic'>Filter By Status</div>
							<div className="border-x border-y border-black border-opacity-40 pr-5 pl-2  py-1  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" >
								<select
									className="w-full bg-[#F9F9FAFF] text-xs"
									onChange={(e) => handlefilter(e, 'status')}
									value={activeStatus}
								>
									<option value={'select'} key={1}>--Select--</option>
									<option value='Passed' key={2}>Passed</option>
									<option value='Failed' key={3}>Failed</option>
									<option value='Not Done' key={4}>Not Done</option>
								</select>
							</div>
						</div>

						<div className="flex  flex-col justify-start mx-3" >
							<div className='text-xs my-1 italic'>Filter By Issue Status</div>
							<div className="border-x border-y border-black border-opacity-40 pr-5 pl-2  py-1  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" >
								<select
									className="w-full bg-[#F9F9FAFF] text-xs"
									onChange={(e) => handlefilter(e, 'issue')}
									value={activeIssue}
								>
									<option value={'select'} key={1}>--Select--</option>
									<option value='Add Issue' key={2}>Add Issue</option>
									<option value='View Issue' key={3}>View Issue</option>
									<option value='View History' key={4}>View History</option>

								</select>
							</div>
						</div>
					</div>
					<div className={`bg-white rounded mt-2 pb-10 h-[500px] mx-2 px-4 py-2 ${!isSideBarOpen ? "w-[1100px] overflow-x-auto" : "w-[1360px] overflow-x-auto"} `}>
						<table className="text-xs justify-center items-center">
							<thead className="my-2 py-2">
								<tr key={'table'} className="flex flex-row item-center justify-around border-b w-auto py-2  border-black border-opacity-30">
									<th key={1}
										className="flex item-center justify-start text-xs font-bold text-black w-[150px] py-2 ">
										Category Name
									</th>
									<th key={2}
										className="flex item-center justify-start text-xs font-bold text-black w-[650px] py-2">
										Task Details
										<button
											className="w-max flex justify-center items-center px-3 rounded text-xs ">
											<svg fill="#000000" height="12" width="12" className='mr-2'
												version="1.1" id="Layer_1"
												viewBox="0 0 455 455">
												<path d="M332.229,90.04l14.238-27.159l-26.57-13.93L305.67,76.087c-19.618-8.465-40.875-13.849-63.17-15.523V30h48.269V0H164.231v30
	H212.5v30.563c-22.295,1.674-43.553,7.059-63.171,15.523L135.103,48.95l-26.57,13.93l14.239,27.16
	C67.055,124.958,30,186.897,30,257.5C30,366.576,118.424,455,227.5,455S425,366.576,425,257.5
	C425,186.896,387.944,124.958,332.229,90.04z M355,272.5H212.5V130h30v112.5H355V272.5z"/>
											</svg>
											<div className="text-xs font-semibold">{getCount('')} Not Done</div>
										</button>
										<button
											className="w-max flex justify-center items-center px-3 rounded text-xs ">
											<svg xmlns="http://www.w3.org/2000/svg" className="text-[#17A948] rounded-xl mr-2 w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24"><path d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8z" /><path d="M9.999 13.587 7.7 11.292l-1.412 1.416 3.713 3.705 6.706-6.706-1.414-1.414z" /></svg>
											<div className="text-xs font-semibold">{getCount('Passed')} Passed</div>
										</button>
										<button
											className="w-max flex justify-center items-center px-3 rounded text-xs">
											<svg xmlns="http://www.w3.org/2000/svg" className="text-[#E05858] rounded-xl mr-2 w-4 h-4" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" ><circle cx="12" cy="12" r="10" /><path d="m15 9-6 6" /><path d="m9 9 6 6" /></svg>
											<div className="text-xs font-semibold">{getCount('Failed')} Failed</div>
										</button>
									</th>
									<th key={3}
										className="flex item-center justify-start text-xs font-bold text-black  w-[350px] py-2 ">
										Status
									</th>
									<th key={4}
										className="flex item-center justify-start text-xs font-bold text-black  w-[130px] py-2 ">
										Action
									</th>
									<th key={5}
										className="flex item-center justify-start text-xs font-bold text-black  w-[100px] py-2 ">
										Created At
									</th>
									<th key={6}
										className="flex item-center justify-start text-xs font-bold text-black  w-[100px] py-2 ">
										Start Date
									</th>
									<th key={7}
										className="flex item-center justify-start text-xs font-bold text-black  w-[150px] py-2">
										Completion Date
									</th>
								</tr>
							</thead>
							{categoryitems?.map((category, _idx) => {
								return <tbody className="w-max" key={_idx}>
									{filteredList?.filter((list) => list?.Item?.CategoryName?.Name == category?.Name)?.map((obj, idx) => {
										return <tr key={idx} className="flex flex-row item-center justify-around border-b w-auto h-max  border-black border-opacity-30 items-baseline  text-slate-200 text-sm  ">
											<td className="flex item-center justify-start mb-2 text-xs font-normal text-black font-bold text-opacity-80 w-[150px]">
												{obj?.Item?.CategoryName?.Name}
											</td>
											<td className="flex item-center justify-start mb-2 text-xs font-normal text-black font-bold text-opacity-80 w-[650px]">
												{obj?.Item?.Description}
											</td>
											<td className="space-x-2 flex flex-row item-center justify-start m-1 w-[350px]">
												<button
													className={`w-max flex justify-center items-center px-5 py-1  rounded-xl text-xs ${obj?.Status ? "bg-[#6B9BD2]" : "bg-[#4069E5] text-white"}`}>
													<svg fill="#ffffff" height="12" width="12" className='mr-2'
														version="1.1" id="Layer_1"
														viewBox="0 0 455 455">
														<path d="M332.229,90.04l14.238-27.159l-26.57-13.93L305.67,76.087c-19.618-8.465-40.875-13.849-63.17-15.523V30h48.269V0H164.231v30
	H212.5v30.563c-22.295,1.674-43.553,7.059-63.171,15.523L135.103,48.95l-26.57,13.93l14.239,27.16
	C67.055,124.958,30,186.897,30,257.5C30,366.576,118.424,455,227.5,455S425,366.576,425,257.5
	C425,186.896,387.944,124.958,332.229,90.04z M355,272.5H212.5V130h30v112.5H355V272.5z"/>
													</svg>
													Not Done
												</button>
												<button
													onClick={() => handleStatus('Passed', obj?.Item?._id)}
													className={`w-max flex justify-center items-center px-5  py-1 rounded-xl text-xs  ${obj?.Status == 'Passed' ? "bg-[#17A948] text-white" : "bg-[#EEFDF3] text-[#17A948]"}`}>
													<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" className="mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24"><path d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8z" /><path d="M9.999 13.587 7.7 11.292l-1.412 1.416 3.713 3.705 6.706-6.706-1.414-1.414z" /></svg>
													Passed
												</button>
												<button
													onClick={() => handleStatus('Failed', obj?.Item?._id)}
													className={`w-max flex justify-center items-center px-5  py-1 rounded-xl text-xs  ${obj?.Status == 'Failed' ? "bg-[#E05858] text-white" : "bg-[#FDF2F2] text-[#E05858]"}`}>
													<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" className="pr-1" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" ><circle cx="12" cy="12" r="10" /><path d="m15 9-6 6" /><path d="m9 9 6 6" /></svg>
													Failed
												</button>
											</td>
											{obj.Status ? <td className="space-x-2 flex flex-row item-center justify-center m-1 w-[130px] px-4">
												{obj.Status != 'Passed' ?
													<>
														{obj.IssueDetails?._id ? <button
															onClick={() => handleViewIssue(obj?.IssueDetails?._id)}
															className={obj?.IssueDetails?._id && obj?.Status == 'Passed' ? "border-green-800 w-full px-2 py-1 rounded text-xs  border-x border-y  text-green-800" : "border-red-500 w-full px-2 py-1 rounded text-xs  border-x border-y  text-red-500"}>
															View {obj?.IssueDetails?._id && obj?.Status == 'Passed' ? 'History' : "Issue"}
														</button> :
															<button
																onClick={() => handleAddIssue(obj)}
																className="w-full px-2 py-1 rounded text-xs border-x border-y border-[#3C50E0FF] text-[#3C50E0FF]">
																Add Issue
															</button>}
													</> : obj?.Status == 'Passed' && obj?.IssueDetails?._id ? <button
														onClick={() => handleViewIssue(obj?.IssueDetails?._id)}
														className={obj?.IssueDetails?._id && obj?.Status == 'Passed' ? "border-green-800 w-full px-2 py-1 rounded text-xs  border-x border-y  text-green-800" : "border-red-500 w-full px-2 py-1 rounded text-xs  border-x border-y  text-red-500"}>
														View {obj?.IssueDetails?._id && obj?.Status == 'Passed' ? 'History' : "Issue"}
													</button> : ''}
											</td> :
												<td className="space-x-2 flex flex-row item-center justify-center m-1 w-[130px] px-4"></td>}
											<td className="flex item-center justify-start mb-2 line-clamp-1 mx-2 text-xs font-normal text-black font-bold text-opacity-80 w-[100px]">
												{obj.IssueDetails ? Moment(obj.IssueDetails?.createdAt).format("DD-MMM-YY") : ""}
											</td>
											<td className="flex item-center justify-start mb-2 text-xs font-normal text-black font-bold text-opacity-80 w-[100px]">
												{obj.IssueDetails ? Moment(obj.IssueDetails?.StartDate).format("DD-MMM-YY") : ""}
											</td>
											<td className="flex item-center justify-start mb-2 text-xs font-normal text-black font-bold text-opacity-80 w-[150px]">
												{obj.IssueDetails && obj.IssueDetails?.ExpectedCompletionDate ? Moment(obj.IssueDetails?.ExpectedCompletionDate).format("DD-MMM-YY") : "-"}
											</td>
										</tr>
									})}
									{filterAddon?.filter((list) => list?.CategoryName?.Name == category.Name)?.map((obj, idx1) => {
										return <tr key={idx1} className="flex flex-row item-center justify-around border-b w-auto  h-max border-black border-opacity-30 items-baseline  text-slate-200 text-sm  ">
											<td className="flex item-center justify-start mb-2 text-xs font-normal text-black font-bold text-opacity-80 w-[150px]">
												{obj?.CategoryName?.Name}
											</td>
											<td className="flex item-center justify-start mb-2 text-xs font-normal text-black font-bold text-opacity-80 w-[650px]">
												{obj?.ChecklistItem}
											</td>
											<td className="space-x-2 flex flex-row item-center justify-start m-1 w-[350px]">
												<button
													className={`w-max flex justify-center items-center px-5 py-1  rounded-xl text-xs ${obj?.Status ? "bg-[#6B9BD2]" : "bg-[#4069E5] text-white"}`}>
													<svg fill="#ffffff" height="12" width="12" className='mr-2'
														version="1.1" id="Layer_1"
														viewBox="0 0 455 455">
														<path d="M332.229,90.04l14.238-27.159l-26.57-13.93L305.67,76.087c-19.618-8.465-40.875-13.849-63.17-15.523V30h48.269V0H164.231v30
	H212.5v30.563c-22.295,1.674-43.553,7.059-63.171,15.523L135.103,48.95l-26.57,13.93l14.239,27.16
	C67.055,124.958,30,186.897,30,257.5C30,366.576,118.424,455,227.5,455S425,366.576,425,257.5
	C425,186.896,387.944,124.958,332.229,90.04z M355,272.5H212.5V130h30v112.5H355V272.5z"/>
													</svg>
													Not Done
												</button>
												<button
													onClick={() => handleAddonStatus('Passed', obj?._id)}
													className={`w-max flex justify-center items-center px-5  py-1 rounded-xl text-xs  ${obj?.Status == 'Passed' ? "bg-[#17A948] text-white" : "bg-[#EEFDF3] text-[#17A948]"}`}>
													<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" className="mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24"><path d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8z" /><path d="M9.999 13.587 7.7 11.292l-1.412 1.416 3.713 3.705 6.706-6.706-1.414-1.414z" /></svg>
													Passed
												</button>
												<button
													onClick={() => handleAddonStatus('Failed', obj?._id)}
													className={`w-max flex justify-center items-center px-5  py-1 rounded-xl text-xs  ${obj?.Status == 'Failed' ? "bg-[#E05858] text-white" : "bg-[#FDF2F2] text-[#E05858]"}`}>
													<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" className="pr-1" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" ><circle cx="12" cy="12" r="10" /><path d="m15 9-6 6" /><path d="m9 9 6 6" /></svg>
													Failed
												</button>
											</td>
											{obj.Status && obj.Status != 'Not Done' ? <td className="space-x-2 flex flex-row item-center justify-center m-1 w-[130px] px-4">
												{obj.Status != 'Passed' ?
													<>
														{obj.IssueDetails?._id ? <button
															onClick={() => handleViewIssue(obj?.IssueDetails?._id)}
															className={"w-full px-2 py-1 rounded text-xs  border-x border-y border-[#3C50E0FF] text-[#3C50E0FF] "}>
															View {obj?.IssueDetails?._id && obj?.Status == 'Passed' ? 'History' : "Issue"}
														</button> :
															<button
																onClick={() => handleAddAddonIssue(obj)}
																className="w-full px-2 py-1 rounded text-xs border-x border-y border-[#3C50E0FF] text-[#3C50E0FF]">
																Add Issue
															</button>}
													</> : obj?.Status == 'Passed' && obj?.IssueDetails?._id ? <button
														onClick={() => handleViewIssue(obj?.IssueDetails?._id)}
														className={"w-full px-2 py-1 rounded text-xs  border-x border-y border-[#3C50E0FF] text-[#3C50E0FF] "}>
														View {obj?.IssueDetails?._id && obj?.Status == 'Passed' ? 'History' : "Issue"}
													</button> : ''}
											</td> :
												<td className="space-x-2 flex flex-row item-center justify-center m-1 w-[130px] px-4"></td>}
											<td className="flex item-center justify-start mb-2 line-clamp-1 mx-2 text-xs font-normal text-black font-bold text-opacity-80 w-[100px]">
												{obj.IssueDetails ? Moment(obj.IssueDetails?.createdAt).format("DD-MMM-YY") : ""}
											</td>
											<td className="flex item-center justify-start mb-2 text-xs font-normal text-black font-bold text-opacity-80 w-[100px]">
												{obj.IssueDetails ? Moment(obj.IssueDetails?.StartDate).format("DD-MMM-YY") : ""}
											</td>
											<td className="flex item-center justify-start mb-2 text-xs font-normal text-black font-bold text-opacity-80 w-[150px]">
												{obj.IssueDetails && obj.IssueDetails?.ExpectedCompletionDate ? Moment(obj.IssueDetails?.ExpectedCompletionDate).format("DD-MMM-YY") : "-"}
											</td>
										</tr>
									})}
								</tbody>
							})}
						</table>
					</div>
				</div>
			</div>
		</>
	);
}
