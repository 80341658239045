export const PROJECT_CHECKLIST_RESET_STATE = 'PROJECT_CHECKLIST_RESET_STATE';
export const PROJECT_CHECKLIST_CURRENT_ITEM = 'PROJECT_CHECKLIST_CURRENT_ITEM';

export const PROJECT_CHECKLIST_REQUEST_LOADING = 'PROJECT_CHECKLIST_REQUEST_LOADING';
export const PROJECT_CHECKLIST_REQUEST_SUCCESS = 'PROJECT_CHECKLIST_REQUEST_SUCCESS';
export const PROJECT_CHECKLIST_REQUEST_FAILED = 'PROJECT_CHECKLIST_REQUEST_FAILED';

export const PROJECT_CHECKLIST_CURRENT_ACTION = 'PROJECT_CHECKLIST_CURRENT_ACTION';
export const PROJECT_CHECKLIST_RESET_ACTION = 'PROJECT_CHECKLIST_RESET_ACTION';
export const PROJECT_CHECKLIST_REQUEST_OFFLINE = 'PROJECT_CHECKLIST_REQUEST_OFFLINE';
