import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash'
import Confirmation from "../../modals/Confirmation";
import { projecttype } from '../../redux/projecttype/actions';
import { typeofhouse } from '../../redux/typeofhouse/actions';
import { masterchecklist } from '../../redux/masterchecklist/actions.js';
import { masterchecklistitems } from '../../redux/masterchecklistitems/actions.js';
import { numberofbedrooms } from '../../redux/numberofbedrooms/actions';
import { crm } from "../../redux/crm/actions";
import { request } from '../../request/index';
import { selectListItems } from '../../redux/projecttype/selectors';
import { selectCurrentCRM } from '../../redux/crm/selectors';
import { selectListItems as selectTypeOfHouseList } from '../../redux/typeofhouse/selectors';
import { selectListItems as selectNumberOfBedroomsList } from '../../redux/numberofbedrooms/selectors';
import { selectListItems as selectMasterCheckList } from '../../redux/masterchecklist/selectors';
import axios from 'axios';
import storePersist from './../../redux/storePersist';

export default function GenerateChecklist() {
	const dispatch = useDispatch();
	const navigate = useNavigate()
	const [file, setFile] = useState(null);
	const [name, setName] = useState('')
	const [projectType, setProjectType] = useState('')
	const [typeOfHouse, setTypeOfHouse] = useState('')
	const [numberOfBedrooms, setNumberOfBedrooms] = useState('')
	const [projectTypePrefix, setProjectTypePrefix] = useState('')
	const [typeOfHousePrefix, setTypeOfHousePrefix] = useState('')
	const [numberOfBedroomsPrefix, setNumberOfBedroomsPrefix] = useState('')
	const [masterId, setMasterId] = useState('')
	const { activeMasterChecklistId, masterChecklistItem, isEditMasterChecklist, activeProjectId } = useSelector(selectCurrentCRM);
	const { result } = useSelector(selectListItems);
	const { result: resultHouse } = useSelector(selectTypeOfHouseList);
	const { result: resultBedroom } = useSelector(selectNumberOfBedroomsList);
	const { result: resultMaster } = useSelector(selectMasterCheckList)
	const { items } = result;
	const { items: houseitems } = resultHouse;
	const { items: bedroomitems } = resultBedroom;
	const { items: masteritems } = resultMaster;


	useEffect(() => {
		dispatch(projecttype.list({ entity: 'PDI_Project_Configuration' }))
		dispatch(typeofhouse.list({ entity: 'PDI_Type_Of_House' }))
		dispatch(numberofbedrooms.list({ entity: 'PDI_Number_Of_Bedrooms' }))
		setName('')
		setNumberOfBedrooms('')
		setProjectType('')
		setTypeOfHouse('')
	}, [])

	const handleChecklist = (e, type) => {
		if (type == 'ProjectType') {
			setProjectType(e.target.value)
			setProjectTypePrefix(items?.find((pt) => pt?._id == e.target.value)?.Name?.slice(0, 3).toUpperCase())
		}
		else if (type == 'TypeOfHouse') {
			setTypeOfHouse(e.target.value)
			setTypeOfHousePrefix(houseitems?.find((th) => th?._id == e.target.value)?.Name?.slice(0, 3).toUpperCase())
		}
		else if (type == 'NumberOfBedrooms') {
			setNumberOfBedrooms(e.target.value)
			setNumberOfBedroomsPrefix(bedroomitems?.find((nb) => nb?._id == e.target.value)?.Name?.slice(0, 5).toUpperCase())
		}
	}

	useEffect(() => {
		setName(projectTypePrefix + '-' + typeOfHousePrefix + '-' + numberOfBedroomsPrefix)
	}, [numberOfBedrooms, projectType, typeOfHouse])

	useEffect(() => {
		if (isEditMasterChecklist) {
			setName(masterChecklistItem?.Name)
			setNumberOfBedrooms(masterChecklistItem?.NumberOfBedrooms._id)
			setProjectType(masterChecklistItem?.ProjectType?._id)
			setTypeOfHouse(masterChecklistItem?.TypeOfHouse?._id)
		}
	}, [isEditMasterChecklist])


	const handleImportMasterChecklist = () => {
		document.getElementById('excelfile').click()
	}

	const handleDownload = () => {
		const token = storePersist.get('auth')?.current

		axios({
			url: `https://api.greypath.co/api/v1/document/templateMasterchecklist`,
			method: "GET",
			headers: {
				'content-type': 'application/json',
				'Authorization': 'Bearer ' + token
			},
			responseType: "blob"
		}).then(response => {
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement("a");
			link.href = url;
			link.setAttribute(
				"download",
				`master-checklist-template.xlsx`
			);
			document.body.appendChild(link);
			link.click();

			// Clean up and remove the link
			link.parentNode.removeChild(link);
		});


	}

	const handleFileChange = async (e) => {
		if (e.target.files) {
			//const _file = e.target.files[0]
			setFile(e.target.files[0]);
			dispatch(crm.trigger({
				activeDocumentId: '',
				activeDocumentFile: '',
				activeUploadLink: '',
				activeDocumentFilename: '',
				activeDownloadedUrl: ''
			}))
		}
	}

	const upload = async () => {
		if (file) {
			const exte = file.name.substring(file.name.indexOf(".") + 1);
			const docDetails = {
				extension: exte,
				file_name: file.name,
				mime_type: file.type,
				entity_type: "excel",
				size: file.size,
			};
			let data = await request.uploadurl({ entity: 'document', jsonData: docDetails, options: {} })
			dispatch(crm.trigger({
				activeDocumentId: data.document_id,
				activeDocumentFileKey: data.filename,
				activeUploadLink: data.uploadLink,
				activeDocumentFilename: file.name
			}))
			const type = file.type
			await request.uploadfileAWSS3({ fileuploadurl: data.uploadLink, file, type }).then(async () => {
				await request.importmasterchecklist({
					jsonData: {
						"checklistName": name,
						"projectType": projectType,
						"typeOfHouse": typeOfHouse,
						"numberOfBedrooms": numberOfBedrooms,
						"key": data.filename
					}
				})
				const fileInput = document.getElementById('excelfile')
				fileInput.value = null
			})
			navigate('/inspection-crm/settings')
		}
	}

	return (
		<>
			<div className="flex flex-col item-center">
				<header className="flex justify-between h-20 w-full mx-auto px-5 dark:text-white items-center">
					<div className="flex justify-start w-full" >
						<nav aria-label="breadcrumb" className="w-max" >
							<ol className="flex flex-wrap items-center w-full px-4 py-2 rounded-md bg-blue-gray-50 bg-opacity-60">
								<li
									className="flex items-center font-sans text-sm antialiased font-normal leading-normal transition-colors duration-300 cursor-pointer text-blue-gray-900 hover:text-light-blue-500">
									<h3 className="text-xl font-bold" onClick={() => navigate('/inspection-crm/settings')}>Snag List</h3>
									<span
										className="mx-2 font-bold text-xl  antialiased font-normal leading-normal pointer-events-none select-none text-blue-gray-500 text-xl">
										<svg fill="#000000" height="12px" width="12px" version="1.1" id="Layer_1"
											viewBox="0 0 330 330">
											<path id="XMLID_222_" d="M250.606,154.389l-150-149.996c-5.857-5.858-15.355-5.858-21.213,0.001
	c-5.857,5.858-5.857,15.355,0.001,21.213l139.393,139.39L79.393,304.394c-5.857,5.858-5.857,15.355,0.001,21.213
	C82.322,328.536,86.161,330,90,330s7.678-1.464,10.607-4.394l149.999-150.004c2.814-2.813,4.394-6.628,4.394-10.606
	C255,161.018,253.42,157.202,250.606,154.389z"/>
										</svg>
									</span>
								</li>
								<li
									className="flex items-center font-sans text-sm antialiased font-normal leading-normal transition-colors duration-300 cursor-pointer text-blue-gray-900 hover:text-light-blue-500">
									<a href="#" className="opacity-60 text-md font-bold">
										Generate Snag List
									</a>
								</li>
							</ol>
						</nav>
					</div>
				</header >
				<div className="bg-blue-grey-100 h-screen">
					<div className="bg-white h-72 w-[1000px] mx-10 rounded p-5 shadow-lg ">
						<div className="flex flex-col ">
							<div className="flex flex-col justify-center items-center">
								<div className="text-sm font-bold flex justify-start">Generate Snag List</div>
								<div className="flex flex-row justify-center item-center w-[900px]">
									<div className="flex flex-col justify-start item-start py-2 w-[1200px]">
										<label className="text-xs font-bold py-1">
											Snag List Name
										</label>
										<div className="px-3 border-0  bg-black bg-opacity-10 text-xs " >
											<input
												type="text"
												placeholder="Generated automatically"
												value={!isEditMasterChecklist ? name : masterChecklistItem.Name}
												onChange={(e) => setName(e.target.value)}
												className="w-full border-0 py-1 bg-black bg-opacity-0 text-xs"
											/>
										</div>
									</div>
								</div>
								<div className="flex flex-row justify-center item-center space-x-3 w-[900px]">
									<div className="flex flex-col justify-start item-start py-2 w-[400px]">
										<label className="text-xs font-bold">
											Project Type
										</label>
										<div className="px-3 border-0  bg-black bg-opacity-10 text-xs " >
											<select
												className="w-full border-0 py-1 bg-black bg-opacity-0 text-xs"
												onChange={(e) => handleChecklist(e, 'ProjectType')}
												value={isEditMasterChecklist ? masterChecklistItem?.ProjectType?._id : projectType}
											>
												<option value={1} key={1}>--Select--</option>
												{items.map((item, index) => {
													return <option value={item._id} key={index}> {item.Name}</option>
												})}
											</select >
										</div >
									</div >
									<div className="flex flex-col justify-start item-start py-2  w-[400px]">
										<label className="text-xs font-bold">
											Type of Houses
										</label>
										<div className="px-3 border-0  bg-black bg-opacity-10 text-xs " >
											<select
												className="w-full border-0 py-1 bg-black bg-opacity-0 text-xs"
												onChange={(e) => handleChecklist(e, 'TypeOfHouse')}
												value={isEditMasterChecklist ? masterChecklistItem?.TypeOfHouse?._id : typeOfHouse}
											>
												<option value={1} key={1}>--Select--</option>

												{houseitems.map((item, index) => {
													return <option value={item._id} key={index}> {item.Name}</option>
												})}
											</select >
										</div >
									</div >
									<div className="flex flex-col justify-start item-start py-2  w-[400px]">
										<label className="text-xs font-bold">
											Number of Bedroom
										</label>
										<div className="px-3 border-0  bg-black bg-opacity-10 text-xs " >
											<select
												className="w-full border-0 py-1 bg-black bg-opacity-0 text-xs"
												onChange={(e) => handleChecklist(e, 'NumberOfBedrooms')}
												value={isEditMasterChecklist ? masterChecklistItem?.NumberOfBedrooms?._id : numberOfBedrooms}
											>
												<option value={1} key={1}>--Select--</option>

												{bedroomitems.map((item, index) => {
													return <option value={item._id} key={index}> {item.Name}</option>
												})}
											</select >
										</div >
									</div >
								</div >
								<div className="flex justify-end ml-auto w-[900px] mr-5 my-3" >
									<button
										onClick={() => handleImportMasterChecklist()}
										className="
						w-auto px-3 m-3 button bg-blue-600 text-white rounded ring-1 ring-inset ring-blue-600
						text-blue-600 p-2 text-xs font-bold">
										Browse
									</button>
									<button
										disabled={file ? false : true}
										onClick={() => upload()}
										className="
						w-auto px-3 m-3 button bg-blue-600 text-white rounded ring-1 ring-inset ring-blue-600
						text-blue-600 p-2 text-xs font-bold">
										+ Import Checklist
									</button>
									<input type="file" id='excelfile' className="hidden" name="file_upload" onChange={(e) => handleFileChange(e)} />
									<button
										onClick={() => handleDownload()}
										className="
						w-auto px-3 m-3 button bg-blue-600 text-white rounded ring-1 ring-inset ring-blue-600
						text-blue-600 p-2 text-xs font-bold" >
										+ Download Format
									</button>
								</div>
							</div >

						</div >
					</div >
					<Confirmation />
				</div >
			</div >
		</>

	);
}
