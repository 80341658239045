import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import App from './app';
import { BrowserRouter } from "react-router-dom";
import { persistor, store } from './redux/store'
import "./index.css"

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	// <React.StrictMode>
	<BrowserRouter>
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				{/* <AppContextProvider> */}
				<App />
				{/* </AppContextProvider> */}
			</PersistGate>
		</Provider>
	</BrowserRouter>
	// </React.StrictMode>
);

if ('serviceWorker' in navigator) {
	navigator.serviceWorker.register('/service-worker.js', {
		scope: '/firebase-push-notification-scope', scope: '',
	}).then(function (registration) {
		console.log('Service Worker registered with scope:', registration.scope);
	}).catch(function (error) {
		console.log('Service Worker registration failed:', error);
	});
}