import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash'
import { toast } from 'tailwind-toast'
import { Bars } from 'react-loader-spinner';
import storePersist from "../../redux/storePersist";
import { useNavigate } from 'react-router-dom';
import { customerdetails } from "../../redux/customerdetails/actions";
import { crm } from "../../redux/crm/actions";
import { inspectionstage } from "../../redux/inspectionstage/actions";
import { inspectionstatus } from "../../redux/inspectionstatus/actions";
import { projectcards } from "../../redux/projectcards/actions";
import { selectCurrentCRM } from "./../../redux/crm/selectors"
import { selectCreatedItem, selectItemsFilter } from '../../redux/customerdetails/selectors';
import { selectItemsFilter as selectMasterChecklistItems } from '../../redux/masterchecklistitems/selectors';
import { selectListItems as selectStageListItems } from '../../redux/inspectionstage/selectors';
import { selectListItems as selectStatusListItems } from '../../redux/inspectionstatus/selectors';
import { selectItemsFilter as selectProjectListItems } from '../../redux/projectcards/selectors';
import AssignUser from '../../modals/AssignUser';
import AddCustomer from "../../modals/AddCustomer";
import Confirmation from "../../modals/Confirmation";
import { selectCreatedItem as projectCheckList } from '../../redux/projectchecklist/selectors';
import { masterchecklistitems } from '../../redux/masterchecklistitems/actions';

export default function InspectionItems() {
	const dispatch = useDispatch();
	const navigate = useNavigate()
	const [search, setSearch] = useState('')
	const [block, setBlock] = useState([])
	const [isCheckAll, setIsCheckAll] = useState(false);
	const [isSelectedArr, setIsSelectedArr] = useState([]);
	const [filteredList, setFilteredList] = useState([])
	const { activeBoardName, reloadCustomerDetails, activeProjectId,
		reloadCustomerCreated, addCustomer, masterChecklistId } = useSelector(selectCurrentCRM)
	const { result } = useSelector(selectItemsFilter);
	const { result: projectResult } = useSelector(selectProjectListItems)
	const { items: projectItem } = projectResult
	const _user = storePersist.get('user')
	const { isLoading } = useSelector(projectCheckList)
	const { result: masterResult } = useSelector(selectMasterChecklistItems)
	const { result: stageResult } = useSelector(selectStageListItems)
	const { result: statusResult } = useSelector(selectStatusListItems)
	const { items: masterItems } = masterResult
	const { items } = result
	const { items: stageitems } = stageResult;
	const { items: statusitems } = statusResult;

	const handleSearch = (e) => {
		const searchTerm = e.target.value
		setSearch(searchTerm)
		setFilteredList(items?.filter((item) => item?.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
			item?.lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
			item?.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
			item?.Block.toLowerCase().includes(searchTerm.toLowerCase()) ||
			item?.NumberOfBedrooms?.Name.toLowerCase().includes(searchTerm.toLowerCase()) ||
			item?.UnitDetails.toLowerCase().includes(searchTerm.toLowerCase())));
	}

	const handleSyncCRM = () => {
		toast().warning("Hold : ", "Analysis is going on").with({
			shape: "pill",
			duration: 2000,
			speed: 1000,
			positionX: 'end',
			icon: 'fa fa-check-circle',
			positionY: 'top',
			color: 'bg-red-50',
			fontColor: 'red',
			fontTone: 'bg-blue-100'
		}).show()
	}

	useEffect(() => {
		setFilteredList(items)
		const _isSelectedArr = []
		items?.map((item) => {
			_isSelectedArr.push({ [item?._id]: false })
		})
		setIsSelectedArr(_isSelectedArr)
	}, [items]);

	useEffect(() => {
		let blockArr = []
		blockArr = projectItem[0]?.BlockTower?.split(",")
		setBlock(blockArr)
	}, [])

	const handleImportCustomer = () => {
		navigate('/inspection-crm/import-customer')
	}

	const handleKandbanBoard = () => {
		navigate('/inspection-crm/inspection-boards')
	}

	const handleProjectChecklist = (obj) => {
		navigate("/inspection-crm/project-checklist")
		dispatch(crm.trigger({ activeCustomerId: obj._id, activeCustomer: obj?.UnitDetails + '_' + obj?.Block + '_' + obj?.firstName + obj?.lastName, assignCRM: obj?.AssignCRM._id, assignSiteEngineer: obj.AssignSiteEngineer._id }))
	}

	const handleProject = () => {
		navigate('/inspection-crm/project-boards')
	}

	const handleAssign = () => {
		dispatch(crm.trigger({ assignUser: 'open', isEditCustomerDetails: true }))
	}

	useEffect(() => {
		if (reloadCustomerCreated) {
			dispatch(customerdetails.filter({ entity: 'PDI_Customer_Details', jsonData: { params: { project: activeProjectId }, page: 1, limit: 200 } })).then(() => {
				dispatch(crm.trigger({ reloadCustomerDetails: true, reloadCustomerCreated: false, assignUser: 'close' }))
			})
		}
	}, [masterItems])

	useEffect(() => {
		if (reloadCustomerCreated) {
			dispatch(masterchecklistitems.filter({ entity: 'PDI_Master_Checklist_Items', jsonData: { params: { MasterChecklist: masterChecklistId }, page: 1, limit: 200 } }))
		}
	}, [reloadCustomerCreated])

	useEffect(() => {
		if (reloadCustomerDetails) {
			dispatch(customerdetails.filter({ entity: 'PDI_Customer_Details', jsonData: { params: { project: activeProjectId }, page: 1, limit: 200 } })).then(() => {
				dispatch(crm.trigger({ reloadCustomerDetails: false, assignUser: 'close', isEditCustomerDetails: false }))
			})
		}
	}, [reloadCustomerDetails])

	useEffect(() => {
		dispatch(customerdetails.filter({ entity: 'PDI_Customer_Details', jsonData: { params: { project: activeProjectId }, page: 1, limit: 200 } }))
		dispatch(inspectionstage.list({ entity: 'PDI_Inspection_Stage', options: {} }))
		dispatch(inspectionstatus.list({ entity: 'PDI_Inspection_Status', options: {} }))
		dispatch(projectcards.filter({ entity: 'PDI_Project_Cards', jsonData: { params: { _id: activeProjectId }, page: 1, limit: 200 } }))
	}, [])

	const handleAddCustomer = () => {
		dispatch(crm.trigger({ addCustomer: 'open', isEditCustomerDetails: false }))
	}

	const handlDownload = () => {

	}
	const handleEdit = (item) => {
		dispatch(crm.trigger({ addCustomer: 'open', activeCustomerItem: item, activeCustomerId: item._id, isEditCustomerDetails: true }))
	}

	const handleDelete = (item) => {
		dispatch(crm.trigger({ deleteConfirmation: 'open', deleteMessage: 'Are you sure you want to delete ' + item?.firstName + ' ' + item?.lastName + '?', deleteItem: { id: item._id, entity: 'PDI_Customer_Details' } }))
	}

	const handleSelectAll = (e) => {
		const { checked } = e.target
		let checkList = []
		items?.map((item) => {
			if (checked) {
				checkList.push({ [item._id]: true })
			}
			else {
				checkList.push({ [item._id]: false })
			}
		})
		setIsSelectedArr(checkList)
		setIsCheckAll(!isCheckAll);
		dispatch(crm.trigger({ selectedCustomer: checkList }))
	}

	const handleCheckbox = (id, _item) => {
		dispatch(crm.trigger({
			activeAssignCRM: _item?.AssignCRM?._id,
			activeSiteEngineer: _item?.AssignSiteEngineer?._id,
			activeStage: _item?.Stage?._id,
			activeStatus: _item?.Status?._id
		}))
		let _isSelectedArr = []
		isSelectedArr.map((item, index) => {
			const objKey = Object.keys(item)[0]
			const ObjValue = Object.values(item)[0]
			if (objKey == id) {
				_isSelectedArr.push({ [objKey]: !ObjValue })
			}
			else {
				_isSelectedArr.push({ [objKey]: ObjValue })
			}
		})
		setIsSelectedArr(_isSelectedArr)
		dispatch(crm.trigger({ selectedCustomer: _isSelectedArr }))
	}

	const handleStageFilter = (e) => {
		if (e.target.value == 1) {
			setFilteredList(items)
		}
		else {
			const id = e.target.value
			const filterItems = items?.filter((item) => (item?.project?._id == activeProjectId && item?.Stage?._id == id))
			setFilteredList(filterItems);
		}
	}

	const handleBlockFilter = (e) => {
		if (e.target.value == 1) {
			setFilteredList(items)
		}
		else {
			const id = e.target.value
			const filterItems = items?.filter((item) => (item?.project?._id == activeProjectId && item?.Block == id))
			setFilteredList(filterItems);
		}
	}

	const handleStatusFilter = (e) => {
		if (e.target.value == 1) {
			setFilteredList(items)
		}
		else {
			const id = e.target.value
			const filterItems = items?.filter((item) => (item?.project?._id == activeProjectId && item?.Status?._id == id))
			setFilteredList(filterItems);
		}
	}

	return (
		<>
			{isLoading ? <div className="opacity-25 fixed inset-0 z-40 bg-black">
				<div className="flex justify-center  items-center h-full w-full"><Bars
					height="80"
					width="80"
					color="#4fa94d"
					ariaLabel="bars-loading"
					wrapperStyle={{}}
					wrapperClass=""
					visible={true}
				/></div>
			</div> : ""}
			{addCustomer == 'open' ? <AddCustomer /> : ""}
			<Confirmation />
			<div className="flex flex-col item-center bg-[#F9F9FAFF]">
				<header className="flex justify-between h-20 w-full mx-auto px-5 dark:text-white items-center">
					<div className="flex justify-start w-full" >
						<nav aria-label="breadcrumb" className="w-max" >
							<ol className="flex flex-wrap items-center w-full px-4 py-2 rounded-md">
								<li
									className="flex items-center ">
									<h3 className="text-xl font-bold text-[#787C83FF] " onClick={() => handleProject()}>Projects</h3>
									<span
										className="mx-2 ">
										<svg fill="#787C83FF" height="12px" width="12px" version="1.1" id="Layer_1"
											viewBox="0 0 330 330">
											<path id="XMLID_222_" d="M250.606,154.389l-150-149.996c-5.857-5.858-15.355-5.858-21.213,0.001
	c-5.857,5.858-5.857,15.355,0.001,21.213l139.393,139.39L79.393,304.394c-5.857,5.858-5.857,15.355,0.001,21.213
	C82.322,328.536,86.161,330,90,330s7.678-1.464,10.607-4.394l149.999-150.004c2.814-2.813,4.394-6.628,4.394-10.606
	C255,161.018,253.42,157.202,250.606,154.389z"/>
										</svg>
									</span>
								</li>
								<li
									className="flex items-center ">
									<a href="#" className=" text-[#787C83FF]  text-md font-bold">
										<span>
											{activeBoardName}
										</span>
									</a>
									<span
										className="mx-2 ">
										<svg fill="#787C83FF" height="12px" width="12px" version="1.1" id="Layer_1"
											viewBox="0 0 330 330">
											<path id="XMLID_222_" d="M250.606,154.389l-150-149.996c-5.857-5.858-15.355-5.858-21.213,0.001
	c-5.857,5.858-5.857,15.355,0.001,21.213l139.393,139.39L79.393,304.394c-5.857,5.858-5.857,15.355,0.001,21.213
	C82.322,328.536,86.161,330,90,330s7.678-1.464,10.607-4.394l149.999-150.004c2.814-2.813,4.394-6.628,4.394-10.606
	C255,161.018,253.42,157.202,250.606,154.389z"/>
										</svg>
									</span>
								</li>
								<li
									className="flex items-center ">
									<a href="#" className="text-[#787C83FF] text-md font-bold">
										Customer Details
									</a>
									<span
										className="mx-2 font-bold text-xl">
									</span>
								</li>
							</ol>
						</nav>
					</div>
					<div className="flex justify-end w-full mx-8" >
						<button className="w-auto mx-1 px-3 rounded p-2 text-[11px] font-semibold border-x border-y border-[#3C50E0FF] text-[#3C50E0FF]"
							onClick={() => {
								handleAddCustomer()
							}} > + Add Customer
						</button>
						{_user?.roleId?._id == "5f616169b7b6a1367e1bfdb9" ? <><button className="w-auto mx-1 px-3 rounded p-2 text-[11px] font-semibold border-x border-y border-[#3C50E0FF] text-[#3C50E0FF]"
							onClick={() => {
								handleSyncCRM()
							}} > + Sync CRM
						</button>
							<button className="w-auto mx-1 px-3 rounded bg-[#3C50E0FF] text-white py-2 text-[11px] font-semibold"
								onClick={() => {
									handleImportCustomer()
								}} > + Import Customer
							</button></> : <></>}
					</div>
				</header >
				<div>
					<div className="flex flex-col  px-3 mx-5 h-[600px]  ">
						<div className="flex flex-row  w-full ">
							<div className='flex flex-row items-end justify-start w-3/5 h-14 my-2 '>
								<form className="mx-3  ">
									<div className="relative flex w-full flex-wrap items-stretch">
										<span className="z-10 h-full leading-snug font-normal absolute text-center absolute rounded text-base items-center justify-center w-8 pl-3 py-2">
											<svg className="h-4 w-5 text-black border-opacity-40 " viewBox="0 0 24 24" fill="#A9A9A9"><path d="M11 2C15.968 2 20 6.032 20 11C20 15.968 15.968 20 11 20C6.032 20 2 15.968 2 11C2 6.032 6.032 2 11 2ZM11 18C14.8675 18 18 14.8675 18 11C18 7.1325 14.8675 4 11 4C7.1325 4 4 7.1325 4 11C4 14.8675 7.1325 18 11 18ZM19.4853 18.0711L22.3137 20.8995L20.8995 22.3137L18.0711 19.4853L19.4853 18.0711Z"></path></svg>
										</span>
										<input
											type="text"
											placeholder="Search here..."
											value={search}
											onChange={(e) => handleSearch(e)}
											className="border-x border-y px-3 py-1 border-black border-opacity-40 text-black text-opacity-60 relative rounded text-sm shadow outline-none focus:outline-none focus:ring w-[300px] pl-10 bg-[#F9F9FAFF]"
										/>
									</div>
								</form>
								<div className="flex justify-end w-30 mx-3 space-x-1 bg-[#F9F9FAFF]" >
									<div className=''>
										<div className='text-xs my-1 italic'>Filter By Stages</div>
										<div className="border-x border-y border-black border-opacity-40 pr-1 pl-2  py-1  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 bg-[#F9F9FAFF]" >
											<select
												className="w-full bg-[#F9F9FAFF] text-xs"
												onChange={(e) => handleStageFilter(e)} >
												<option value={1} key={10}>--Select--</option>
												{stageitems.map((item, index) => {
													return <option value={item._id} key={index}> {item.Name}</option>
												})}
											</select>
										</div>
									</div>
								</div>
								<div className="flex justify-end w-30 mx-3  space-x-1" >
									<div>
										<div className='text-xs my-1 italic'>Filter By Status</div>
										<div className="border-x border-y border-black border-opacity-40 pr-5 pl-2  py-1  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" >
											<select
												className="w-full bg-[#F9F9FAFF] text-xs"
												onChange={(e) => handleStatusFilter(e)} >
												<option value={1} key={10}>--Select--</option>
												{statusitems.map((item, index) => {
													return <option value={item._id} key={index}> {item.Name}</option>
												})}
											</select>
										</div>
									</div>
								</div>

							</div>
							<div className='flex flex-row items-end justify-start w-2/5 h-14 my-2 '>
								<div className="flex justify-end w-36 mx-auto mb-0.5 space-x-3 mr-0"
									onClick={() => handleAssign()}>
									<button className="w-auto px-3 button bg-transparent rounded ring-1 ring-inset ring-blue-600 text-blue-600 p-2 text-xs font-bold"
									> Assign
									</button>
								</div>

								<div className="w-48 h-10 mb-1">
									<label htmlFor="Toggle3" className="inline-flex items-center p-2 rounded-md cursor-pointer dark:text-gray-800">
										<input id="Toggle3" type="checkbox" className="hidden peer" />
										<span className="px-4 py-2 w-20 text-center text-sm font-semibold rounded-l-md bg-blue-600 peer-checked:bg-gray-300 text-white peer-checked:text-black">List</span>
										<span
											onClick={() => {
												handleKandbanBoard()
											}}
											className="px-4 py-2 w-20 text-center  text-sm  font-semibold rounded-r-md bg-gray-300 peer-checked:bg-blue-600 text-black peer-checked:text-white">Kanban</span>
									</label>
								</div>
							</div>
						</div>
						<AssignUser />
						<div className="min-h-[450px] overflow-y-auto  bg-white shadow-xl p-4 rounded  ">
							<table className=" w-[1550px] overflow-auto  text-xs">
								<thead className="w-full border-y border-black border-opacity-20 ">
									<tr key={'table'} className="w-full text-left h-12">
										<th key={0}
											className="px-5">
											<input
												checked={isCheckAll}
												type="checkbox"
												name="selectAll"
												id="selectAll"
												onChange={(e) => handleSelectAll(e)} />
										</th>
										<th key={1}
											className="">
											Customer Name
										</th>
										<th key={2}
											className="">
											Email
										</th>
										<th key={3}
											className="">
											Mobile
										</th>
										<th key={4}
											className="">
											Unit
										</th>
										<th key={5}
											className="">
											Block
										</th>
										<th key={6}
											className="">
											Bedrooms
										</th>
										<th key={7}
											className="">
											Project Type
										</th>
										<th key={8}
											className="">
											Type of House
										</th>
										<th key={9}
											className="">
											CRM Executive
										</th>
										<th key={10}
											className="">
											QC/Engineer
										</th>
										<th key={11}
											className="">
											Stage
										</th>
										<th key={12}
											className="">
											Status
										</th>
										<th key={13}
											className="">
											Action
										</th>
									</tr>
								</thead>
								<tbody className="w-full ">
									{filteredList?.map((item, idx) => {
										return <tr key={idx}
											className="w-full cursor-pointer h-16 even:bg-[#F8F9FAFF] odd:bg-white" >
											<td key={0} >
												<span className="px-5" >
													<input
														type="checkbox"
														key={item._id}
														id={item._id}
														onChange={(e) => handleCheckbox(item?._id, item)}
														checked={isSelectedArr?.length > 0 && isSelectedArr[idx] && isSelectedArr[idx][item?._id] ? true : false} />
												</span>
											</td>
											<td key={1} onClick={() => handleProjectChecklist(item)}>
												<span className="" >
													<div>{item?.firstName} {item?.lastName}  </div>
												</span>
											</td>
											<td key={2} onClick={() => handleProjectChecklist(item)}>
												<div>{item?.email}</div>
											</td>
											<td key={3} onClick={() => handleProjectChecklist(item)}>
												<div>{item?.mobile}</div>
											</td>
											<td key={4} onClick={() => handleProjectChecklist(item)}>
												<div>{item?.UnitDetails}</div>
											</td>
											<td key={5} onClick={() => handleProjectChecklist(item)}>
												<div>{item?.Block}</div>
											</td>
											<td key={6} onClick={() => handleProjectChecklist(item)}>
												<div>{item?.NumberOfBedrooms?.Name}</div>
											</td>
											<td key={7} onClick={() => handleProjectChecklist(item)}>
												<div>{item?.ProjectType?.Name}</div>
											</td>
											<td key={8} onClick={() => handleProjectChecklist(item)}>
												<div>{item?.TypeOfHouse?.Name}</div>
											</td>
											<td key={9} onClick={() => handleProjectChecklist(item)}>
												<div>{item?.AssignCRM?.firstName} {item?.AssignCRM?.lastName}</div>
											</td>
											<td key={10} onClick={() => handleProjectChecklist(item)}>
												<div>{item?.AssignSiteEngineer?.firstName} {item?.AssignSiteEngineer?.lastName}</div>
											</td>
											<td key={11} onClick={() => handleProjectChecklist(item)}>
												<div className={item?.Stage?.Name == 'Customer Review' ? 'bg-green-400 text-xs w-max px-2 rounded-md' : 'bg-yellow-200 text-xs w-max px-2 rounded-md'}>{item?.Stage?.Name} </div>
											</td>
											<td key={12} onClick={() => handleProjectChecklist(item)}>
												{item?.Status?.Name == 'Not Started' ? <button className="outline outline-double outline-[#E16B16FF] text-[#E16B16FF] px-2 py-1 text-xs">{item?.Status?.Name}</button>
													: item?.Status?.Name == 'In Progress' ? <button className="outline outline-double outline-[#3C50E0FF] text-[#3C50E0FF] px-2 py-1 text-xs">{item?.Status?.Name}</button>
														: <button className="outline outline-double outline-[#008843FF] text-[#008843FF] px-2 py-1 text-xs">{item?.Status?.Name}</button>}
											</td>
											<td key={13} className="flex flex-row space-x-2 justify-start items-center h-16">
												<svg xmlns="http://www.w3.org/2000/svg" onClick={() => handleEdit(item)} width="12" height="12" viewBox="0 0 24 24"><path d="M19.045 7.401c.378-.378.586-.88.586-1.414s-.208-1.036-.586-1.414l-1.586-1.586c-.378-.378-.88-.586-1.414-.586s-1.036.208-1.413.585L4 13.585V18h4.413L19.045 7.401zm-3-3 1.587 1.585-1.59 1.584-1.586-1.585 1.589-1.584zM6 16v-1.585l7.04-7.018 1.586 1.586L7.587 16H6zm-2 4h16v2H4z" /></svg>
												<svg xmlns="http://www.w3.org/2000/svg" onClick={() => handleDelete(item)} width="12" height="12" viewBox="0 0 24 24" fill="currentColor"><path d="M17 6H22V8H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V8H2V6H7V3C7 2.44772 7.44772 2 8 2H16C16.5523 2 17 2.44772 17 3V6ZM18 8H6V20H18V8ZM13.4142 13.9997L15.182 15.7675L13.7678 17.1817L12 15.4139L10.2322 17.1817L8.81802 15.7675L10.5858 13.9997L8.81802 12.232L10.2322 10.8178L12 12.5855L13.7678 10.8178L15.182 12.232L13.4142 13.9997ZM9 4V6H15V4H9Z"></path></svg>
											</td>
										</tr>
									})}
								</tbody>
							</table>
						</div >
					</div>
				</div>
			</div>

		</>
	);
}
