import { createSelector } from 'reselect';

const selectPermissions = (state) => state.permissions;

export const selectCurrentItem = createSelector([selectPermissions], (permissions) => permissions.current);

export const selectListItems = createSelector([selectPermissions], (permissions) => permissions.list);

export const selectItemById = (itemId) =>
	createSelector(selectListItems, (list) => list.result.items.find((item) => item._id === itemId));

export const selectCreatedItem = createSelector([selectPermissions], (permissions) => permissions.create);

export const selectUpdatedItem = createSelector([selectPermissions], (permissions) => permissions.update);

export const selectReadItem = createSelector([selectPermissions], (permissions) => permissions.read);

export const selectDeletedItem = createSelector([selectPermissions], (permissions) => permissions.delete);

export const selectSearchedItems = createSelector([selectPermissions], (permissions) => permissions.search);

export const selectItemsCount = createSelector([selectPermissions], (permissions) => permissions.count);

export const selectItemsFilter = createSelector([selectPermissions], (permissions) => permissions.filter);