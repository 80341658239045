import { createSelector } from 'reselect';

const selectProjectStatus = (state) => state.projectstatus;

export const selectCurrentItem = createSelector([selectProjectStatus], (projectstatus) => projectstatus.current);

export const selectListItems = createSelector([selectProjectStatus], (projectstatus) => projectstatus.list);


export const selectItemById = (itemId) =>
	createSelector(selectListItems, (list) => list.result.items.find((item) => item._id === itemId));

export const selectCreatedItem = createSelector([selectProjectStatus], (projectstatus) => projectstatus.create);

export const selectUpdatedItem = createSelector([selectProjectStatus], (projectstatus) => projectstatus.update);

export const selectReadItem = createSelector([selectProjectStatus], (projectstatus) => projectstatus.read);

export const selectDeletedItem = createSelector([selectProjectStatus], (projectstatus) => projectstatus.delete);

export const selectSearchedItems = createSelector([selectProjectStatus], (projectstatus) => projectstatus.search);

export const selectItemsCount = createSelector([selectProjectStatus], (projectstatus) => projectstatus.count);

export const selectItemsFilter = createSelector([selectProjectStatus], (projectstatus) => projectstatus.filter);



