import { crm } from "../redux/crm/actions";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentCRM } from '../redux/crm/selectors';
import { roles } from '../redux/roles/actions';
import { useForm } from "react-hook-form";
import { selectCreatedItem, selectUpdatedItem } from '../redux/roles/selectors'
export default function AddRoles() {
	const dispatch = useDispatch();
	const { register, handleSubmit, clearErrors, formState: { errors }, setValue } = useForm();
	const { addRole, isEditRole, roleItem, activeRoleId } = useSelector(selectCurrentCRM);
	const [name, setName] = useState('')
	const { isSuccess: isSuccessCreated, error, isNameExists } = useSelector(selectCreatedItem);
	const { isSuccess: isSuccessUpdated } = useSelector(selectUpdatedItem);

	useEffect(() => {
		if (addRole == 'open') {
			clearErrors("name")
			if (isNameExists) {
				dispatch(roles.resetName());

			}
		}
	}, [addRole])

	const handleSave = () => {
		if (!isEditRole) {
			dispatch(roles.resetAction({ actionType: 'create' }))
			dispatch(roles.create({
				entity: 'roles', jsonData: {
					entity: {
						"name": name,
						"permissions": ["5f615faeb7b6a1367e1bfdb8"
						]
					}
				}
			}))
		}
		else {
			dispatch(roles.resetAction({ actionType: 'update' }))
			dispatch(roles.update({
				entity: 'roles', id: activeRoleId, jsonData: {
					entity: {
						"name": name
					}
				}
			}))
		}
		setName('')
	}

	useEffect(() => {
		if (isSuccessCreated) {
			dispatch(crm.trigger({ reloadRole: true, addRole: 'close', isRoleEdit: false }))
		}
	}, [isSuccessCreated])

	useEffect(() => {
		if (isSuccessUpdated) {
			dispatch(crm.trigger({ reloadRole: true, addRole: 'close' }))
		}
	}, [isSuccessUpdated])


	useEffect(() => {
		if (isEditRole) {
			setName(roleItem)
			setValue('name', roleItem)
		}
		else {
			setName('')
			setValue('name', '')
		}
	}, [roleItem])

	const handleCancel = () => {
		dispatch(crm.trigger({ addRole: 'close' }))
	}

	return (
		<>
			{addRole == 'open' ? (
				<>
					<div className="absolute fixed w-full left-[50px] top-[250px] z-50 flex flex-warp justify-center align-middle w-[350px] h-[215px]">
						<div className="w-auto my-2 mx-auto max-w-sm">
							<div className="rounded-lg shadow-lg flex flex-col w-full bg-white">
								<h3 className="text-xs font-bold pt-3 pb-1 px-5">
									{isEditRole ? 'Edit ' : 'Add '} Role
								</h3>
								<form onSubmit={handleSubmit(handleSave)} >
									<div className="flex flex-col justify-start align-middle px-5 py-3" >
										<span className="text-blue-600 text-xs font-bold py-1">Role</span>
										<input
											id="name"
											aria-invalid={errors.name ? "true" : "false"}
											{...register('name', { required: true, maxLength: 30 })}
											type="text"
											name={"name"}
											className="border-0 px-3 py-1 bg-black bg-opacity-10  text-xs w-full"
											onChange={(e) => setName(e.target.value)}
										/>
										{errors.name && errors.name.type === "required" && (
											<span role="alert" className="w-full text-red-500 bg-white text-xs my-1">This is required</span>
										)}
										{errors.name && errors.name.type === "maxLength" && (
											<span role="alert" className="w-full text-red-500 bg-white text-xs my-1">Max length exceeded</span>
										)}
										{isNameExists && (
											<span role="alert" className="w-full text-red-500 bg-white text-xs my-1">{error?.message}</span>
										)}
									</div>
									<div className="flex items-center justify-around px-6 my-4 mx-3 ">
										<button
											className="text-blue-500 bg-transparent rounded font-bold  px-2 text-xs outline py-1"
											type="button"
											onClick={() => handleCancel()}
										>
											Cancel
										</button>
										<input type="submit"
											className="bg-blue-500 text-white rounded  px-2 text-xs py-1 mx-3"
											value={isEditRole ? 'Update' : 'Save'}
										/>
									</div>
								</form>
							</div>
						</div>
					</div>
					<div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
				</>
			) : null}
		</>
	);
}