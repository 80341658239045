import { crm } from "../redux/crm/actions";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentCRM } from '../redux/crm/selectors';
import { categoryname } from '../redux/categoryname/actions';
import { useForm } from "react-hook-form";
import { request } from "../request";

export default function AddCategoryName() {
	const dispatch = useDispatch();
	const { register, handleSubmit, clearErrors, formState: { errors }, setValue } = useForm();
	const { addCategoryName, isEditCategoryName, categoryNameItem, activeCategoryNameId } = useSelector(selectCurrentCRM);
	const [name, setName] = useState('')
	const [url, setUrl] = useState('')
	const [profile, setProfile] = useState('');
	const [file, setFile] = useState();

	useEffect(() => {
		if (addCategoryName == 'open') {
			clearErrors("name")
		}
	}, [addCategoryName])

	const handleSave = async () => {
		if (file) {
			const exte = file?.name.substring(file?.name.indexOf(".") + 1);
			const docDetails = {
				extension: exte,
				file_name: file?.name,
				mime_type: file?.type,
				entity_type: "crm",
				size: file?.size,
			};
			let data = await request.uploadurl({ entity: 'document', jsonData: docDetails, options: {} })
			const type = file?.type
			await request.uploadfileAWSS3({ fileuploadurl: data?.uploadLink, file, type })
			if (!isEditCategoryName) {
				dispatch(categoryname.create({ entity: 'PDI_Category_Name', jsonData: { entity: { Name: name, IconName: data?.filename } } })).then(() => {
					dispatch(crm.trigger({ reloadCategoryName: true, addCategoryName: 'close' }))
				})
			}
			else {
				dispatch(categoryname.update({ entity: 'PDI_Category_Name', id: activeCategoryNameId, jsonData: { entity: { Name: name, IconName: data?.filename } } })).then(() => {
					dispatch(crm.trigger({ reloadCategoryName: true, addCategoryName: 'close', isEditCategoryName: false }))
				})
			}
		}
		else {
			if (isEditCategoryName) {
				dispatch(categoryname.update({ entity: 'PDI_Category_Name', id: activeCategoryNameId, jsonData: { entity: { Name: name, IconName: 'fa fa-home' } } })).then(() => {
					dispatch(crm.trigger({ reloadCategoryName: true, addCategoryName: 'close', isEditCategoryName: false }))
				})
			}
		}
	}

	const handleDelete = () => {
		setUrl('')
		setProfile('')
	}

	const handleChange = (e) => {
		setProfile(URL.createObjectURL(e.target.files[0]));
		setFile(e.target.files[0])
	}

	const getImageUrl = async (key) => {
		const url = "https://proptech-pdi.s3.amazonaws.com/" + key
		setUrl(url)
	}

	const handleCancel = () => {
		dispatch(crm.trigger({ addCategoryName: 'close' }))
	}

	useEffect(() => {
		if (isEditCategoryName) {
			setValue('name', categoryNameItem?.Name)
			setName(categoryNameItem?.Name)
			getImageUrl(categoryNameItem?.IconName)
		}
		else {
			setName('')
			setUrl('')
		}
	}, [categoryNameItem])

	return (
		<>
			{addCategoryName == 'open' ? (
				<>
					<div className="absolute fixed w-full left-[50px] top-[250px] z-50 flex flex-warp justify-center align-middle w-[350px] h-[215px]">
						<div className="w-[300px] my-2 mx-auto">
							<div className="rounded-lg shadow-lg flex flex-col w-full bg-white">
								<h3 className="text-xs font-bold pt-3 pb-1 px-5">
									{isEditCategoryName ? 'Edit ' : 'Add '} Category Name
								</h3>
								<form onSubmit={handleSubmit(handleSave)} >
									<div className="flex flex-col justify-start align-middle px-5 py-3" >
										<span className="text-blue-600 text-xs font-bold py-1">Category Name</span>
										<input
											id="name"
											aria-invalid={errors.name ? "true" : "false"}
											{...register('name', { required: true, maxLength: 30 })}
											type="text"
											name={"name"}
											className="border-0 px-3 py-1 bg-black bg-opacity-10  text-xs w-full"
											onChange={(e) => setName(e.target.value)}
										/>
										{errors.name && errors.name.type === "required" && (
											<span role="alert" className="w-full text-red-500 bg-white text-xs my-1">This is required</span>
										)}
										{errors.name && errors.name.type === "maxLength" && (
											<span role="alert" className="w-full text-red-500 bg-white text-xs my-1">Max length exceeded</span>
										)}
									</div>
									<div className="w-full flex items-start justify-start px-3">
										{!url ? <div className="w-1/2 ml-3 flex flex-col items-start justify-center" >
											<label
												className="block text-xs font-bold"
												htmlFor="grid-password">
												Image
											</label>
											<div className="w-full bg-transparent  text-xs " >
												<div className="flex flex-row ">
													<input type="file" id="file-input" name="ImageStyle" onChange={(e) => handleChange(e)} />
												</div>
												{profile ? <img
													className="h-24 w-24 py-2"
													src={profile} /> : ""}
											</div>
										</div> :
											<div className="w-1/2 mx-3 flex flex-col justify-center items-start" >
												<div className="w-full flex flex-col item-center justify-between">
													<div className="w-full flex flex-row items-center justify-start my-2">
														<div className="text-grey-600 text-sm font-bold">
															Category Image
														</div>
														<div>
															<svg onClick={() => handleDelete()} width="15" height="15" className="mx-2" viewBox="0 0 24 24" fill="currentColor"><path d="M17 6H22V8H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V8H2V6H7V3C7 2.44772 7.44772 2 8 2H16C16.5523 2 17 2.44772 17 3V6ZM18 8H6V20H18V8ZM13.4142 13.9997L15.182 15.7675L13.7678 17.1817L12 15.4139L10.2322 17.1817L8.81802 15.7675L10.5858 13.9997L8.81802 12.232L10.2322 10.8178L12 12.5855L13.7678 10.8178L15.182 12.232L13.4142 13.9997ZM9 4V6H15V4H9Z"></path></svg>
														</div>
													</div>
													<img
														className="h-24 w-24"
														src={url}
														alt="avatar"
													/>
												</div>
											</div>}
									</div>
									<div className="flex items-center justify-around px-6 my-4 mx-3 ">
										<button
											className="cursor-pointer text-blue-500 bg-transparent rounded font-bold  px-2 text-xs outline py-1"
											type="button"
											onClick={() => handleCancel()}
										>
											Cancel
										</button>
										<input type="submit"
											className="cursor-pointer bg-blue-500 text-white rounded  px-2 text-xs py-1 mx-3"
											value={isEditCategoryName ? 'Update' : 'Save'}
										/>
									</div>
								</form>
							</div>
						</div>
					</div>
					<div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
				</>
			) : null}
		</>
	);
}