export const ROLES_RESET_STATE = 'ROLES_RESET_STATE';
export const ROLES_CURRENT_ITEM = 'ROLES_CURRENT_ITEM';

export const ROLES_REQUEST_LOADING = 'ROLES_REQUEST_LOADING';
export const ROLES_REQUEST_SUCCESS = 'ROLES_REQUEST_SUCCESS';
export const ROLES_REQUEST_FAILED = 'ROLES_REQUEST_FAILED';

export const ROLES_CURRENT_ACTION = 'ROLES_CURRENT_ACTION';
export const ROLES_RESET_ACTION = 'ROLES_RESET_ACTION';

export const ROLES_NAME_EXISTS_ACTION = 'ROLES_NAME_EXISTS_ACTION';
export const ROLES_RESET_NAME_EXISTS = 'ROLES_RESET_NAME_EXISTS';
