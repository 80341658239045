// import history from '@/utils/history';
import codeMessage from './codeMessage';
import { toast, snackbar } from 'tailwind-toast'


const errorHandler = (error) => {
	const { response } = error;

	if (response && response.status) {
		const message = response.data && response.data.message;

		const errorText = message || codeMessage[response.status];
		const { status } = response;
		toast().danger(status, errorText).show()
		if (status == 401 || status == 401) {
			localStorage.clear()
			window.location.href = '/auth/login'

		}

		return response.data;
	}
	// return {
	// 	success: false,
	// 	result: null,
	// 	message: 'Cannot connect to the server, Check your internet network',
	// };
};

export default errorHandler;
