import { createSelector } from 'reselect';

const selectMasterChecklistItems = (state) => state.masterchecklistitems;

export const selectCurrentItem = createSelector([selectMasterChecklistItems], (masterchecklistitems) => masterchecklistitems.current);

export const selectListItems = createSelector([selectMasterChecklistItems], (masterchecklistitems) => masterchecklistitems.list);


export const selectItemById = (itemId) =>
	createSelector(selectListItems, (list) => list.result.items.find((item) => item._id === itemId));

export const selectCreatedItem = createSelector([selectMasterChecklistItems], (masterchecklistitems) => masterchecklistitems.create);

export const selectUpdatedItem = createSelector([selectMasterChecklistItems], (masterchecklistitems) => masterchecklistitems.update);

export const selectReadItem = createSelector([selectMasterChecklistItems], (masterchecklistitems) => masterchecklistitems.read);

export const selectDeletedItem = createSelector([selectMasterChecklistItems], (masterchecklistitems) => masterchecklistitems.delete);

export const selectSearchedItems = createSelector([selectMasterChecklistItems], (masterchecklistitems) => masterchecklistitems.search);

export const selectItemsCount = createSelector([selectMasterChecklistItems], (masterchecklistitems) => masterchecklistitems.count);

export const selectItemsFilter = createSelector([selectMasterChecklistItems], (masterchecklistitems) => masterchecklistitems.filter);



