import * as actionTypes from './types';
import * as authService from './../../auth';

export const login =
	({ loginData }) =>
		async (dispatch) => {
			dispatch({
				type: actionTypes.AUTH_LOADING_REQUEST,
				payload: { loading: true },
			});
			const data = await authService.login({ loginData });

			if (data.success === true) {
				dispatch({
					type: actionTypes.AUTH_LOGIN_SUCCESS,
					payload: data.result.accessToken,
					user: data.result.user,
					deviceDetails: data.result.deviceDetails
				});
			} else {
				dispatch({
					type: actionTypes.AUTH_FAILED_REQUEST,
					payload: data,
				});
			}
		};

export const changePassword =
	({ passwordData }) =>
		async (dispatch) => {
			dispatch({
				type: actionTypes.AUTH_LOADING_REQUEST,
				payload: { loading: true },
			});
			const data = await authService.changePassword({ passwordData });
			if (data?.success === true) {
				dispatch({
					type: actionTypes.AUTH_LOGIN_SUCCESS,
					user: data
				});
				localStorage.clear()
				window.location.href = '/inspection-crm/project-boards'
			} else {
				dispatch({
					type: actionTypes.AUTH_FAILED_REQUEST,
					payload: data,
				});
			}
		};

export const logout = () => async (dispatch) => {
	authService.logout();
	dispatch({
		type: actionTypes.AUTH_LOGOUT_SUCCESS,
	});
};
