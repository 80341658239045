import { crm } from "../../redux/crm/actions";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash'
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import { selectCurrentCRM } from '../../redux/crm/selectors';
import { selectListItems as selectSetStatusList } from '../../redux/issuestatus/selectors';
import { selectListItems as selectSetPriorityList } from '../../redux/issuepriority/selectors';
import { selectListItems as selectUserList } from '../../redux/users/selectors';
import { selectListItems as selectIssueList } from '../../redux/issuecards/selectors';
import { users } from "../../redux/users/actions";
import { issuestatus } from "../../redux/issuestatus/actions";
import { issuepriority } from "../../redux/issuepriority/actions";
import { issuecards } from "../../redux/issuecards/actions";
import { selectItemsFilter as projectUserFiter } from '../../redux/projectusers/selectors';
import { request } from '../../request/index';
import { projectusers } from "../../redux/projectusers/actions";


export default function AddIssue() {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const { register, handleSubmit, clearErrors, formState: { errors }, setValue, getValue } = useForm()
	const [issueRefNumber, setIssueRefNumber] = useState(0)
	const [file, setFile] = useState(null);
	const [file1, setFile1] = useState(null);
	const [attachmentList, setAttachmentList] = useState([])
	const [newAttachmentList, setNewAttachmentList] = useState([])
	const [attachmentList1, setAttachmentList1] = useState([])
	const [newAttachmentList1, setNewAttachmentList1] = useState([])
	const { activeBoardName, activeDownloadedUrl, activeCategoryNameId, activeChecklistId, isEditIssue, issueItem, selectedCheckListItem, activeIssueId, activeProjectId, activeCategoryName, activeCustomerId, activeDocumentFileKey, activeCustomer, assignCRM, assignSiteEngineer, activeInspectionId, activeInspectionDescription } = useSelector(selectCurrentCRM);
	const { result: userResult } = useSelector(selectUserList)
	const { items: useritems } = userResult
	const { result: statusResult } = useSelector(selectSetStatusList)
	const { items: statusitems } = statusResult
	const { result: priorityResult } = useSelector(selectSetPriorityList)
	const { items: priorityitems } = priorityResult
	const { result: issueResult } = useSelector(selectIssueList)
	const { items: issueitems } = issueResult
	const { result: projectUserResult } = useSelector(projectUserFiter)
	const { items: projectUser } = projectUserResult
	const [steps, setSteps] = useState({});
	const [userIds, setUserIds] = useState([])

	useEffect(() => {
		const _userIds = []
		Promise.all(projectUser.map((item) => {
			_userIds.push(item.User._id)
		}))
		setUserIds(_userIds)
	}, [projectUser])

	const handleCancel = (data) => {
		navigate(-1)
	}

	const downloadAttachment = async (key) => {
		const url = "https://proptech-pdi.s3.amazonaws.com/" + key
		const link = document.createElement('a');
		link.href = url;
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	}

	const downloadAttachment1 = async (key) => {
		const url = "https://proptech-pdi.s3.amazonaws.com/" + key
		const link = document.createElement('a');
		link.href = url;
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	}

	const handleDeleteAttachment = (obj, idx, type) => {
		if (type == 'uploading') {
			let filteredArray = newAttachmentList.filter(item => item !== obj)
			setNewAttachmentList(filteredArray);
		}
		else {
			let filteredArray = attachmentList.filter(item => item !== obj)
			setAttachmentList(filteredArray);
		}
	}

	const handleDeleteAttachment1 = (obj, idx, type) => {
		if (type == 'uploading') {
			let filteredArray = newAttachmentList1.filter(item => item !== obj)
			setNewAttachmentList1(filteredArray);
		}
		else {
			let filteredArray = attachmentList1.filter(item => item !== obj)
			setAttachmentList1(filteredArray);
		}
	}

	const handleSave = async () => {
		let _attach = []
		let _attach1 = []
		await Promise.all(newAttachmentList?.map(async (attach, idx) => {
			const _file = file[idx]
			const exte = _file?.name.substring(_file?.name.indexOf(".") + 1);
			const docDetails = {
				extension: exte,
				file_name: _file.name,
				mime_type: _file.type,
				entity_type: "crm",
				size: _file.size,
			};
			let data = await request.uploadurl({ entity: 'document', jsonData: docDetails, options: {} })
			const type = _file.type
			await request.uploadfileAWSS3({ fileuploadurl: data.uploadLink, file: _file, type })
			_attach.push({ filename: _file?.name, fileKey: data?.filename })

		}))
		await Promise.all(newAttachmentList1?.map(async (attach, idx) => {
			const _file = file1[idx]
			const exte = _file?.name.substring(_file?.name.indexOf(".") + 1);
			const docDetails = {
				extension: exte,
				file_name: _file.name,
				mime_type: _file.type,
				entity_type: "crm",
				size: _file.size,
			};
			let data = await request.uploadurl({ entity: 'document', jsonData: docDetails, options: {} })
			const type = _file.type
			await request.uploadfileAWSS3({ fileuploadurl: data.uploadLink, file: _file, type })
			_attach1.push({ filename: _file?.name, fileKey: data?.filename })

		}))
		attachmentList?.map((attached) => {
			_attach.push({ filename: attached?.filename, fileKey: attached?.fileKey })
		})
		attachmentList1?.map((attached) => {
			_attach1.push({ filename: attached?.filename, fileKey: attached?.fileKey })
		})
		if (isEditIssue) {
			dispatch(issuecards.update({
				entity: 'PDI_Issue_Cards',
				id: activeIssueId,
				jsonData: {
					entity: {
						"IssueName": steps['IssueName'],
						//"IssueNumber": steps['IssueNumber'],
						"CRMExecutive": steps['CRMExecutive'],
						"SiteEngineer": steps['SiteEngineer'],
						"Status": steps['Status'],
						"Priority": steps['Priority'],
						"StartDate": steps['StartDate'],
						"ExpectedCompletionDate": steps['ExpectedCompletionDate'],
						"Description": steps['Description'],
						"Comment": steps['Comment'],
						"Project": activeProjectId,
						"Customer": activeCustomerId,
						"ImageUrl": _attach1,
						"Attachments": _attach,
						"CategoryName": issueitems?.CategoryName?._id,
						"ChecklistItem": selectedCheckListItem
					}
				}
			})).then(() => {
				setSteps({})
				navigate('/inspection-crm/project-checklist')
			})
		}
		else {
			dispatch(issuecards.create({
				entity: 'PDI_Issue_Cards',
				jsonData: {
					entity: {
						"IssueName": steps['IssueName'],
						//"IssueNumber": String(issueitems?.length).padStart(4, '0'),
						"CRMExecutive": steps['CRMExecutive'],
						"SiteEngineer": steps['SiteEngineer'],
						"Status": '659cdc8dcbe502001aafb6dd',
						"Priority": steps['Priority'],
						"StartDate": new Date(steps['StartDate']),
						"ExpectedCompletionDate": new Date(steps['ExpectedCompletionDate']),
						"Description": steps['Description'],
						"Comment": 'NA',
						"Project": activeProjectId,
						"Customer": activeCustomerId,
						"ImageUrl": _attach1,
						"Attachments": _attach,
						"CategoryName": activeCategoryNameId, //issueitems?.CategoryName?._id,
						"ChecklistItem": activeChecklistId
					}
				}
			})).then(() => {
				setSteps({})
				dispatch(crm.trigger({ reloadWebIssueCreated: true }))
				navigate('/inspection-crm/project-checklist')
			})
		}
	}

	useEffect(() => {
		if (isEditIssue) {
			setValue('IssueNumber', issueItem?.IssueNumber)
			setValue('IssueName', issueItem?.IssueName)
			setValue('CRMExecutive', issueItem?.CRMExecutive?._id)
			setValue('SiteEngineer', issueItem?.SiteEngineer?._id)
			setValue('Description', issueItem?.Description)
			setValue('Status', issueItem?.Status?._id)
			setValue('Priority', issueItem?.Priority?._id)
			setValue('StartDate', issueItem?.StartDate?.slice(0, 10))
			setValue('ExpectedCompletionDate', issueItem?.ExpectedCompletionDate?.slice(0, 10))
		}
	}, [isEditIssue])

	const handleBrowseAttachementFile = () => {
		document.getElementById('attachmentfile').click()
	}

	const handleBrowseAttachementFile1 = () => {
		document.getElementById('attachmentfile1').click()
	}

	const handleFileAttachmentChange = async (e) => {
		setFile(e.target.files);
		const chosenFiles = Array.prototype.slice.call(e.target.files)
		let arr = []
		chosenFiles?.map((attach) => {
			arr.push({ filename: attach.name, fileKey: '' })
		})
		setNewAttachmentList(arr)

	};

	const handleFileAttachmentChange1 = async (e) => {
		setFile1(e.target.files);
		const chosenFiles = Array.prototype.slice.call(e.target.files)
		let arr = []
		chosenFiles?.map((attach) => {
			arr.push({ filename: attach.name, fileKey: '' })
		})
		setNewAttachmentList1(arr)

	}

	const handleSteps = (e, key) => {
		setSteps({ ...steps, [key]: e.target.value })
	}

	const handleProject = () => {
		navigate('/inspection-crm/project-boards')
	}

	const handleChecklist = () => {
		navigate('/inspection-crm/project-checklist')
	}

	const handleCustomer = () => {
		navigate('/inspection-crm/inspection-boards')
	}

	useEffect(() => {
		let arr = []
		let arr1 = []
		selectedCheckListItem?.Attachments?.map((attach, index) => {
			arr.push(attach[0])
		})
		setAttachmentList(arr)
		selectedCheckListItem?.ImageUrl?.map((attach, index) => {
			arr1.push(attach[0])
		})
		setAttachmentList1(arr)
	}, [selectedCheckListItem])

	useEffect(() => {
		if (!isEditIssue) {
			//setValue('IssueNumber', String(issueitems?.length).padStart(4, '0'))
			setNewAttachmentList([])
			setAttachmentList([])
		}
	}, [issueitems])

	useEffect(() => {
		dispatch(projectusers.filter({ entity: 'PDI_Project_Users', jsonData: { params: { isDeleted: false, ProjectName: activeProjectId } }, page: 1, limit: 200 })).then(() => {
			if (isEditIssue) {
				setValue('CRMExecutive', issueItem?.CRMExecutive?._id)
				setValue('SiteEngineer', issueItem?.SiteEngineer?._id)
			}
		})
		if (!isEditIssue) {
			dispatch(issuecards.list({ entity: 'PDI_Issue_Cards', options: {} }))
			dispatch(crm.trigger({ activeDownloadedUrl: '' }))
			setFile('')
			setFile1('')
		}
		dispatch(users.list({ entity: 'users', options: {} }))
		dispatch(issuestatus.list({ entity: 'PDI_Issue_Status', options: {} }))
		dispatch(issuepriority.list({ entity: 'PDI_Issue_Priority', options: {} }))
		clearErrors('IssueName')
		clearErrors('CRMExecutive')
		clearErrors('Status')
		clearErrors('StartDate')
		clearErrors('ExpectedCompletionDate')
		clearErrors('Description')
	}, [])

	useEffect(() => {
		if (!isEditIssue) {
			setSteps({ ...steps, ['IssueName']: activeInspectionDescription })
			setValue('IssueName', activeInspectionDescription)
		}
	}, [assignCRM])

	return (
		<div className="flex flex-col item-center">
			<header className="flex justify-between h-20 w-full mx-auto px-5 dark:text-white items-center">
				<div className="flex justify-start w-full" >
					<nav aria-label="breadcrumb" className="w-max" >
						<ol className="flex flex-wrap items-center w-full px-4 py-2 rounded-md bg-blue-gray-50 bg-opacity-60">
							<li
								className="flex items-center font-sans text-sm antialiased font-normal leading-normal transition-colors duration-300 cursor-pointer text-blue-gray-900 hover:text-light-blue-500">
								<h3 className="text-xl font-bold" onClick={() => handleProject()}>Projects</h3>
								<span
									className="mx-2 font-bold text-xl  antialiased font-normal leading-normal pointer-events-none select-none text-blue-gray-500 text-xl">
									<svg fill="#000000" height="12px" width="12px" version="1.1" id="Layer_1"
										viewBox="0 0 330 330">
										<path id="XMLID_222_" d="M250.606,154.389l-150-149.996c-5.857-5.858-15.355-5.858-21.213,0.001
	c-5.857,5.858-5.857,15.355,0.001,21.213l139.393,139.39L79.393,304.394c-5.857,5.858-5.857,15.355,0.001,21.213
	C82.322,328.536,86.161,330,90,330s7.678-1.464,10.607-4.394l149.999-150.004c2.814-2.813,4.394-6.628,4.394-10.606
	C255,161.018,253.42,157.202,250.606,154.389z"/>
									</svg>
								</span>
							</li>
							<li
								className="flex items-center font-sans text-sm antialiased font-normal leading-normal transition-colors duration-300 cursor-pointer text-blue-gray-900 hover:text-light-blue-500">
								<a href="#" className="opacity-60 text-md font-bold">
									{activeBoardName}
								</a>
								<span
									className="mx-2 font-bold text-xl  antialiased font-normal leading-normal pointer-events-none select-none text-blue-gray-500 text-xl">
									<svg fill="#000000" height="12px" width="12px" version="1.1" id="Layer_1"
										viewBox="0 0 330 330">
										<path id="XMLID_222_" d="M250.606,154.389l-150-149.996c-5.857-5.858-15.355-5.858-21.213,0.001
	c-5.857,5.858-5.857,15.355,0.001,21.213l139.393,139.39L79.393,304.394c-5.857,5.858-5.857,15.355,0.001,21.213
	C82.322,328.536,86.161,330,90,330s7.678-1.464,10.607-4.394l149.999-150.004c2.814-2.813,4.394-6.628,4.394-10.606
	C255,161.018,253.42,157.202,250.606,154.389z"/>
									</svg>
								</span>
							</li>
							<li

								className="flex items-center font-sans text-sm antialiased font-normal leading-normal transition-colors duration-300 cursor-pointer text-blue-gray-900 hover:text-light-blue-500">
								<span className="cursor-pointer opacity-60 text-md font-bold"
									onClick={() => handleCustomer()} >
									{activeCustomer}</span>
								<span
									className="mx-2 font-bold text-xl  antialiased font-normal leading-normal pointer-events-none select-none text-blue-gray-500 text-xl">
									<svg fill="#000000" height="12px" width="12px" version="1.1" id="Layer_1"
										viewBox="0 0 330 330">
										<path id="XMLID_222_" d="M250.606,154.389l-150-149.996c-5.857-5.858-15.355-5.858-21.213,0.001
	c-5.857,5.858-5.857,15.355,0.001,21.213l139.393,139.39L79.393,304.394c-5.857,5.858-5.857,15.355,0.001,21.213
	C82.322,328.536,86.161,330,90,330s7.678-1.464,10.607-4.394l149.999-150.004c2.814-2.813,4.394-6.628,4.394-10.606
	C255,161.018,253.42,157.202,250.606,154.389z"/>
									</svg>
								</span>
							</li>
							<li
								className="flex items-center font-sans text-sm antialiased font-normal leading-normal transition-colors duration-300 cursor-pointer text-blue-gray-900 hover:text-light-blue-500">
								<span onClick={() => handleChecklist()} className="opacity-60 text-md font-bold">
									Checklist
								</span>
								<span
									className="mx-2 font-bold text-xl  antialiased font-normal leading-normal pointer-events-none select-none text-blue-gray-500 text-xl">
									<svg fill="#000000" height="12px" width="12px" version="1.1" id="Layer_1"
										viewBox="0 0 330 330">
										<path id="XMLID_222_" d="M250.606,154.389l-150-149.996c-5.857-5.858-15.355-5.858-21.213,0.001
	c-5.857,5.858-5.857,15.355,0.001,21.213l139.393,139.39L79.393,304.394c-5.857,5.858-5.857,15.355,0.001,21.213
	C82.322,328.536,86.161,330,90,330s7.678-1.464,10.607-4.394l149.999-150.004c2.814-2.813,4.394-6.628,4.394-10.606
	C255,161.018,253.42,157.202,250.606,154.389z"/>
									</svg>
								</span>
							</li>
							<li
								className="flex items-center font-sans text-sm antialiased font-normal leading-normal transition-colors duration-300 cursor-pointer text-blue-gray-900 hover:text-light-blue-500">
								<a href="#" className="opacity-60 text-md font-bold">
									{!isEditIssue ? 'Add ' : 'Edit '} Issue
								</a>
							</li>
						</ol>
					</nav>
				</div>
			</header >
			<div className="w-auto flex flex-row mx-5">
				<div className={isEditIssue ? "w-3/5 h-full mx-2 bg-white rounded shawdow-xl" : "w-4/5 h-full mx-2 bg-white rounded shawdow-xl"} >
					<form onSubmit={handleSubmit(handleSave)} >
						<div className="w-full lg:w-12/12 px-4 my-6 overflow-auto max-h-[65vh] space-y-4">
							<div className="text-md font-bold"> Issue Details - Main Entry</div>
							<div className="flex flex-row space-x-4">
								<div className="relative w-1/5 ">
									<label
										className="block  text-xs font-bold mb-2"
										htmlFor="grid-password">
										Issue Number
									</label>
									<div className="border-0  bg-[#F3F4F6FF] text-xs " >
										<input
											type="text"
											name="IssueNumber"
											{...register('IssueNumber')}
											disabled={true}
											className="w-full px-3 py-1 bg-[#F3F4F6FF] text-xs"
											onChange={(e) => handleSteps(e, 'IssueNumber')}
										/>
									</div>

								</div>
								<div className="relative w-4/5 ">
									<label
										className="block  text-xs font-bold mb-2"
										htmlFor="grid-password">
										Issue Name
									</label>
									<div className="border-0  bg-[#F3F4F6FF] text-xs " >
										<input
											type="text"
											placeholder="Name of Issue"
											id="IssueName"
											name="IssueName"
											{...register('IssueName', {
												required: true, maxLength: 300
											})}
											className="w-full px-3 py-1 bg-[#F3F4F6FF] text-xs"
											onChange={(e) => handleSteps(e, 'IssueName')}
										/>
									</div>
									{errors.IssueName && errors.IssueName.type === "required" && (
										<span role="alert" className="w-full text-red-500 bg-white text-xs my-1">Please fill in the issue name field.</span>
									)}
									{errors.IssueName && errors.IssueName.type === "maxLength" && (
										<span role="alert" className="w-full text-red-500 bg-white text-xs my-1">Max length exceeded</span>
									)}
								</div>
							</div>
							<div className="flex flex-row space-x-4">
								<div className="relative w-full">
									<label
										className="block text-xs font-bold mb-2"
										htmlFor="grid-password" >
										CRM Executive
									</label>
									<div className="px-3 border-0  bg-[#F3F4F6FF] text-xs " >
										<select
											className="w-full  py-1 bg-[#F3F4F6FF] text-xs"
											name="CRMExecutive"
											{...register('CRMExecutive', { required: true })}
											onChange={(e) => handleSteps(e, 'CRMExecutive')}
											value={steps['CRMExecutive']}
										>
											<option value="" >--Select--</option>
											{useritems?.filter(item1 => userIds?.some(item2 => item2 == item1._id))?.filter((obj) => obj?.roleId?._id == '65dfe45c6ad846404c6cc572')?.map((item, index) => {
												return <option value={item._id} key={index}> {item.userId}</option>
											})}
										</select>
									</div>
									{errors.CRMExecutive && errors.CRMExecutive.type === "required" && (
										<span role="alert" className="w-full text-red-500 bg-white text-xs my-1">Please fill in the crm executive field.</span>
									)}
								</div>
								<div className="relative w-full">
									<label
										className="block text-xs font-bold mb-2"
										htmlFor="grid-password" >
										QC/Engineer
									</label>
									<div className="px-3 border-0  bg-[#F3F4F6FF] text-xs " >
										<select
											className="w-full  py-1 bg-[#F3F4F6FF] text-xs"
											name="SiteEngineer"
											{...register('SiteEngineer', { required: true })}
											onChange={(e) => handleSteps(e, 'SiteEngineer')}
											value={steps['SiteEngineer']}
										>
											<option value="" >--Select--</option>
											{useritems?.filter(item1 => userIds?.some(item2 => item2 == item1._id))?.filter((obj) => obj?.roleId?._id == '65dfe4766ad846404c6cc573')?.map((item, index) => {
												return <option value={item._id} key={index}> {item.userId}</option>
											})}
										</select>
									</div>
									{errors.SiteEngineer && errors.SiteEngineer.type === "required" && (
										<span role="alert" className="w-full text-red-500 bg-white text-xs my-1">Please fill in the QC/Engineer field.</span>
									)}
								</div>
							</div>
							<div className="flex flex-row space-x-4">
								<div className="relative w-full">
									<label
										className="block text-xs font-bold mb-2"
										htmlFor="grid-password" >
										Set Status
									</label>
									<div className="px-3 border-0  bg-[#F3F4F6FF] text-xs " >
										<select
											className="w-full py-1 bg-[#F3F4F6FF] text-xs"
											name="Status"
											{...register('Status', { required: true })}
											onChange={(e) => handleSteps(e, 'Status')}
										>
											<option value="" >--Select--</option>
											{statusitems.map((item, index) => {
												return <option value={item._id} key={index}> {item.Name}</option>
											})}
										</select>
									</div>
									{errors.Status && errors.Status.type === "required" && (
										<span role="alert" className="w-full text-red-500 bg-white text-xs my-1">Please fill in the status field.</span>
									)}
								</div>
								<div className="relative w-full">
									<label
										className="block text-xs font-bold mb-2"
										htmlFor="grid-password">
										Set Priority
									</label>
									<div className="px-3 border-0  bg-[#F3F4F6FF] text-xs " >
										<select
											className="w-full py-1 bg-[#F3F4F6FF] text-xs"
											name="Priority"
											{...register('Priority', { required: true })}
											onChange={(e) => handleSteps(e, 'Priority')}
											value={steps['Priority']}
										>
											<option value="">--Select--</option>
											{priorityitems.map((item, index) => {
												return <option value={item._id} key={index}> {item.Name}</option>
											})}
										</select>
									</div>
									{errors.Priority && errors.Priority.type === "required" && (
										<span role="alert" className="w-full text-red-500 bg-white text-xs my-1">Please fill in the priority field.</span>
									)}
								</div>
							</div>
							<div className="border-b-2 border-black border-opacity-10"></div>
							<div className="text-md font-bold"> Timeline</div>
							<div className="flex flex-row space-x-4">
								<div className="relative w-full">
									<label
										className="block text-xs font-bold mb-2"
										htmlFor="grid-password" >
										Start Date
									</label>
									<div className="px-3 border-0  bg-[#F3F4F6FF] text-xs " >
										<input
											type="date"
											name="StartDate"
											{...register('StartDate', {
												required: true
											})}
											placeholder=""
											className="w-full py-1 bg-[#F3F4F6FF] text-xs"
											onChange={(e) => handleSteps(e, 'StartDate')}
										/>
									</div>
									{errors.StartDate && errors.StartDate.type === "required" && (
										<span role="alert" className="w-full text-red-500 bg-white text-xs my-1">Please fill in the start date field.</span>
									)}
								</div>
								<div className="relative w-full">
									<label
										className="block text-xs font-bold mb-2"
										htmlFor="grid-password">
										Expected Completion Date
									</label>
									<div className="px-3 border-0  bg-[#F3F4F6FF] text-xs" >
										<input
											type="date"
											name="ExpectedCompletionDate"
											{...register('ExpectedCompletionDate', {
												required: true
											})}
											className="w-full py-1 bg-[#F3F4F6FF] text-xs"
											onChange={(e) => handleSteps(e, 'ExpectedCompletionDate')}
											value={isEditIssue ? steps['ExpectedCompletionDate']?.slice(0, 10) : steps['ExpectedCompletionDate']}
										/>
									</div>
									{errors.ExpectedCompletionDate && errors.ExpectedCompletionDate.type === "required" && (
										<span role="alert" className="w-full text-red-500 bg-white text-xs my-1">Please fill in the expected completion date field.</span>
									)}
								</div>
							</div>
							<div className="border-b-2 border-black border-opacity-10"></div>
							<div className="text-md font-bold"> Other Relevant Details</div>
							<div className="flex flex-row space-x-2">
								<div className="relative w-full mb-1">
									<label
										htmlFor="formFile"
										className="block uppercase text-xs font-bold mb-2" >
										Description
									</label>
									<div className="border-0  bg-[#F3F4F6FF] text-xs" >
										<textarea
											type="text"
											placeholder="detailed description of issue"
											name="Description"
											{...register('Description', {
												required: true, maxLength: 300
											})}
											className="w-full px-3 py-1 bg-[#F3F4F6FF] text-xs"
											onChange={(e) => handleSteps(e, 'Description')}
										/>
									</div>
									{errors.Description && errors.Description.type === "required" && (
										<span role="alert" className="w-full text-red-500 bg-white text-xs my-1">Please fill in the project name field.</span>
									)}
								</div>
							</div>
							<div className="flex flex-row space-x-2">
								<div className="relative w-full mb-2">
									<label
										htmlFor="formFile"
										className="block uppercase text-xs font-bold mb-2" >
										Add Comment
									</label>
									<div className=" border-0  bg-[#F3F4F6FF] text-xs" >
										<input
											type="text"
											placeholder="input text"
											name={"comment"}
											className="w-full px-3 py-1 bg-[#F3F4F6FF] text-xs"
											onChange={(e) => handleSteps(e, 'Comment')}
											value={steps['Comment']}
										/>
									</div>
								</div>
							</div>
						</div>
						<div className="flex flex-row item-baseline justify-center space-x-2 my-4 h-8">
							<button
								className="items-center dark:text-white dark:bg-blue-600   text-blue-600 outline px-2 py-1 rounded text-xs font-bold"
								onClick={() => {
									handleCancel()
								}}
							> Cancel</button>
							<input type="submit"
								className="bg-blue-500 cursor-pointer text-white rounded  px-2 text-xs py-1"
								value={isEditIssue ? 'Update' : 'Add'}
							/>
						</div>
					</form>
				</div>
				<div className="w-1/5 max-h-[75vh] overflow-y-auto overflow-x-hidden mx-2 bg-white rounded shawdow-xl flex justify-start item-start flex-col">
					<div className="flex flex-row space-x-2 m-4 mx-5">
						<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#1C49D0FF" viewBox="0 0 24 24"><path d="M19.903 8.586a.997.997 0 0 0-.196-.293l-6-6a.997.997 0 0 0-.293-.196c-.03-.014-.062-.022-.094-.033a.991.991 0 0 0-.259-.051C13.04 2.011 13.021 2 13 2H6c-1.103 0-2 .897-2 2v16c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V9c0-.021-.011-.04-.013-.062a.952.952 0 0 0-.051-.259c-.01-.032-.019-.063-.033-.093zM16.586 8H14V5.414L16.586 8zM6 20V4h6v5a1 1 0 0 0 1 1h5l.002 10H6z" /><path d="M8 12h8v2H8zm0 4h8v2H8zm0-8h2v2H8z" /></svg>
						<div className="text-[18px] text-[#323842FF] font-semibold ">Issue Ref Image </div>
					</div>
					<div className="flex mx-5 p-4" >
						<div className="flex justify-center   w-[250px]  px-4 py-2 transition bg-[#1C49D01A] border-x border-y border-[#171A1FFF] border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none">
							<span className="flex items-center justify-center flex-col space-x-2">
								<svg className="w-[60px] h-[60px] text-[#1C49D0FF]" fill="#1C49D0FF" viewBox="0 0 256 256"><path d="M248,128a87.34,87.34,0,0,1-17.6,52.81,8,8,0,1,1-12.8-9.62A71.34,71.34,0,0,0,232,128a72,72,0,0,0-144,0,8,8,0,0,1-16,0,88,88,0,0,1,3.29-23.88C74.2,104,73.1,104,72,104a48,48,0,0,0,0,96H96a8,8,0,0,1,0,16H72A64,64,0,1,1,81.29,88.68,88,88,0,0,1,248,128Zm-90.34-5.66a8,8,0,0,0-11.32,0l-32,32a8,8,0,0,0,11.32,11.32L144,147.31V208a8,8,0,0,0,16,0V147.31l18.34,18.35a8,8,0,0,0,11.32-11.32Z"></path></svg>
								<span
									onClick={() => handleBrowseAttachementFile()}
									className="bg-[#1C49D0FF] my-2  text-[14px] font-[400]  text-white px-2 py-2 rounded text-xs ">
									Browse File
								</span>
							</span>
							<input type="file" id='attachmentfile' className="hidden" multiple name="attachment_upload" onChange={(e) => handleFileAttachmentChange(e)} />
						</div>
					</div>
					<div className="flex flex-col justify-start item-center w-full mx-5 px-5 h-full pb-20" >
						<div className="flex flex-row space-x-2 m-4">
							<div className="text-xs font-normal  text-black text-opacity-60 ">Uploading files - {attachmentList?.length}</div>
						</div>
						{newAttachmentList?.map((obj, index) => {
							return <div key={index} className="flex w-full flex-row jusity-center item-center h-full">
								<svg
									xmlns="http://www.w3.org/2000/svg" width="48px" height="48px"
									fill="#1C49D0ff" viewBox="0 0 24 24">
									<path d="M7.782 14.576c-.186 0-.312.018-.377.036v1.193c.077.018.174.023.306.023.485 0 .785-.246.785-.659 0-.371-.258-.593-.714-.593z" /><path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8l-6-6zM9.03 16.105c-.313.293-.774.426-1.313.426-.12 0-.229-.007-.312-.019v1.445h-.906V13.97a7.504 7.504 0 0 1 1.235-.083c.563 0 .966.107 1.235.323.258.204.432.54.432.936s-.131.731-.371.959zm4.302 1.853h-.96l-.863-1.56c-.24-.432-.504-.953-.701-1.427l-.019.006c.024.534.036 1.104.036 1.763v1.218h-.84v-4.042h1.067l.84 1.481c.24.426.479.93.659 1.385h.019a14.746 14.746 0 0 1-.078-1.685v-1.182h.84v4.043zm4.169-.186a4.512 4.512 0 0 1-1.349.228c-.737 0-1.271-.186-1.644-.546-.371-.348-.575-.875-.569-1.469.006-1.344.983-2.111 2.309-2.111.521 0 .924.103 1.121.198l-.191.731c-.222-.096-.498-.174-.941-.174-.762 0-1.338.432-1.338 1.308 0 .833.522 1.325 1.271 1.325.21 0 .378-.024.45-.061v-.846h-.624v-.713h1.505v2.13zM14 9h-1V4l5 5h-4z" /></svg>
								<div className="w-1/2 m-2  pt-1">
									<div className="mb-2 flex w-full items-center justify-between text-xs mr-8">
										<div onClick={() => downloadAttachment(obj?.fileKey)}
											className="cursor-pointer text-gray-600">{obj?.filename}</div>
										<div className="text-gray-600">100%</div>
									</div>
									<div className="flex flex-row w-full justify-center item-center">
										<div className="w-full mb-4 flex h-2 overflow-hidden rounded bg-gray-100 text-xs ">
											<div className="bg-green-500 w-full"></div>
										</div>
									</div>
								</div>
								<div onClick={() => handleDeleteAttachment(obj, index, 'uploading')}
									className="m-1 p-2 cursor-pointer rounded  text-black h-7" >
									<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="currentColor"><path d="M17 6H22V8H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V8H2V6H7V3C7 2.44772 7.44772 2 8 2H16C16.5523 2 17 2.44772 17 3V6ZM18 8H6V20H18V8ZM13.4142 13.9997L15.182 15.7675L13.7678 17.1817L12 15.4139L10.2322 17.1817L8.81802 15.7675L10.5858 13.9997L8.81802 12.232L10.2322 10.8178L12 12.5855L13.7678 10.8178L15.182 12.232L13.4142 13.9997ZM9 4V6H15V4H9Z"></path></svg>
								</div>
							</div>
						})}
						{attachmentList?.map((obj, index) => {
							return <div key={index} className="flex w-full flex-row jusity-center item-center h-full">
								<svg xmlns="http://www.w3.org/2000/svg" width="48px" height="48px" fill="#1C49D0ff" viewBox="0 0 24 24"><path d="M7.782 14.576c-.186 0-.312.018-.377.036v1.193c.077.018.174.023.306.023.485 0 .785-.246.785-.659 0-.371-.258-.593-.714-.593z" /><path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8l-6-6zM9.03 16.105c-.313.293-.774.426-1.313.426-.12 0-.229-.007-.312-.019v1.445h-.906V13.97a7.504 7.504 0 0 1 1.235-.083c.563 0 .966.107 1.235.323.258.204.432.54.432.936s-.131.731-.371.959zm4.302 1.853h-.96l-.863-1.56c-.24-.432-.504-.953-.701-1.427l-.019.006c.024.534.036 1.104.036 1.763v1.218h-.84v-4.042h1.067l.84 1.481c.24.426.479.93.659 1.385h.019a14.746 14.746 0 0 1-.078-1.685v-1.182h.84v4.043zm4.169-.186a4.512 4.512 0 0 1-1.349.228c-.737 0-1.271-.186-1.644-.546-.371-.348-.575-.875-.569-1.469.006-1.344.983-2.111 2.309-2.111.521 0 .924.103 1.121.198l-.191.731c-.222-.096-.498-.174-.941-.174-.762 0-1.338.432-1.338 1.308 0 .833.522 1.325 1.271 1.325.21 0 .378-.024.45-.061v-.846h-.624v-.713h1.505v2.13zM14 9h-1V4l5 5h-4z" /></svg>
								<div className="w-1/2 m-2  pt-1">
									<div className="mb-2 flex w-full items-center justify-between text-xs mr-8">
										<div onClick={() => downloadAttachment(obj?.fileKey)}
											className="cursor-pointer text-gray-600">{obj?.filename}</div>
										<div className="text-gray-600">100%</div>
									</div>
									<div className="flex flex-row w-full justify-center item-center">
										<div className="w-full mb-4 flex h-2 overflow-hidden rounded bg-gray-100 text-xs ">
											<div className="bg-green-500 w-full"></div>
										</div>
									</div>
								</div>
								<div onClick={() => handleDeleteAttachment(obj, index, 'attached')}
									className="m-1 p-2 cursor-pointer rounded  text-black h-7" >
									<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="currentColor"><path d="M17 6H22V8H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V8H2V6H7V3C7 2.44772 7.44772 2 8 2H16C16.5523 2 17 2.44772 17 3V6ZM18 8H6V20H18V8ZM13.4142 13.9997L15.182 15.7675L13.7678 17.1817L12 15.4139L10.2322 17.1817L8.81802 15.7675L10.5858 13.9997L8.81802 12.232L10.2322 10.8178L12 12.5855L13.7678 10.8178L15.182 12.232L13.4142 13.9997ZM9 4V6H15V4H9Z"></path></svg>
								</div>
							</div>
						})
						}

					</div >
				</div>
				{isEditIssue && <div className="w-1/5 max-h-[75vh] overflow-y-auto overflow-x-hidden mx-2 bg-white rounded shawdow-xl flex justify-start item-start flex-col">
					<div className="flex flex-row space-x-2 m-4 mx-5">
						<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#1C49D0FF" viewBox="0 0 24 24"><path d="M19.903 8.586a.997.997 0 0 0-.196-.293l-6-6a.997.997 0 0 0-.293-.196c-.03-.014-.062-.022-.094-.033a.991.991 0 0 0-.259-.051C13.04 2.011 13.021 2 13 2H6c-1.103 0-2 .897-2 2v16c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V9c0-.021-.011-.04-.013-.062a.952.952 0 0 0-.051-.259c-.01-.032-.019-.063-.033-.093zM16.586 8H14V5.414L16.586 8zM6 20V4h6v5a1 1 0 0 0 1 1h5l.002 10H6z" /><path d="M8 12h8v2H8zm0 4h8v2H8zm0-8h2v2H8z" /></svg>
						<div className="text-[18px] text-[#323842FF] font-semibold ">Post-Fix-Image </div>
					</div>
					<div className="flex mx-5 p-4" >
						<div className="flex justify-center   w-[250px]  px-4 py-2 transition bg-[#1C49D01A] border-x border-y border-[#171A1FFF] border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none">
							<span className="flex items-center justify-center flex-col space-x-2">
								<svg className="w-[60px] h-[60px] text-[#1C49D0FF]" fill="#1C49D0FF" viewBox="0 0 256 256"><path d="M248,128a87.34,87.34,0,0,1-17.6,52.81,8,8,0,1,1-12.8-9.62A71.34,71.34,0,0,0,232,128a72,72,0,0,0-144,0,8,8,0,0,1-16,0,88,88,0,0,1,3.29-23.88C74.2,104,73.1,104,72,104a48,48,0,0,0,0,96H96a8,8,0,0,1,0,16H72A64,64,0,1,1,81.29,88.68,88,88,0,0,1,248,128Zm-90.34-5.66a8,8,0,0,0-11.32,0l-32,32a8,8,0,0,0,11.32,11.32L144,147.31V208a8,8,0,0,0,16,0V147.31l18.34,18.35a8,8,0,0,0,11.32-11.32Z"></path></svg>
								<span
									onClick={() => handleBrowseAttachementFile1()}
									className="bg-[#1C49D0FF] my-2  text-[14px] font-[400]  text-white px-2 py-2 rounded text-xs ">
									Browse File
								</span>
							</span>
							<input type="file" id='attachmentfile1' className="hidden" multiple name="attachment_upload" onChange={(e) => handleFileAttachmentChange1(e)} />
						</div>
					</div>
					<div className="flex flex-col justify-start item-center w-full mx-5 px-5 h-full pb-20" >
						<div className="flex flex-row space-x-2 m-4">
							<div className="text-xs font-normal  text-black text-opacity-60 ">Uploading files - {attachmentList1?.length}</div>
						</div>
						{newAttachmentList1?.map((obj, index) => {
							return <div key={index} className="flex w-full flex-row jusity-center item-center h-full">
								<svg
									xmlns="http://www.w3.org/2000/svg" width="48px" height="48px"
									fill="#1C49D0ff" viewBox="0 0 24 24">
									<path d="M7.782 14.576c-.186 0-.312.018-.377.036v1.193c.077.018.174.023.306.023.485 0 .785-.246.785-.659 0-.371-.258-.593-.714-.593z" /><path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8l-6-6zM9.03 16.105c-.313.293-.774.426-1.313.426-.12 0-.229-.007-.312-.019v1.445h-.906V13.97a7.504 7.504 0 0 1 1.235-.083c.563 0 .966.107 1.235.323.258.204.432.54.432.936s-.131.731-.371.959zm4.302 1.853h-.96l-.863-1.56c-.24-.432-.504-.953-.701-1.427l-.019.006c.024.534.036 1.104.036 1.763v1.218h-.84v-4.042h1.067l.84 1.481c.24.426.479.93.659 1.385h.019a14.746 14.746 0 0 1-.078-1.685v-1.182h.84v4.043zm4.169-.186a4.512 4.512 0 0 1-1.349.228c-.737 0-1.271-.186-1.644-.546-.371-.348-.575-.875-.569-1.469.006-1.344.983-2.111 2.309-2.111.521 0 .924.103 1.121.198l-.191.731c-.222-.096-.498-.174-.941-.174-.762 0-1.338.432-1.338 1.308 0 .833.522 1.325 1.271 1.325.21 0 .378-.024.45-.061v-.846h-.624v-.713h1.505v2.13zM14 9h-1V4l5 5h-4z" /></svg>
								<div className="w-1/2 m-2  pt-1">
									<div className="mb-2 flex w-full items-center justify-between text-xs mr-8">
										<div onClick={() => downloadAttachment1(obj?.fileKey)}
											className="cursor-pointer text-gray-600">{obj?.filename}</div>
										<div className="text-gray-600">100%</div>
									</div>
									<div className="flex flex-row w-full justify-center item-center">
										<div className="w-full mb-4 flex h-2 overflow-hidden rounded bg-gray-100 text-xs ">
											<div className="bg-green-500 w-full"></div>
										</div>
									</div>
								</div>
								<div onClick={() => handleDeleteAttachment1(obj, index, 'uploading')}
									className="m-1 p-2 cursor-pointer rounded  text-black h-7" >
									<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="currentColor"><path d="M17 6H22V8H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V8H2V6H7V3C7 2.44772 7.44772 2 8 2H16C16.5523 2 17 2.44772 17 3V6ZM18 8H6V20H18V8ZM13.4142 13.9997L15.182 15.7675L13.7678 17.1817L12 15.4139L10.2322 17.1817L8.81802 15.7675L10.5858 13.9997L8.81802 12.232L10.2322 10.8178L12 12.5855L13.7678 10.8178L15.182 12.232L13.4142 13.9997ZM9 4V6H15V4H9Z"></path></svg>
								</div>
							</div>
						})}
						{attachmentList1?.map((obj, index) => {
							return <div key={index} className="flex w-full flex-row jusity-center item-center h-full">
								<svg xmlns="http://www.w3.org/2000/svg" width="48px" height="48px" fill="#1C49D0ff" viewBox="0 0 24 24"><path d="M7.782 14.576c-.186 0-.312.018-.377.036v1.193c.077.018.174.023.306.023.485 0 .785-.246.785-.659 0-.371-.258-.593-.714-.593z" /><path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8l-6-6zM9.03 16.105c-.313.293-.774.426-1.313.426-.12 0-.229-.007-.312-.019v1.445h-.906V13.97a7.504 7.504 0 0 1 1.235-.083c.563 0 .966.107 1.235.323.258.204.432.54.432.936s-.131.731-.371.959zm4.302 1.853h-.96l-.863-1.56c-.24-.432-.504-.953-.701-1.427l-.019.006c.024.534.036 1.104.036 1.763v1.218h-.84v-4.042h1.067l.84 1.481c.24.426.479.93.659 1.385h.019a14.746 14.746 0 0 1-.078-1.685v-1.182h.84v4.043zm4.169-.186a4.512 4.512 0 0 1-1.349.228c-.737 0-1.271-.186-1.644-.546-.371-.348-.575-.875-.569-1.469.006-1.344.983-2.111 2.309-2.111.521 0 .924.103 1.121.198l-.191.731c-.222-.096-.498-.174-.941-.174-.762 0-1.338.432-1.338 1.308 0 .833.522 1.325 1.271 1.325.21 0 .378-.024.45-.061v-.846h-.624v-.713h1.505v2.13zM14 9h-1V4l5 5h-4z" /></svg>
								<div className="w-1/2 m-2  pt-1">
									<div className="mb-2 flex w-full items-center justify-between text-xs mr-8">
										<div onClick={() => downloadAttachment1(obj?.fileKey)}
											className="cursor-pointer text-gray-600">{obj?.filename}</div>
										<div className="text-gray-600">100%</div>
									</div>
									<div className="flex flex-row w-full justify-center item-center">
										<div className="w-full mb-4 flex h-2 overflow-hidden rounded bg-gray-100 text-xs ">
											<div className="bg-green-500 w-full"></div>
										</div>
									</div>
								</div>
								<div onClick={() => handleDeleteAttachment1(obj, index, 'attached')}
									className="m-1 p-2 cursor-pointer rounded  text-black h-7" >
									<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="currentColor"><path d="M17 6H22V8H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V8H2V6H7V3C7 2.44772 7.44772 2 8 2H16C16.5523 2 17 2.44772 17 3V6ZM18 8H6V20H18V8ZM13.4142 13.9997L15.182 15.7675L13.7678 17.1817L12 15.4139L10.2322 17.1817L8.81802 15.7675L10.5858 13.9997L8.81802 12.232L10.2322 10.8178L12 12.5855L13.7678 10.8178L15.182 12.232L13.4142 13.9997ZM9 4V6H15V4H9Z"></path></svg>
								</div>
							</div>
						})
						}
					</div>
				</div>}
			</div >
		</div >
	);
}

