
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash'
import Confirmation from "../../modals/Confirmation";
import AddBedrooms from '../../modals/AddBedrooms';
import { crm } from "../../redux/crm/actions";
import AddTypeOfHouse from '../../modals/AddTypeOfHouse';
import AddProjectType from '../../modals/AddProjectType';
import AddProjectPhase from '../../modals/AddProjectPhase';
import { projecttype } from '../../redux/projecttype/actions';
import { projectstatus } from '../../redux/projectstatus/actions';
import { inspectionstatus } from '../../redux/inspectionstatus/actions';
import { inspectionstage } from '../../redux/inspectionstage/actions';
import { typeofhouse } from '../../redux/typeofhouse/actions';
import { numberofbedrooms } from '../../redux/numberofbedrooms/actions';
import { issuechangetype } from '../../redux/issuechangetype/actions';
import { selectCurrentCRM } from '../../redux/crm/selectors';
import { selectListItems as selectProjectStatusList } from '../../redux/projectstatus/selectors';
import { selectListItems as selectInspectionStageList } from '../../redux/inspectionstage/selectors';
import { selectListItems as selectInspectionStatusList } from '../../redux/inspectionstatus/selectors';
import { selectListItems as selectIssueStatusList } from '../../redux/issuestatus/selectors';
import { selectListItems as selectIssuePriorityList } from '../../redux/issuepriority/selectors';
import { selectListItems as selectRolesList } from '../../redux/roles/selectors';
import { selectListItems } from '../../redux/projecttype/selectors';
import { selectListItems as selectIssueChangeTypeList } from '../../redux/issuechangetype/selectors';
import { selectListItems as selectTypeOfHouseList } from '../../redux/typeofhouse/selectors';
import { selectListItems as selectNumberOfBedroomsList } from '../../redux/numberofbedrooms/selectors';
import { selectListItems as selectPermissionList } from '../../redux/permissions/selectors';
import { issuestatus } from '../../redux/issuestatus/actions';
import { issuepriority } from '../../redux/issuepriority/actions';
import { roles } from '../../redux/roles/actions';
import AddInspectionStage from '../../modals/AddInspectionStage';
import AddInspectionStatus from '../../modals/AddInspectionStatus';
import AddIssueStatus from '../../modals/AddIssueStatus';
import AddIssuePriority from '../../modals/AddIssuePriority';
import AddRoles from '../../modals/AddRoles';
import { permissions } from '../../redux/permissions/actions';
import AddPermissions from '../../modals/AddPermissions';
import { db } from '../../services/db.js'
import { useLiveQuery } from "dexie-react-hooks";
import AddIssueChangeType from '../../modals/AddIssueChangeType';


const getOnLineStatus = () =>
	typeof navigator !== 'undefined' && typeof navigator.onLine === 'boolean'
		? navigator.onLine
		: true;
export default function MasterSettings() {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const [status, setStatus] = useState(getOnLineStatus());
	const dbcache = useLiveQuery(() => db.permission_table.toArray(), []);
	const setOffline = () => setStatus(false);
	const setOnline = () => setStatus(true);
	const { reloadProjectType, reloadTypeOfHouse, reloadPermission,
		reloadBedrooms, reloadProjectPhase, reloadIssuePriority, reloadRole, reloadIssueStatus,
		reloadInspectionStage, reloadProjectStatus, reloadIssueChangeType, reloadInspectionStatus, isSideBarOpen } = useSelector(selectCurrentCRM);

	const { result } = useSelector(selectListItems);
	const { result: result1 } = useSelector(selectTypeOfHouseList);
	const { result: result2 } = useSelector(selectNumberOfBedroomsList);
	const { result: projectStatusResult } = useSelector(selectProjectStatusList)
	const { result: inspectionStatusResult } = useSelector(selectInspectionStatusList)
	const { result: inspectionStageResult } = useSelector(selectInspectionStageList)
	const { result: issueStatusResult } = useSelector(selectIssueStatusList)
	const { result: issuePriorityResult } = useSelector(selectIssuePriorityList)
	const { result: rolesResult } = useSelector(selectRolesList)
	const { result: permissionsResult } = useSelector(selectPermissionList)
	const { result: issueChangeTypeResult } = useSelector(selectIssueChangeTypeList)
	const { items } = result;
	const { items: issueChangeTypeItems } = issueChangeTypeResult;
	const { items: _items } = result1;
	const { items: __items } = result2;
	const { items: projectStatusItems } = projectStatusResult;
	const { items: inspectionStatusItems } = inspectionStatusResult;
	const { items: issueStatusItems } = issueStatusResult;
	const { items: inspectionStageItems } = inspectionStageResult;
	const { items: issuePriorityItems } = issuePriorityResult;
	const { items: rolesItems } = rolesResult
	const _rolesItems = _.orderBy(rolesItems, [item => item.displayIndex], ['asc']);
	const { items: permissionsItems } = permissionsResult

	const handleAddBedrooms = () => {
		dispatch(crm.trigger({ addBedrooms: 'open', isBedroomsEdit: false, bedroomsItem: '' }))
	}

	useEffect(() => {
		window.scrollTo(0, 0);
		window.addEventListener('online', setOnline);
		window.addEventListener('offline', setOffline);
		return () => {
			window.removeEventListener('online', setOnline);
			window.removeEventListener('offline', setOffline);
		};
	}, [])

	useEffect(() => {
		if (status) {
			db.permission_table.count().then((count) => {
				dbcache?.map((obj) => {
					db.permission_table.delete(obj.id)
					dispatch(permissions.create({
						entity: 'permissions', jsonData: obj?.data
					})).then(() => {
						//if (isSuccess) {
						dispatch(crm.trigger({ reloadPermission: true, addPermission: 'close' }))
						//}
					})
				})
			})
		}
	}, [status])

	useEffect(() => {
		if (reloadProjectType) {
			dispatch(projecttype.list({ entity: 'PDI_Project_Configuration' }))
			dispatch(crm.trigger({ reloadProjectType: false }))
		}
	}, [reloadProjectType])

	useEffect(() => {
		if (reloadIssueChangeType) {
			dispatch(issuechangetype.list({ entity: 'PDI_Issue_Change_Type' }))
			dispatch(crm.trigger({ reloadIssueChangeType: false }))
		}
	}, [reloadIssueChangeType])

	useEffect(() => {
		if (reloadTypeOfHouse) {
			dispatch(typeofhouse.list({ entity: 'PDI_Type_Of_House' }))
			dispatch(crm.trigger({ reloadTypeOfHouse: false }))
		}
	}, [reloadTypeOfHouse])

	useEffect(() => {
		if (reloadInspectionStatus) {
			dispatch(inspectionstatus.list({ entity: 'PDI_Inspection_Status' }))
			dispatch(crm.trigger({ reloadInspectionStatus: false }))
		}
	}, [reloadInspectionStatus])

	useEffect(() => {
		if (reloadIssueStatus) {
			dispatch(issuestatus.list({ entity: 'PDI_Issue_Status' }))
			dispatch(crm.trigger({ reloadIssueStatus: false }))
		}
	}, [reloadIssueStatus])

	useEffect(() => {
		if (reloadPermission) {
			dispatch(permissions.list({ entity: 'permissions' }))
			dispatch(crm.trigger({ reloadPermission: false }))
		}
	}, [reloadPermission])

	useEffect(() => {
		if (reloadRole) {
			dispatch(roles.list({ entity: 'roles' }))
			dispatch(crm.trigger({ reloadRole: false }))
		}
	}, [reloadRole])

	useEffect(() => {
		if (reloadIssuePriority) {
			dispatch(issuepriority.list({ entity: 'PDI_Issue_Priority' }))
			dispatch(crm.trigger({ reloadIssuePriority: false }))
		}
	}, [reloadIssuePriority])

	useEffect(() => {
		if (reloadBedrooms) {
			dispatch(numberofbedrooms.list({ entity: 'PDI_Number_Of_Bedrooms' }))
			dispatch(crm.trigger({ reloadBedrooms: false }))
		}
	}, [reloadBedrooms])

	useEffect(() => {
		if (reloadProjectStatus) {
			dispatch(projectstatus.list({ entity: 'PDI_Project_Status' }))
			dispatch(crm.trigger({ reloadProjectStatus: false }))
		}
	}, [reloadProjectStatus])

	useEffect(() => {
		if (reloadInspectionStage) {
			dispatch(inspectionstage.list({ entity: 'PDI_Inspection_Stage' }))
			dispatch(crm.trigger({ reloadInspectionStage: false }))
		}
	}, [reloadInspectionStage])

	const handleAddProjectType = () => {
		dispatch(crm.trigger({ addProjectType: 'open', isProjectTypeEdit: false, projectTypeItem: '' }))
	}

	const handleAddPermission = () => {
		dispatch(crm.trigger({ addPermission: 'open', isEditPermission: false, permissionItem: '' }))
	}

	const handleAddIssueStatus = () => {
		dispatch(crm.trigger({ addIssueStatus: 'open', isEditIssueStatus: false, issueStatusItem: '' }))
	}

	const handleAddIssuePriority = () => {
		dispatch(crm.trigger({ addIssuePriority: 'open', isEditIssuePriority: false, issuePriorityItem: '' }))
	}

	const handleAddRole = () => {
		dispatch(crm.trigger({ addRole: 'open', isEditRole: false, roleItem: '' }))
	}

	const handleAddProjectPhase = () => {
		dispatch(crm.trigger({ addProjectPhase: 'open', isProjectPhaseEdit: false, projectPhaseItem: '' }))
	}

	const handleAddInspectionStatus = () => {
		dispatch(crm.trigger({ addInspectionStatus: 'open', isInspectionStatusEdit: false, inspectionStatusItem: '' }))
	}

	const handleAddTypeOfHouse = () => {
		dispatch(crm.trigger({ addTypeOfHouse: 'open', isTypeOfHouseEdit: false, typeOfHouseItem: '' }))
	}

	const handleAddInspectionStage = () => {
		dispatch(crm.trigger({ addInspectionStage: 'open', isInspectionStageEdit: false, inspectionStageItem: '' }))
	}

	const handleAddIssueChangeType = () => {
		dispatch(crm.trigger({ addIssueChangeType: 'open', isIssueChangeTypeEdit: false, issueChangeTypeItem: '' }))
	}

	const handleEditBedrooms = (id, data) => {
		dispatch(crm.trigger({ addBedrooms: 'open', isBedroomsEdit: true, bedroomsItem: data, activeBedroomsId: id }))
	}

	const handleEditPermission = (id, data) => {
		dispatch(crm.trigger({ addPermission: 'open', isEditPermission: true, permissionItem: data, activePermissionId: id }))
	}

	const handleEditRole = (id, data) => {
		dispatch(crm.trigger({ addRole: 'open', isEditRole: true, roleItem: data, activeRoleId: id }))
	}

	const handleEditIssueStatus = (id, data) => {
		dispatch(crm.trigger({ addIssueStatus: 'open', isEditIssueStatus: true, issueStatusItem: data, activeIssueStatusId: id }))
	}

	const handleEditIssuePriority = (id, data) => {
		dispatch(crm.trigger({ addIssuePriority: 'open', isEditIssuePriority: true, issuePriorityItem: data, activeIssuePriorityId: id }))
	}

	const handleEditInspectionStatus = (id, data) => {
		dispatch(crm.trigger({ addInspectionStatus: 'open', isInspectionStatusEdit: true, inspectionStatusItem: data, activeInspectionStatusId: id }))
	}

	const handleEditProjectType = (id, data) => {
		dispatch(crm.trigger({ addProjectType: 'open', isProjectTypeEdit: true, projectTypeItem: data, activeProjectTypeId: id }))
	}

	const handleEditProjectPhase = (id, data) => {
		dispatch(crm.trigger({ addProjectPhase: 'open', isProjectPhaseEdit: true, projectPhaseItem: data, activeProjectPhaseId: id }))
	}

	const handleEditInspectionStage = (id, data) => {
		dispatch(crm.trigger({ addInspectionStage: 'open', isInspectionStageEdit: true, inspectionStageItem: data, activeInspectionStageId: id }))
	}

	const handleEditTypeOfHouse = (id, data) => {
		dispatch(crm.trigger({ addTypeOfHouse: 'open', isTypeOfHouseEdit: true, typeOfHouseItem: data, activeTypeOfHouseId: id }))
	}

	const handleEditIssueChangeType = (id, data) => {
		dispatch(crm.trigger({ addIssueChangeType: 'open', isIssueChangeTypeEdit: true, issueChangeTypeItem: data, activeIssueChangeTypeId: id }))
	}

	const handleDeleteProjectType = (id) => {
		dispatch(crm.trigger({ deleteConfirmation: 'open', deleteMessage: 'Are you sure you want to delete this item ?', deleteItem: { id: id, entity: 'PDI_Project_Configuration' } }))
	}

	const handleDeleteIssueStatus = (id) => {
		dispatch(crm.trigger({ deleteConfirmation: 'open', deleteMessage: 'Are you sure you want to delete this item ?', deleteItem: { id: id, entity: 'PDI_Issue_Status' } }))
	}

	const handleDeleteRole = (id) => {
		dispatch(crm.trigger({ deleteConfirmation: 'open', deleteMessage: 'Are you sure you want to delete this item ?', deleteItem: { id: id, entity: 'roles' } }))
	}

	const handleDeletePermission = (id) => {
		dispatch(crm.trigger({ deleteConfirmation: 'open', deleteMessage: 'Are you sure you want to delete this item ?', deleteItem: { id: id, entity: 'permissions' } }))
	}

	const handleDeleteIssuePriority = (id) => {
		dispatch(crm.trigger({ deleteConfirmation: 'open', deleteMessage: 'Are you sure you want to delete this item ?', deleteItem: { id: id, entity: 'PDI_Issue_Priority' } }))
	}

	const handleDeleteProjectPhase = (id) => {
		dispatch(crm.trigger({ deleteConfirmation: 'open', deleteMessage: 'Are you sure you want to delete this item ?', deleteItem: { id: id, entity: 'PDI_Project_Status' } }))
	}
	const handleDeleteInspectionStage = (id) => {
		dispatch(crm.trigger({ deleteConfirmation: 'open', deleteMessage: 'Are you sure you want to delete this item ?', deleteItem: { id: id, entity: 'PDI_Inspection_Stage' } }))
	}
	const handleDeleteInspectionStatus = (id) => {
		dispatch(crm.trigger({ deleteConfirmation: 'open', deleteMessage: 'Are you sure you want to delete this item ?', deleteItem: { id: id, entity: 'PDI_Inspection_Status' } }))
	}

	const handleDeleteTypeOfHouse = (id) => {
		dispatch(crm.trigger({ deleteConfirmation: 'open', deleteMessage: 'Are you sure you want to delete this item ?', deleteItem: { id: id, entity: 'PDI_Type_Of_House' } }))
	}

	const handleDeleteNumberOfBedrooms = (id) => {
		dispatch(crm.trigger({ deleteConfirmation: 'open', deleteMessage: 'Are you sure you want to delete this item ?', deleteItem: { id: id, entity: 'PDI_Number_Of_Bedrooms' } }))
	}

	const handleDeleteIssueChangeType = (id) => {
		dispatch(crm.trigger({ deleteConfirmation: 'open', deleteMessage: 'Are you sure you want to delete this item ?', deleteItem: { id: id, entity: 'PDI_Issue_Change_Type' } }))
	}

	useEffect(() => {
		dispatch(projecttype.list({ entity: 'PDI_Project_Configuration' }))
		dispatch(issuepriority.list({ entity: 'PDI_Issue_Priority' }))
		dispatch(issuestatus.list({ entity: 'PDI_Issue_Status' }))
		dispatch(inspectionstage.list({ entity: 'PDI_Inspection_Stage' }))
		dispatch(inspectionstatus.list({ entity: 'PDI_Inspection_Status' }))
		dispatch(projectstatus.list({ entity: 'PDI_Project_Status' }))
		dispatch(typeofhouse.list({ entity: 'PDI_Type_Of_House' }))
		dispatch(numberofbedrooms.list({ entity: 'PDI_Number_Of_Bedrooms' }))
		dispatch(issuechangetype.list({ entity: 'PDI_Issue_Change_Type' }))
		dispatch(roles.list({ entity: 'roles' }))
		dispatch(permissions.list({ entity: 'permissions' }))
	}, [])

	return <div className="flex flex-col justify-center items-center bg-[#F9F9FAFF]" >
		<header className="flex justify-between h-24 w-full px-6  items-center bg-[#F9F9FAFF]">
			<div className="flex flex-col justify-start w-full" >
				<nav aria-label="breadcrumb" className="w-max" >
					<ol className="flex flex-wrap items-center w-full px-3 py-1 rounded-md ">
						<li
							className="flex items-center font-sans text-sm antialiased font-Editrmal leading-Editrmal transition-colors duration-300 cursor-pointer text-blue-gray-900 hover:text-light-blue-500">
							<h3 className="text-xl font-bold">Master Settings</h3>
						</li>
					</ol>
				</nav>
				<div className="flex justify-start w-full py-2" >
					<button onClick={() => navigate('/inspection-crm/settings')} className="w-auto mx-1 button     text-black  text-opacity-40  px-3 rounded  text-xs font-bold"
					> Settings
					</button>
					<button onClick={() => navigate('/inspection-crm/category')} className="w-auto mx-1 button     text-black  text-opacity-40  px-3 rounded  text-xs font-bold"
					> Category
					</button>
					<button onClick={() => navigate('/inspection-crm/master-settings')} className="w-auto button rounded  text-blue-600   px-3 rounded text-xs font-bold"
					> Master Settings
					</button>

				</div>
			</div>
		</header>
		<Confirmation />
		<AddBedrooms />
		<AddTypeOfHouse />
		<AddProjectType />
		<AddProjectPhase />
		<AddInspectionStage />
		<AddInspectionStatus />
		<AddIssueStatus />
		<AddIssuePriority />
		<AddRoles />
		<AddPermissions />
		<AddIssueChangeType />
		<Confirmation />
		<div className="flex flex-row w-full  h-[600px] bg-[#F9F9FAFF] overflow-auto ">
			<div className="flex flex-col  h-max  ">
				<div className="flex flex-row mx-4 justify-start items-start text-xs">
					<div className="rounded shadow-xl bg-white mx-4 py-4 px-6 h-full">
						<div className="flex flex-row justify-between items-center">
							<div className="py-4 text-[14px] font-bold px-3 ">
								Project Type
							</div>
							<span
								className="cursor-pointer px-2 py-1 text-center text-blue-900 font-bold text-[9px] bg-[#F2F3FDFF] border-x border-y rounded-xl"
								onClick={() => handleAddProjectType()}	>Add More
							</span>
						</div>
						<div className="felx flex-row space-y-3 p-3 border-y border-black">
							{items.map((obj) => {
								return <div className="flex flex-row justify-around item-center  space-x-5 border-black" >
									<div className="w-28">{obj.Name}</div>
									<div className="flex flex-row justify-center item-center space-x-2 ">
										<div className="cursor-pointer flex flex-row justify-between item-center px-2  border-x border-y border-color rounded-xl">
											<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24"><path d="M19.045 7.401c.378-.378.586-.88.586-1.414s-.208-1.036-.586-1.414l-1.586-1.586c-.378-.378-.88-.586-1.414-.586s-1.036.208-1.413.585L4 13.585V18h4.413L19.045 7.401zm-3-3 1.587 1.585-1.59 1.584-1.586-1.585 1.589-1.584zM6 16v-1.585l7.04-7.018 1.586 1.586L7.587 16H6zm-2 4h16v2H4z" /></svg>
											<span
												onClick={() => handleEditProjectType(obj._id, obj.Name)}
												className="text-center mx-1 text-[9px]  font-semibold  ">
												Edit
											</span>
										</div>
										<div className="cursor-pointer" onClick={() => handleDeleteProjectType(obj._id)}>
											<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="currentColor"><path d="M17 6H22V8H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V8H2V6H7V3C7 2.44772 7.44772 2 8 2H16C16.5523 2 17 2.44772 17 3V6ZM18 8H6V20H18V8ZM13.4142 13.9997L15.182 15.7675L13.7678 17.1817L12 15.4139L10.2322 17.1817L8.81802 15.7675L10.5858 13.9997L8.81802 12.232L10.2322 10.8178L12 12.5855L13.7678 10.8178L15.182 12.232L13.4142 13.9997ZM9 4V6H15V4H9Z"></path></svg>
										</div>
									</div>
								</div>
							})}
						</div>
					</div>
					<div className="rounded shadow-xl bg-white mx-4 py-4 px-6 h-full">
						<div className="flex flex-row justify-between items-center">
							<div className="py-4 text-[14px] font-bold px-3 ">
								Type of House
							</div>
							<span
								className="cursor-pointer px-2 py-1 text-center text-blue-900 font-bold text-[9px] bg-[#F2F3FDFF] border-x border-y rounded-xl"
								onClick={() => handleAddTypeOfHouse()}	>Add More
							</span>
						</div>
						<div className="felx flex-row space-y-3 p-3 border-y border-black">
							{_items.map((obj) => {
								return <div className="flex flex-row justify-around item-center  space-x-5 border-black" >
									<div className="w-28">{obj.Name}</div>
									<div className="flex flex-row justify-center item-center space-x-2 ">
										<div className="cursor-pointer flex flex-row justify-between item-center px-2  border-x border-y border-color rounded-xl">
											<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24"><path d="M19.045 7.401c.378-.378.586-.88.586-1.414s-.208-1.036-.586-1.414l-1.586-1.586c-.378-.378-.88-.586-1.414-.586s-1.036.208-1.413.585L4 13.585V18h4.413L19.045 7.401zm-3-3 1.587 1.585-1.59 1.584-1.586-1.585 1.589-1.584zM6 16v-1.585l7.04-7.018 1.586 1.586L7.587 16H6zm-2 4h16v2H4z" /></svg>
											<span
												onClick={() => handleEditTypeOfHouse(obj._id, obj.Name)}
												className="text-center mx-1 text-[9px]  font-semibold  ">
												Edit
											</span>
										</div>
										<div className="cursor-pointer" onClick={() => handleDeleteTypeOfHouse(obj._id)}>
											<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="currentColor"><path d="M17 6H22V8H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V8H2V6H7V3C7 2.44772 7.44772 2 8 2H16C16.5523 2 17 2.44772 17 3V6ZM18 8H6V20H18V8ZM13.4142 13.9997L15.182 15.7675L13.7678 17.1817L12 15.4139L10.2322 17.1817L8.81802 15.7675L10.5858 13.9997L8.81802 12.232L10.2322 10.8178L12 12.5855L13.7678 10.8178L15.182 12.232L13.4142 13.9997ZM9 4V6H15V4H9Z"></path></svg>
										</div>
									</div>
								</div>
							})}
						</div>
					</div>
					<div className="rounded shadow-xl bg-white mx-4 py-4 px-6 h-full">
						<div className="flex flex-row justify-between items-center">
							<div className="py-4 text-[14px] font-bold px-3 ">
								No. of Bedrooms
							</div>
							<span
								className="px-2 py-1 text-center text-blue-900 font-bold text-[9px] bg-[#F2F3FDFF] border-x border-y rounded-xl cursor-pointer"
								onClick={() => handleAddBedrooms()}	>Add More
							</span>
						</div>
						<div className="felx flex-row space-y-3 p-3 border-y border-black">
							{__items.map((obj) => {
								return <div className="flex flex-row justify-around item-center  space-x-5 border-black" >
									<div className="w-28">{obj.Name}</div>
									<div className="flex flex-row justify-center item-center space-x-2 ">
										<div className="cursor-pointer flex flex-row justify-between item-center px-2  border-x border-y border-color rounded-xl">
											<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24"><path d="M19.045 7.401c.378-.378.586-.88.586-1.414s-.208-1.036-.586-1.414l-1.586-1.586c-.378-.378-.88-.586-1.414-.586s-1.036.208-1.413.585L4 13.585V18h4.413L19.045 7.401zm-3-3 1.587 1.585-1.59 1.584-1.586-1.585 1.589-1.584zM6 16v-1.585l7.04-7.018 1.586 1.586L7.587 16H6zm-2 4h16v2H4z" /></svg>
											<span
												onClick={() => handleEditBedrooms(obj._id, obj.Name)}
												className=" text-center mx-1 text-[9px]  font-semibold  ">
												Edit
											</span>
										</div>
										<div className="cursor-pointer" onClick={() => handleDeleteNumberOfBedrooms(obj._id)}>
											<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="currentColor"><path d="M17 6H22V8H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V8H2V6H7V3C7 2.44772 7.44772 2 8 2H16C16.5523 2 17 2.44772 17 3V6ZM18 8H6V20H18V8ZM13.4142 13.9997L15.182 15.7675L13.7678 17.1817L12 15.4139L10.2322 17.1817L8.81802 15.7675L10.5858 13.9997L8.81802 12.232L10.2322 10.8178L12 12.5855L13.7678 10.8178L15.182 12.232L13.4142 13.9997ZM9 4V6H15V4H9Z"></path></svg>
										</div>
									</div>
								</div>
							})}
						</div>
					</div>
				</div>
				<div className="flex flex-row mx-4 justify-start items-start text-xs  my-4">
					<div className="rounded shadow-xl bg-white mx-4 py-4 px-6 h-full">
						<div className="flex flex-row justify-between items-center">
							<div className="py-4 text-[14px] font-bold px-3 ">
								Project Phase
							</div>
							<span
								className="cursor-pointer px-2 py-1 text-center text-blue-900 font-bold text-[9px] bg-[#F2F3FDFF] border-x border-y rounded-xl"
								onClick={() => handleAddProjectPhase()}	>Add More
							</span>
						</div>
						<div className="felx flex-row space-y-3 p-3 border-y border-black">
							{projectStatusItems.map((obj) => {
								return <div className="flex flex-row justify-around item-center  space-x-5 border-black" >
									<div className="w-28 truncate ...">{obj.Name}</div>
									<div className="flex flex-row justify-center item-center space-x-2 ">
										<div className="cursor-pointer flex flex-row justify-between item-center px-2  border-x border-y border-color rounded-xl">
											<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24"><path d="M19.045 7.401c.378-.378.586-.88.586-1.414s-.208-1.036-.586-1.414l-1.586-1.586c-.378-.378-.88-.586-1.414-.586s-1.036.208-1.413.585L4 13.585V18h4.413L19.045 7.401zm-3-3 1.587 1.585-1.59 1.584-1.586-1.585 1.589-1.584zM6 16v-1.585l7.04-7.018 1.586 1.586L7.587 16H6zm-2 4h16v2H4z" /></svg>
											<span
												onClick={() => handleEditProjectPhase(obj._id, obj.Name)}
												className="text-center mx-1 text-[9px]  font-semibold  ">
												Edit
											</span>
										</div>
										<div className="cursor-pointer" onClick={() => handleDeleteProjectPhase(obj._id)}>
											<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="currentColor"><path d="M17 6H22V8H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V8H2V6H7V3C7 2.44772 7.44772 2 8 2H16C16.5523 2 17 2.44772 17 3V6ZM18 8H6V20H18V8ZM13.4142 13.9997L15.182 15.7675L13.7678 17.1817L12 15.4139L10.2322 17.1817L8.81802 15.7675L10.5858 13.9997L8.81802 12.232L10.2322 10.8178L12 12.5855L13.7678 10.8178L15.182 12.232L13.4142 13.9997ZM9 4V6H15V4H9Z"></path></svg>
										</div>
									</div>
								</div>
							})}
						</div>
					</div>
					<div className="rounded shadow-xl bg-white mx-4 py-4 px-6 h-full">
						<div className="flex flex-row justify-between items-center">
							<div className="py-4 text-[14px] font-bold px-3 ">
								Inspection Stage
							</div>
							<span
								className="cursor-pointer px-2 py-1 text-center text-blue-900 font-bold text-[9px] bg-[#F2F3FDFF] border-x border-y rounded-xl"
								onClick={() => handleAddInspectionStage()}	>Add More
							</span>
						</div>
						<div className="felx flex-row space-y-3 p-3 border-y border-black">
							{inspectionStageItems.map((obj) => {
								return <div className="flex flex-row justify-around item-center  space-x-5 border-black" >
									<div className="w-28 truncate ...">{obj.Name}</div>
									<div className="flex flex-row justify-center item-center space-x-2 ">
										<div className="cursor-pointer flex flex-row justify-between item-center px-2  border-x border-y border-color rounded-xl">
											<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24"><path d="M19.045 7.401c.378-.378.586-.88.586-1.414s-.208-1.036-.586-1.414l-1.586-1.586c-.378-.378-.88-.586-1.414-.586s-1.036.208-1.413.585L4 13.585V18h4.413L19.045 7.401zm-3-3 1.587 1.585-1.59 1.584-1.586-1.585 1.589-1.584zM6 16v-1.585l7.04-7.018 1.586 1.586L7.587 16H6zm-2 4h16v2H4z" /></svg>
											<span
												onClick={() => handleEditInspectionStage(obj._id, obj.Name)}
												className="text-center mx-1 text-[9px]  font-semibold  ">
												Edit
											</span>
										</div>
										<div className="cursor-pointer" onClick={() => handleDeleteInspectionStage(obj._id)}>
											<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="currentColor"><path d="M17 6H22V8H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V8H2V6H7V3C7 2.44772 7.44772 2 8 2H16C16.5523 2 17 2.44772 17 3V6ZM18 8H6V20H18V8ZM13.4142 13.9997L15.182 15.7675L13.7678 17.1817L12 15.4139L10.2322 17.1817L8.81802 15.7675L10.5858 13.9997L8.81802 12.232L10.2322 10.8178L12 12.5855L13.7678 10.8178L15.182 12.232L13.4142 13.9997ZM9 4V6H15V4H9Z"></path></svg>
										</div>
									</div>
								</div>
							})}
						</div>
					</div>
					<div className="rounded shadow-xl bg-white mx-4 py-4 px-6 h-full">
						<div className="flex flex-row justify-between items-center">
							<div className="py-4 text-[14px] font-bold px-3 ">
								Inspection Status
							</div>
							<span
								className="cursor-pointer px-2 py-1 text-center text-blue-900 font-bold text-[9px] bg-[#F2F3FDFF] border-x border-y rounded-xl"
								onClick={() => handleAddInspectionStatus()}	>Add More
							</span>
						</div>
						<div className="felx flex-row space-y-3 p-3 border-y border-black">
							{inspectionStatusItems.map((obj) => {
								return <div className="flex flex-row justify-around item-center  space-x-5 border-black" >
									<div className="w-28">{obj.Name}</div>
									<div className="flex flex-row justify-center item-center space-x-2 ">
										<div className="cursor-pointer flex flex-row justify-between item-center px-2  border-x border-y border-color rounded-xl">
											<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24"><path d="M19.045 7.401c.378-.378.586-.88.586-1.414s-.208-1.036-.586-1.414l-1.586-1.586c-.378-.378-.88-.586-1.414-.586s-1.036.208-1.413.585L4 13.585V18h4.413L19.045 7.401zm-3-3 1.587 1.585-1.59 1.584-1.586-1.585 1.589-1.584zM6 16v-1.585l7.04-7.018 1.586 1.586L7.587 16H6zm-2 4h16v2H4z" /></svg>
											<span
												onClick={() => handleEditInspectionStatus(obj._id, obj.Name)}
												className="text-center mx-1 text-[9px]  font-semibold  ">
												Edit
											</span>
										</div>
										<div className="cursor-pointer" onClick={() => handleDeleteInspectionStatus(obj._id)}>
											<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="currentColor"><path d="M17 6H22V8H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V8H2V6H7V3C7 2.44772 7.44772 2 8 2H16C16.5523 2 17 2.44772 17 3V6ZM18 8H6V20H18V8ZM13.4142 13.9997L15.182 15.7675L13.7678 17.1817L12 15.4139L10.2322 17.1817L8.81802 15.7675L10.5858 13.9997L8.81802 12.232L10.2322 10.8178L12 12.5855L13.7678 10.8178L15.182 12.232L13.4142 13.9997ZM9 4V6H15V4H9Z"></path></svg>
										</div>
									</div>
								</div>
							})}
						</div>
					</div>
				</div>
				<div className="flex flex-row  mx-4 justify-start items-start text-xs my-4">
					<div className="rounded shadow-xl bg-white mx-4 py-4 px-6 h-full">
						<div className="flex flex-row justify-between items-center">
							<div className="py-4 text-[14px] font-bold px-3 ">
								Issue Priority
							</div>
							<span
								className="cursor-pointer px-2 py-1 text-center text-blue-900 font-bold text-[9px] bg-[#F2F3FDFF] border-x border-y rounded-xl"
								onClick={() => handleAddIssuePriority()}	>Add More
							</span>
						</div>
						<div className="felx flex-row space-y-3 p-3 border-y border-black">
							{issuePriorityItems.map((obj) => {
								return <div className="flex flex-row justify-around item-center  space-x-5 border-black" >
									<div className="w-28">{obj.Name}</div>
									<div className="flex flex-row justify-center item-center space-x-2 ">
										<div className="cursor-pointer flex flex-row justify-between item-center px-2  border-x border-y border-color rounded-xl">
											<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24"><path d="M19.045 7.401c.378-.378.586-.88.586-1.414s-.208-1.036-.586-1.414l-1.586-1.586c-.378-.378-.88-.586-1.414-.586s-1.036.208-1.413.585L4 13.585V18h4.413L19.045 7.401zm-3-3 1.587 1.585-1.59 1.584-1.586-1.585 1.589-1.584zM6 16v-1.585l7.04-7.018 1.586 1.586L7.587 16H6zm-2 4h16v2H4z" /></svg>
											<span
												onClick={() => handleEditIssuePriority(obj._id, obj.Name)}
												className="text-center mx-1 text-[9px]  font-semibold  ">
												Edit
											</span>
										</div>
										<div className="cursor-pointer" onClick={() => handleDeleteIssuePriority(obj._id)}>
											<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="currentColor"><path d="M17 6H22V8H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V8H2V6H7V3C7 2.44772 7.44772 2 8 2H16C16.5523 2 17 2.44772 17 3V6ZM18 8H6V20H18V8ZM13.4142 13.9997L15.182 15.7675L13.7678 17.1817L12 15.4139L10.2322 17.1817L8.81802 15.7675L10.5858 13.9997L8.81802 12.232L10.2322 10.8178L12 12.5855L13.7678 10.8178L15.182 12.232L13.4142 13.9997ZM9 4V6H15V4H9Z"></path></svg>
										</div>
									</div>
								</div>
							})}
						</div>
					</div>
					<div className="rounded shadow-xl bg-white mx-4 py-4 px-6 h-full">
						<div className="flex flex-row justify-between items-center">
							<div className="py-4 text-[14px] font-bold px-3 ">
								Issue Status
							</div>
							<span
								className="cursor-pointer px-2 py-1 text-center text-blue-900 font-bold text-[9px] bg-[#F2F3FDFF] border-x border-y rounded-xl"
								onClick={() => handleAddIssueStatus()}	>Add More
							</span>
						</div>
						<div className="felx flex-row space-y-3 p-3 border-y border-black">
							{issueStatusItems.map((obj) => {
								return <div className="flex flex-row justify-around item-center  space-x-5 border-black" >
									<div className="w-28">{obj.Name}</div>
									<div className="flex flex-row justify-center item-center space-x-2 ">
										<div className="cursor-pointer flex flex-row justify-between item-center px-2  border-x border-y border-color rounded-xl">
											<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24"><path d="M19.045 7.401c.378-.378.586-.88.586-1.414s-.208-1.036-.586-1.414l-1.586-1.586c-.378-.378-.88-.586-1.414-.586s-1.036.208-1.413.585L4 13.585V18h4.413L19.045 7.401zm-3-3 1.587 1.585-1.59 1.584-1.586-1.585 1.589-1.584zM6 16v-1.585l7.04-7.018 1.586 1.586L7.587 16H6zm-2 4h16v2H4z" /></svg>
											<span
												onClick={() => handleEditIssueStatus(obj._id, obj.Name)}
												className="text-center mx-1 text-[9px]  font-semibold  ">
												Edit
											</span>
										</div>
										<div className="cursor-pointer" onClick={() => handleDeleteIssueStatus(obj._id)}>
											<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="currentColor"><path d="M17 6H22V8H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V8H2V6H7V3C7 2.44772 7.44772 2 8 2H16C16.5523 2 17 2.44772 17 3V6ZM18 8H6V20H18V8ZM13.4142 13.9997L15.182 15.7675L13.7678 17.1817L12 15.4139L10.2322 17.1817L8.81802 15.7675L10.5858 13.9997L8.81802 12.232L10.2322 10.8178L12 12.5855L13.7678 10.8178L15.182 12.232L13.4142 13.9997ZM9 4V6H15V4H9Z"></path></svg>
										</div>
									</div>
								</div>
							})}
						</div>
					</div>
					<div className="rounded shadow-xl bg-white mx-4 py-4 px-6 h-full">
						<div className="flex flex-row justify-between items-center">
							<div className="py-4 text-[14px] font-bold px-3 ">
								Roles
							</div>
							<span
								className="cursor-pointer px-2 py-1 text-center text-blue-900 font-bold text-[9px] bg-[#F2F3FDFF] border-x border-y rounded-xl"
								onClick={() => handleAddRole()}	>Add More
							</span>
						</div>
						<div className="felx flex-row space-y-3 p-3 border-y border-black">
							{_rolesItems.map((obj) => {
								return <div className="flex flex-row justify-around item-center  space-x-5 border-black" >
									<div className="w-28">{obj?.name}</div>
									<div className="flex flex-row justify-center item-center space-x-2 ">
										<div className="cursor-pointer flex flex-row justify-between item-center px-2  border-x border-y border-color rounded-xl">
											<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24"><path d="M19.045 7.401c.378-.378.586-.88.586-1.414s-.208-1.036-.586-1.414l-1.586-1.586c-.378-.378-.88-.586-1.414-.586s-1.036.208-1.413.585L4 13.585V18h4.413L19.045 7.401zm-3-3 1.587 1.585-1.59 1.584-1.586-1.585 1.589-1.584zM6 16v-1.585l7.04-7.018 1.586 1.586L7.587 16H6zm-2 4h16v2H4z" /></svg>
											<span
												onClick={() => handleEditRole(obj?._id, obj?.name)}
												className="text-center mx-1 text-[9px]  font-semibold  ">
												Edit
											</span>
										</div>
										<div className="cursor-pointer" onClick={() => handleDeleteRole(obj?._id)}>
											<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="currentColor"><path d="M17 6H22V8H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V8H2V6H7V3C7 2.44772 7.44772 2 8 2H16C16.5523 2 17 2.44772 17 3V6ZM18 8H6V20H18V8ZM13.4142 13.9997L15.182 15.7675L13.7678 17.1817L12 15.4139L10.2322 17.1817L8.81802 15.7675L10.5858 13.9997L8.81802 12.232L10.2322 10.8178L12 12.5855L13.7678 10.8178L15.182 12.232L13.4142 13.9997ZM9 4V6H15V4H9Z"></path></svg>
										</div>
									</div>
								</div>
							})}
						</div>
					</div>
				</div>
				<div className="flex flex-row mx-4   justify-start items-start text-xs my-4">
					<div className="rounded shadow-xl  bg-white mx-4 py-4 px-6 h-full">
						<div className="flex flex-row justify-between items-center">
							<div className="py-4 text-[14px] font-bold px-3 ">
								Permissions
							</div>
							<span
								className="cursor-pointer px-2 py-1 text-center text-blue-900 font-bold text-[9px] bg-[#F2F3FDFF] border-x border-y rounded-xl"
								onClick={() => handleAddPermission()}	>Add More
							</span>
						</div>
						<div className="felx flex-row space-y-3 p-3 border-y border-black">
							{permissionsItems.map((obj) => {
								return <div className="flex flex-row justify-around item-center  space-x-5 border-black" >
									<div className="w-28 truncate">{obj.displayText}</div>
									<div className="flex flex-row justify-center item-center space-x-2 ">
										<div className="cursor-pointer flex flex-row justify-between item-center px-2  border-x border-y border-color rounded-xl">
											<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24"><path d="M19.045 7.401c.378-.378.586-.88.586-1.414s-.208-1.036-.586-1.414l-1.586-1.586c-.378-.378-.88-.586-1.414-.586s-1.036.208-1.413.585L4 13.585V18h4.413L19.045 7.401zm-3-3 1.587 1.585-1.59 1.584-1.586-1.585 1.589-1.584zM6 16v-1.585l7.04-7.018 1.586 1.586L7.587 16H6zm-2 4h16v2H4z" /></svg>
											<span
												onClick={() => handleEditPermission(obj?._id, obj)}
												className="text-center mx-1 text-[9px]  font-semibold  ">
												Edit
											</span>
										</div>
										<div className="cursor-pointer" onClick={() => handleDeletePermission(obj._id)}>
											<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="currentColor"><path d="M17 6H22V8H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V8H2V6H7V3C7 2.44772 7.44772 2 8 2H16C16.5523 2 17 2.44772 17 3V6ZM18 8H6V20H18V8ZM13.4142 13.9997L15.182 15.7675L13.7678 17.1817L12 15.4139L10.2322 17.1817L8.81802 15.7675L10.5858 13.9997L8.81802 12.232L10.2322 10.8178L12 12.5855L13.7678 10.8178L15.182 12.232L13.4142 13.9997ZM9 4V6H15V4H9Z"></path></svg>
										</div>
									</div>
								</div>
							})}
						</div>
					</div>
					<div className="rounded shadow-xl w-2/3 bg-white mx-4 py-4 px-6 h-full">
						<div className="flex flex-row justify-between items-center">
							<div className="py-4 text-[14px] font-bold px-3 ">
								Issue Change Type
							</div>
							<span
								className="cursor-pointer px-2 py-1 text-center text-blue-900 font-bold text-[9px] bg-[#F2F3FDFF] border-x border-y rounded-xl"
								onClick={() => handleAddIssueChangeType()}	>Add More
							</span>
						</div>
						<div className="felx flex-row space-y-3 p-3 border-y border-black">
							{issueChangeTypeItems?.map((obj) => {
								return <div className="flex flex-row justify-between item-center  space-x-5 border-black" >
									<div className="w-28 truncate">{obj.Name}</div>
									<div className="flex flex-row justify-center item-center space-x-2 ">
										<div className="cursor-pointer flex flex-row justify-between item-center px-2  border-x border-y border-color rounded-xl">
											<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24"><path d="M19.045 7.401c.378-.378.586-.88.586-1.414s-.208-1.036-.586-1.414l-1.586-1.586c-.378-.378-.88-.586-1.414-.586s-1.036.208-1.413.585L4 13.585V18h4.413L19.045 7.401zm-3-3 1.587 1.585-1.59 1.584-1.586-1.585 1.589-1.584zM6 16v-1.585l7.04-7.018 1.586 1.586L7.587 16H6zm-2 4h16v2H4z" /></svg>
											<span
												onClick={() => handleEditIssueChangeType(obj?._id, obj)}
												className="text-center mx-1 text-[9px]  font-semibold  ">
												Edit
											</span>
										</div>
										<div className="cursor-pointer" onClick={() => handleDeleteIssueChangeType(obj._id)}>
											<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="currentColor"><path d="M17 6H22V8H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V8H2V6H7V3C7 2.44772 7.44772 2 8 2H16C16.5523 2 17 2.44772 17 3V6ZM18 8H6V20H18V8ZM13.4142 13.9997L15.182 15.7675L13.7678 17.1817L12 15.4139L10.2322 17.1817L8.81802 15.7675L10.5858 13.9997L8.81802 12.232L10.2322 10.8178L12 12.5855L13.7678 10.8178L15.182 12.232L13.4142 13.9997ZM9 4V6H15V4H9Z"></path></svg>
										</div>
									</div>
								</div>
							})}
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
}