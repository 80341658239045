import { createSelector } from 'reselect';

const selectCustomerDetails = (state) => state.customerdetails;

export const selectCurrentItem = createSelector([selectCustomerDetails], (customerdetails) => customerdetails.current);

export const selectListItems = createSelector([selectCustomerDetails], (customerdetails) => customerdetails.list);

export const selectItemById = (itemId) =>
	createSelector(selectListItems, (list) => list.result.items.find((item) => item._id === itemId));

export const selectCreatedItem = createSelector([selectCustomerDetails], (customerdetails) => customerdetails.create);

export const selectUpdatedItem = createSelector([selectCustomerDetails], (customerdetails) => customerdetails.update);

export const selectReadItem = createSelector([selectCustomerDetails], (customerdetails) => customerdetails.read);

export const selectDeletedItem = createSelector([selectCustomerDetails], (customerdetails) => customerdetails.delete);

export const selectSearchedItems = createSelector([selectCustomerDetails], (customerdetails) => customerdetails.search);

export const selectItemsCount = createSelector([selectCustomerDetails], (customerdetails) => customerdetails.count);

export const selectItemsFilter = createSelector([selectCustomerDetails], (customerdetails) => customerdetails.filter);