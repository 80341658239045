import * as actionTypes from './types';


const INITIAL_KEY_STATE = {
	value: null
};

const INITIAL_STATE = {
	value: {
		activeAssignCRM: '',
		activeSiteEngineer: '',
		activeStage: '',
		activeStatus: '',
		projectSiteEngineer: [],
		activeBoardName: '-',
		activeDashboardProject: '',
		boardType: 'drag',
		activeProjectId: '',
		activeProjectBlockList: [],
		activeCustomer: '',
		activeCustomerId: '',
		activeProjectChecklistId: '',
		activeIssueImageKey: '',
		reloadCustomerCreated: false,
		reloadWebIssueCreated: false,
		activeAddonId: '',
		activeAddonStatus: '',
		commentAdded: false,
		isAddonTask: false,
		projectFilter: [],
		isAddTaskByWeb: true,
		masterChecklistId: '',
		assignProject: '',
		inspectionReload: false,
		createdMember: false,
		assignCRM: '',
		assignSiteEngineer: '',
		activeChecklistId: '',
		isSideBarOpen: true,
		categoryName: '',
		checklistReload: false,
		checklistModalOpen: 'close',
		isCheckllistEdit: false,
		checklistItem: {},
		isPostFixIssue: false,
		issueReload: false,
		issueModalOpen: 'close',
		isEditIssue: false,
		isUpdateIssue: false,
		activeIssueId: '',
		issueItem: {},
		projectChecklistOpen: 'close',
		projectItem: {},
		addChecklist: 'close',
		editMasterChecklist: 'close',
		addMasterChecklist: 'close',
		isEditMasterChecklist: false,
		masterChecklistItem: {},
		activeMasterChecklistId: '',
		addBlocksTowers: 'close',
		myAccount: 'close',
		assignUser: 'close',
		addCustomer: 'close',
		isEditCustomerDetails: false,
		reloadCustomerDetails: false,
		activeCustomerItem: {},
		activeCustomerId: '',
		addProjectTask: 'close',
		reloadProjectTask: false,
		clearInfiniteScroll: false,
		viewIssue: 'close',
		qualityMetricsCategory: '',
		uploadImage: 'close',
		notificationList: 'close',
		isIssueCameraImage: false,
		addBedrooms: 'close',
		addIssueChangeType: 'close',
		addTypeOfHouse: 'close',
		addProjectType: 'close',
		reloadProjectType: false,
		reloadIssueChangeType: false,
		isProjectTypeEdit: false,
		isIssueChangeTypeEdit: false,
		projectTypeItem: '',
		issueChangeType: false,
		issuechangeTypeItem: '',
		activeProjectTypeId: '',
		isTypeOfHouseEdit: false,
		reloadTypeOfHouse: false,
		reloadBedrooms: false,
		isBedroomsEdit: false,
		isProjectEdit: false,
		activeMasterChecklist: {},
		selectedCheckListItem: '',
		activeDocumentId: '',
		activeDocumentFilename: '',
		activeDocumentFileKey: '',
		activeUploadLink: '',
		activeCategory: '',
		activeMasterTaskItem: '',
		activeMasterTaskId: '',
		reloadMasterChecklist: false,
		reloadProjectCard: false,
		isEditProjectPhase: false,
		addProjectPhase: 'close',
		activeProjectPhaseId: '',
		projectPhaseItem: '',
		reloadProjectPhase: false,
		reloadInspectionStage: false,
		inspectionStageItem: '',
		addInspectionStage: 'close',
		activeInspectionStageId: '',
		isEditInspectionStage: false,
		reloadInspectionStatus: false,
		inspectionStatusItem: '',
		addInspectionStatus: 'close',
		activeInspectionStatusId: '',
		isEditInspectionStatus: false,
		reloadIssueStatus: false,
		issueStatusItem: '',
		addIssueStatus: 'close',
		activeIssueStatusId: '',
		isEditIssueStatus: false,
		reloadIssuePriority: false,
		issuePriorityItem: '',
		addIssuePriority: 'close',
		activeIssuePriorityId: '',
		isEditIssuePriority: false,
		reloadRole: false,
		RoleItem: '',
		addRole: 'close',
		activeRoleId: '',
		isEditRole: false,
		selectedCustomer: [],
		reloadCategoryName: false,
		categoryNameItem: '',
		selectedCount: 0,
		addCategoryName: 'close',
		activeCategoryNameId: '',
		isEditCategoryName: false,
		deleteItem: '',
		deleteConfirmation: 'close',
		addMember: 'close',
		activeMemberId: '',
		isEditMember: '',
		memberItem: {},
		reloadMember: false,
		reloadPermission: false,
		permissionItem: '',
		addPermission: 'close',
		activePermissionId: '',
		isEditPermission: false,
		isNameExists: false,
		isCameraOn: false,

	},
	trigger: INITIAL_KEY_STATE,
};


const crmReducer = (state = INITIAL_STATE, action) => {
	const { payload } = action;
	switch (action.type) {
		case actionTypes.CRM_ON_CHANGE:
			return {
				value: {
					...state.value,
					...payload
				}
			};
		default:
			return state;
	}
};

export default crmReducer;
