import { crm } from "./../redux/crm/actions";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentCRM } from './../redux/crm/selectors';
import { projecttype } from '../redux/projecttype/actions';
import { projectstatus } from '../redux/projectstatus/actions';
import { inspectionstatus } from '../redux/inspectionstatus/actions';
import { inspectionstage } from '../redux/inspectionstage/actions';
import { typeofhouse } from '../redux/typeofhouse/actions';
import { roles } from '../redux/roles/actions';
import { numberofbedrooms } from '../redux/numberofbedrooms/actions';
import { issuepriority } from "../redux/issuepriority/actions";
import { issuestatus } from "../redux/issuestatus/actions";
import { issuechangetype } from "../redux/issuechangetype/actions";
import { inspectioncards } from "../redux/inspectioncards/actions";
import { permissions } from "../redux/permissions/actions";
import { categoryname } from "../redux/categoryname/actions";
export default function Confirmation() {
	const dispatch = useDispatch();
	const { deleteConfirmation, deleteMessage, deleteItem } = useSelector(selectCurrentCRM);

	const handleCancel = () => {
		dispatch(crm.trigger({ deleteConfirmation: 'close', isDelete: false }))
	}

	const handleDelete = () => {
		if (deleteItem.entity == 'PDI_Project_Configuration') {
			dispatch(projecttype.delete({ entity: deleteItem.entity, id: deleteItem.id })).then(() => {
				dispatch(crm.trigger({ reloadProjectType: true, deleteConfirmation: 'close' }))
			})
		}
		else if (deleteItem.entity == 'PDI_Issue_Change_Type') {
			dispatch(issuechangetype.delete({ entity: deleteItem.entity, id: deleteItem.id })).then(() => {
				dispatch(crm.trigger({ reloadIssueChangeType: true, deleteConfirmation: 'close' }))
			})
		}
		else if (deleteItem.entity == 'PDI_Type_Of_House') {
			dispatch(typeofhouse.delete({ entity: deleteItem.entity, id: deleteItem.id })).then(() => {
				dispatch(crm.trigger({ reloadTypeOfHouse: true, deleteConfirmation: 'close' }))
			})
		}
		else if (deleteItem.entity == 'PDI_Number_Of_Bedrooms') {
			dispatch(numberofbedrooms.delete({ entity: deleteItem.entity, id: deleteItem.id })).then(() => {
				dispatch(crm.trigger({ reloadBedrooms: true, deleteConfirmation: 'close' }))
			})
		}
		else if (deleteItem.entity == 'roles') {
			dispatch(roles.delete({ entity: deleteItem.entity, id: deleteItem.id })).then(() => {
				dispatch(crm.trigger({ reloadRole: true, deleteConfirmation: 'close' }))
			})
		}
		else if (deleteItem.entity == 'permissions') {
			dispatch(permissions.delete({ entity: deleteItem.entity, id: deleteItem.id })).then(() => {
				dispatch(crm.trigger({ reloadPermission: true, deleteConfirmation: 'close' }))
			})
		}
		else if (deleteItem.entity == 'PDI_Project_Status') {
			dispatch(projectstatus.delete({ entity: deleteItem.entity, id: deleteItem.id })).then(() => {
				dispatch(crm.trigger({ reloadProjectStatus: true, deleteConfirmation: 'close' }))
			})
		}
		else if (deleteItem.entity == 'PDI_Inspection_Status') {
			dispatch(inspectionstatus.delete({ entity: deleteItem.entity, id: deleteItem.id })).then(() => {
				dispatch(crm.trigger({ reloadInspectionStatus: true, deleteConfirmation: 'close' }))
			})
		}
		else if (deleteItem.entity == 'PDI_Inspection_Stage') {
			dispatch(inspectionstage.delete({ entity: deleteItem.entity, id: deleteItem.id })).then(() => {
				dispatch(crm.trigger({ reloadInspectionStage: true, deleteConfirmation: 'close' }))
			})
		}
		else if (deleteItem.entity == 'PDI_Issue_Priority') {
			dispatch(issuepriority.delete({ entity: deleteItem.entity, id: deleteItem.id })).then(() => {
				dispatch(crm.trigger({ reloadIssuePriority: true, deleteConfirmation: 'close' }))
			})
		}
		else if (deleteItem.entity == 'PDI_Issue_Status') {
			dispatch(issuestatus.delete({ entity: deleteItem.entity, id: deleteItem.id })).then(() => {
				dispatch(crm.trigger({ reloadIssueStatus: true, deleteConfirmation: 'close' }))
			})
		}
		else if (deleteItem.entity == 'PDI_Customer_Details') {
			dispatch(inspectioncards.delete({ entity: deleteItem.entity, id: deleteItem.id })).then(() => {
				dispatch(crm.trigger({ reloadCustomerDetails: true, deleteConfirmation: 'close' }))
			})
		}
		else if (deleteItem.entity == 'PDI_Category_Name') {
			dispatch(categoryname.delete({ entity: deleteItem.entity, id: deleteItem.id })).then(() => {
				dispatch(crm.trigger({ reloadCategoryName: true, deleteConfirmation: 'close' }))
			})
		}
	}

	return (
		<>
			{deleteConfirmation == 'open' ? (
				<>
					<div className="absolute fixed w-full  inset-y-auto inset-x-0 z-50 outline-none focus:outline-none flex flex-warp justify-center align-middle">
						<div className="relative w-auto my-1 mx-auto max-w-sm">
							<div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
								<div className="relative p-2 flex flex-wrap justify-center align-middle" >
									<div className="w-48 h-14 m-3 mx-5">
										<div className="text-black text-sm font-bold">
											{deleteMessage}
										</div>
									</div>
								</div>
								<div className="flex items-center justify-center p-3 space-x-3 border-t border-solid border-blueGray-200 rounded-b">
									<button
										className="text-[#2D3A92FF] border-x border-y rounded font-bold border-[#2D3A92FF] px-2 py-1 text-xs "
										type="button"
										onClick={() => handleCancel()}
									>
										Cancel
									</button>
									<button
										className="bg-[#E05858FF] text-white font-bold  text-xs px-2 py-1 rounded"
										type="button"
										onClick={() => handleDelete()}
									>
										Delete
									</button>
								</div>
							</div>
						</div>
					</div>
					<div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
				</>
			) : null}
		</>
	);
}