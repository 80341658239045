import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectItemsFilter } from '../../redux/projectcards/selectors';
import { selectItemsFilter as selectProjectUsers } from '../../redux/projectusers/selectors';
import { projectcards } from '../../redux/projectcards/actions'
import { selectCurrentCRM } from '../../redux/crm/selectors';
import { projectusers } from "../../redux/projectusers/actions";
import storePersist from "../../redux/storePersist";
import { crm } from '../../redux/crm/actions'
import Moment from 'moment';
import { request } from '../../request';

export default function ProjectBoard() {
	const navigate = useNavigate();
	const dispatch = useDispatch()
	const [imageUrls, setImageUrls] = useState([])
	const [filteredList, setFilteredList] = useState([])
	const { result: projectResult } = useSelector(selectItemsFilter);
	const { result: projectusersResult } = useSelector(selectProjectUsers);
	const { reloadProjectCard, projectFilter } = useSelector(selectCurrentCRM)
	const { items: projectItems } = projectResult;
	const { items: projectUsersItems } = projectusersResult;


	const handleProjectEdit = (project) => {
		navigate('/inspection-crm/add-project')
		dispatch(crm.trigger({ isProjectEdit: true, activeProjectId: project._id, projectItem: project, activeProjectBlockList: project.BlockTower }))
	}

	const handleInspectionBoard = (project) => {
		dispatch(crm.trigger({ activeBoardName: project.ProjectName, activeProjectId: project._id, boardType: "drag", inspectionReload: true, projectItem: project }))
		navigate('/inspection-crm/inspection-items')
	}

	useEffect(() => {
		const _user = storePersist.get('user')

		if (reloadProjectCard) {
			dispatch(crm.trigger({ reloadProjectCard: false }))
			dispatch(projectusers.filter({ entity: 'PDI_Project_Users', jsonData: { params: { isDeleted: false, User: _user?._id }, page: 1, limit: 200 } }))
		}
	}, [reloadProjectCard])

	useEffect(() => {
		let projectId = []
		Promise.all(projectUsersItems.map((item) => {
			projectId.push(item?.ProjectName?._id)
		}))
		dispatch(projectcards.filter({ entity: 'PDI_Project_Cards', jsonData: { params: { _id: projectId }, page: 1, limit: 200 } }))
	}, [projectUsersItems])

	useEffect(() => {
		const _user = storePersist.get('user')
		dispatch(crm.trigger({ reloadProjectCard: false }))
		dispatch(projectusers.filter({ entity: 'PDI_Project_Users', jsonData: { params: { isDeleted: false, User: _user?._id }, page: 1, limit: 200 } }))
	}, [])

	const getImage = (id) => {
		let url = imageUrls.filter((obj) => obj.id == id)[0]?.url
		return url
	}

	const getImageUrl = async () => {
		let images = []
		await Promise.all(projectItems.map(async (item) => {
			const url = "https://proptech-pdi.s3.amazonaws.com/" + item?.ImageUrl
			images.push({ id: item._id, url: url })
		}))
		setImageUrls(images)
	}
	useEffect(() => {
		getImageUrl()
		setFilteredList(projectItems)
	}, [projectItems])

	useEffect(() => {
		if (projectFilter.length > 0) {
			setFilteredList(projectFilter)
		}
	}, [projectFilter])

	const handleCreateProject = () => {
		navigate('/inspection-crm/add-project')
		dispatch(crm.trigger({ isProjectEdit: false }))
	}

	return (
		<div className="flex flex-col  bg-[#F9F9FAFF]" >
			{projectItems.length == 0 ? <div className="flex flex-col item-center">
				<header className="flex justify-between h-24 w-full mx-auto px-5 dark:text-white items-center">
					<div className="flex flex-col justify-start w-full" >
						<nav aria-label="breadcrumb" className="w-max" >
							<ol className="flex flex-wrap items-center w-full px-4 py-1 rounded-md bg-blue-gray-50 bg-opacity-60">
								<li
									className="flex items-center font-sans text-sm antialiased font-normal leading-normal transition-colors duration-300 cursor-pointer text-blue-gray-900 hover:text-light-blue-500">
									<h3 className="text-xl font-bold">Projects</h3>
								</li>
							</ol>
						</nav>
					</div>
				</header >
				<div className="flex flex-col bg-blue-gray-50 h-screen ">
					<div className="flex flex-col items-center justify-around mt-10 pt-24">
						<span className="text-3xl font-bold m-3 w-96 text-center">Add Project Details</span>
						<span className="text-xs font-bold w-96 text-center">
							To intiate the project, kindly provide the necessary details by uploading project-releated information
						</span>
						<div className="flex justify-center my-4" >
							<button
								onClick={() => navigate('/inspection-crm/add-project')}
								className="w-auto px-3 button bg-blue-600 text-white rounded ring-1 ring-inset ring-blue-600 text-blue-600 p-2 text-xs font-bold"
							>
								Add Project
							</button>
						</div>
					</div>
				</div>
			</div> :
				<div className="flex flex-row justify-between mx-10 mt-3 ">
					<div className="text-xl font-bold">Projects
					</div>
					<button
						onClick={() => handleCreateProject()}
						className="w-32 w-min-32 button px-1 bg-blue-600 text-white rounded p-2 text-xs font-bold"
					> Add Project
					</button>
				</div>}
			<div className="flex flex-wrap item-center justify-center lg:justify-start">
				{filteredList?.map((project, idx) => {
					return <div key={idx}
						className="cursor-pointer flex  rounded shadow-2xl flex-row w-60 lg:ml-8 justify-center my-5 bg-white py-1 pt-1">
						<div className="flex flex-col w-60 w-max-60 ">
							<img
								className="h-40 w-54 my-3 px-3 rounded-bl-md rounded-tr-none rounded-tr-md rounded-tl-md"
								src={getImage(project._id)}
								alt="image-text"
								onClick={() => handleInspectionBoard(project)}
							/>
							<div className="flex flex-row w-64 px-4">
								<div className="w-64 flex flex-col justify-between">
									<div className="flex flex-col w-64 max-w-64 ">
										<div
											onClick={() => handleInspectionBoard(project)}
											className=" flex flex-wrap justify-baseline  items-start text-gray-500 text-xs h-14 w-48 ">
											<div className=' flex flex-wrap'>{project?.ProjectType?.map((projecttype, index) => {
												return <div key={index}> {projecttype[0].label}-</div>
											})}
												{project?.TypeOfHouse?.map((typeofhouse, idx1) => {
													return <div key={idx1}> {typeofhouse[0].label} {project?.TypeOfHouse?.length > (idx1 + 1) ? "-" : ""} </div>
												})}</div>
										</div>
										<div className="my-3   flex flex-row items-center justify-start w-54 text-left">
											<div
												onClick={() => handleInspectionBoard(project)}
												className="text-gray-700  font-bold mr-3 text-left w-40  truncate " >
												{project.ProjectName}<br />
											</div>
											<div onClick={() => handleProjectEdit(project)}>
												<svg className="feather feather-edit" fill="none" height="16" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" width="24"
													xmlns="http://www.w3.org/2000/svg">
													<path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" />
													<path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" />
												</svg>
											</div>
										</div>
									</div>
									<div onClick={() => handleInspectionBoard(project)}
										className="flex flex-col space-y-2 justify-start w-64 ">
										<div className="flex flex-row w-64 justify-start">
											<div className=" text-gray-800 text-xs min-w-36">Start Date :</div>
											<div className="text-black text-[9px] font-semibold">{Moment(project.StartDate).format('MMM-DD-YYYY')}</div>
										</div>
										<div className="flex flex-row w-64 justify-start">
											<div className=" text-gray-700 text-xs min-w-36">Completion Date :</div>
											<div className="text-black text-[9px] font-semibold"> {Moment(project.ExpectedCompletionDate).format('MMM-DD-YYYY')}</div>
										</div>
									</div>

									<div className="my-2 w-64 ">
										{project?.ProjectStage?.Name == "Pre Delivery Stage" ? <span className="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">
											{project?.ProjectStage?.Name}</span> :
											(project?.ProjectStage?.Name == 'Under Construction') ? <span className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">{project?.ProjectStage?.Name}</span> :
												<span className="inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-700 ring-1 ring-inset ring-green-600/20">{project?.ProjectStage?.Name}</span>
										}
									</div>
								</div>
							</div>
						</div>
					</div>
				})}
			</div>
		</div >
	);
}
