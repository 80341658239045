import { crm } from "../redux/crm/actions";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentCRM } from '../redux/crm/selectors';
import storePersist from "../redux/storePersist";
import { request } from "../request";
import { subscriber } from "../redux/subscribers/actions";
import { getFirebaseToken, messaging } from '../services/firebase';
import { db } from '../services/db'
import { useLiveQuery } from "dexie-react-hooks";
import Moment from 'moment';
import { selectItemsFilter } from '../redux/subscribers/selectors.js'



export default function Notification({ enable }) {
	const dispatch = useDispatch();
	const notifications = useLiveQuery(() => db.notifications.toArray(), []);
	const { result } = useSelector(selectItemsFilter);
	const { items } = result
	const { notificationList } = useSelector(selectCurrentCRM);
	const [user, setUser] = useState({})
	const [url, setUrl] = useState('')
	const [isEditProfile, setIsEditProfile] = useState(false)

	const handleClose = () => {
		dispatch(crm.trigger({ notificationList: 'close' }))
	}

	const handleSend = () => {
		dispatch(subscriber.send({ entity: 'PDI_Push_Subscriber', jsonData: { "entity": { "users": ['rajak'], "title": 'Issue Assigned', "body": "Stair case light not working properly" } } })).then(() => {
		})
	}

	const handleGetFirebaseToken = async () => {
		const _user = JSON.parse(localStorage.getItem('user'))
		const firebaseToken = await getFirebaseToken().catch((err) => console.error('An error occured while retrieving firebase token. ', err))
		if (firebaseToken) {
			dispatch(subscriber.create({
				entity: 'PDI_Push_Subscriber', jsonData: {
					entity: {
						deviceDetails: {
							Platform: navigator.userAgent,
							Language: "en",
							FcmToken: firebaseToken,
							appVersion: "1.9.3",
							deviceModel: navigator.platform
						},
						User: _user?.userId
					}
				}
			})).then(() => {
				dispatch(subscriber.filter({ entity: 'PDI_Push_Subscriber', jsonData: { params: { isDeleted: false, User: _user?.userId }, page: 1, limit: 200 } }))

			})
		}
	}

	const getUrl = async (user) => {
		const image_url = "https://proptech-pdi.s3.amazonaws.com/" + user?.profileImage
		setUrl(image_url)
	}

	const handleEditProfile = () => {
		setIsEditProfile(true)
	}

	useEffect(() => {
		const _user = storePersist.get('user')
		dispatch(subscriber.filter({ entity: 'PDI_Push_Subscriber', jsonData: { params: { isDeleted: false, User: _user?.userId }, page: 1, limit: 200 } }))
		setUser(_user)
		getUrl(_user)
	}, [])
	const handleSubscription = (obj) => {
		const _user = storePersist.get('user')
		dispatch(subscriber.delete({ entity: 'PDI_Push_Subscriber', id: obj._id })).then(() => {
			dispatch(subscriber.filter({ entity: 'PDI_Push_Subscriber', jsonData: { params: { isDeleted: false, User: _user?.userId }, page: 1, limit: 200 } }))

		})
	}

	const handleDelete = (id) => {

		db.notifications.delete(id)
	}

	return (
		<>
			{notificationList == 'open' ? (
				<>
					<div className="absolute fixed w-full mt-10 inset-0 z-50 flex flex-warp justify-center align-middle">
						<div className="w-auto my-2 mx-auto max-w-sm">
							<div className="rounded-lg shadow-lg flex flex-col w-full bg-white p-3">
								<div className="flex flex-row items-center justify-between w-full space-x-10 p-3">
									<div className="flex flex-row justify-end items-baseline font-medium leading-none" onClick={() => handleEditProfile()}>
										Notification List
									</div>
									<span className="cursor-pointer" onClick={() => handleClose()}>
										<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-x"><path d="M18 6 6 18" /><path d="m6 6 12 12" /></svg>
									</span>
								</div>
								<div className="flex flex-col px-3 pb-5 overflow-auto">
									<button
										onClick={() => handleGetFirebaseToken()}
										className="flex ml-auto  w-25 text-white px-3 py-2 my-5 text-sm  shadow-sm font-medium bg-red-500 ">
										Registration
									</button>
									{items?.map((obj, index) => {
										return <div key={index} className="w-auto px-3 m-1 shadow-xl bg-white">
											<div className="flex flex-col ">
												<div className="flex flex-row justify-between items-center text-sm font-bold py-2">
													Device {index + 1}
													<svg xmlns="http://www.w3.org/2000/svg" className="m-1 cursor-pointer" onClick={() => handleSubscription(obj)} width="12" height="12" viewBox="0 0 24 24" fill="currentColor"><path d="M17 6H22V8H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V8H2V6H7V3C7 2.44772 7.44772 2 8 2H16C16.5523 2 17 2.44772 17 3V6ZM18 8H6V20H18V8ZM13.4142 13.9997L15.182 15.7675L13.7678 17.1817L12 15.4139L10.2322 17.1817L8.81802 15.7675L10.5858 13.9997L8.81802 12.232L10.2322 10.8178L12 12.5855L13.7678 10.8178L15.182 12.232L13.4142 13.9997ZM9 4V6H15V4H9Z"></path></svg>

													<div className="inline-block bg-gray-200 rounded-full px-3 py-1 text-xs font-semibold text-gray-700 mr-2 mb-2">
														{Moment(obj?.createdAt).format('DD-MM-YYYY hh:mm')}
													</div>
												</div>
												<div className="flex flex-row justify-between items-center ">
													<div className="text-xs font-bold pb-2">
														{obj?.deviceDetails?.deviceModel}
													</div>
													<div className="inline-block bg-gray-200 rounded-full px-3 py-1 text-xs font-semibold text-gray-700 mr-2 mb-2">
														{obj?.deviceDetails?.Platform.slice(0, 20)}
													</div>
												</div>
											</div>
										</div>
									})}
									<div className="py-2 "></div>
									{notifications?.map((obj, idx) => {
										return <div key={idx} className="flex flex-col p-1 bg-white shadow-md hover:shodow-lg rounded-2xl">
											<div className="flex items-center justify-between p-1">
												<div className="flex items-center">
													<svg xmlns="http://www.w3.org/2000/svg"
														className="w-10 h-10 rounded p-1 border border-blue-100 text-blue-400 bg-blue-50" fill="none"
														viewBox="0 0 24 24" stroke="currentColor">
														<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
															d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
													</svg>
													<div className="flex flex-col ml-3">
														<div className="font-medium leading-none">{obj.title}</div>
														<p className="text-sm text-gray-600 leading-none mt-1">{obj.message}...
														</p>
													</div>
												</div>
												<button className="flex-no-shrink bg-red-500 px-2 ml-4 py-2 text-sm shadow-sm hover:shadow-lg font-medium tracking-wider border-2 border-red-500 text-white rounded">
													<svg xmlns="http://www.w3.org/2000/svg" onClick={() => handleDelete(obj.id)} width="12" height="12" viewBox="0 0 24 24" fill="currentColor"><path d="M17 6H22V8H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V8H2V6H7V3C7 2.44772 7.44772 2 8 2H16C16.5523 2 17 2.44772 17 3V6ZM18 8H6V20H18V8ZM13.4142 13.9997L15.182 15.7675L13.7678 17.1817L12 15.4139L10.2322 17.1817L8.81802 15.7675L10.5858 13.9997L8.81802 12.232L10.2322 10.8178L12 12.5855L13.7678 10.8178L15.182 12.232L13.4142 13.9997ZM9 4V6H15V4H9Z"></path></svg>
												</button>
											</div>
										</div>
									})}

								</div>

							</div>
						</div>
					</div>

					<div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
				</>
			) : null}
		</>
	);
}