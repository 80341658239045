import { crm } from "../redux/crm/actions";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentCRM } from '../redux/crm/selectors';
import { logout } from "../redux/auth/actions";
import { useNavigate } from 'react-router-dom';
import storePersist from "../redux/storePersist";
import { request } from "../request";
import { selectUpdatedItem } from '../redux/users/selectors'
import { users } from "../redux/users/actions";
import { subscriber } from "../redux/subscribers/actions";
import { getFirebaseToken, messaging } from './../services/firebase';

export default function MyAccount() {
	const dispatch = useDispatch();
	const navigate = useNavigate()
	const { myAccount } = useSelector(selectCurrentCRM);
	const { result } = useSelector(selectUpdatedItem)
	const [user, setUser] = useState({})
	const [url, setUrl] = useState('')
	const [isEditProfile, setIsEditProfile] = useState(false)
	const [profile, setProfile] = useState('');
	const [file, setFile] = useState();
	const handleClose = () => {
		dispatch(crm.trigger({ myAccount: 'close' }))
	}

	const handleChange = (e) => {
		setProfile(URL.createObjectURL(e.target.files[0]));
		setFile(e.target.files[0])
	}

	const handleSend = () => {
		dispatch(subscriber.send({ entity: 'PDI_Push_Subscriber', jsonData: { "entity": { "users": ['rajak'], "title": 'Issue Assigned', "body": "Stair case light not working properly" } } })).then(() => {
		})
	}

	const handleGetFirebaseToken = () => {
		getFirebaseToken()
			.then((firebaseToken) => {
				// if (firebaseToken) {
				// 	setShowNotificationBanner(false);
				// }
				dispatch(subscriber.create({ entity: 'PDI_Push_Subscriber', jsonData: { entity: { FcmToken: firebaseToken, User: '64b016e1e802f7faeadadda8' } } }))
			})
			.catch((err) => console.error('An error occured while retrieving firebase token. ', err))
	}

	const clearCache = async () => {
		if ('serviceWorker' in navigator && navigator.serviceWorker.controller) {
			// Send a message to the service worker to clear all cache
			navigator.serviceWorker.controller.postMessage({ type: 'CLEAR_ALL_DYNAMIC_CACHE' });
			alert('Cache clearing message sent to the Service Worker!');
		} else {
			alert('Service Worker is not available.');
		}
	};

	const getUrl = async (user) => {
		const image_url = "https://proptech-pdi.s3.amazonaws.com/" + user?.profileImage
		setUrl(image_url)
	}

	const handleEditProfile = () => {
		setIsEditProfile(true)
	}
	useEffect(() => {
		const _user = storePersist.get('user')
		setUser(_user)
		getUrl(_user)
	}, [])

	useEffect(() => {

	}, [result])
	const handleLogout = () => {
		dispatch(logout())
		navigate('/auth/login')
	}

	const handleUpdate = async (user) => {
		if (file) {
			const exte = file?.name.substring(file?.name.indexOf(".") + 1);
			const docDetails = {
				extension: exte,
				file_name: file?.name,
				mime_type: file?.type,
				entity_type: "crm",
				size: file?.size,
			};
			let data = await request.uploadurl({ entity: 'document', jsonData: docDetails, options: {} })
			const type = file?.type
			await request.uploadfileAWSS3({ fileuploadurl: data?.uploadLink, file, type })
			dispatch(users.update({
				entity: 'users', id: user?._id, jsonData: {
					entity: {
						"profileImage": data?.filename,
						"roleId": user?.roleId,
						"firstName": user?.firstName,
						"lastName": user?.lastName
					}
				}
			})).then(() => {
				setIsEditProfile(false)
				const _user = storePersist.get('user')
				setUser(_user)
				getUrl(_user)
			})
			// dispatch(logout())
			// navigate('/auth/login')

		}
	}
	const handleChangePassword = () => {
		dispatch(crm.trigger({ myAccount: 'close' }))
		navigate('/auth/changepassword')
	}

	return (
		<>
			{myAccount == 'open' ? (
				<>
					<div className="absolute fixed w-full mt-10 inset-0 z-50 flex flex-warp justify-center align-middle">
						<div className="w-auto my-2 mx-auto max-w-sm">
							<div className="rounded-lg shadow-lg flex flex-col w-full bg-white">
								<div className="flex items-center justify-between m-5 ">
									<div className="flex flex-row justify-end items-baseline cursor-pointer" onClick={() => handleEditProfile()}>
										<img
											className="h-10 w-10 rounded-full"
											src={url}
										/>
										<svg xmlns="http://www.w3.org/2000/svg" className="-ml-2 pt-3" width="24" height="24" viewBox="0 0 24 24"><path d="M19.045 7.401c.378-.378.586-.88.586-1.414s-.208-1.036-.586-1.414l-1.586-1.586c-.378-.378-.88-.586-1.414-.586s-1.036.208-1.413.585L4 13.585V18h4.413L19.045 7.401zm-3-3 1.587 1.585-1.59 1.584-1.586-1.585 1.589-1.584zM6 16v-1.585l7.04-7.018 1.586 1.586L7.587 16H6zm-2 4h16v2H4z" /></svg>
									</div>
									<span className="cursor-pointer" onClick={() => handleClose()}>
										<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-x"><path d="M18 6 6 18" /><path d="m6 6 12 12" /></svg>
									</span>
								</div>
								{isEditProfile ?
									<div className="w-1/2 ml-3 flex flex-col items-start justify-center" >
										<label
											className="block text-xs font-bold"
											htmlFor="grid-password">
											Image
										</label>
										<div className="w-full bg-transparent  text-xs " >
											<div className="flex flex-row ">
												<input type="file" id="file-input" name="ImageStyle" onChange={(e) => handleChange(e)} />
											</div>
											{profile ? <img
												className="h-24 w-24 py-2"
												src={profile} /> : ""}
										</div>
									</div> : <></>}
								<div className="px-2 flex flex-wrap justify-center align-middle border-b-2 border-black border-opacity-10" >
									<div className="w-64 flex flex-col item-center justify-between h-10 mx-2 my-2">
										<span className="text-grey-600 text-xs font-bold">Full Name</span>
										<span className="text-grey-600 text-xs font-normal">{user?.firstName} {user?.lastName}</span>
									</div>
								</div>
								<div className="px-2 flex flex-wrap justify-center align-middle  border-b-2 border-black border-opacity-10" >
									<div className="w-64 flex flex-col item-center justify-between h-10 mx-2 my-4">
										<span className="text-grey-600 text-xs font-bold">Email</span>
										<span className="text-grey-600 text-xs font-normal">{user?.email}</span>
									</div>
								</div>
								<div className="px-2 flex flex-wrap justify-center align-middle  border-b-2 border-black border-opacity-10" >
									<div className="w-64 flex flex-col item-center justify-between h-10 mx-2 my-4">
										<span className="text-grey-600 text-xs font-bold">Phone Number</span>
										<span className="text-grey-600 text-xs font-normal">{user?.mobileNumber}</span>
									</div>
								</div>
								<div className="px-2 flex flex-wrap justify-center align-middle  border-b-2 border-black border-opacity-10" >
									<div className="w-64 flex flex-col item-center justify-between h-10 mx-2 my-4">
										<span className="text-grey-600 text-xs font-bold">Role</span>
										<span className="text-grey-600 text-xs font-normal">{user?.roleId?.name}</span>
									</div>
								</div>
								<div className="flex flex-col space-y-3 items-end justify-end p-3 border-b-2 border-black border-opacity-10" >
									<button
										className="border-x border-y border-blue-300 text-blue-500 font-bold px-2 text-xs py-1"
										type="button"
										onClick={() => handleChangePassword()} >
										Change Password
									</button>

									<button
										className="border-x border-y border-blue-300 text-blue-500 font-bold px-2 text-xs py-1"
										type="button"
										onClick={() => clearCache()}>
										Clear Cache
									</button>
									{/* <button
										className="border-x border-y border-blue-300 text-blue-500 font-bold px-2 text-xs py-1"
										type="button"
										onClick={() => handleSend()}>
										Send Notification
									</button> */}
								</div>
								<div className="flex items-center justify-center px-6 py-4 mx-3 space-x-3 ">
									{isEditProfile ? <> <button
										className="border-x border-y border-blue-300 text-blue-500 font-bold px-2 text-xs py-1"
										type="button"
										onClick={() => setIsEditProfile(false)} >
										Cancel
									</button>
										<button
											className="border-x border-y border-blue-300 text-blue-500 font-bold px-2 text-xs py-1"
											type="button"
											onClick={() => handleUpdate(user)} >
											Update
										</button>
									</> :
										<button
											className="border-x border-y border-blue-300 text-blue-500 font-bold px-2 text-xs py-1"
											type="button"
											onClick={() => handleLogout()} >
											Logout
										</button>}
								</div>
							</div>
						</div>
					</div>
					<div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
				</>
			) : null}
		</>
	);
}