import { createSelector } from 'reselect';

const selectMasterChecklist = (state) => state.masterchecklist;

export const selectCurrentItem = createSelector([selectMasterChecklist], (masterchecklist) => masterchecklist.current);

export const selectListItems = createSelector([selectMasterChecklist], (masterchecklist) => masterchecklist.list);


export const selectItemById = (itemId) =>
	createSelector(selectListItems, (list) => list.result.items.find((item) => item._id === itemId));

export const selectCreatedItem = createSelector([selectMasterChecklist], (masterchecklist) => masterchecklist.create);

export const selectUpdatedItem = createSelector([selectMasterChecklist], (masterchecklist) => masterchecklist.update);

export const selectReadItem = createSelector([selectMasterChecklist], (masterchecklist) => masterchecklist.read);

export const selectDeletedItem = createSelector([selectMasterChecklist], (masterchecklist) => masterchecklist.delete);

export const selectSearchedItems = createSelector([selectMasterChecklist], (masterchecklist) => masterchecklist.search);

export const selectItemsCount = createSelector([selectMasterChecklist], (masterchecklist) => masterchecklist.count);

export const selectItemsFilter = createSelector([selectMasterChecklist], (masterchecklist) => masterchecklist.filter);



