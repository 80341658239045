import { crm } from "../redux/crm/actions";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentCRM } from '../redux/crm/selectors';

export default function AddBlocksTowers() {
	const dispatch = useDispatch();
	const [name, setName] = useState('')
	const [block, setBlock] = useState([])
	const { addBlocksTowers, activeProjectBlockList } = useSelector(selectCurrentCRM);

	const handleProject = () => {
		dispatch(crm.trigger({ addBlocksTowers: 'close', activeProjectBlockList: block?.join(',') }))
	}

	const handleClose = () => {
		dispatch(crm.trigger({ addBlocksTowers: 'close' }))
	}

	const handleMore = () => {
		setBlock([...block, name])
		setName('')
	}
	useEffect(() => {
		if (activeProjectBlockList != "") {
			const array = activeProjectBlockList?.split(',');
			setBlock(array)
		}
	}, [activeProjectBlockList])

	const handleDelete = (obj) => {
		let remainingItems = block.filter((item) => { return item !== obj });
		setBlock(remainingItems);
	}

	return (
		<>
			{addBlocksTowers == 'open' ? (
				<>
					<div className="absolute fixed mt-10 w-full inset-0 z-50 flex flex-warp justify-center align-middle">
						<div className="w-[300px] my-2">
							<div className="rounded-lg shadow-lg flex flex-col justify-around w-full bg-white">
								<div className="flex flex-row items-center px-4 justify-between py-4 w-full border-b border-black border-opacity-10">
									<div className="flex justify-start item-start flex-col  ">
										<h3 className="text-md font-bold">
											Add Blocks/Towers
										</h3>
										<span className="text-[8px]">Choose how many blocks you want</span>
									</div>
									<span className="flex justify-end cursor-pointer mr-1" onClick={() => handleClose()}>
										<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-x"><path d="M18 6 6 18" /><path d="m6 6 12 12" /></svg>
									</span>
								</div>
								{block.map((item, index) => {
									return <div key={index} className="px-2 flex flex-wrap justify-center align-middle  border-b border-black border-opacity-10" >
										<div className="w-56 flex flex-col item-center justify-between h-10 mx-2 my-2">
											<span className="text-grey-600 text-xs font-bold">Block/Tower {index + 1} </span>
											<span className="text-grey-600 text-xs font-normal">{item}</span>
										</div>
										<div className="w-8  flex items-center justify-center" onClick={() => handleDelete(item)}>
											<svg xmlns="http://www.w3.org/2000/svg" className="" width="12" height="12" viewBox="0 0 24 24" fill="currentColor"><path d="M17 6H22V8H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V8H2V6H7V3C7 2.44772 7.44772 2 8 2H16C16.5523 2 17 2.44772 17 3V6ZM18 8H6V20H18V8ZM13.4142 13.9997L15.182 15.7675L13.7678 17.1817L12 15.4139L10.2322 17.1817L8.81802 15.7675L10.5858 13.9997L8.81802 12.232L10.2322 10.8178L12 12.5855L13.7678 10.8178L15.182 12.232L13.4142 13.9997ZM9 4V6H15V4H9Z"></path></svg>
										</div>
									</div>
								})}

								<div className="flex flex-row items-end justify-around py-2 ">
									<div className="flex flex-col justify-around item-center " >
										<label
											htmlFor="formFile"
											className="block text-xs font-bold mb-2" >
											Add Block Name
										</label>
										<div className="border-0  bg-[#F3F4F6FF] text-xs " >
											<input
												type="text"
												name={"name"}
												className="w-full py-1 bg-black bg-opacity-0 text-xs px-3"
												onChange={(e) => setName(e.target.value)}
												value={name}
											/>
										</div>
									</div>
									<button
										className="bg-[#F2F3FDFF] rounded-xl text-[#3C50E0FF] font-semibold px-3 text-[10px] py-1"
										type="button"
										onClick={() => handleMore()}
									>
										Add More
									</button>
								</div>
								<div className="flex items-center justify-around px-6 py-4 mx-3 ">
									<button
										disabled={name ? true : false}
										className="bg-[#3C50E0FF]  text-white font-bold px-2 text-[10px] py-1 rounded"
										type="button"
										onClick={() => handleProject()}
									>
										Add Project
									</button>
								</div>
							</div>
						</div>
					</div>
					<div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
				</>
			) : null
			}
		</>
	);
}