import { crm } from "../redux/crm/actions";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentCRM } from '../redux/crm/selectors';
import { permissions } from "../redux/permissions/actions";
import { useForm } from "react-hook-form";
import { selectCreatedItem, selectUpdatedItem } from "../redux/permissions/selectors";
export default function AddPermissions() {
	const dispatch = useDispatch();

	const { addPermission, isEditPermission, permissionItem, activePermissionId } = useSelector(selectCurrentCRM);
	const [name, setName] = useState('')
	const [displayText, setDisplayText] = useState('')
	const [displayIndex, setDisplayIndex] = useState('')
	const { register, handleSubmit, clearErrors, formState: { errors }, setValue } = useForm();
	const { isSuccess: isSuccessCreated, error, isNameExists } = useSelector(selectCreatedItem);
	const { isSuccess: isSuccessUpdated } = useSelector(selectUpdatedItem);


	const handleSave = () => {
		if (!isEditPermission) {
			dispatch(permissions.resetAction({ actionType: 'create' }))
			dispatch(permissions.create({
				entity: 'permissions', jsonData: {
					entity:
					{
						name: name,
						displayIndex: displayIndex,
						displayText: displayText
					}
				}
			}))
		}
		else {
			dispatch(permissions.resetAction({ actionType: 'update' }))
			dispatch(permissions.update({
				entity: 'permissions', id: activePermissionId, jsonData: {
					entity: {
						name: name,
						displayIndex: displayIndex,
						displayText: displayText
					}
				}
			}))
		}
	}

	useEffect(() => {
		if (isSuccessCreated) {
			dispatch(crm.trigger({ reloadPermission: true, addPermission: 'close' }))
		}
	}, [isSuccessCreated])

	useEffect(() => {
		if (isSuccessCreated) {
			dispatch(crm.trigger({ reloadPermission: true, addPermission: 'close', isEditPermission: false }))
		}
	}, [isSuccessUpdated])
	useEffect(() => {
		if (addPermission == 'open') {
			clearErrors("name")
			clearErrors("displayText")
			clearErrors("displayIndex")
		}
	}, [addPermission])

	useEffect(() => {
		if (isEditPermission) {
			setValue('name', permissionItem?.name);
			setValue('displayIndex', permissionItem?.displayIndex);
			setValue('displayText', permissionItem?.displayText)
			setName(permissionItem?.name)
			setDisplayIndex(permissionItem?.displayIndex)
			setDisplayText(permissionItem?.displayText)
		} else {
			setValue('name', '');
			setValue('displayIndex', '');
			setValue('displayText', '')
			setName('')
			setDisplayIndex('')
			setDisplayText('')
			if (isNameExists) {
				dispatch(permissions.resetName());
			}
		}

	}, [permissionItem])

	const handleCancel = () => {
		dispatch(crm.trigger({ addPermission: 'close' }))
	}

	return (
		<>
			{addPermission == 'open' ? (
				<>
					<div className="absolute fixed w-full inset-x-0 inset-y-auto  z-50 flex flex-warp justify-center align-middle w-[350px] h-[215px]">
						<div className="w-[300px] my-2 p-3 mx-auto">
							<div className="rounded-lg shadow-lg flex flex-col w-full bg-white">
								<h3 className="text-xs font-bold pt-3 pb-1 px-5">
									{isEditPermission ? 'Edit ' : 'Add '} Permission
								</h3>
								<form onSubmit={handleSubmit(handleSave)} >
									<div className="flex flex-col justify-start align-middle px-5 py-1" >
										<span className="text-blue-600 text-xs font-bold py-1">Name</span>
										<input
											id="name"
											aria-invalid={errors.name ? "true" : "false"}
											{...register('name', {
												required: true, maxLength: 30
											})}
											name={"name"}
											type="text"
											className="border-0 px-3 py-1 bg-black bg-opacity-10  text-xs w-full"
											onChange={(e) => setName(e.target.value)}
										/>
										{errors.name && errors.name.type === "required" && (
											<span role="alert" className="w-full text-red-500 bg-white text-xs my-1">This is required</span>
										)}
										{errors.name && errors.name.type === "maxLength" && (
											<span role="alert" className="w-full text-red-500 bg-white text-xs my-1">Max length exceeded</span>
										)}
										{isNameExists && (
											<span role="alert" className="w-full text-red-500 bg-white text-xs my-1">{error?.message}</span>
										)}
									</div>
									<div className="flex flex-col justify-start align-middle px-5 py-1" >
										<span className="text-blue-600 text-xs font-bold py-1">Display Text</span>
										<input
											id="displayText"
											aria-invalid={errors.displayText ? "true" : "false"}
											{...register('displayText', { required: true, maxLength: 30 })}
											name={"displayText"}
											type="text"
											className="border-0 px-3 py-1 bg-black bg-opacity-10  text-xs w-full"
											onChange={(e) => setDisplayText(e.target.value)}
										/>
										{errors.displayText && errors.displayText.type === "required" && (
											<span role="alert" className="w-full text-red-500 bg-white text-xs my-1">This is required</span>
										)}
										{errors.displayText && errors.displayText.type === "maxLength" && (
											<span role="alert" className="w-full text-red-500 bg-white text-xs my-1">Max length exceeded</span>
										)}
									</div>
									<div className="flex flex-col justify-start align-middle px-5 py-1" >
										<span className="text-blue-600 text-xs font-bold py-1">Display Index</span>
										<input
											id="displayIndex"
											aria-invalid={errors.displayIndex ? "true" : "false"}
											{...register('displayIndex', { required: true })}
											name={"displayIndex"}
											type="text"
											className="border-0 px-3 py-1 bg-black bg-opacity-10  text-xs w-full"
											onChange={(e) => setDisplayIndex(e.target.value)}
										/>
										{errors.displayIndex && errors.displayIndex.type === "required" && (
											<span role="alert" className="w-full text-red-500 bg-white text-xs my-1">This is required</span>
										)}
									</div>
									<div className="flex items-center justify-around px-6 my-4 mx-3 ">
										<button
											className="text-blue-500 bg-transparent rounded font-bold  px-2 text-xs outline py-1"
											type="button"
											onClick={() => handleCancel()}
										>
											Cancel
										</button>
										<input type="submit"
											className="bg-blue-500 text-white rounded  px-2 text-xs py-1 mx-3"
											value={isEditPermission ? 'Update' : 'Save'}
										/>
									</div>
								</form>
							</div>
						</div>
					</div>
					<div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
				</>
			) : null}
		</>
	);
}