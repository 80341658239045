
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Confirmation from "../../modals/Confirmation";
import { crm } from "../../redux/crm/actions";
import { categoryname } from '../../redux/categoryname/actions';
import { selectCurrentCRM } from '../../redux/crm/selectors';
import { selectListItems } from '../../redux/categoryname/selectors';
import AddCategoryName from '../../modals/AddCategoryName';
import { request } from '../../request';
import storePersist from "../../redux/storePersist";

export default function Category() {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	// const [imageUrls, setImageUrls] = useState([])
	const _user = storePersist.get('user')
	const { reloadCategoryName } = useSelector(selectCurrentCRM);
	const { result } = useSelector(selectListItems);
	const { items } = result

	useEffect(() => {
		if (reloadCategoryName) {
			dispatch(categoryname.list({ entity: 'PDI_Category_Name' }))
			dispatch(crm.trigger({ reloadCategoryName: false }))
		}
	}, [reloadCategoryName])

	const handleAddCategory = () => {
		dispatch(crm.trigger({ addCategoryName: 'open', isEditCategoryName: false, categoryNameItem: '' }))
	}

	const handleEditCategory = (id, data) => {
		dispatch(crm.trigger({ addCategoryName: 'open', isEditCategoryName: true, categoryNameItem: data, activeCategoryNameId: id }))
	}

	const handleDeleteCategoryName = (id) => {
		dispatch(crm.trigger({ deleteConfirmation: 'open', deleteMessage: 'Are you sure you want to delete this item ?', deleteItem: { id: id, entity: 'PDI_Category_Name' } }))
	}

	useEffect(() => {
		dispatch(categoryname.list({ entity: 'PDI_Category_Name' }))
	}, [])

	return <>
		<header className="flex justify-between h-24 w-full mx-auto px-5  items-center bg-[#F9F9FAFF]">
			<div className="flex flex-col justify-start w-full" >
				<nav aria-label="breadcrumb" className="w-max" >
					<ol className="flex flex-wrap items-center w-full px-4 py-1 rounded-md ">
						<li
							className="flex items-center font-sans text-sm antialiased font-Editrmal leading-Editrmal transition-colors duration-300 cursor-pointer text-blue-gray-900 hover:text-light-blue-500">
							<h3 className="text-xl font-bold">Settings</h3>
						</li>
					</ol>
				</nav>
				<div className="flex justify-start w-full py-2 mx-3" >
					<button onClick={() => navigate('/inspection-crm/settings')} className="w-auto mx-1 button     text-black  text-opacity-40  px-3 rounded  text-xs font-bold"
					> Checklist
					</button>
					<button onClick={() => navigate('/inspection-crm/category')} className="w-auto button rounded  text-blue-600   px-3 rounded text-xs font-bold"
					> Category
					</button>
					{_user?.roleId?._id == "5f616169b7b6a1367e1bfdb9" ? <button onClick={() => navigate('/inspection-crm/master-settings')} className="w-auto mx-1 button     text-black  text-opacity-40  px-3 rounded  text-xs font-bold"
					> Master Settings
					</button> : <></>}

				</div>
			</div>
		</header>
		<Confirmation />
		<AddCategoryName />
		<div className="flex flex-col justify-start items-start h-screen  bg-[#F9F9FAFF]">
			<div className="flex flex-row w-full mx-10 justify-start items-start text-xs w-full">
				<div className="rounded shadow-xl bg-white mx-4 py-4 px-6 h-full">
					<div className="flex flex-row justify-between items-center">
						<div className="py-4 text-[14px] font-bold px-3 ">
							Checklist Category
						</div>
						<span
							className="cursor-pointer px-2 py-1 text-center text-blue-900 font-bold text-[9px] bg-[#F2F3FDFF] border-x border-y rounded-xl"
							onClick={() => handleAddCategory()}	>Add More
						</span>
					</div>
					<div className="felx flex-row space-y-3 p-3 border-y border-black">
						{items.map((obj, index) => {
							return <div key={index} className="flex flex-row justify-around align-middle item-center space-x-5 border-black" >
								<img
									className="h-4 w-4"
									src={obj.IconBase64}
									alt="image-text"
								/>
								<div className="w-[500px]">{obj.Name}</div>
								<div className="flex flex-row justify-center item-center space-x-2 ">
									<div
										onClick={() => handleEditCategory(obj._id, obj)}
										className="cursor-pointer flex flex-row justify-between item-center px-2  border-x border-y border-color rounded-xl">
										<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24"><path d="M19.045 7.401c.378-.378.586-.88.586-1.414s-.208-1.036-.586-1.414l-1.586-1.586c-.378-.378-.88-.586-1.414-.586s-1.036.208-1.413.585L4 13.585V18h4.413L19.045 7.401zm-3-3 1.587 1.585-1.59 1.584-1.586-1.585 1.589-1.584zM6 16v-1.585l7.04-7.018 1.586 1.586L7.587 16H6zm-2 4h16v2H4z" /></svg>
										<span

											className="text-center mx-1 text-[9px]  font-semibold  ">
											Edit
										</span>
									</div>
									<div className="cursor-pointer" onClick={() => handleDeleteCategoryName(obj._id)}>
										<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="currentColor"><path d="M17 6H22V8H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V8H2V6H7V3C7 2.44772 7.44772 2 8 2H16C16.5523 2 17 2.44772 17 3V6ZM18 8H6V20H18V8ZM13.4142 13.9997L15.182 15.7675L13.7678 17.1817L12 15.4139L10.2322 17.1817L8.81802 15.7675L10.5858 13.9997L8.81802 12.232L10.2322 10.8178L12 12.5855L13.7678 10.8178L15.182 12.232L13.4142 13.9997ZM9 4V6H15V4H9Z"></path></svg>
									</div>
								</div>
							</div>
						})}
					</div>
				</div>
			</div>
		</div>
	</>
}