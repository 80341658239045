import { crm } from "../redux/crm/actions";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentCRM } from '../redux/crm/selectors';
import { selectListItems } from '../redux/projecttype/selectors';
import { selectListItems as selectStatusListItems } from '../redux/inspectionstatus/selectors';
import { selectListItems as selectUserListItems } from '../redux/users/selectors';
import { selectListItems as selectStageListItems } from '../redux/inspectionstage/selectors';
import { selectListItems as selectBedroomsListItems } from '../redux/numberofbedrooms/selectors';
import { selectListItems as selectProjectTypeListItems } from '../redux/projecttype/selectors';
import { selectListItems as selectTypeOfHouseListItems } from '../redux/typeofhouse/selectors';
import { selectListItems as selectMasterItems } from '../redux/masterchecklist/selectors';
import { inspectionstatus } from "../redux/inspectionstatus/actions";
import { inspectionstage } from "../redux/inspectionstage/actions";
import { numberofbedrooms } from "../redux/numberofbedrooms/actions";
import { customerdetails } from "../redux/customerdetails/actions";
import { projecttype } from "../redux/projecttype/actions";
import { typeofhouse } from "../redux/typeofhouse/actions";
import { masterchecklist } from "../redux/masterchecklist/actions";
import { useForm } from "react-hook-form";
import { selectItemsFilter as projectUserFiter } from '../redux/projectusers/selectors';
import { projectusers } from "../redux/projectusers/actions";


export default function AddCustomer() {
	const dispatch = useDispatch();
	const { addCustomer, activeBoardName, activeCustomerItem, activeProjectId, activeCustomerId, isEditCustomerDetails, projectItem } = useSelector(selectCurrentCRM);
	const { result } = useSelector(selectListItems)
	const { register, handleSubmit, clearErrors, formState: { errors }, setValue } = useForm()

	const { result: masterResult } = useSelector(selectMasterItems)
	const { items: _items } = result
	const { items: masteritems } = masterResult
	const { result: statusResult } = useSelector(selectStatusListItems)
	const { result: userResult } = useSelector(selectUserListItems)
	const { result: stageResult } = useSelector(selectStageListItems)
	const { result: bedroomsResult } = useSelector(selectBedroomsListItems)
	const { result: projectTypeResult } = useSelector(selectProjectTypeListItems)
	const { result: typeOfHouseResult } = useSelector(selectTypeOfHouseListItems)
	const { result: projectUserResult } = useSelector(projectUserFiter)
	const { items: projectUser } = projectUserResult
	const { items: statusitems } = statusResult
	const { items: bedroomsitems } = bedroomsResult
	const { items: useritems } = userResult
	const { items: stageitems } = stageResult
	const { items: projectTypeitems } = projectTypeResult
	const { items: typeOfHouseitems } = typeOfHouseResult
	const [steps, setSteps] = useState({});
	const [block, setBlock] = useState([])
	const [userIds, setUserIds] = useState([])


	const handleClose = () => {
		dispatch(crm.trigger({ addCustomer: 'close', }))
	}

	const handleSteps = (e, key) => {
		setSteps({ ...steps, [key]: e.target.value })
	}

	const handleReset = () => {
		setSteps({ firstName: '', lastName: '', mobile: '', email: '', Block: '', Type: '', UnitDetails: '', AssignCRM: '', AssignSiteEngineer: '', Status: '', Stage: '', MasterChecklist: '' })
		clearErrors('firstName')
		clearErrors('lastName')
		clearErrors('email')
		clearErrors('mobile')
		clearErrors('Block')
		clearErrors('NumberOfBedrooms')
		clearErrors('ProjectType')
		clearErrors('TypeOfHouse')
		clearErrors('UnitDetails')
		clearErrors('AssignCRM')
		clearErrors('AssignSiteEngineer')
		clearErrors('MasterChecklist')
		clearErrors('Stage')
		clearErrors('Status')
	}

	const handleSave = () => {
		if (isEditCustomerDetails) {
			dispatch(customerdetails.update({
				entity: 'PDI_Customer_Details', id: activeCustomerId, jsonData: {
					entity: {
						"firstName": steps['firstName'],
						"lastName": steps['lastName'],
						"email": steps['email'],
						"mobile": steps['mobile'],
						"project": activeProjectId,
						"Block": steps['Block'],
						"TypeOfHouse": steps['TypeOfHouse'],
						"NumberOfBedrooms": steps['NumberOfBedrooms'],
						"ProjectType": steps['ProjectType'],
						"UnitDetails": steps['UnitDetails'],
						"AssignCRM": steps['AssignCRM'],
						"AssignSiteEngineer": steps['AssignSiteEngineer'],
						"Stage": steps['Stage'],
						"Status": steps['Status'],
						"MasterChecklist": steps['MasterChecklist'],
						"project": activeProjectId
					}
				}
			})).then(() => {
				dispatch(crm.trigger({ reloadCustomerDetails: true, addCustomer: 'close', isEditCustomerDetails: false }))
			})
		}
		else {
			dispatch(customerdetails.create({
				entity: 'PDI_Customer_Details', jsonData: {
					entity: {
						"firstName": steps['firstName'],
						"lastName": steps['lastName'],
						"email": steps['email'],
						"mobile": steps['mobile'],
						"project": activeProjectId,
						"Block": steps['Block'],
						"TypeOfHouse": steps['TypeOfHouse'],
						"NumberOfBedrooms": steps['NumberOfBedrooms'],
						"ProjectType": steps['ProjectType'],
						"UnitDetails": steps['UnitDetails'],
						"AssignCRM": steps['AssignCRM'],
						"AssignSiteEngineer": steps['AssignSiteEngineer'],
						"Stage": steps['Stage'],
						"Status": steps['Status'],
						"MasterChecklist": steps['MasterChecklist']
					}
				}
			})).then(() => {
				dispatch(crm.trigger({ reloadCustomerDetails: false, reloadCustomerCreated: true, addCustomer: 'close', masterChecklistId: steps['MasterChecklist'] }))
			})
		}
	}

	useEffect(() => {
		const block = projectItem?.BlockTower
		if (block != "") {
			setBlock(block?.split(','))
		}
		else {
			setBlock([])
		}
	}, [projectItem])

	useEffect(() => {
		const _userIds = []
		Promise.all(projectUser.map((item) => {
			_userIds.push(item.User._id)
		}))
		setUserIds(_userIds)
	}, [projectUser])

	useEffect(() => {
		dispatch(inspectionstatus.list({ entity: 'PDI_Inspection_Status', options: {} }))
		dispatch(masterchecklist.list({ entity: 'PDI_Master_Checklist', options: {} }))
		dispatch(inspectionstage.list({ entity: 'PDI_Inspection_Stage', options: {} }))
		dispatch(numberofbedrooms.list({ entity: 'PDI_Number_Of_Bedrooms', options: {} }))
		dispatch(projecttype.list({ entity: 'PDI_Project_Configuration', options: {} }))
		dispatch(typeofhouse.list({ entity: 'PDI_Type_Of_House', options: {} }))
		dispatch(projectusers.filter({ entity: 'PDI_Project_Users', jsonData: { params: { isDeleted: false, ProjectName: activeProjectId }, offset: 200, limit: 200 } })).then(() => {
			if (isEditCustomerDetails) {
				setValue('firstName', activeCustomerItem?.firstName)
				setValue('lastName', activeCustomerItem?.lastName)
				setValue('mobile', activeCustomerItem?.mobile)
				setValue('email', activeCustomerItem?.email)
				setValue('Block', activeCustomerItem?.Block)
				setValue('ProjectType', activeCustomerItem?.ProjectType?._id)
				setValue('TypeOfHouse', activeCustomerItem?.TypeOfHouse?._id)
				setValue('UnitDetails', activeCustomerItem?.UnitDetails)
				setValue('Status', activeCustomerItem.Status?._id)
				setValue('Stage', activeCustomerItem.Stage?._id)
				setValue('NumberOfBedrooms', activeCustomerItem.NumberOfBedrooms?._id)
				setValue('MasterChecklist', activeCustomerItem.MasterChecklist)
				setValue('project', activeCustomerItem?.project?.ProjectName)
				setValue('AssignCRM', activeCustomerItem?.AssignCRM?._id)
				setValue('AssignSiteEngineer', activeCustomerItem.AssignSiteEngineer?._id)
			}
			else {
				setSteps({
					firstName: '', lastName: '', mobile: '', email: '', Block: '', ProjectType: '', UnitDetails: '',
					AssignCRM: '', AssignSiteEngineer: '', Status: '', Stage: '', NumberOfBedrooms: '', MasterChecklist: ''
				})
			}
		})
	}, [activeCustomerItem])

	useEffect(() => {
		if (!isEditCustomerDetails) {
			setValue('project', activeBoardName)
			setSteps({ firstName: '', lastName: '', mobile: '', email: '', Block: '', Type: '', UnitDetails: '', AssignCRM: '', AssignSiteEngineer: '', Status: '', Stage: '', MasterChecklist: '' })
		}
	}, [isEditCustomerDetails])

	useEffect(() => {
		clearErrors('firstName')
		clearErrors('lastName')
		clearErrors('email')
		clearErrors('mobile')
		clearErrors('Block')
		clearErrors('NumberOfBedrooms')
		clearErrors('ProjectType')
		clearErrors('TypeOfHouse')
		clearErrors('UnitDetails')
		clearErrors('AssignCRM')
		clearErrors('AssignSiteEngineer')
		clearErrors('MasterChecklist')
		clearErrors('Stage')
		clearErrors('Status')
	}, [addCustomer])

	return (
		<>
			<div className="absolute fixed w-full mt-10 inset-0 z-50 flex flex-warp justify-center align-middle">
				<div className="w-full my-2 mx-auto max-w-md">
					<form onSubmit={handleSubmit(handleSave)} >
						<div className="rounded-lg shadow-lg flex flex-col w-full bg-white">
							<div className="flex items-center justify-between mx-2 mr-4 py-2 my-2">
								<div className="flex flex-col items-start justify-start mx-1 mt-3 mb-4">
									<span className="text-xl">Customer Details </span>
									<span className="text-xs px-1">{isEditCustomerDetails ? 'edit' : 'add '} customer & unit details </span>
								</div>
								<div className="cursor-pointer ">
									<svg onClick={() => handleClose()} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-x-circle"><circle cx="12" cy="12" r="10" /><path d="m15 9-6 6" /><path d="m9 9 6 6" /></svg>
								</div>
							</div>
							<div className="flex flex-row space-x-2 px-4 w-full my-2">
								<div className="w-1/2">
									<label
										className="block  text-xs font-bold"
										htmlFor="grid-password">
										First Name
									</label>
									<div className="border-0  bg-[#F3F4F6FF] text-xs " >
										<input
											type="text"
											id="firstName"
											name="firstName"
											{...register('firstName', { required: true, maxLength: 30 })}
											className="w-full px-3 py-1 bg-[#F3F4F6FF] text-xs  "
											onChange={(e) => handleSteps(e, 'firstName')}
										/>
									</div>
									{errors.firstName && errors.firstName.type === "required" && (
										<span role="alert" className="w-full text-red-500 bg-white text-xs my-1">Please fill in the first name field.</span>
									)}
									{errors.firstName && errors.firstName.type === "maxLength" && (
										<span role="alert" className="w-full text-red-500 bg-white text-xs my-1">Max length exceeded</span>
									)}
								</div>
								<div className="w-1/2">
									<label
										className="block text-xs font-bold"
										htmlFor="grid-password">
										Last Name
									</label >
									<div className="border-0  bg-[#F3F4F6FF] text-xs " >
										<input
											type="text"
											id="lastName"
											name="lastName"
											{...register('lastName', { required: true, maxLength: 30 })}
											placeholder=""
											className="w-full px-3 py-1 bg-[#F3F4F6FF] text-xs"
											onChange={(e) => handleSteps(e, 'lastName')}
										/>
									</div>
									{errors.lastName && errors.lastName.type === "required" && (
										<span role="alert" className="w-full text-red-500 bg-white text-xs my-1">Please fill in the last name field.</span>
									)}
									{errors.lastName && errors.lastName.type === "maxLength" && (
										<span role="alert" className="w-full text-red-500 bg-white text-xs my-1">Max length exceeded</span>
									)}
								</div>
							</div>
							<div className="flex flex-row space-x-2 px-4 w-full my-2">
								<div className="w-1/2">
									<label
										className="block  text-xs font-bold"
										htmlFor="grid-password">
										Email
									</label>
									<div className="border-0  bg-[#F3F4F6FF] text-xs " >
										<input
											type="text"
											id="email"
											name="email"
											{...register('email', {
												required: true,
												pattern:
													/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
											})}
											className="w-full px-3 py-1 bg-[#F3F4F6FF] text-xs"
											onChange={(e) => handleSteps(e, 'email')}
										/>
									</div>
									{errors.email && errors.email.type === "required" && (
										<span role="alert" className="w-full text-red-500 bg-white text-xs my-1">Please fill in the email field.</span>
									)}
									{errors.email && errors.email.type === "maxLength" && (
										<span role="alert" className="w-full text-red-500 bg-white text-xs my-1">Max length exceeded</span>
									)}
								</div>
								<div className="w-1/2">
									<label
										className="block text-xs font-bold"
										htmlFor="grid-password">
										Mobile Number
									</label >
									<div className="border-0  bg-[#F3F4F6FF] text-xs " >
										<input
											type="text"
											id="mobile"
											name="mobile"
											{...register('mobile', {
												required: true,
												maxLength: 10,
												minLength: 10,
												pattern: /^[0-9+-]+$/
											})}
											placeholder=""
											className="w-full px-3 py-1 bg-[#F3F4F6FF] text-xs"
											onChange={(e) => handleSteps(e, 'mobile')}
										/>
									</div>
									{errors.mobile && errors.mobile.type === "required" && (
										<span role="alert" className="w-full text-red-500 bg-white text-xs my-1">This is required</span>
									)}
									{errors.mobile && errors.mobile.type === "maxLength" && (
										<span role="alert" className="w-full text-red-500 bg-white text-xs my-1">Please enter valid mobile number</span>
									)}
									{errors.mobile && errors.mobile.type === "minLength" && (
										<span role="alert" className="w-full text-red-500 bg-white text-xs my-1">Please enter valid mobile number</span>
									)}
									{errors.mobile && errors.mobile.type === "pattern" && (
										<span role="alert" className="w-full text-red-500 bg-white text-xs my-1">Please enter valid mobile number</span>
									)}
								</div>
							</div>
							<div className="my-2 border-b border-black border-opacity-20"></div>
							<div className="flex flex-row space-x-2 px-4 w-full my-2">
								<div className="w-full">
									<label
										className="block  text-xs font-bold"
										htmlFor="grid-password">
										Project
									</label>
									<div className="border-0  bg-[#F3F4F6FF] text-xs " >
										<input
											type="text"
											id="project"
											name="project"
											{...register('project', {
												required: true
											})}
											className="w-full px-3 py-1 bg-[#F3F4F6FF] text-xs"
											disabled={true}
										/>
									</div>
								</div>
							</div>
							<div className="flex flex-row space-x-2 px-4 w-full my-4">
								<div className="relative w-1/2">
									<label
										className="block text-xs font-bold mb-2"
										htmlFor="grid-password" >
										Select Block
									</label>
									<div className="px-3 border-0  bg-[#F3F4F6FF] text-xs " >
										<select
											{...register('Block', { required: true })}
											className="w-full py-1 bg-[#F3F4F6FF] text-xs"
											name="Block"
											onChange={(e) => handleSteps(e, 'Block')}
										// value={activeCustomerItem?.Block}
										>
											<option value="" >--Select--</option>
											{block?.map((item, index) => {
												return <option value={item} key={index}> {item}</option>
											})}
										</select>
									</div>
									{errors.Block && errors.Block.type === "required" && (
										<span role="alert" className="w-full text-red-500 bg-white text-xs my-1">Please fill in the bedroom field.</span>
									)}
								</div>
								<div className="w-1/2">
									<label
										className="block text-xs font-bold mb-2"
										htmlFor="grid-password">
										Select Number Of Bedrooms
									</label>
									<div className="px-3 border-0  bg-[#F3F4F6FF] text-xs " >
										<select
											{...register('NumberOfBedrooms', { required: true })}
											className="w-full py-1 bg-[#F3F4F6FF] text-xs"
											onChange={(e) => handleSteps(e, 'NumberOfBedrooms')}
										>
											<option value="" >--Select--</option>
											{bedroomsitems?.filter(item1 => projectItem?.NumberOfBedrooms?.some(item2 => item2[0]?.value == item1?._id))?.map((item, index) => {
												return <option value={item?._id} key={index}> {item?.Name}</option>
											})}
										</select>
									</div>
									{errors.NumberOfBedrooms && errors.NumberOfBedrooms.type === "required" && (
										<span role="alert" className="w-full text-red-500 bg-white text-xs my-1">Please fill in the bedroom field.</span>
									)}
								</div>
							</div>
							<div className="flex flex-row space-x-2 px-4 w-full my-4">
								<div className="relative w-1/2">
									<label
										className="block text-xs font-bold mb-2"
										htmlFor="grid-password" >
										Select Project Type
									</label>
									<div className="px-3 border-0  bg-[#F3F4F6FF] text-xs " >
										<select
											{...register('ProjectType', { required: true })}
											className="w-full  py-1 bg-[#F3F4F6FF] text-xs"
											onChange={(e) => handleSteps(e, 'ProjectType')}
										>
											<option value="">--Select--</option>
											{projectTypeitems.filter(item1 => projectItem?.ProjectType?.some(item2 => item2[0]?.value == item1?._id))?.map((item, index) => {
												return <option value={item._id} key={index}> {item.Name}</option>
											})}
										</select>
									</div>
									{errors.ProjectType && errors.ProjectType.type === "required" && (
										<span role="alert" className="w-full text-red-500 bg-white text-xs my-1">Please fill in the project type field.</span>
									)}
								</div>
								<div className="w-1/2">
									<label
										className="block text-xs font-bold mb-2"
										htmlFor="grid-password">
										Select Type Of House
									</label>
									<div className="px-3 border-0  bg-[#F3F4F6FF] text-xs " >
										<select
											{...register('TypeOfHouse', { required: true })}
											className="w-full py-1 bg-[#F3F4F6FF] text-xs"
											onChange={(e) => handleSteps(e, 'TypeOfHouse')}
										>
											<option value="">--Select--</option>
											{typeOfHouseitems.filter(item1 => projectItem?.TypeOfHouse?.some(item2 => item2[0]?.value == item1?._id))?.map((item, index) => {
												return <option value={item._id} key={index}> {item.Name}</option>
											})}
										</select>
									</div>
									{errors.TypeOfHouse && errors.TypeOfHouse.type === "required" && (
										<span role="alert" className="w-full text-red-500 bg-white text-xs my-1">Please fill in the type of house field.</span>
									)}
								</div>
							</div>
							<div className="flex flex-row space-x-2 px-4 w-full my-2">
								<div className="w-1/2">
									<label
										className="block  text-xs font-bold"
										htmlFor="grid-password">
										Enter Unit Details
									</label>
									<div className="border-0  bg-[#F3F4F6FF] text-xs " >
										<input

											type="text"
											id="UnitDetails"
											name="UnitDetails"
											{...register('UnitDetails', { required: true, maxLength: 10 })}
											className="w-full px-3 py-1 bg-[#F3F4F6FF] text-xs"
											onChange={(e) => handleSteps(e, 'UnitDetails')}
										/>
									</div>
									{errors.UnitDetails && errors.UnitDetails.type === "required" && (
										<span role="alert" className="w-full text-red-500 bg-white text-xs my-1">Please fill in the unit details field.</span>
									)}
									{errors.UnitDetails && errors.UnitDetails.type === "maxLength" && (
										<span role="alert" className="w-full text-red-500 bg-white text-xs my-1">Max length exceeded</span>
									)}
								</div>
								<div className="w-1/2">
									<label
										className="block text-xs font-bold"
										htmlFor="grid-password">
										Select Master Snag List
									</label>
									<div className="px-3 border-0  bg-[#F3F4F6FF] text-xs " >
										<select
											className="w-full py-1 bg-[#F3F4F6FF] text-xs"
											{...register('MasterChecklist', { required: true })}
											onChange={(e) => handleSteps(e, 'MasterChecklist')}
										>
											<option value="" >--Select--</option>
											{masteritems.map((item, index) => {
												return <option value={item._id} key={index}> {item.Name}</option>
											})}
										</select>
									</div>

								</div>
							</div>
							<div className="my-4 border-b border-black border-opacity-20"></div>
							<div className="flex flex-row space-x-2 px-4 w-full my-2">
								<div className="w-1/2">
									<label
										className="block text-xs font-bold mb-2"
										htmlFor="grid-password" >
										CRM Executive
									</label>
									<div className="px-3 border-0  bg-[#F3F4F6FF] text-xs " >
										<select
											{...register('AssignCRM', { required: true })}
											className="w-full py-1 bg-[#F3F4F6FF] text-xs"
											onChange={(e) => handleSteps(e, 'AssignCRM')}
										>
											<option value="" >--Select--</option>
											{useritems?.filter(item1 => userIds?.some(item2 => item2 == item1?._id))?.filter((obj) => obj?.roleId?._id == '65dfe45c6ad846404c6cc572')?.map((item, index) => {
												return <option value={item?._id} key={index}> {item?.userId}</option>
											})}
										</select>
									</div>
									{errors.AssignCRM && errors.AssignCRM.type === "required" && (
										<span role="alert" className="w-full text-red-500 bg-white text-xs my-1">Please fill in the assign crm.</span>
									)}
								</div>
								<div className=" w-1/2">
									<label
										className="block text-xs font-bold mb-2"
										htmlFor="grid-password" >
										QC/Engineer
									</label>
									<div className="px-3 border-0  bg-[#F3F4F6FF] text-xs " >
										<select
											{...register('AssignSiteEngineer', { required: true })}
											className="w-full py-1 bg-black bg-opacity-0 text-xs"
											onChange={(e) => handleSteps(e, 'AssignSiteEngineer')}
										>
											<option value="">--Select--</option>
											{useritems?.filter(item1 => userIds?.some(item2 => item2 == item1?._id))?.filter((obj) => obj?.roleId?._id == '65dfe4766ad846404c6cc573')?.map((item, index) => {
												return <option value={item?._id} key={index}> {item?.userId}</option>
											})}
										</select>
									</div>
									{errors.AssignSiteEngineer && errors.AssignSiteEngineer.type === "required" && (
										<span role="alert" className="w-full text-red-500 bg-white text-xs my-1">Please fill in the QC/Engineer.</span>
									)}
								</div>
							</div>
							<div className="flex flex-row space-x-2 px-4 w-full my-2">
								<div className=" w-1/2">
									<label
										className="block text-xs font-bold mb-2"
										htmlFor="grid-password" >
										Stage
									</label>
									<div className="px-3 border-0  bg-[#F3F4F6FF] text-xs " >
										<select
											{...register('Stage', { required: true })}
											className="w-full py-1 bg-black bg-opacity-0 text-xs"
											onChange={(e) => handleSteps(e, 'Stage')}
										>
											<option value="" >--Select--</option>
											{stageitems.map((item, index) => {
												return <option value={item._id} key={index}> {item.Name}</option>
											})}
										</select>
									</div>
									{errors.Stage && errors.Stage.type === "required" && (
										<span role="alert" className="w-full text-red-500 bg-white text-xs my-1">Please fill in the stage field.</span>
									)}
								</div>
								<div className="w-1/2">
									<label
										className="block text-xs font-bold mb-2"
										htmlFor="grid-password" >
										Status
									</label>
									<div className="px-3 border-0  bg-[#F3F4F6FF] text-xs " >
										<select
											{...register('Status', { required: true })}
											className="w-full py-1 bg-black bg-opacity-0 text-xs"
											onChange={(e) => handleSteps(e, 'Status')}
										>
											<option value="">--Select--</option>
											{statusitems.map((item, index) => {
												return <option value={item._id} key={index}> {item.Name}</option>
											})}
										</select>
									</div>
									{errors.Status && errors.Status.type === "required" && (
										<span role="alert" className="w-full text-red-500 bg-white text-xs my-1">Please fill in the status field.</span>
									)}
								</div>
							</div>
							<div className="flex items-center justify-end space-x-3 px-6 py-4 mx-3 ">
								<button
									className="text-black rounded text-opacity-40 bg-transparent font-bold  px-2 text-xs  py-1"
									type="button"
									onClick={() => handleReset()}
								>
									Reset
								</button>
								<input type="submit"
									className="bg-blue-500 text-white rounded  px-2 text-xs py-1"
									value={isEditCustomerDetails ? 'Update' : 'Add'}
								/>
							</div>
						</div>
					</form>
				</div>
			</div >
			<div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
		</>
	);
}