import { createSelector } from 'reselect';

const selectInspectionStage = (state) => state.inspectionstage;

export const selectCurrentItem = createSelector([selectInspectionStage], (inspectionstage) => inspectionstage.current);

export const selectListItems = createSelector([selectInspectionStage], (inspectionstage) => inspectionstage.list);


export const selectItemById = (itemId) =>
	createSelector(selectListItems, (list) => list.result.items.find((item) => item._id === itemId));

export const selectCreatedItem = createSelector([selectInspectionStage], (inspectionstage) => inspectionstage.create);

export const selectUpdatedItem = createSelector([selectInspectionStage], (inspectionstage) => inspectionstage.update);

export const selectReadItem = createSelector([selectInspectionStage], (inspectionstage) => inspectionstage.read);

export const selectDeletedItem = createSelector([selectInspectionStage], (inspectionstage) => inspectionstage.delete);

export const selectSearchedItems = createSelector([selectInspectionStage], (inspectionstage) => inspectionstage.search);

export const selectItemsCount = createSelector([selectInspectionStage], (inspectionstage) => inspectionstage.count);

export const selectItemsFilter = createSelector([selectInspectionStage], (inspectionstage) => inspectionstage.filter);



