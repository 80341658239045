import { createSelector } from 'reselect';

const selectIssueChangeType = (state) => state.issuechangetype;

export const selectCurrentItem = createSelector([selectIssueChangeType], (issuechangetype) => issuechangetype.current);

export const selectListItems = createSelector([selectIssueChangeType], (issuechangetype) => issuechangetype.list);


export const selectItemById = (itemId) =>
	createSelector(selectListItems, (list) => list.result.items.find((item) => item._id === itemId));

export const selectCreatedItem = createSelector([selectIssueChangeType], (issuechangetype) => issuechangetype.create);

export const selectUpdatedItem = createSelector([selectIssueChangeType], (issuechangetype) => issuechangetype.update);

export const selectReadItem = createSelector([selectIssueChangeType], (issuechangetype) => issuechangetype.read);

export const selectDeletedItem = createSelector([selectIssueChangeType], (issuechangetype) => issuechangetype.delete);

export const selectSearchedItems = createSelector([selectIssueChangeType], (issuechangetype) => issuechangetype.search);

export const selectItemsCount = createSelector([selectIssueChangeType], (issuechangetype) => issuechangetype.count);

export const selectItemsFilter = createSelector([selectIssueChangeType], (issuechangetype) => issuechangetype.filter);



