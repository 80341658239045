import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash'
import 'simple-react-donut-chart/src/style.css'
import { useForm } from "react-hook-form";
import { selectCurrentCRM } from "../../redux/crm/selectors"
import { selectListItems } from '../../redux/categoryname/selectors';
import { selectItemsFilter as selectProjectListItems } from '../../redux/projectchecklist/selectors.js';
import { selectItemsFilter as selectProjectAddonList } from '../../redux/projectaddon/selectors.js';
import { selectListItems as selectStatusListItems } from '../../redux/inspectionstatus/selectors';
import { selectItemsFilter } from '../../redux/customerdetails/selectors';
import { categoryname } from "../../redux/categoryname/actions";
import { crm } from "../../redux/crm/actions";
import { customerdetails } from "../../redux/customerdetails/actions";
import { projectchecklist } from "../../redux/projectchecklist/actions.js";
import Confirmation from "../../modals/Confirmation.js";
import { projectaddon } from '../../redux/projectaddon/actions';


const getOnLineStatus = () =>
	typeof navigator !== 'undefined' && typeof navigator.onLine === 'boolean'
		? navigator.onLine
		: true;
export default function CategoryList() {
	const dispatch = useDispatch();
	const navigate = useNavigate()
	const [checklistCount, setChecklistCount] = useState(0)
	const [addonCount, setAddonCount] = useState(0)
	const [checklistTotalCount, setChecklistTotalCount] = useState(0)
	const [addonTotalCount, setAddonTotalCount] = useState(0)
	const [status, setStatus] = useState(1)
	const [networkStatus, setNetworkStatus] = useState(getOnLineStatus());
	const setOffline = () => setNetworkStatus(false);
	const setOnline = () => setNetworkStatus(true);
	const { register, formState: { errors } } = useForm()
	const { result: statusResult } = useSelector(selectStatusListItems)
	const { items: statusitems } = statusResult;
	const { activeCustomerId, activeCustomer } = useSelector(selectCurrentCRM)
	const { result } = useSelector(selectListItems);
	const { items } = result
	const { result: resultProject } = useSelector(selectProjectListItems);
	const { result: resultAddon } = useSelector(selectProjectAddonList)
	const { items: addonItems } = resultAddon
	const { items: projectitems } = resultProject;
	const { result: customerResult } = useSelector(selectItemsFilter);
	const { items: customerItem } = customerResult;
	const [imageUrls, setImageUrls] = useState([])
	const [filterCategory, setFilterCategory] = useState([])

	useEffect(() => {
		window.scrollTo(0, 0);
		window.addEventListener('online', setOnline);
		window.addEventListener('offline', setOffline);
		return () => {
			window.removeEventListener('online', setOnline);
			window.removeEventListener('offline', setOffline);
		};
	}, [])

	const getImage = (id) => {
		let url = "https://proptech-pdi.s3.amazonaws.com/" + imageUrls?.filter((obj) => obj.id == id)[0]?.url
		return url
	}

	const handleStatus = (e) => {
		const status = e.target.value
		dispatch(customerdetails.update({
			entity: 'PDI_Customer_Details', id: activeCustomerId, jsonData: {
				entity: {
					"Status": status,
				}
			}
		})).then(() => {
			dispatch(customerdetails.filter({ entity: 'PDI_Customer_Details', jsonData: { params: { _id: activeCustomerId }, page: 1, limit: 200 } }))
		})
	}

	useEffect(() => {
		setStatus(customerItem[0]?.Status?._id)
	}, [customerItem])

	const getImageUrl = async () => {
		let images = []
		await Promise.all(items.map(async (item) => {
			images.push({ id: item._id, url: item.IconName })
		}))
		setImageUrls(images)
	}

	useEffect(() => {
		getImageUrl()
	}, [items])

	useEffect(() => {
		const list = projectitems[0]?.Checklist.map(obj => obj?.Item?.CategoryName?.Name).filter((value, index, self) => self.indexOf(value) === index)
		const list1 = projectitems[0]?.Checklist.map(obj => obj?.Item?.CategoryName?._id).filter((value, index, self) => self.indexOf(value) === index)
		let result = []
		list?.map((item, idx) => {
			result.push({ Name: item, _id: list1[idx] })
		})
		setFilterCategory(result)
		const notDone = projectitems[0]?.Checklist?.filter((checkls) => checkls?.Status == '')
		setChecklistCount(notDone?.length)
		setChecklistTotalCount(projectitems[0]?.Checklist?.length)
	}, [projectitems])

	useEffect(() => {
		const notDone = addonItems?.filter((checkls) => checkls?.Status == '')
		setAddonCount(notDone?.length)
		setAddonTotalCount(addonItems?.length)
	}, [addonItems])

	const handleCategory = (obj) => {
		const activeCategory = items.filter((item) => item?._id == obj?._id)
		dispatch(crm.trigger({ activeCategoryName: activeCategory[0] }))
		navigate('/mobile/task-items')
	}

	useEffect(() => {
		if (networkStatus) {
			dispatch(projectchecklist.filter({ entity: 'PDI_Project_Checklist', jsonData: { params: { UnitDetails: activeCustomerId }, page: 1, limit: 200 } }))
			dispatch(categoryname.list({ entity: 'PDI_Category_Name', options: {} }))
			dispatch(customerdetails.filter({ entity: 'PDI_Customer_Details', jsonData: { params: { _id: activeCustomerId }, page: 1, limit: 200 } }))
			dispatch(projectaddon.filter({ entity: 'PDI_Project_Addon_Checklist', jsonData: { params: { UnitDetails: activeCustomerId }, page: 1, limit: 200 } }))

		}
	}, [])

	return (
		<>
			<div className="max-h-screen w-full bg-[#F9F9FA]">
				<header className="flex fixed top-0 left-0  justify-center w-full h-20 items-center bg-[#3C50E0FF] text-white">

					<div className="flex flex-row text-xl font-bold">
						<button
							onClick={() => navigate('/mobile/inspection-items')}
							className="absolute fixed left-10  cursor-pointer">
							<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#ffffff" viewBox="0 0 24 24"><path d="M21 11H6.414l5.293-5.293-1.414-1.414L2.586 12l7.707 7.707 1.414-1.414L6.414 13H21z" /></svg>
						</button>
						Category List
					</div>
				</header >
				<Confirmation />

				<div className="flex flex-col pt-20  px-3 m-3 overflow-auto h-full">
					<div className="w-full flex flex-row justify-around items-center space-x-3 h-10">
						<label
							className="text-xs font-bold"
						>
							Status
						</label>
						<div className="px-3 border-x border-y border-black   bg-[#F3F4F6FF] text-xs " >
							<select
								className="w-auto py-1  px-5 bg-[#F3F4F6FF] text-xs"
								name="Status"
								{...register('Status', { required: true })}
								onChange={(e) => handleStatus(e, 'Status')}
								value={status}
							>
								<option value="" >--Select--</option>
								{statusitems.map((item, index) => {
									return <option value={item._id} key={index}> {item.Name}</option>
								})}
							</select>
						</div>
						{errors.Status && errors.Status.type === "required" && (
							<span role="alert" className="w-full text-red-500 bg-white text-xs my-1">Please fill in the status field.</span>
						)}
					</div>
					<div className="py-3 flex flex-col justify-start items-start text-black text-md font-bold truncate ...">
						{activeCustomer?.replace('_', ', ').replace('_', ', ')}, Pre-Delivery
					</div>
					<div className="h-20 flex flex-col justify-center items-start bg-black text-white font-bold px-5">
						<div>Task Completed</div>
						<div>{checklistCount + addonCount}/{addonTotalCount + checklistTotalCount}</div>
					</div>
					<div className="w-full h-max flex flex-wrap justify-center items-center my-3 mb-20 ">
						{filterCategory.map((item, index) => {
							return <div
								key={index}
								onClick={() => handleCategory(item)}
								className="w-1/2  bg-white cursor-pointer">
								<div className="flex h-16 flex-row justify-start items-center m-1 px-3 py-3 border-x border-y text-sm rounded border-[#575E6CFF]">
									<img
										className="h-5 w-5"
										src={getImage(item._id)}
										alt="image-text"
									/>
									<div className="text-sm mx-2 font-normal text-[#171A1FFF]">{item.Name}</div>
								</div>
							</div>
						})}
					</div>
				</div>
				<footer className="flex fixed bottom-0 left-0 flex-row w-full justify-center min-h-20 pb-2 item-center bg-[#3C50E0FF] text-white">
					<button
						onClick={() => navigate('/mobile/project-board')}
						className="w-1/4  flex flex-col justify-center   items-center ">
						<svg xmlns="http://www.w3.org/2000/svg" height="32" width="32" fill="#ffffff" viewBox="0 0 24 24" ><path d="M19 21H5C4.44772 21 4 20.5523 4 20V11L1 11L11.3273 1.6115C11.7087 1.26475 12.2913 1.26475 12.6727 1.6115L23 11L20 11V20C20 20.5523 19.5523 21 19 21ZM13 19H18V9.15745L12 3.7029L6 9.15745V19H11V13H13V19Z"></path></svg>						<span className="text-xs">Home</span>
					</button>
					<button
						onClick={() => navigate('/mobile/chat')}
						className="w-1/4  flex flex-col justify-center  items-center ">
						<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#ffffff" viewBox="0 0 256 256"><path d="M216,48H40A16,16,0,0,0,24,64V224a15.85,15.85,0,0,0,9.24,14.5A16.13,16.13,0,0,0,40,240a15.89,15.89,0,0,0,10.25-3.78.69.69,0,0,0,.13-.11L82.5,208H216a16,16,0,0,0,16-16V64A16,16,0,0,0,216,48ZM40,224h0ZM216,192H82.5a16,16,0,0,0-10.3,3.75l-.12.11L40,224V64H216ZM88,112a8,8,0,0,1,8-8h64a8,8,0,0,1,0,16H96A8,8,0,0,1,88,112Zm0,32a8,8,0,0,1,8-8h64a8,8,0,1,1,0,16H96A8,8,0,0,1,88,144Z"></path></svg>
						<span className="text-xs">Chats</span>
					</button>
					<button
						onClick={() => navigate('/mobile/notification')}
						className="w-1/4  flex  flex-col justify-center items-center ">
						<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#ffffff" viewBox="0 0 24 24"><path d="M19 13.586V10c0-3.217-2.185-5.927-5.145-6.742C13.562 2.52 12.846 2 12 2s-1.562.52-1.855 1.258C7.185 4.074 5 6.783 5 10v3.586l-1.707 1.707A.996.996 0 0 0 3 16v2a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1v-2a.996.996 0 0 0-.293-.707L19 13.586zM19 17H5v-.586l1.707-1.707A.996.996 0 0 0 7 14v-4c0-2.757 2.243-5 5-5s5 2.243 5 5v4c0 .266.105.52.293.707L19 16.414V17zm-7 5a2.98 2.98 0 0 0 2.818-2H9.182A2.98 2.98 0 0 0 12 22z" /></svg>
						<span className="text-xs">Notifications</span>
					</button>
					<button
						onClick={() => navigate('/mobile/help-center')}
						className="w-1/4  flex flex-col justify-center  items-center ">
						<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#ffffff" viewBox="0 0 256 256"><path d="M201.89,54.66A103.43,103.43,0,0,0,128.79,24H128A104,104,0,0,0,24,128v56a24,24,0,0,0,24,24H64a24,24,0,0,0,24-24V144a24,24,0,0,0-24-24H40.36A88.12,88.12,0,0,1,190.54,65.93,87.39,87.39,0,0,1,215.65,120H192a24,24,0,0,0-24,24v40a24,24,0,0,0,24,24h24a24,24,0,0,1-24,24H136a8,8,0,0,0,0,16h56a40,40,0,0,0,40-40V128A103.41,103.41,0,0,0,201.89,54.66ZM64,136a8,8,0,0,1,8,8v40a8,8,0,0,1-8,8H48a8,8,0,0,1-8-8V136Zm128,56a8,8,0,0,1-8-8V144a8,8,0,0,1,8-8h24v56Z"></path></svg>
						<span className="text-xs">Help</span>
					</button>
				</footer>
			</div >
		</>

	);
}
