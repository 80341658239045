export const PROJECT_CARDS_RESET_STATE = 'PROJECT_CARDS_RESET_STATE';
export const PROJECT_CARDS_CURRENT_ITEM = 'PROJECT_CARDS_CURRENT_ITEM';

export const PROJECT_CARDS_REQUEST_LOADING = 'PROJECT_CARDS_REQUEST_LOADING';
export const PROJECT_CARDS_REQUEST_SUCCESS = 'PROJECT_CARDS_REQUEST_SUCCESS';
export const PROJECT_CARDS_REQUEST_FAILED = 'PROJECT_CARDS_REQUEST_FAILED';

export const PROJECT_CARDS_CURRENT_ACTION = 'PROJECT_CARDS_CURRENT_ACTION';
export const PROJECT_CARDS_RESET_ACTION = 'PROJECT_CARDS_RESET_ACTION';
export const PROJECT_CARDS_NAME_EXISTS_ACTION = 'PROJECT_CARDS_NAME_EXISTS_ACTION';
export const PROJECT_CARDS_RESET_NAME_EXISTS = 'PROJECT_CARDS_RESET_NAME_EXISTS';
