import { crm } from "../redux/crm/actions";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentCRM } from '../redux/crm/selectors';
import { selectListItems } from '../redux/categoryname/selectors';
import { categoryname } from "../redux/categoryname/actions";
import { projectaddon } from "../redux/projectaddon/actions";

export default function AddTask({ filteredCategory }) {
	const dispatch = useDispatch();
	const { activeCategoryName, activeProjectId, activeCustomerId, isAddTaskByWeb } = useSelector(selectCurrentCRM);
	const { result } = useSelector(selectListItems)
	const { items } = result
	const [taskDetails, setTaskDetails] = useState('')
	const [category, setCategory] = useState('')
	useEffect(() => {
		dispatch(categoryname.list({ entity: 'PDI_Category_Name' }))
	}, [])

	const handleCategory = (e) => {
		const id = items.filter((obj) => obj?.Name == e)?._id
		setCategory(id)
	}
	const handleCancel = () => {
		dispatch(crm.trigger({ addProjectTask: 'close' }))
	}

	const handleAdd = () => {
		dispatch(projectaddon.create({
			entity: 'PDI_Project_Addon_Checklist',
			jsonData: {
				entity: {
					ProjectName: activeProjectId,
					CategoryName: category,
					UnitDetails: activeCustomerId,
					ChecklistItem: taskDetails,
					IssueDetails: null,
					Status: ''
				}
			}
		})).then(() => {
			dispatch(crm.trigger({ addProjectTask: 'close', reloadProjectTask: true }))
			setTaskDetails('')
		})
	}

	useEffect(() => {
		setCategory(activeCategoryName?._id)
	}, [activeCategoryName])
	return (
		<>
			<div className="absolute fixed inset-0 mt-20 z-50 flex  justify-center align-middle w-screen">
				<div className="w-[600px] p-4">
					<div className="rounded-lg shadow-lg flex flex-col bg-white px-3 py-2 ">
						<div className="flex items-start justify-between px-4 py-1 ">
							<h3 className="text-sm font-bold text-[#323842FF]">
								Add Task
							</h3>
						</div>
						<div className="flex flex-wrap justify-start align-middle" >
							<div className="w-full flex flex-col item-center justify-between  px-4 py-2">
								<span className="text-[#323842FF] text-xs font-bold my-1">Selected Category</span>
								<div className="px-3 border-0  bg-[#F3F4F6FF] text-xs " >
									<select
										className="w-full  py-1 bg-[#F3F4F6FF] text-xs"
										onChange={(e) => handleCategory(e)}
										value={category}>
										<option value="" key={0}>--Select--</option>
										{filteredCategory.map((item, index) => {
											return <option value={item} key={index}> {item}</option>
										})}
									</select>
								</div>
							</div>
						</div>
						<div className="border-y border-[#3238420F] my-2 mx-4"></div>
						<div className="flex flex-row w-full">
							<div className="flex justify-start align-middle w-full" >
								<div className="w-full flex flex-col item-center justify-between  px-4 py-2">
									<span className="text-[#323842FF] text-xs font-bold my-1">Task Details</span>
									<div className="border-0  bg-[#F3F4F6FF] text-xs" >
										<textarea
											type="text"
											rows="3"
											placeholder="Task Details"
											name={"description"}
											className="w-full px-3 py-1 bg-[#F3F4F6FF] text-xs"
											onChange={(e) => setTaskDetails(e.target.value)}
											value={taskDetails}
										/>
									</div>
								</div>
							</div>
						</div>
						<div className="flex items-center justify-center px-6 py-4 mx-3 space-x-3">
							<button
								className="font-semibold  px-2 text-xs rounded border-x border-y  border-[#1C49D0FF] text-[#1C49D0FF] py-1"
								type="button"
								onClick={() => handleCancel()}
							>
								Cancel
							</button>
							<button
								className=" bg-[#1C49D0FF] text-white  font-bold px-2 rounded text-xs py-1"
								type="button"
								onClick={() => handleAdd()}
							>
								Add
							</button>
						</div>
					</div>
				</div>
			</div>
			<div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
		</>
	);
}