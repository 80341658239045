import { createSelector } from 'reselect';

const selectTypeOfHouse = (state) => state.typeofhouse;

export const selectCurrentItem = createSelector([selectTypeOfHouse], (typeofhouse) => typeofhouse.current);

export const selectListItems = createSelector([selectTypeOfHouse], (typeofhouse) => typeofhouse.list);


export const selectItemById = (itemId) =>
	createSelector(selectListItems, (list) => list.result.items.find((item) => item._id === itemId));

export const selectCreatedItem = createSelector([selectTypeOfHouse], (typeofhouse) => typeofhouse.create);

export const selectUpdatedItem = createSelector([selectTypeOfHouse], (typeofhouse) => typeofhouse.update);

export const selectReadItem = createSelector([selectTypeOfHouse], (typeofhouse) => typeofhouse.read);

export const selectDeletedItem = createSelector([selectTypeOfHouse], (typeofhouse) => typeofhouse.delete);

export const selectSearchedItems = createSelector([selectTypeOfHouse], (typeofhouse) => typeofhouse.search);

export const selectItemsCount = createSelector([selectTypeOfHouse], (typeofhouse) => typeofhouse.count);

export const selectItemsFilter = createSelector([selectTypeOfHouse], (typeofhouse) => typeofhouse.filter);
