import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Confirmation from "./../../modals/Confirmation";
import { crm } from "../../redux/crm/actions";
import { selectCurrentCRM } from "../../redux/crm/selectors"
import { selectCurrent } from "../../redux/message/selectors.js"
import { selectListItems } from '../../redux/masterchecklistitems/selectors';
import { selectListItems as selectProjectType } from '../../redux/projecttype/selectors';
import { masterchecklistitems } from '../../redux/masterchecklistitems/actions';
import _ from 'lodash'
import { projecttype } from '../../redux/projecttype/actions.js';

export default function InspectionChecklist() {
	const dispatch = useDispatch();
	const { checklistReload, checklistModalOpen } = useSelector(selectCurrentCRM)
	const { page } = useSelector(selectCurrent)
	const { result: _result } = useSelector(selectProjectType)
	const { result } = useSelector(selectListItems);
	const { items, pagination } = result
	const { items: _items } = _result
	const { current, pageSize } = pagination;
	const pageCount = pageSize;
	const [filterItems, setFilterItems] = useState(items)

	const columnName =
	{
		"ProjectType": {},
		"categoryName": {},
		"description": {},
		"additionalInfo": {},
	}

	const propertyNames = Object.keys(columnName);

	const displayFormat = (data) => {
		return _.startCase(data)
	}

	const handleInspectionChecklist = () => {
		dispatch(crm.trigger({ checklistModalOpen: 'open', isCheckllistEdit: false }))
	}

	const handleUpload = () => {

	}

	const handleProjectType = (e) => {
		const _filter = items.filter((obj) => obj.ProjectType._id == e.target.value)
		setFilterItems(_filter)
	}

	useEffect(() => {
		if (checklistReload) {
			dispatch(masterchecklistitems.list({ entity: 'PDI_Checklist_Items', options: { page: 1, limit: 200 } }))
			dispatch(crm.trigger({ checklistReload: false }))
		}
	}, [checklistReload])

	useEffect(() => {
		dispatch(masterchecklistitems.list({ entity: 'PDI_Checklist_Items', options: { page: page, limit: 5 } }))
	}, [page])

	useEffect(() => {
		dispatch(projecttype.list({ entity: 'PDI_Project_Configuration', options: { page: page, limit: 5 } }))
	}, [])


	return (
		<>
			<div className="flex flex-col item-center">
				<header className="flex justify-between h-20 w-full mx-auto px-5 dark:text-white items-center">
					<div className="flex justify-start w-full" >
						<nav aria-label="breadcrumb" className="w-max" >
							<ol className="flex flex-wrap items-center w-full px-4 py-2 rounded-md bg-blue-gray-50 bg-opacity-60">
								<li
									className="flex items-center font-sans text-sm antialiased font-normal leading-normal transition-colors duration-300 cursor-pointer text-blue-gray-900 hover:text-light-blue-500">
									<a href="#" className="opacity-60">
										<svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" viewBox="0 0 20 20"
											fill="currentColor">
											<path
												d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z">
											</path>
										</svg>
									</a>
									<span
										className="mx-2 font-sans text-sm antialiased font-normal leading-normal pointer-events-none select-none text-blue-gray-500">
										/
									</span>
								</li>
								<li
									className="flex items-center font-sans text-sm antialiased font-normal leading-normal transition-colors duration-300 cursor-pointer text-blue-gray-900 hover:text-light-blue-500">
									<a href="#">
										Inspection Checklist
									</a>
									<span
										className="mx-2 font-sans text-sm antialiased font-normal leading-normal pointer-events-none select-none text-blue-gray-500">
										/
									</span>
								</li>
								<li
									className="flex items-center font-sans text-xs antialiased font-normal leading-normal transition-colors duration-300 cursor-pointer text-blue-gray-900 hover:text-light-blue-500">
									<select
										className="w-full py-1 dark:bg-black bg-black"
										onChange={(e) => handleProjectType(e)}
										defaultValue={'65941338a916df001ac6bf45'}>
										{_items.map((item, index) => {
											return <option value={item._id} key={index}> {item.Name}</option>
										})}
									</select>
								</li>
							</ol>
						</nav>
					</div>
				</header >
				<Confirmation />
				<div className="flex flex-col  px-3 mx-5 h-[600px] shadow-lg rounded bg-white dark:bg-[#2b2c37]">
					<div className="rounded-t mb-0 px-4 py-3 border-0 ">
						<div className="flex flex-row items-center ">
							<div className=" w-screen px-2">
								<h3
									className="font-semibold text-lg dark:text-white" >
									Inspection Check List
								</h3>
							</div>
							<div className="flex justify-end w-screen space-x-3" >
								<button onClick={() => handleInspectionChecklist()} className="bg-blue-600 mb-2 text-black active:bg-blue-600 font-bold uppercase text-xs 
							 text-white py-2 px-4 rounded shadow hover:shadow-md outline-none focus:outline-none
							 ease-linear transition-all duration-150">Add_Edit_Inspection_Checklist</button>
								<button className="bg-blue-600 mb-2 text-black active:bg-blue-600 font-bold uppercase text-xs  text-white py-2 px-4 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150"
									onClick={() => {
										handleUpload()
									}} > Upload Excel
								</button>
							</div>
						</div>
					</div>
					<div className="min-h-[450px] w-full overflow-auto  ">
						<table className="items-center border-collapse w-full  ">
							<thead>
								<tr key={'table'}>
									{propertyNames.map((column, index) => {
										return <th key={index}
											className="w-auto mx-2 px-3 dark:text-white align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
											{displayFormat(column)}
										</th>
									})}
									<th key={propertyNames.length + 1}
										className="w-auto mx-2 px-3  dark:text-white align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ">
										Action
									</th>
								</tr>
							</thead>
							<tbody>
								{filterItems.map((item, idx) => {
									return <tr key={idx}  >
										<td key={'categoryName'} className="w-auto mx-2 px-3  dark:text-white  align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
											{item.ProjectType.Name}
										</td>
										<td key={'categoryName'} className="w-auto mx-2 px-3  dark:text-white  align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
											{item.categoryName.Name}
										</td>
										<td key={'description'} className="w-auto mx-2 px-3  dark:text-white  align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
											{item.description}
										</td>
										<td key={'additionalInfo'} className="w-auto mx-2 px-3  dark:text-white  align-middle py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
											{item.additionalInfo}
										</td>
										<td className="px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
										</td>
									</tr>
								})}
							</tbody>
						</table>
					</div >
				</div>
			</div>
		</>

	);
}
