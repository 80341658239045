
import codeMessage from './codeMessage';
import { toast } from 'tailwind-toast'
const successHandler = (response, options = { notifyOnSuccess: false, notifyOnFailed: true }) => {
	const { data } = response;

	if (data && data.status) {
		const message = response.data && data.message;
		const successText = message || codeMessage[response.status];
		const { status } = response;
		if (options.notifyOnSuccess) {
			return toast().success(status, successText)
		}
	}
};

export default successHandler;
