import React, { lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import PageLoader from '../components/PageLoader';
import UserManagement from "../pages/auth/UserManagement.js";
import InspectionBoard from "../pages/inspection-crm/InspectionBoard.js";
import ProjectBoard from "../pages/inspection-crm/ProjectBoard.js";
import InspectionItems from '../pages/inspection-crm/InspectionItems';
import TaskItems from '../pages/inspection-crm/TaskItems';
import Tasks from '../pages/inspection-crm/Tasks';
import InspectionChecklist from '../pages/inspection-crm/InspectionChecklist';
import ProjectChecklist from '../pages/inspection-crm/ProjectChecklist';
import Permissions from '../pages/auth/Permissions';
import MasterSettings from '../pages/inspection-crm/MasterSettings';
import Settings from '../pages/inspection-crm/Settings';
import ProjectHome from '../pages/inspection-crm/ProjectHome';
import ImportCustomer from '../pages/inspection-crm/ImportCustomer';
import GenerateChecklist from '../pages/inspection-crm/GenerateChecklist';
import MasterChecklist from '../pages/inspection-crm/MasterChecklist';
import InspectionItemsMobile from '../pages/inspection-crm/InspectionItemsMobile';
import AddProject from '../pages/inspection-crm/AddProject';
import AddIssue from '../pages/inspection-crm/AddIssue';
import AddIssueMobile from '../pages/inspection-crm/AddIssueMobile';
import ViewIssueMobile from '../pages/inspection-crm/ViewIssueMobile';
import Chat from '../pages/inspection-crm/Chat';

import Category from '../pages/inspection-crm/Category';
import CategoryList from '../pages/inspection-crm/CategoryList';
import ProjectBoardMobile from '../pages/inspection-crm/ProjectBoardMobile';
import EmptyBoard from '../pages/inspection-crm/EmptyBoard';
import NotificationSettings from '../pages/inspection-crm/NotificationSettings';
import HelpCenter from '../pages/inspection-crm/HelpCenter';
import Dashboard from '../pages/inspection-crm/Dashboard';
import QualityMetrics from '../pages/inspection-crm/QualityMetrics';
import FileManager from '../pages/inspection-crm/FileManager';
import Reports from '../pages/inspection-crm/Reports';
const IndexedDB = lazy(() => import(/*webpackChunkName:'LogoutPage'*/ './../pages/auth/IndexDB'));
const CacheStorage = lazy(() => import(/*webpackChunkName:'LogoutPage'*/ './../pages/auth/CacheStorage'));
const Logout = lazy(() => import(/*webpackChunkName:'LogoutPage'*/ './../pages/Logout'));
const NotFound = lazy(() => import(/*webpackChunkName:'NotFoundPage'*/ './../pages/NotFound'));
const Auth = lazy(() => import(/*webpackChunkName:'LogoutPage'*/ './../layouts/Auth'));
const InspectionCRM = lazy(() => import(/*webpackChunkName:'LogoutPage'*/ './../layouts/InspectionCRM'));
const Mobile = lazy(() => import(/*webpackChunkName:'LogoutPage'*/ './../layouts/Mobile'));
const Login = lazy(() => import(/*webpackChunkName:'LoginPage'*/ './../pages/auth/Login'));
const ChangePassword = lazy(() => import(/*webpackChunkName:'LoginPage'*/ '../pages/auth/ChangePassword'));

export default function AppRouter() {
	return (
		<Suspense fallback={<PageLoader />}>
			<AnimatePresence exitBeforeEnter initial={false}>
				<Routes>
					<Route element={<Mobile />} >
						<Route path="/mobile/task-items" element={<TaskItems />} />
						<Route path="/mobile/tasks" element={<Tasks />} />
						<Route path="/mobile/category-list" element={<CategoryList />} />
						<Route path="/mobile/inspection-items" element={<InspectionItemsMobile />} />
						<Route path="/mobile/add-issue" element={<AddIssueMobile />} />
						<Route path="/mobile/view-issue" element={<ViewIssueMobile />} />
						<Route path="/mobile/project-board" element={<ProjectBoardMobile />} />
						<Route path="/mobile/notification" element={<NotificationSettings />} />
						<Route path="/mobile/help-center" element={<HelpCenter />} />
						<Route path="/mobile/chat" element={<Chat />} />
					</Route>
					<Route element={<InspectionCRM />} >
						<Route path="/inspection-crm/dashboard" element={<Dashboard />} />
						<Route path="/inspection-crm/quality-metrics" element={<QualityMetrics />} />
						<Route path="/inspection-crm/project-boards" element={<ProjectBoard />} />
						<Route path="/inspection-crm/inspection-boards" element={<InspectionBoard />} />
						<Route path="/inspection-crm/inspection-items" element={<InspectionItems />} />
						<Route path="/inspection-crm/project-checklist" element={<ProjectChecklist />} />
						<Route path="/inspection-crm/inspection-checklist-items" element={<InspectionChecklist />} />
						<Route path="/inspection-crm/user-management" element={<UserManagement />} />
						<Route path="/inspection-crm/permissions" element={<Permissions />} />
						<Route path="/inspection-crm/settings" element={<Settings />} />
						<Route path="/inspection-crm/master-settings" element={<MasterSettings />} />
						<Route path="/inspection-crm/project-home" element={<ProjectHome />} />
						<Route path="/inspection-crm/import-customer" element={<ImportCustomer />} />
						<Route path="/inspection-crm/generate-checklist" element={<GenerateChecklist />} />
						<Route path="/inspection-crm/master-checklist" element={<MasterChecklist />} />
						<Route path="/inspection-crm/add-project" element={<AddProject />} />
						<Route path="/inspection-crm/add-issue" element={<AddIssue />} />
						<Route path="/inspection-crm/category" element={<Category />} />
						<Route path="/inspection-crm/fms" element={<FileManager />} />
						<Route path="/inspection-crm/reports" element={<Reports />} />
					</Route>
					<Route element={<Auth />} >
						<Route path="/" element={<Navigate to="/auth/login" />} />
						<Route path="/auth/changepassword" element={<ChangePassword />} />
						<Route path="/auth/login" element={<Login />} />
						<Route path="/auth/indexdb" element={<IndexedDB />} />
						<Route path="/auth/cache" element={<CacheStorage />} />
						<Route path="*" element={<Navigate to="/notfound" />} />
					</Route>
					<Route path="/" element={<Navigate to="/auth/login" replace={true} />} />
					<Route path="*" element={<Navigate to="/notfound" replace={true} />} />
					<Route path="/logout" element={Logout} />
					<Route path="/notfound" element={NotFound} />
				</Routes>
			</AnimatePresence>
		</ Suspense >
	);
}
