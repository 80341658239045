import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { MultiSelect } from "react-multi-select-component";
import _ from 'lodash'
import { Bars } from 'react-loader-spinner';
import { useForm } from "react-hook-form";
import AddBlocksTowers from "../../modals/AddBlocksTowers";
import { typeofhouse } from '../../redux/typeofhouse/actions';
import { numberofbedrooms } from '../../redux/numberofbedrooms/actions';
import { projectcards } from '../../redux/projectcards/actions';
import { projectstatus } from '../../redux/projectstatus/actions';
import { crm } from "../../redux/crm/actions";
import { projecttype } from "../../redux/projecttype/actions";
import { selectCurrentCRM } from '../../redux/crm/selectors';
import { selectListItems as selectProjectTypeList } from '../../redux/projecttype/selectors';
import { selectListItems as selectTypeOfHouseList } from '../../redux/typeofhouse/selectors';
import { selectListItems as selectNumberOfBedroomsList } from '../../redux/numberofbedrooms/selectors';
import { selectListItems as selectProjectStatusList } from '../../redux/projectstatus/selectors';
import { selectListItems as selectUserList } from '../../redux/users/selectors';
import { selectUpdatedItem, selectCreatedItem } from '../../redux/projectcards/selectors';
import { users } from "../../redux/users/actions";
import { request } from '../../request/index';
export default function AddProject() {
	const [selectedProjectType, setSelectedProjectType] = useState([]);
	const [projectTypeOptions, setProjectTypeOptions] = useState([]);
	const [selectedTypeOfHouse, setSelectedTypeOfHouse] = useState([]);
	const [typeOfHouseOptions, setTypeOfHouseOptions] = useState([]);
	const [selectedNumberOfBedrooms, setSelectedNumberOfBedrooms] = useState([]);
	const [numberOfBedroomsOptions, setNumberOfBedroomsOptions] = useState([]);
	const { register, handleSubmit, clearErrors, formState: { errors }, setValue } = useForm();
	const dispatch = useDispatch();
	const navigate = useNavigate()
	const [file, setFile] = useState(null);
	const [files, setFiles] = useState(null);
	const [projectImage, setProjectImage] = useState('');
	const [attachmentList, setAttachmentList] = useState([]);
	const [newAttachmentList, setNewAttachmentList] = useState([]);
	const { projectItem, isProjectEdit, activeProjectId, addBlocksTowers, activeDownloadedUrl, activeProjectBlockList, reloadProjectCard, activeDocumentFileKey } = useSelector(selectCurrentCRM);
	const { result: projectTypeResult } = useSelector(selectProjectTypeList)
	const { isSuccess: isSuccessCreated, isNameExists, error } = useSelector(selectCreatedItem)
	const { isSuccess: isSuccessUpdated } = useSelector(selectUpdatedItem)
	const { result: typeOfHouseResult } = useSelector(selectTypeOfHouseList)
	const { result: numberOfBedroomsResult } = useSelector(selectNumberOfBedroomsList)
	const { result: projectStatusResult } = useSelector(selectProjectStatusList)
	const { items: projectTypeItems } = projectTypeResult
	const { items: typeOfHouseItems } = typeOfHouseResult
	const { items: numberOfBedroomsItems } = numberOfBedroomsResult
	const { items: projectStatusItems } = projectStatusResult
	const { result: userResult } = useSelector(selectUserList)
	const { items: useritems } = userResult
	const [steps, setSteps] = useState({});

	const handleCancel = () => {
		navigate('/inspection-crm/project-boards')
	}

	const handleSteps = (e, key) => {
		setSteps({ ...steps, [key]: e.target?.value })
	}

	useEffect(() => {
		if (addBlocksTowers == 'close') {
			setValue('BlockTower', activeProjectBlockList)
			clearErrors("ProjectName")
			clearErrors("ReraRegistrationNumber")
			clearErrors("BlockTower")
			clearErrors("ProjectStage")
			clearErrors("Location")
			clearErrors("StartDate")
			clearErrors("ExpectedCompletionDate")
			clearErrors("CRMManager")
		}
	}, [addBlocksTowers])

	const handleDeleteImage = () => {
		dispatch(crm.trigger({
			activeDocumentId: '',
			activeDocumentFile: '',
			activeUploadLink: '',
			activeDocumentFilename: '',
			activeDownloadedUrl: ''
		}))
	}

	const handleProject = () => {
		navigate('/inspection-crm/project-boards')
	}

	useEffect(() => {
		let _projectTypeOptions = []
		projectTypeItems.map((item) => {
			_projectTypeOptions.push({ label: item.Name, value: item?._id })
		})
		setProjectTypeOptions(_projectTypeOptions)
	}, [projectTypeItems])

	useEffect(() => {
		let _typeOfHouseOptions = []
		typeOfHouseItems.map((item) => {
			_typeOfHouseOptions.push({ label: item.Name, value: item?._id })
		})
		setTypeOfHouseOptions(_typeOfHouseOptions)
	}, [typeOfHouseItems])

	useEffect(() => {
		let _numberOfBedroomsOptions = []
		numberOfBedroomsItems.map((item) => {
			_numberOfBedroomsOptions.push({ label: item.Name, value: item?._id })
		})
		setNumberOfBedroomsOptions(_numberOfBedroomsOptions)
	}, [numberOfBedroomsItems])

	const handleSave = async () => {
		let _attach = []
		await Promise.all(newAttachmentList.map(async (attachment, idx) => {
			const attachmentFile = files[idx]
			const exte = attachmentFile.name.substring(attachmentFile.name.indexOf(".") + 1);
			const docDetails = {
				extension: exte,
				file_name: attachmentFile.name,
				mime_type: attachmentFile.type,
				entity_type: "crm",
				size: attachmentFile.size,
			};
			const type = attachmentFile.type
			let data = await request.uploadurl({ entity: 'document', jsonData: docDetails, options: {} })
			_attach.push({ filename: attachmentFile?.name, fileKey: data?.filename })
			await request.uploadfileAWSS3({ fileuploadurl: data.uploadLink, file: attachmentFile, type })
		}))
		attachmentList.map((attached) => {
			_attach.push({ filename: attached?.filename, fileKey: attached?.fileKey })
		})
		if (file) {
			const exte = file.name.substring(file.name.indexOf(".") + 1);
			const docDetails = {
				extension: exte,
				file_name: file.name,
				mime_type: file.type,
				entity_type: "crm",
				size: file.size,
			};
			let data = await request.uploadurl({ entity: 'document', jsonData: docDetails, options: {} })
			const type = file.type

			await request.uploadfileAWSS3({ fileuploadurl: data.uploadLink, file, type })

			if (isProjectEdit) {
				dispatch(projectcards.resetAction({ actionType: 'update' }))
				dispatch(projectcards.update({
					entity: 'PDI_Project_Cards', id: activeProjectId, jsonData: {
						entity: {
							"ReraRegistrationNumber": steps['ReraRegistrationNumber'],
							"ProjectType": selectedProjectType,
							"BlockTower": activeProjectBlockList,
							"TypeOfHouse": selectedTypeOfHouse,
							"NumberOfBedrooms": selectedNumberOfBedrooms,
							"ProjectStage": steps['ProjectStage'],
							"Location": steps['Location'],
							"StartDate": steps['StartDate'],
							"ExpectedCompletionDate": steps['ExpectedCompletionDate'],
							"Description": steps['Description'],
							"Status": steps['Status'],
							"Attachments": _attach,
							"CRMManager": steps['CRMManager'],
							"ImageUrl": data?.filename ? data?.filename : projectItem.ImageUrl
						}
					}
				}))
			}
			else {
				dispatch(projectcards.resetAction({ actionType: 'create' }))
				dispatch(projectcards.create({
					entity: 'PDI_Project_Cards', jsonData: {
						entity: {
							"ProjectName": steps['ProjectName'],
							"ReraRegistrationNumber": steps['ReraRegistrationNumber'],
							"ProjectType": selectedProjectType,
							"BlockTower": activeProjectBlockList,
							"TypeOfHouse": selectedTypeOfHouse,
							"NumberOfBedrooms": selectedNumberOfBedrooms,
							"ProjectStage": steps['ProjectStage'],
							"Location": steps['Location'],
							"StartDate": steps['StartDate'],
							"ExpectedCompletionDate": steps['ExpectedCompletionDate'],
							"Description": steps['Description'],
							"Status": steps['Status'],
							"Attachments": _attach,
							"CRMManager": steps['CRMManager'],
							"ImageUrl": data?.filename
						}
					}
				}))
			}
		}
		else {
			if (isProjectEdit) {
				dispatch(projectcards.resetAction({ actionType: 'update' }))
				dispatch(projectcards.update({
					entity: 'PDI_Project_Cards', id: activeProjectId, jsonData: {
						entity: {
							"ReraRegistrationNumber": steps['ReraRegistrationNumber'],
							"ProjectType": selectedProjectType,
							"BlockTower": activeProjectBlockList,
							"TypeOfHouse": selectedTypeOfHouse,
							"NumberOfBedrooms": selectedNumberOfBedrooms,
							"ProjectStage": steps['ProjectStage'],
							"Location": steps['Location'],
							"StartDate": steps['StartDate'],
							"ExpectedCompletionDate": steps['ExpectedCompletionDate'],
							"Description": steps['Description'],
							"Status": steps['Status'],
							"Attachments": _attach,
							"CRMManager": steps['CRMManager'],
							"ImageUrl": projectItem.ImageUrl
						}
					}
				}))
			}
			else {
				dispatch(projectcards.resetAction({ actionType: 'create' }))
				dispatch(projectcards.create({
					entity: 'PDI_Project_Cards', jsonData: {
						entity: {
							"ProjectName": steps['ProjectName'],
							"ReraRegistrationNumber": steps['ReraRegistrationNumber'],
							"ProjectType": selectedProjectType,
							"BlockTower": activeProjectBlockList,
							"TypeOfHouse": selectedTypeOfHouse,
							"NumberOfBedrooms": selectedNumberOfBedrooms,
							"ProjectStage": steps['ProjectStage'],
							"Location": steps['Location'],
							"StartDate": steps['StartDate'],
							"ExpectedCompletionDate": steps['ExpectedCompletionDate'],
							"Description": steps['Description'],
							"Status": steps['Status'],
							"Attachments": _attach,
							"CRMManager": steps['CRMManager'],
							"ImageUrl": "No_image_available.svg"
						}
					}
				}))
			}
		}
	}

	useEffect(() => {
		if (isSuccessCreated) {
			dispatch(projectcards.resetAction({ actionType: 'create' }))
			dispatch(crm.trigger({ reloadProjectCard: true, activeProjectBlockList: "" }))
			navigate('/inspection-crm/project-boards')
		}
	}, [isSuccessCreated])

	useEffect(() => {
		if (isSuccessUpdated) {
			dispatch(projectcards.resetAction({ actionType: 'update' }))
			dispatch(crm.trigger({ reloadProjectCard: true, activeProjectBlockList: "" }))
			navigate('/inspection-crm/project-boards')
		}
	}, [isSuccessUpdated])

	const handleBlock = () => {
		dispatch(crm.trigger({ addBlocksTowers: 'open' }))
	}

	const getImageUrl = async (key) => {
		const url = "https://proptech-pdi.s3.amazonaws.com/" + key
		dispatch(crm.trigger({ activeDownloadedUrl: url }))
	}

	useEffect(() => {
		if (isProjectEdit) {
			let _selectedProjectTypeOptions = []
			projectItem?.ProjectType?.map((item) => {
				_selectedProjectTypeOptions.push({ label: item[0]?.label, value: item[0]?.value })
			})
			setSelectedProjectType(_selectedProjectTypeOptions)
			let _selectedTypeOfHouseOptions = []
			projectItem?.TypeOfHouse?.map((item) => {
				_selectedTypeOfHouseOptions.push({ label: item[0]?.label, value: item[0]?.value })
			})
			setSelectedTypeOfHouse(_selectedTypeOfHouseOptions)
			let _selectedNumberOfBedroomsOptions = []
			projectItem?.NumberOfBedrooms?.map((item) => {
				_selectedNumberOfBedroomsOptions.push({ label: item[0]?.label, value: item[0]?.value })
			})
			setSelectedNumberOfBedrooms(_selectedNumberOfBedroomsOptions)
			setValue("ProjectName", projectItem?.ProjectName)
			setValue("ReraRegistrationNumber", projectItem?.ReraRegistrationNumber)
			setValue("BlockTower", projectItem?.BlockTower)
			setValue("ProjectStage", projectItem?.ProjectStage?._id)
			setValue("Location", projectItem?.Location)
			setValue("StartDate", projectItem?.StartDate?.slice(0, 10))
			setValue("ExpectedCompletionDate", projectItem?.ExpectedCompletionDate?.slice(0, 10))
			setValue("CRMManager", projectItem?.CRMManager?._id)
			setSteps(projectItem)
			getImageUrl(projectItem.ImageUrl)
			let arr = []
			// attachmentList.map((attach) => {
			// 	arr.push(attach[0])
			// })
			projectItem.Attachments.map((attach) => {
				arr.push(attach[0])
			})
			setAttachmentList(arr)
			setProjectImage(null)
		}
	}, [projectItem])

	useEffect(() => {
		dispatch(projecttype.list({ entity: 'PDI_Project_Configuration' }))
		dispatch(typeofhouse.list({ entity: 'PDI_Type_Of_House' }))
		dispatch(numberofbedrooms.list({ entity: 'PDI_Number_Of_Bedrooms' }))
		dispatch(projectstatus.list({ entity: 'PDI_Project_Status' }))
		dispatch(users.list({ entity: 'users', options: {} }))
		if (!isProjectEdit) {
			setFile('')
			setFiles([])
			setAttachmentList([])
			setProjectImage('')
			clearErrors("ProjectName")
			clearErrors("ReraRegistrationNumber")
			clearErrors("BlockTower")
			clearErrors("ProjectStage")
			clearErrors("Location")
			clearErrors("StartDate")
			clearErrors("ExpectedCompletionDate")
			clearErrors("CRMManager")
			dispatch(crm.trigger({ activeDownloadedUrl: '', activeProjectBlockList: "" }))
		}
	}, [])

	const downloadAttachment = async (key) => {
		const url = "https://proptech-pdi.s3.amazonaws.com/" + key
		const link = document.createElement('a');
		link.href = url;
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	}

	const handleBrowseImagefile = () => {
		document.getElementById('imagefile').click()
	}

	const handleBrowseAttachementFile = () => {
		document.getElementById('attachmentfile').click()
	}

	const handleFileChange = async (e) => {
		if (e.target.files) {
			setProjectImage(URL.createObjectURL(e.target.files[0]));
			setFile(e.target.files[0]);
		}
	};

	const handleFileAttachmentChange = async (e) => {
		setFiles(e.target.files);
		const chosenFiles = Array.prototype.slice.call(e.target.files)
		let arr = []
		chosenFiles.map((attach) => {
			arr.push({ filename: attach.name, fileKey: '' })
		})
		setNewAttachmentList(arr)
	};

	const handleDeleteAttachment = (obj, idx, type) => {
		if (type == 'uploading') {
			let filteredArray = newAttachmentList.filter(item => item !== obj)
			setNewAttachmentList(filteredArray);
		}
		else {
			let filteredArray = attachmentList.filter(item => item !== obj)
			setAttachmentList(filteredArray);
		}
	}

	return (
		<>
			<AddBlocksTowers />
			<div className="flex flex-col item-center ">
				<header className="flex justify-between h-20 w-full mx-auto px-5 items-center">
					<div className="flex justify-start w-full" >
						<nav aria-label="breadcrumb" className="w-max" >
							<ol className="flex flex-wrap items-center w-full px-4 py-2 rounded-md">
								<li
									className="flex items-center font-sans text-sm antialiased font-normal leading-normal transition-colors duration-300 cursor-pointer text-blue-gray-900 hover:text-light-blue-500">
									<h3 className="text-xl font-bold" onClick={() => handleProject()}>Projects</h3>
									<span
										className="mx-2 font-bold text-xl  antialiased font-normal leading-normal pointer-events-none select-none text-blue-gray-500 text-xl">
										<svg fill="#000000" height="12px" width="12px" version="1.1" id="Layer_1"
											viewBox="0 0 330 330">
											<path id="XMLID_222_" d="M250.606,154.389l-150-149.996c-5.857-5.858-15.355-5.858-21.213,0.001
	c-5.857,5.858-5.857,15.355,0.001,21.213l139.393,139.39L79.393,304.394c-5.857,5.858-5.857,15.355,0.001,21.213
	C82.322,328.536,86.161,330,90,330s7.678-1.464,10.607-4.394l149.999-150.004c2.814-2.813,4.394-6.628,4.394-10.606
	C255,161.018,253.42,157.202,250.606,154.389z"/>
										</svg>
									</span>
								</li>
								<li
									className="flex items-center font-sans text-sm antialiased font-normal leading-normal transition-colors duration-300 cursor-pointer text-blue-gray-900 hover:text-light-blue-500">
									<a href="#" className="opacity-60 text-md font-bold">
										{!isProjectEdit ? 'Add ' : 'Edit '} Project
									</a>
								</li>
							</ol>
						</nav>
					</div>
				</header >
				<div className="w-full flex flex-row">
					<div className="w-2/3 h-full max-h-[65vh] overflow-auto   mx-2 bg-white rounded shawdow-xl">
						<form onSubmit={handleSubmit(handleSave)} >
							<div className="w-full lg:w-12/12 px-4 mt-6  space-y-4">
								<div className="text-md font-bold"> Project Details</div>
								<div className="flex flex-row space-x-4">
									<div className="relative w-full">
										<label
											className="block  text-xs font-bold mb-2"
											htmlFor="grid-password">
											Project Name<sup className="text-red-500">*</sup>
										</label>
										<div className="border-0  bg-[#F3F4F6FF] text-xs " >
											<input
												id="ProjectName"
												type="text"
												name="ProjectName"
												{...register('ProjectName', { required: true, maxLength: 50 })}
												className="w-full px-3 py-1 bg-[#F3F4F6FF] text-xs"
												onChange={(e) => handleSteps(e, 'ProjectName')}
											/>
										</div>
										{addBlocksTowers != 'open' && errors.ProjectName && errors.ProjectName.type === "required" && (
											<span role="alert" className="w-full text-red-500 bg-white text-xs my-1">Please fill in the project name field.</span>
										)}
										{addBlocksTowers != 'open' && errors.ProjectName && errors.ProjectName.type === "maxLength" && (
											<span role="alert" className="w-full text-red-500 bg-white text-xs my-1">Max length exceeded</span>
										)}
									</div>
									<div className="relative w-full">
										<label
											className="block text-xs font-bold mb-2"
											htmlFor="grid-password">
											Commencement Certificate Number<sup className="text-red-500">*</sup>
										</label >
										<div className="border-0  bg-[#F3F4F6FF] text-xs " >
											<input
												id="ReraRegistrationNumber"
												type="text"
												{...register('ReraRegistrationNumber', { required: true, maxLength: 30 })}
												name={"ReraRegistrationNumber"}
												className="w-full px-3 py-1 bg-[#F3F4F6FF] text-xs "
												onChange={(e) => handleSteps(e, 'ReraRegistrationNumber')}
											/>
										</div>
										{addBlocksTowers != 'open' && errors.ReraRegistrationNumber && errors.ReraRegistrationNumber.type === "required" && (
											<span role="alert" className="w-full text-red-500 bg-white text-xs my-1">Please fill in the RERA registration number.</span>
										)}
										{addBlocksTowers != 'open' && errors.ReraRegistrationNumber && errors.ReraRegistrationNumber.type === "maxLength" && (
											<span role="alert" className="w-full text-red-500 bg-white text-xs my-1">Max length exceeded</span>
										)}
									</div>
								</div>
								<div className="flex flex-row space-x-4">
									<div className="relative w-full">
										<label
											className="block text-xs font-bold mb-2"
											htmlFor="grid-password" >
											Project Type<sup className="text-red-500">*</sup>
										</label>
										<div className="border-0  bg-[#F3F4F6FF] text-xs my-1 h-max-6 " >
											<MultiSelect
												options={projectTypeOptions}
												className="w-full border-0  bg-black bg-opacity-0 text-xs "
												value={selectedProjectType}
												onChange={setSelectedProjectType}
												labelledBy="Select"
											/>
										</div>
									</div>
									<div className="relative w-full">
										<div className="flex flex-row">
											<label
												className="block text-xs font-bold mb-2"
												htmlFor="grid-password">
												Number of Blocks/Towers<sup className="text-red-500">*</sup></label>
											<button
												onClick={() => handleBlock()}
												className="bg-blue-600 px-1 text-[8px] m-1 mx-10 rounded text-white font-bold">Add</button>
										</div>
										<div className="px-3 border-0  bg-[#F3F4F6FF] text-xs " >
											<input
												id="BlockTower"
												type="text"
												{...register('BlockTower', { required: true, maxLength: 50 })}
												name="BlockTower"
												className="w-full border-0 py-1 bg-black bg-opacity-0 text-xs"
												onChange={(e) => handleSteps(e, 'BlockTower')}
											/>
										</div>
										{addBlocksTowers != 'open' && errors.BlockTower && errors.BlockTower.type === "required" && (
											<span role="alert" className="w-full text-red-500 bg-white text-xs my-1">Please add at least one block/tower.</span>
										)}
										{addBlocksTowers != 'open' && errors.BlockTower && errors.BlockTower.type === "maxLength" && (
											<span role="alert" className="w-full text-red-500 bg-white text-xs my-1">Max length exceeded</span>
										)}
									</div>
								</div>
								<div className="flex flex-row space-x-4">
									<div className="relative w-full">
										<label
											className="block text-xs font-bold mb-2"
											htmlFor="grid-password" >
											Type of Houses<sup className="text-red-500">*</sup>
										</label>
										<div className="border-0  bg-[#F3F4F6FF] text-xs " >
											<MultiSelect
												options={typeOfHouseOptions}
												className="w-full border-0  bg-black bg-opacity-0 text-xs "
												value={selectedTypeOfHouse}
												onChange={setSelectedTypeOfHouse}
												labelledBy="Select"
											/>
										</div>
									</div>
									<div className="relative w-full">
										<label
											className="block text-xs font-bold mb-2"
											htmlFor="grid-password">
											Number of Bedrooms<sup className="text-red-500">*</sup>
										</label>
										<div className="border-0  bg-[#F3F4F6FF] text-xs " >
											<MultiSelect
												options={numberOfBedroomsOptions}
												className="w-full border-0  bg-black bg-opacity-0 text-xs "
												value={selectedNumberOfBedrooms}
												onChange={setSelectedNumberOfBedrooms}
												labelledBy="Select"
											/>
										</div>
									</div>
								</div>
								<div className="flex flex-row space-x-4">
									<div className="relative w-full">
										<label
											className="block text-xs font-bold mb-2"
											htmlFor="grid-password" >
											Project Phase<sup className="text-red-500">*</sup>
										</label>
										<div className="px-3 border-0  bg-[#F3F4F6FF] text-xs " >
											<select
												{...register('ProjectStage', { required: true })}
												name="ProjectStage"
												className="w-full py-1 bg-black bg-opacity-0 text-xs"
												onChange={(e) => handleSteps(e, 'ProjectStage')}
											>
												<option value="" >--Select--</option>
												{projectStatusItems.map((item, index) => {
													return <option value={item?._id} key={index}> {item?.Name}</option>
												})}
											</select>
										</div>
										{addBlocksTowers != 'open' && errors.ProjectStage && errors.ProjectStage.type === "required" && (
											<span role="alert" className="w-full text-red-500 bg-white text-xs my-1">Please select project phase.</span>
										)}
									</div>
									<div className="relative w-full">
										<label
											className="block text-xs font-bold mb-2"
											htmlFor="grid-password">
											Location<sup className="text-red-500">*</sup>
										</label>
										<div className="border-0  bg-[#F3F4F6FF] text-xs " >
											<input
												type="text"
												id="Location"
												{...register('Location', { required: true, maxLength: 40 })}
												name={"Location"}
												className="w-full px-3 py-1 bg-black bg-opacity-0 text-xs"
												onChange={(e) => handleSteps(e, 'Location')}
											/>
										</div>
										{addBlocksTowers != 'open' && errors.Location && errors.Location.type === "required" && (
											<span role="alert" className="w-full text-red-500 bg-white text-xs my-1">Please fill in the location field..</span>
										)}
										{addBlocksTowers != 'open' && errors.Location && errors.Location.type === "maxLength" && (
											<span role="alert" className="w-full text-red-500 bg-white text-xs my-1">Max length exceeded</span>
										)}
									</div>
								</div>
								<div className="border-b-2 border-black border-opacity-10"></div>
								<div className="text-md font-bold"> Timeline</div>
								<div className="flex flex-row space-x-4">
									<div className="relative w-full">
										<label
											className="block text-xs font-bold mb-2"
											htmlFor="grid-password" >
											Start Date<sup className="text-red-500">*</sup>
										</label>
										<div className="border-0  bg-[#F3F4F6FF] text-xs " >
											<input
												{...register('StartDate', { required: true })}
												type="date"
												name={"StartDate"}
												placeholder=""
												className="w-full py-1 bg-black bg-opacity-0 text-xs px-3"
												onChange={(e) => handleSteps(e, 'StartDate')}
											/>
										</div>
										{addBlocksTowers != 'open' && errors.StartDate && errors.StartDate.type === "required" && (
											<span role="alert" className="w-full text-red-500 bg-white text-xs my-1">Please select start date.</span>
										)}
									</div>
									<div className="relative w-full">
										<label
											className="block text-xs font-bold mb-2"
											htmlFor="grid-password">
											Expected Completion Date<sup className="text-red-500">*</sup>
										</label>
										<div className="border-0  bg-[#F3F4F6FF] text-xs " >
											<input
												type="date"
												{...register('ExpectedCompletionDate', { required: true })}
												name={"ExpectedCompletionDate"}
												placeholder=""
												className="w-full py-1 bg-black bg-opacity-0 text-xs px-3"
												onChange={(e) => handleSteps(e, 'ExpectedCompletionDate')}
											/>
										</div>
										{addBlocksTowers != 'open' && errors.ExpectedCompletionDate && errors.ExpectedCompletionDate.type === "required" && (
											<span role="alert" className="w-full text-red-500 bg-white text-xs my-1">Please select expected completion date.</span>
										)}
									</div>
								</div>
								<div className="border-b-2 border-black border-opacity-10"></div>
								<div className="text-md font-bold"> Other Relevant Details</div>
								<div className="flex flex-row space-x-4">
									<div className="relative w-full mb-3">
										<label
											htmlFor="formFile"
											className="block uppercase text-xs font-bold mb-2" >
											Description
										</label>
										<div className="border-0  bg-[#F3F4F6FF] text-xs " >
											<textarea
												className="w-full py-1 bg-black bg-opacity-0 text-xs px-3"
												type="text"
												name="Description"
												onChange={(e) => handleSteps(e, 'Description')}
												value={steps['Description']}
											/>
										</div>
									</div>
									<div className="relative w-full">
										<label
											className="block text-xs font-bold mb-2">
											CRM Manager<sup className="text-red-500">*</sup>
										</label>
										<div className="border-0 px-3 bg-[#F3F4F6FF] text-xs " >
											<select
												name={"CRMManager"}
												{...register('CRMManager', { required: true })}
												className="w-full  py-1 bg-[#F3F4F6FF] text-xs"
												onChange={(e) => handleSteps(e, 'CRMManager')}
												value={steps['CRMManager']?._id}
											>
												<option value="" >--Select--</option>
												{useritems?.filter((obj) => obj?.roleId?._id == '65dfe4466ad846404c6cc571')?.map((item, index) => {
													return <option value={item._id} key={index}> {item.userId}</option>
												})}
											</select>
										</div>
										{addBlocksTowers != 'open' && errors.CRMManager && errors.CRMManager.type === "required" && (
											<span role="alert" className="w-full text-red-500 bg-white text-xs my-1">Please select crm manager.</span>
										)}
									</div>
								</div>

							</div>
							<div className="flex flex-row item-baseline justify-center space-x-2 my-2 h-8 mb-10">
								<button
									className="items-center border-x border-y cursor-pointer  border-blue-600 text-blue-600 px-2 py-1 rounded text-xs font-bold"
									onClick={() => {
										handleCancel()
									}}>
									Cancel
								</button>
								<input type="submit"
									className="items-center dark:text-white dark:bg-blue-600 cursor-pointer text-white bg-[#635fc7] px-2 py-1 rounded text-xs font-bold"
									value={isProjectEdit ? 'Update' : 'Save'}
								/>
							</div>
						</form>
					</div>
					<div className="max-h-[75vh] overflow-y-auto overflow-x-hidden mx-2 bg-white rounded shawdow-xl flex justify-start item-start flex-col">
						<div className="w-full mx-3 flex flex-col justify-center item-center">
							<div className="flex flex-row space-x-2 mt-3 mx-5">
								<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#1C49D0FF" viewBox="0 0 24 24"><path d="M19.903 8.586a.997.997 0 0 0-.196-.293l-6-6a.997.997 0 0 0-.293-.196c-.03-.014-.062-.022-.094-.033a.991.991 0 0 0-.259-.051C13.04 2.011 13.021 2 13 2H6c-1.103 0-2 .897-2 2v16c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V9c0-.021-.011-.04-.013-.062a.952.952 0 0 0-.051-.259c-.01-.032-.019-.063-.033-.093zM16.586 8H14V5.414L16.586 8zM6 20V4h6v5a1 1 0 0 0 1 1h5l.002 10H6z" /><path d="M8 12h8v2H8zm0 4h8v2H8zm0-8h2v2H8z" /></svg>
								<div className="text-[18px] text-[#323842FF] font-semibold ">Project Image</div>
							</div>
							{activeDownloadedUrl != '' ? <div className="flex flex-row px-5    items-baseline">
								<img src={activeDownloadedUrl} className="object-scale-down  w-[280px] h-56  shadow-xl p-1 bg-white rounded " />
								<div onClick={() => handleDeleteImage()}
									className="m-2 p-2 cursor-pointer rounded  text-black h-8" >
									<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="currentColor"><path d="M17 6H22V8H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V8H2V6H7V3C7 2.44772 7.44772 2 8 2H16C16.5523 2 17 2.44772 17 3V6ZM18 8H6V20H18V8ZM13.4142 13.9997L15.182 15.7675L13.7678 17.1817L12 15.4139L10.2322 17.1817L8.81802 15.7675L10.5858 13.9997L8.81802 12.232L10.2322 10.8178L12 12.5855L13.7678 10.8178L15.182 12.232L13.4142 13.9997ZM9 4V6H15V4H9Z"></path></svg>
								</div>
							</div> : <div className="flex mx-5" >
								<div
									className="flex justify-center  h-[170px] w-[280px]  px-4 py-2 transition bg-[#1C49D01A] border-x border-y border-[#171A1FFF] border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none">
									<span className="flex items-center justify-center w-1/2 flex-col space-x-2">
										<svg className="w-[60px] h-[60px] text-[#1C49D0FF]" fill="#1C49D0FF" viewBox="0 0 256 256"><path d="M248,128a87.34,87.34,0,0,1-17.6,52.81,8,8,0,1,1-12.8-9.62A71.34,71.34,0,0,0,232,128a72,72,0,0,0-144,0,8,8,0,0,1-16,0,88,88,0,0,1,3.29-23.88C74.2,104,73.1,104,72,104a48,48,0,0,0,0,96H96a8,8,0,0,1,0,16H72A64,64,0,1,1,81.29,88.68,88,88,0,0,1,248,128Zm-90.34-5.66a8,8,0,0,0-11.32,0l-32,32a8,8,0,0,0,11.32,11.32L144,147.31V208a8,8,0,0,0,16,0V147.31l18.34,18.35a8,8,0,0,0,11.32-11.32Z"></path></svg>
										<span
											onClick={() => handleBrowseImagefile()}
											className="bg-[#1C49D0FF] my-2  text-[14px] font-[400]  text-white px-2 py-2 rounded text-xs ">
											Browse File
										</span>
									</span>
									{projectImage ? <img
										className="h-24 w-24 py-2"
										src={projectImage} /> : ""}
									<input type="file" id='imagefile' className="hidden" name="file_upload" onChange={(e) => handleFileChange(e)} />
								</div>
							</div>}
							<div className="flex flex-row space-x-2 m-4 mx-5">
								<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#1C49D0FF" viewBox="0 0 24 24"><path d="M19.903 8.586a.997.997 0 0 0-.196-.293l-6-6a.997.997 0 0 0-.293-.196c-.03-.014-.062-.022-.094-.033a.991.991 0 0 0-.259-.051C13.04 2.011 13.021 2 13 2H6c-1.103 0-2 .897-2 2v16c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V9c0-.021-.011-.04-.013-.062a.952.952 0 0 0-.051-.259c-.01-.032-.019-.063-.033-.093zM16.586 8H14V5.414L16.586 8zM6 20V4h6v5a1 1 0 0 0 1 1h5l.002 10H6z" /><path d="M8 12h8v2H8zm0 4h8v2H8zm0-8h2v2H8z" /></svg>
								<div className="text-[18px] text-[#323842FF] font-semibold ">Related documents</div>
							</div>
							<div className="flex mx-5 " >
								<div
									className="flex justify-center   w-[280px]  px-4 py-2 transition bg-[#1C49D01A] border-x border-y border-[#171A1FFF] border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none">
									<span className="flex items-center justify-center flex-col space-x-2">
										<svg className="w-[60px] h-[60px] text-[#1C49D0FF]" fill="#1C49D0FF" viewBox="0 0 256 256"><path d="M248,128a87.34,87.34,0,0,1-17.6,52.81,8,8,0,1,1-12.8-9.62A71.34,71.34,0,0,0,232,128a72,72,0,0,0-144,0,8,8,0,0,1-16,0,88,88,0,0,1,3.29-23.88C74.2,104,73.1,104,72,104a48,48,0,0,0,0,96H96a8,8,0,0,1,0,16H72A64,64,0,1,1,81.29,88.68,88,88,0,0,1,248,128Zm-90.34-5.66a8,8,0,0,0-11.32,0l-32,32a8,8,0,0,0,11.32,11.32L144,147.31V208a8,8,0,0,0,16,0V147.31l18.34,18.35a8,8,0,0,0,11.32-11.32Z"></path></svg>
										<span
											onClick={() => handleBrowseAttachementFile()}
											className="bg-[#1C49D0FF] my-2  text-[14px] font-[400]  text-white px-2 py-2 rounded text-xs ">
											Browse File
										</span>
									</span>
									<input type="file" id='attachmentfile' className="hidden" multiple name="attachment_upload" onChange={(e) => handleFileAttachmentChange(e)} />
								</div>
							</div>
							<div className="flex flex-col justify-center item-center w-full mx-5 px-5 h-full pb-20">
								<div className="flex flex-row space-x-2 m-4">
									<div className="text-xs font-normal  text-black text-opacity-60 ">Uploading files - {attachmentList?.length}</div>
								</div>
								{newAttachmentList?.map((obj, index) => {
									return <div className="flex w-full flex-row jusity-center item-center h-full">
										<svg
											xmlns="http://www.w3.org/2000/svg" width="48px" height="48px"
											fill="#1C49D0ff" viewBox="0 0 24 24">
											<path d="M7.782 14.576c-.186 0-.312.018-.377.036v1.193c.077.018.174.023.306.023.485 0 .785-.246.785-.659 0-.371-.258-.593-.714-.593z" /><path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8l-6-6zM9.03 16.105c-.313.293-.774.426-1.313.426-.12 0-.229-.007-.312-.019v1.445h-.906V13.97a7.504 7.504 0 0 1 1.235-.083c.563 0 .966.107 1.235.323.258.204.432.54.432.936s-.131.731-.371.959zm4.302 1.853h-.96l-.863-1.56c-.24-.432-.504-.953-.701-1.427l-.019.006c.024.534.036 1.104.036 1.763v1.218h-.84v-4.042h1.067l.84 1.481c.24.426.479.93.659 1.385h.019a14.746 14.746 0 0 1-.078-1.685v-1.182h.84v4.043zm4.169-.186a4.512 4.512 0 0 1-1.349.228c-.737 0-1.271-.186-1.644-.546-.371-.348-.575-.875-.569-1.469.006-1.344.983-2.111 2.309-2.111.521 0 .924.103 1.121.198l-.191.731c-.222-.096-.498-.174-.941-.174-.762 0-1.338.432-1.338 1.308 0 .833.522 1.325 1.271 1.325.21 0 .378-.024.45-.061v-.846h-.624v-.713h1.505v2.13zM14 9h-1V4l5 5h-4z" /></svg>
										<div className="w-1/2 m-2  pt-1">
											<div className="mb-2 flex w-full items-center justify-between text-xs mr-8">
												<div onClick={() => downloadAttachment(obj?.fileKey)}
													className="cursor-pointer text-gray-600">{obj?.filename}</div>
												<div className="text-gray-600">100%</div>
											</div>
											<div className="flex flex-row w-full justify-center item-center">
												<div className="w-full mb-4 flex h-2 overflow-hidden rounded bg-gray-100 text-xs ">
													<div className="bg-green-500 w-full"></div>
												</div>
											</div>
										</div>
										<div onClick={() => handleDeleteAttachment(obj, index, 'uploading')}
											className="m-1 p-2 cursor-pointer rounded  text-black h-7" >
											<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="currentColor"><path d="M17 6H22V8H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V8H2V6H7V3C7 2.44772 7.44772 2 8 2H16C16.5523 2 17 2.44772 17 3V6ZM18 8H6V20H18V8ZM13.4142 13.9997L15.182 15.7675L13.7678 17.1817L12 15.4139L10.2322 17.1817L8.81802 15.7675L10.5858 13.9997L8.81802 12.232L10.2322 10.8178L12 12.5855L13.7678 10.8178L15.182 12.232L13.4142 13.9997ZM9 4V6H15V4H9Z"></path></svg>
										</div>
									</div>
								})}
								{attachmentList?.map((obj, index) => {
									return <div className="flex w-full flex-row jusity-center item-center h-full">
										<svg xmlns="http://www.w3.org/2000/svg" width="48px" height="48px" fill="#1C49D0ff" viewBox="0 0 24 24"><path d="M7.782 14.576c-.186 0-.312.018-.377.036v1.193c.077.018.174.023.306.023.485 0 .785-.246.785-.659 0-.371-.258-.593-.714-.593z" /><path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8l-6-6zM9.03 16.105c-.313.293-.774.426-1.313.426-.12 0-.229-.007-.312-.019v1.445h-.906V13.97a7.504 7.504 0 0 1 1.235-.083c.563 0 .966.107 1.235.323.258.204.432.54.432.936s-.131.731-.371.959zm4.302 1.853h-.96l-.863-1.56c-.24-.432-.504-.953-.701-1.427l-.019.006c.024.534.036 1.104.036 1.763v1.218h-.84v-4.042h1.067l.84 1.481c.24.426.479.93.659 1.385h.019a14.746 14.746 0 0 1-.078-1.685v-1.182h.84v4.043zm4.169-.186a4.512 4.512 0 0 1-1.349.228c-.737 0-1.271-.186-1.644-.546-.371-.348-.575-.875-.569-1.469.006-1.344.983-2.111 2.309-2.111.521 0 .924.103 1.121.198l-.191.731c-.222-.096-.498-.174-.941-.174-.762 0-1.338.432-1.338 1.308 0 .833.522 1.325 1.271 1.325.21 0 .378-.024.45-.061v-.846h-.624v-.713h1.505v2.13zM14 9h-1V4l5 5h-4z" /></svg>
										<div className="w-1/2 m-2  pt-1">
											<div className="mb-2 flex w-full items-center justify-between text-xs mr-8">
												<div onClick={() => downloadAttachment(obj?.fileKey)}
													className="cursor-pointer text-gray-600">{obj?.filename}</div>
												<div className="text-gray-600">100%</div>
											</div>
											<div className="flex flex-row w-full justify-center item-center">
												<div className="w-full mb-4 flex h-2 overflow-hidden rounded bg-gray-100 text-xs ">
													<div className="bg-green-500 w-full"></div>
												</div>
											</div>
										</div>
										<div onClick={() => handleDeleteAttachment(obj, index, 'attached')}
											className="m-1 p-2 cursor-pointer rounded  text-black h-7" >
											<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="currentColor"><path d="M17 6H22V8H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V8H2V6H7V3C7 2.44772 7.44772 2 8 2H16C16.5523 2 17 2.44772 17 3V6ZM18 8H6V20H18V8ZM13.4142 13.9997L15.182 15.7675L13.7678 17.1817L12 15.4139L10.2322 17.1817L8.81802 15.7675L10.5858 13.9997L8.81802 12.232L10.2322 10.8178L12 12.5855L13.7678 10.8178L15.182 12.232L13.4142 13.9997ZM9 4V6H15V4H9Z"></path></svg>
										</div>
									</div>
								})}

							</div>
						</div>
					</div>
				</div >
			</div >
		</>
	);
}


