export const INSPECTION_STAGE_RESET_STATE = 'INSPECTION_STAGE_RESET_STATE';
export const INSPECTION_STAGE_CURRENT_ITEM = 'INSPECTION_STAGE_CURRENT_ITEM';

export const INSPECTION_STAGE_REQUEST_LOADING = 'INSPECTION_STAGE_REQUEST_LOADING';
export const INSPECTION_STAGE_REQUEST_SUCCESS = 'INSPECTION_STAGE_REQUEST_SUCCESS';
export const INSPECTION_STAGE_REQUEST_FAILED = 'INSPECTION_STAGE_REQUEST_FAILED';

export const INSPECTION_STAGE_CURRENT_ACTION = 'INSPECTION_STAGE_CURRENT_ACTION';
export const INSPECTION_STAGE_RESET_ACTION = 'INSPECTION_STAGE_RESET_ACTION';

export const INSPECTION_STAGE_NAME_EXISTS_ACTION = 'INSPECTION_STAGE_NAME_EXISTS_ACTION';
export const INSPECTION_STAGE_RESET_NAME_EXISTS = 'INSPECTION_STAGE_RESET_NAME_EXISTS';

