import { createSelector } from 'reselect';

const selectProjectUsers = (state) => state.projectusers;

export const selectCurrentItem = createSelector([selectProjectUsers], (projectusers) => projectusers.current);

export const selectListItems = createSelector([selectProjectUsers], (projectusers) => projectusers.list);


export const selectItemById = (itemId) =>
	createSelector(selectListItems, (list) => list.result.items.find((item) => item._id === itemId));

export const selectCreatedItem = createSelector([selectProjectUsers], (projectusers) => projectusers.create);

export const selectUpdatedItem = createSelector([selectProjectUsers], (projectusers) => projectusers.update);

export const selectReadItem = createSelector([selectProjectUsers], (projectusers) => projectusers.read);

export const selectDeletedItem = createSelector([selectProjectUsers], (projectusers) => projectusers.delete);

export const selectSearchedItems = createSelector([selectProjectUsers], (projectusers) => projectusers.search);

export const selectItemsCount = createSelector([selectProjectUsers], (projectusers) => projectusers.count);

export const selectItemsFilter = createSelector([selectProjectUsers], (projectusers) => projectusers.filter);



