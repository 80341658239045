import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { selectCurrentCRM } from '../../redux/crm/selectors';
import { Chart } from 'react-google-charts';
import { FaTasks, FaStar, FaBox, FaClock } from 'react-icons/fa';
import { selectItemsFilter } from '../../redux/projectcards/selectors';
import { projectcards } from '../../redux/projectcards/actions'
import { useDispatch, useSelector } from 'react-redux';
import storePersist from './../../redux/storePersist';
import { useNavigate } from 'react-router-dom';
import { crm } from "../../redux/crm/actions";


const SortableTable = ({ data }) => {
	const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const sortedData = React.useMemo(() => {
		let sortableItems = [...data];
		if (sortConfig.key !== null) {
			sortableItems.sort((a, b) => {
				if (a[sortConfig.key] < b[sortConfig.key]) {
					return sortConfig.direction === 'ascending' ? -1 : 1;
				}
				if (a[sortConfig.key] > b[sortConfig.key]) {
					return sortConfig.direction === 'ascending' ? 1 : -1;
				}
				return 0;
			});
		}
		return sortableItems;
	}, [data, sortConfig]);

	const requestSort = (key, direction) => {
		// let direction = 'ascending';
		// if (sortConfig.key === key && sortConfig.direction === 'ascending') {
		// 	direction = 'descending';
		// }
		setSortConfig({ key, direction });
	};

	const getClassNamesFor = (key) => {
		if (!sortConfig.key) {
			return;
		}
		return sortConfig.key === key ? (sortConfig.direction === 'ascending' ? 'text-blue-600' : 'text-red-600') : undefined;
	};

	const handleView = (name) => {
		dispatch(crm.trigger({ qualityMetricsCategory: name }))
		navigate('/inspection-crm/quality-metrics')
	}

	return (
		<table className="min-w-full bg-white ring-black ring-1 rounded">
			<thead>
				<tr className='bg-gray-200'>
					<th

						className={`py-2 font-normal px-4 text-left  ${getClassNamesFor('category')}`}
					>
						<span className='flex flex-row justify-start items-center '>	Category
							<svg
								onClick={() => requestSort('category', 'descending')}
								className='ml-6 mr-3 cursor-pointer ' fill="#000000" height="12px" width="12px" version="1.1" id="Layer_1"
								viewBox="0 0 330 330" >
								<path id="XMLID_21_" d="M213.107,41.894l-37.5-37.5c-5.857-5.858-15.355-5.858-21.213,0l-37.5,37.5
	c-4.29,4.29-5.573,10.742-3.252,16.347c2.322,5.605,7.792,9.26,13.858,9.26H150V315c0,8.284,6.716,15,15,15c8.284,0,15-6.716,15-15
	V67.5h22.5c6.067,0,11.537-3.655,13.858-9.26C218.68,52.635,217.397,46.184,213.107,41.894z"/>
							</svg>
							<svg
								onClick={() => requestSort('category', 'ascending')}
								className='mx-3 cursor-pointer ' fill="#000000" height="12px" width="12px" version="1.1" id="Layer_1"
								viewBox="0 0 330 330" >
								<path id="XMLID_24_" d="M216.358,271.76c-2.322-5.605-7.792-9.26-13.858-9.26H180V15c0-8.284-6.716-15-15-15
	c-8.284,0-15,6.716-15,15v247.5h-22.5c-6.067,0-11.537,3.655-13.858,9.26c-2.321,5.605-1.038,12.057,3.252,16.347l37.5,37.5
	C157.322,328.536,161.161,330,165,330s7.678-1.464,10.607-4.394l37.5-37.5C217.396,283.816,218.68,277.365,216.358,271.76z"/>
							</svg>
						</span>
					</th>
					<th

						className={`py-2 font-normal px-4  text-left  ${getClassNamesFor('total')}`}
					>
						<span className='flex flex-row justify-start items-center '>Issues Reported
							<svg
								onClick={() => requestSort('total', 'descending')}
								className='ml-6 mr-3 cursor-pointer' fill="#000000" height="12px" width="12px" version="1.1" id="Layer_1"
								viewBox="0 0 330 330" >
								<path id="XMLID_21_" d="M213.107,41.894l-37.5-37.5c-5.857-5.858-15.355-5.858-21.213,0l-37.5,37.5
	c-4.29,4.29-5.573,10.742-3.252,16.347c2.322,5.605,7.792,9.26,13.858,9.26H150V315c0,8.284,6.716,15,15,15c8.284,0,15-6.716,15-15
	V67.5h22.5c6.067,0,11.537-3.655,13.858-9.26C218.68,52.635,217.397,46.184,213.107,41.894z"/>
							</svg>
							<svg
								onClick={() => requestSort('total', 'ascending')}
								className='mx-3 cursor-pointer' fill="#000000" height="12px" width="12px" version="1.1" id="Layer_1"
								viewBox="0 0 330 330" >
								<path id="XMLID_24_" d="M216.358,271.76c-2.322-5.605-7.792-9.26-13.858-9.26H180V15c0-8.284-6.716-15-15-15
	c-8.284,0-15,6.716-15,15v247.5h-22.5c-6.067,0-11.537,3.655-13.858,9.26c-2.321,5.605-1.038,12.057,3.252,16.347l37.5,37.5
	C157.322,328.536,161.161,330,165,330s7.678-1.464,10.607-4.394l37.5-37.5C217.396,283.816,218.68,277.365,216.358,271.76z"/>
							</svg>
						</span>
					</th>
					<th

						className={`py-2 font-normal px-4  text-left  ${getClassNamesFor('IsReopened')}`}
					>
						<span className='flex flex-row justify-start items-center '>	Issues Reopened
							<svg
								onClick={() => requestSort('IsReopened', 'descending')}
								className='ml-6 mr-3 cursor-pointer' fill="#000000" height="12px" width="12px" version="1.1" id="Layer_1"
								viewBox="0 0 330 330" >
								<path id="XMLID_21_" d="M213.107,41.894l-37.5-37.5c-5.857-5.858-15.355-5.858-21.213,0l-37.5,37.5
	c-4.29,4.29-5.573,10.742-3.252,16.347c2.322,5.605,7.792,9.26,13.858,9.26H150V315c0,8.284,6.716,15,15,15c8.284,0,15-6.716,15-15
	V67.5h22.5c6.067,0,11.537-3.655,13.858-9.26C218.68,52.635,217.397,46.184,213.107,41.894z"/>
							</svg>
							<svg
								onClick={() => requestSort('IsReopened', 'ascending')}
								className='mx-3 cursor-pointer' fill="#000000" height="12px" width="12px" version="1.1" id="Layer_1"
								viewBox="0 0 330 330" >
								<path id="XMLID_24_" d="M216.358,271.76c-2.322-5.605-7.792-9.26-13.858-9.26H180V15c0-8.284-6.716-15-15-15
	c-8.284,0-15,6.716-15,15v247.5h-22.5c-6.067,0-11.537,3.655-13.858,9.26c-2.321,5.605-1.038,12.057,3.252,16.347l37.5,37.5
	C157.322,328.536,161.161,330,165,330s7.678-1.464,10.607-4.394l37.5-37.5C217.396,283.816,218.68,277.365,216.358,271.76z"/>
							</svg>
						</span>
					</th>
					<th

						className={`py-2 font-normal px-4  text-left  ${getClassNamesFor('avgClosureTime')}`}
					>
						<span className='flex flex-row justify-start items-center '>
							Avg. Time to Resolve(in days)
							<svg
								onClick={() => requestSort('avgClosureTime', 'descending')}
								className='ml-6 mr-3 cursor-pointer' fill="#000000" height="12px" width="12px" version="1.1" id="Layer_1"
								viewBox="0 0 330 330" >
								<path id="XMLID_21_" d="M213.107,41.894l-37.5-37.5c-5.857-5.858-15.355-5.858-21.213,0l-37.5,37.5
	c-4.29,4.29-5.573,10.742-3.252,16.347c2.322,5.605,7.792,9.26,13.858,9.26H150V315c0,8.284,6.716,15,15,15c8.284,0,15-6.716,15-15
	V67.5h22.5c6.067,0,11.537-3.655,13.858-9.26C218.68,52.635,217.397,46.184,213.107,41.894z"/>
							</svg>
							<svg
								onClick={() => requestSort('avgClosureTime', 'ascending')}
								className='mx-3 cursor-pointer' fill="#000000" height="12px" width="12px" version="1.1" id="Layer_1"
								viewBox="0 0 330 330" >
								<path id="XMLID_24_" d="M216.358,271.76c-2.322-5.605-7.792-9.26-13.858-9.26H180V15c0-8.284-6.716-15-15-15
	c-8.284,0-15,6.716-15,15v247.5h-22.5c-6.067,0-11.537,3.655-13.858,9.26c-2.321,5.605-1.038,12.057,3.252,16.347l37.5,37.5
	C157.322,328.536,161.161,330,165,330s7.678-1.464,10.607-4.394l37.5-37.5C217.396,283.816,218.68,277.365,216.358,271.76z"/>
							</svg>
						</span>
					</th>
					<th className={"py-2 px-4 font-normal text-left "}>
						Report
					</th>
				</tr>
			</thead>
			<tbody>
				{sortedData.map((item, index) => (
					<tr key={index} className="border-b">
						<td key={1} className="py-2 px-4 text-sm">
							{item?.category}
						</td>
						<td key={2} className="py-2 px-4 text-sm">
							{item?.total}						</td>
						<td key={3} className="py-2 px-4 text-sm">
							{item?.IsReopened}
						</td>
						<td key={4} className="py-2 px-4 text-sm">
							{Math.round(item?.avgClosureTime)}
						</td>
						<td
							onClick={() => handleView(item.category)}
							key={100} className="py-2 px-4 underline text-sm text-blue-500 cursor-pointer">
							View
						</td>
					</tr>
				))}
			</tbody>
		</table>
	);
};

function Dashboard() {
	const [toggle, setToggle] = useState(true)
	const [reportDetails, setReportDetails] = useState([])
	const [qualityReport, setQualityReport] = useState([])
	const [crmExecutives, setCrmExecutives] = useState([])
	const [siteEngineer, setSiteEngineer] = useState([])
	const [customerReview, setCustomerReview] = useState(0)
	const [notStarted, setNotStarted] = useState(0)
	const [pDIStage, setPDIStage] = useState(0)
	const [barData, setBarData] = useState([])
	const [pieData, setPieData] = useState([])
	const { result: projectResult } = useSelector(selectItemsFilter);
	const { activeDashboardProject } = useSelector(selectCurrentCRM);
	const { items } = projectResult;
	const dispatch = useDispatch()
	const [activeProject, setActiveProject] = useState('')

	useEffect(() => {
		dispatch(projectcards.list({ entity: 'PDI_Project_Cards', jsonData: { page: 1, limit: 200 } }))
	}, [])

	const getDashboardAPI = async (id) => {
		const token = storePersist.get('auth')?.current
		let response
		try {
			response = await axios.post('https://api.greypath.co/api/v1/PDI_Issue_Cards/dashBoard', {
				"Project": id
			},
				{
					method: 'POST',
					mode: 'no-cors',
					cache: 'no-cache',
					credentials: 'include',
					headers: {
						'Content-Type': 'application/json',
						'Authorization': 'Bearer ' + token
					},
					redirect: 'follow',
					referrerPolicy: 'no-referrer',
				})
		} catch (error) {
			return JSON.stringify(error);
		}
		finally {
			if (response && response?.data && response?.data?.result) {
				const report = Object.values(response?.data?.result?.detailsReport)
				const keys = Object.keys(response?.data?.result?.detailsReport)
				setReportDetails(report)
				const crmArr = Object.values(response?.data?.result?.crmExecutives)
				setCrmExecutives(crmArr)
				const siteArr = Object.values(response?.data?.result?.siteEngineers)
				setSiteEngineer(siteArr)
				let barChartData = []
				let notStarted = 0
				let inProgress = 0
				let completed = 0
				barChartData.push(['Category', 'Completed', 'Not Started', 'In Progress'])
				keys?.map((category, idx) => {
					barChartData.push([category, report[idx]['Finished'], report[idx]['In Progress'], report[idx]['Todo']])
					notStarted = notStarted + report[idx]['Todo']
					inProgress = inProgress + report[idx]['In Progress']
					completed = completed + report[idx]['Finished']
				})
				setPieData([
					['Task', 'No of Task'],
					['Completed', completed],
					['Not Started', notStarted],
					['In Progress', inProgress]])
				setBarData(barChartData)
			}
		}
	}

	const getCustomerDetails = async (id) => {
		const token = storePersist.get('auth')?.current
		let response
		try {
			response = await axios.post('https://api.greypath.co/api/v1/PDI_Customer_Details/overview', {
				"project": id
			},
				{
					method: 'POST',
					mode: 'no-cors',
					cache: 'no-cache',
					credentials: 'include',
					headers: {
						'Content-Type': 'application/json',
						'Authorization': 'Bearer ' + token
					},
					redirect: 'follow',
					referrerPolicy: 'no-referrer',
				})
		} catch (error) {
			return JSON.stringify(error);
		}
		finally {
			if (response && response?.data && response?.data?.result) {
				setPDIStage(response?.data?.result['PDI Stage'])
				setNotStarted(response?.data?.result['Not Started'])
				setCustomerReview(response?.data?.result['Customer Review'])
			}
		}
	}

	const getPDI_Issue_Cards = async (id) => {
		const token = storePersist.get('auth')?.current
		let response
		try {
			response = await axios.post('https://api.greypath.co/api/v1/PDI_Issue_Cards/lastMonth', {
				"Project": id
			},
				{
					method: 'POST',
					mode: 'no-cors',
					cache: 'no-cache',
					credentials: 'include',
					headers: {
						'Content-Type': 'application/json',
						'Authorization': 'Bearer ' + token
					},
					redirect: 'follow',
					referrerPolicy: 'no-referrer',
				})
		} catch (error) {
			return JSON.stringify(error);
		}
		finally {
			if (response && response?.data && response?.data?.result) {

			}
		}
	}

	const getQualityMetric = async (id) => {
		const token = storePersist.get('auth')?.current
		let response
		try {
			response = await axios.post('https://api.greypath.co/api/v1/PDI_Issue_Cards/qualityMetrics', {
				"Project": id
			},
				{
					method: 'POST',
					mode: 'no-cors',
					cache: 'no-cache',
					credentials: 'include',
					headers: {
						'Content-Type': 'application/json',
						'Authorization': 'Bearer ' + token
					},
					redirect: 'follow',
					referrerPolicy: 'no-referrer',
				})
		} catch (error) {
			return JSON.stringify(error);
		}
		finally {
			if (response && response?.data && response?.data?.result) {
				const _report = Object.values(response?.data?.result?.categoryReport)
				const _qualityReportKey = Object.keys(response?.data?.result?.categoryReport)
				let qualityMetricsData = []
				_qualityReportKey?.map((item, idx) => {
					qualityMetricsData.push({ category: item, total: _report[idx]['total'], IsReopened: _report[idx]['IsReopened'], avgClosureTime: _report[idx]['avgClosureTime'] })
				})
				setQualityReport(qualityMetricsData)
			}
		}
	}

	useEffect(() => {
		if (activeDashboardProject != "") {
			setActiveProject(activeDashboardProject)
		}
	}, [activeDashboardProject])

	useEffect(() => {
		if (activeProject != '') {
			getDashboardAPI(activeProject)
			getQualityMetric(activeProject)
			getCustomerDetails(activeProject)
			getPDI_Issue_Cards(activeProject)
		}
	}, [activeProject])

	const data1 = [
		{ name: 'Alice', role: 'CEO', age: 40 },
		{ name: 'Bob', role: 'CTO', age: 35 },
		{ name: 'Charlie', role: 'CFO', age: 45 },
		// Add more data as needed
	];

	const options = {
		title: '',
		pieHole: 0.4,
		width: 250,
		height: 250,
		is3D: false,
		legend: 'none', // 
	};

	const Card = ({ title, subtitle, count, bgColor, icon, tag }) => {
		return (
			<div className={`${bgColor} text-gray-500 rounded-lg shadow-md p-6 space-y-3`}>
				<div className="flex items-center mb-4">
					<div className="text-3xl">{icon}</div>
					<div className="ml-4">
						<h2 className="text-md font-bold">{title}</h2>
					</div>
				</div>
				<div className="text-md flex justify-start items-center font-bold h-12">{subtitle}</div>
				<div className='flex flex-row justify-start items-center space-x-3'>
					<div className="text-4xl font-bold text-blue-500">{count}</div>
					<div className="text-xs font-bold">{tag}</div>
				</div>


			</div>
		);
	}

	const barChartOptions = {
		title: '',
		explorer: { axis: 'horizontal', keepInBounds: true, rightClickToReset: true, dragToZoom: true, maxZoomIn: 0.5, maxZoomOut: 8 },
		chartArea: {
			width: '95%',
			height: 160
		},
		width: 1000,
		height: 300,
		legend: 'none',
		textStyle: {
			fontName: 'Arial',
			fontSize: 14,
			padding: 5,
			margin: 5,
			bold: true,
			italic: false,
			color: '#333',
		},
		hAxis: {
			title: 'Category',
			textStyle: {
				fontName: 'Arial',
				fontSize: 14,
				padding: 5,
				margin: 5,
				bold: true,
				italic: false,
				color: '#333',
			},
		},
		vAxis: {
			title: 'Number of Tasks',
			minValue: 0,
		},
	};

	const handleProject = (id) => {
		setActiveProject(id)
		dispatch(crm.trigger({ activeDashboardProject: id }))
	}
	const pieChartColors = [
		{ label: 'Completed', color: '#3366CC' },
		{ label: 'Not Started', color: '#DC3912' },
		{ label: 'In Progress', color: '#FF9900' },
	];

	return (
		<div className="min-h-screen bg-gray-100 p-6">
			<h1 className="text-3xl font-bold mb-6">Dashboard</h1>
			<div className='flex flex-row justify-between items-center px-10 py-1'>
				<div className="text-3xl font-bold ">Overview</div>
				<div className="border-x border-y border-black border-opacity-40 pr-5 pl-2  py-1  rounded text-sm shadow focus:outline-none focus:ring w-max ease-linear transition-all duration-150" >
					<select
						className="w-max bg-gray-100 text-lg"
						onChange={(e) => handleProject(e.target.value)}
						value={activeProject}>
						{items.map((item, index) => {
							return <option value={item._id} key={index}> {item?.ProjectName}</option>
						})}
					</select>
				</div>
			</div>
			<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-6 m-6 p-6">
				<Card
					title="Total Units"
					subtitle="Total no of units"
					count={pDIStage + customerReview}
					bgColor='bg-[#EBFDFF]'
					icon={<FaBox />}
					tag=""
				/>
				<Card
					title="Pre-delivery"
					subtitle="No. of units in pre-delivery stage"
					count={pDIStage}
					bgColor='bg-[#F5F2FD]'
					icon={<FaTasks />}
					tag=""
				/>
				<Card
					title="Customer Review"
					subtitle="No. of units in customer reviews stage"
					count={customerReview}
					bgColor='bg-[#F1F4FD]'
					icon={<FaStar />}
					tag=""
				/>
				<Card
					title="Overdue Task"
					subtitle="Tasks that are overdue"
					count={notStarted}
					bgColor='bg-[#FEF6F1]'
					icon={<FaClock />}
					tag=""
				/>
			</div>
			<div className="text-3xl font-bold px-10 py-1">Detailed reports</div>
			<div className="flex flex-row w-full justify-around items-center p-5">
				<div className="bg-white h-[500px] justify-center items-center shadow-md rounded-lg p-6 w-2/3">
					<div className="flex items-start justify-start text-md font-bold ">Snaglist Categories</div>
					<div className="h-12 flex flex-row justify-center items-center space-x-5">
						{pieChartColors.map((item, index) => (
							<div key={index} className="flex items-center mr-4">
								<div
									className="w-4 h-4 mr-2"
									style={{ backgroundColor: item.color }}
								></div>
								<span>{item.label}</span>
							</div>
						))}
					</div>
					<div className='bg-white shadow-md rounded-lg p-6 mb-6 h-96 overflow-scroll'>
						<Chart
							chartType="ColumnChart"
							width="100%"
							height="100%"
							data={barData}
							options={barChartOptions}
						/>
					</div>
				</div>
				<div className="bg-white h-[500px] flex flex-col shadow-md rounded-lg p-5 1/3">
					<div className="flex items-center justify-center text-md font-bold">Last 30 Days Permformance</div>

					<div className="bg-red-500">
						<Chart
							chartType="PieChart"
							width="100%"
							height="100%"
							data={pieData}
							options={options}
						/>
					</div>
					<div className="-pt-24 flex flex-col justify-center items-center space-y-5">
						{pieChartColors.map((item, index) => (
							<div key={index} className="flex items-center mr-4">
								<div
									className="w-4 h-4 mr-2"
									style={{ backgroundColor: item.color }}
								></div>
								<span>{item.label}</span>
							</div>
						))}
					</div>
				</div>

			</div>
			<div className="container mx-auto my-8">
				<h1 className="text-2xl font-bold mb-4">Team Metrics</h1>
				<div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-3 '>
					<div>
						<div className='text-xl flex w-full justify-center items-center my-2 font-bold'>CRM Executive</div>
						<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-3 p-6 bg-gray-300">
							{crmExecutives?.map((item) => {
								return <Card
									title={item.firstName + ' ' + item.lastName}
									subtitle="No. of Appartment Reviewed"
									count={item?.CurrentMonth?.Finished}
									bgColor='bg-[#EBFDFF]'
									icon={<img src={item?.base64Image ? item?.base64Image : ''} className='h-12 w-12 rounded-3xl' />
									}
									tag={`+${item?.PreviousMonth?.Finished} from last month`}
								/>
							})}

						</div>
					</div>
					<div>
						<div className='text-xl flex w-full justify-center items-center my-2 font-bold'>QC/Engineer</div>

						<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-3 bg-indigo-300  p-6">
							{siteEngineer?.map((item) => {
								return <Card
									title={item.firstName + " " + (items.lastName || '-')}
									subtitle="No. of Issues Reviewed"
									count={item?.CurrentMonth?.Finished}
									bgColor='bg-[#EBFDFF]'
									icon={<img src={item?.base64Image ? item?.base64Image : ''} className='h-12 w-12 rounded-3xl' />}
									tag={`+${item?.PreviousMonth?.Finished} from last month`}
								/>
							})}

						</div>
					</div>

				</div>
			</div>
			<div className="container mx-auto my-8">
				<h1 className="text-2xl font-bold mb-4">Quality Metrics</h1>
				{qualityReport.length > 0 ? <SortableTable data={qualityReport} /> : <></>}
			</div>
			{/* <img src={toggle ? image : image1}
				onClick={() => setToggle(!toggle)}
				className="w-screen h-full -m-2" /> */}
		</div>
	);
}

export default Dashboard;
