import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import storePersist from "../../redux/storePersist.js";
import MyAccount from "../../modals/MyAccount.js";
import Notification from "../../modals/Notification.js";
import { crm } from "../../redux/crm/actions.js";
import { request } from "../../request";


function HelpCenter() {
	const navigate = useNavigate()
	const dispatch = useDispatch();
	const [url, setUrl] = useState('')
	const [user, setUser] = useState({})

	const getUrl = async (user) => {
		const image_url = "https://proptech-pdi.s3.amazonaws.com/" + user?.profileImage
		setUrl(image_url)
	}

	const handleProfile = () => {
		dispatch(crm.trigger({ myAccount: 'open' }))
	}

	useEffect(() => {
		const _user = storePersist.get('user')
		setUser(_user)
		getUrl(_user)
	}, [])


	// const handleNotification = () => {
	// 	dispatch(crm.trigger({ notificationList: 'open' }))
	// }


	return (
		<>
			<MyAccount />
			<Notification enable={true} />
			<div className="max-h-screen w-full bg-[#F9F9FA]">
				<header className="flex absolute z-30 fixed top-0 left-0 justify-center w-full h-20 items-center bg-[#3C50E0FF] text-white">
					<div className="flex flex-row justify-between items-center w-full">
						<div className="flex flex-row text-white items-center justify-center my-3 mx-3 px-3" >
							<svg fill="#ffffff" version="1.1" id="Capa_1"
								width="24" height="24" viewBox="0 0 956.815 956.815" >
								<path d="M137.621,162.622H20c-11.046,0-20,8.954-20,20v72.919c0,11.046,8.954,20,20,20h117.622L137.621,162.622L137.621,162.622z"
								/>
								<path d="M774.193,956.815c11.046,0,20-8.954,20-20V819.193H681.274v117.621c0,11.046,8.954,20,20,20L774.193,956.815
		L774.193,956.815z"/>
								<path d="M794.193,656.275V182.622c0-11.046-8.954-20-20-20H300.54v112.919h380.734v380.734H794.193z" />
								<path d="M936.814,681.275H794.193H681.274H275.54V275.541V162.622V20c0-11.046-8.954-20-20-20h-72.918c-11.046,0-20,8.954-20,20
		v142.622v112.919v498.653c0,11.046,8.954,20,20,20h498.653h112.918h142.622c11.045,0,20-8.954,20-20v-72.918
		C956.814,690.229,947.86,681.275,936.814,681.275z"/>
							</svg>
							<div className=" text-xl font-medium mx-2">
								PropCheck
							</div>
						</div>
						<div className="flex flex-row items-center justify-center">
							{/* <div className="cursor-pointer  mx-2"
								onClick={() => handleNotification()}>
								<svg fill="#ffffff" height="20px" width="20px" version="1.1" id="Capa_1"
									viewBox="0 0 611.999 611.999" >
									<path d="M570.107,500.254c-65.037-29.371-67.511-155.441-67.559-158.622v-84.578c0-81.402-49.742-151.399-120.427-181.203
				C381.969,34,347.883,0,306.001,0c-41.883,0-75.968,34.002-76.121,75.849c-70.682,29.804-120.425,99.801-120.425,181.203v84.578
				c-0.046,3.181-2.522,129.251-67.561,158.622c-7.409,3.347-11.481,11.412-9.768,19.36c1.711,7.949,8.74,13.626,16.871,13.626
				h164.88c3.38,18.594,12.172,35.892,25.619,49.903c17.86,18.608,41.479,28.856,66.502,28.856
				c25.025,0,48.644-10.248,66.502-28.856c13.449-14.012,22.241-31.311,25.619-49.903h164.88c8.131,0,15.159-5.676,16.872-13.626
				C581.586,511.664,577.516,503.6,570.107,500.254z M484.434,439.859c6.837,20.728,16.518,41.544,30.246,58.866H97.32
				c13.726-17.32,23.407-38.135,30.244-58.866H484.434z M306.001,34.515c18.945,0,34.963,12.73,39.975,30.082
				c-12.912-2.678-26.282-4.09-39.975-4.09s-27.063,1.411-39.975,4.09C271.039,47.246,287.057,34.515,306.001,34.515z
				 M143.97,341.736v-84.685c0-89.343,72.686-162.029,162.031-162.029s162.031,72.686,162.031,162.029v84.826
				c0.023,2.596,0.427,29.879,7.303,63.465H136.663C143.543,371.724,143.949,344.393,143.97,341.736z M306.001,577.485
				c-26.341,0-49.33-18.992-56.709-44.246h113.416C355.329,558.493,332.344,577.485,306.001,577.485z"/>
									<path d="M306.001,119.235c-74.25,0-134.657,60.405-134.657,134.654c0,9.531,7.727,17.258,17.258,17.258
				c9.531,0,17.258-7.727,17.258-17.258c0-55.217,44.923-100.139,100.142-100.139c9.531,0,17.258-7.727,17.258-17.258
				C323.259,126.96,315.532,119.235,306.001,119.235z"/>
								</svg>
							</div> */}
							<div
								onClick={() => handleProfile()}
								className="flex items-center justify-between m-5 ml-2  ">
								<img
									className="h-10 w-10 rounded-full"
									src={url}
								/>
							</div>
						</div>
					</div>
				</header >

				<div className="flex flex-col w-screen h-screen justify-center items-center bg-white">
					<div className="text-xl py-2 font-bold">Email
					</div>
					<div className="text-xl pb-2 "> info@greypathsolutions.com</div>
					<div className="text-xl py-2 font-bold">Contact No.
					</div>
					<div className="text-xl  pb-2">+91 8041320073</div>

				</div>
				<footer className="flex fixed bottom-0 left-0 flex-row w-full justify-center min-h-20 pb-2 item-center bg-[#3C50E0FF] text-white">
					<button
						onClick={() => navigate('/mobile/project-board')}
						className="w-1/4  flex flex-col justify-center   items-center ">
						<svg xmlns="http://www.w3.org/2000/svg" height="32" width="32" fill="#ffffff" viewBox="0 0 24 24" ><path d="M19 21H5C4.44772 21 4 20.5523 4 20V11L1 11L11.3273 1.6115C11.7087 1.26475 12.2913 1.26475 12.6727 1.6115L23 11L20 11V20C20 20.5523 19.5523 21 19 21ZM13 19H18V9.15745L12 3.7029L6 9.15745V19H11V13H13V19Z"></path></svg>
						<span className="text-xs">Home</span>
					</button>
					<button
						onClick={() => navigate('/mobile/chat')}
						className="w-1/4  flex flex-col justify-center  items-center ">
						<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#ffffff" viewBox="0 0 256 256"><path d="M216,48H40A16,16,0,0,0,24,64V224a15.85,15.85,0,0,0,9.24,14.5A16.13,16.13,0,0,0,40,240a15.89,15.89,0,0,0,10.25-3.78.69.69,0,0,0,.13-.11L82.5,208H216a16,16,0,0,0,16-16V64A16,16,0,0,0,216,48ZM40,224h0ZM216,192H82.5a16,16,0,0,0-10.3,3.75l-.12.11L40,224V64H216ZM88,112a8,8,0,0,1,8-8h64a8,8,0,0,1,0,16H96A8,8,0,0,1,88,112Zm0,32a8,8,0,0,1,8-8h64a8,8,0,1,1,0,16H96A8,8,0,0,1,88,144Z"></path></svg>
						<span className="text-xs">Chats</span>
					</button>
					<button
						onClick={() => navigate('/mobile/notification')}
						className="w-1/4  flex  flex-col justify-center items-center ">
						<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#ffffff" viewBox="0 0 24 24"><path d="M19 13.586V10c0-3.217-2.185-5.927-5.145-6.742C13.562 2.52 12.846 2 12 2s-1.562.52-1.855 1.258C7.185 4.074 5 6.783 5 10v3.586l-1.707 1.707A.996.996 0 0 0 3 16v2a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1v-2a.996.996 0 0 0-.293-.707L19 13.586zM19 17H5v-.586l1.707-1.707A.996.996 0 0 0 7 14v-4c0-2.757 2.243-5 5-5s5 2.243 5 5v4c0 .266.105.52.293.707L19 16.414V17zm-7 5a2.98 2.98 0 0 0 2.818-2H9.182A2.98 2.98 0 0 0 12 22z" /></svg>
						<span className="text-xs">Notifications</span>
					</button>
					<button
						onClick={() => navigate('/mobile/help-center')}
						className="w-1/4  flex flex-col justify-center  items-center ">
						<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#ffffff" viewBox="0 0 256 256"><path d="M201.89,54.66A103.43,103.43,0,0,0,128.79,24H128A104,104,0,0,0,24,128v56a24,24,0,0,0,24,24H64a24,24,0,0,0,24-24V144a24,24,0,0,0-24-24H40.36A88.12,88.12,0,0,1,190.54,65.93,87.39,87.39,0,0,1,215.65,120H192a24,24,0,0,0-24,24v40a24,24,0,0,0,24,24h24a24,24,0,0,1-24,24H136a8,8,0,0,0,0,16h56a40,40,0,0,0,40-40V128A103.41,103.41,0,0,0,201.89,54.66ZM64,136a8,8,0,0,1,8,8v40a8,8,0,0,1-8,8H48a8,8,0,0,1-8-8V136Zm128,56a8,8,0,0,1-8-8V144a8,8,0,0,1,8-8h24v56Z"></path></svg>
						<span className="text-xs">Help</span>
					</button>
				</footer>
			</div >
		</>
	);
}

export default HelpCenter;
