import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Moment from 'moment';
import storePersist from "../../redux/storePersist";
import { request } from "../../request";
import MyAccount from "../../modals/MyAccount";
import { selectItemsFilter } from '../../redux/projectcards/selectors';
import { selectItemsFilter as selectProjectUsers } from '../../redux/projectusers/selectors';
import { projectcards } from "../../redux/projectcards/actions";
import { projectusers } from "../../redux/projectusers/actions";
import { crm } from "../../redux/crm/actions.js";
// import Notification from "../../modals/Notification.js";

const getOnLineStatus = () =>
	typeof navigator !== 'undefined' && typeof navigator.onLine === 'boolean'
		? navigator.onLine
		: true;

function ProjectBoardMobile() {
	const navigate = useNavigate()
	const dispatch = useDispatch();
	const [imageUrls, setImageUrls] = useState([])
	const [url, setUrl] = useState('')
	const [user, setUser] = useState({})
	const [networkStatus, setNetworkStatus] = useState(getOnLineStatus());
	const setOffline = () => setNetworkStatus(false);
	const setOnline = () => setNetworkStatus(true);
	const { result: projectResult } = useSelector(selectItemsFilter);
	const { result: projectusersResult } = useSelector(selectProjectUsers);
	const { items: projectItems } = projectResult;
	const { items: projectUsersItems } = projectusersResult;
	// const [isNotification, setIsNotification] = useState(true)

	useEffect(() => {
		window.scrollTo(0, 0);
		window.addEventListener('online', setOnline);
		window.addEventListener('offline', setOffline);
		return () => {
			window.removeEventListener('online', setOnline);
			window.removeEventListener('offline', setOffline);
		};
	}, [])

	const getUrl = async (user) => {
		const image_url = "https://proptech-pdi.s3.amazonaws.com/" + user?.profileImage
		setUrl(image_url)
	}

	const handleProfile = () => {
		dispatch(crm.trigger({ myAccount: 'open' }))
	}

	const getImage = (id) => {
		let url = imageUrls.filter((obj) => obj.id == id)[0]?.url
		return url
	}

	const getImageUrl = async () => {
		let images = []
		await Promise.all(projectItems.map(async (item) => {
			const url = "https://proptech-pdi.s3.amazonaws.com/" + item?.ImageUrl
			images.push({ id: item._id, url: url })
		}))
		setImageUrls(images)
	}

	useEffect(() => {
		getImageUrl()
	}, [projectItems])

	useEffect(() => {
		const _user = storePersist.get('user')
		if (networkStatus) {
			dispatch(projectusers.filter({ entity: 'PDI_Project_Users', jsonData: { params: { isDeleted: false, User: _user?._id }, page: 1, limit: 200 } }))
			getUrl(_user)
		}
		setUser(_user)

	}, [])

	useEffect(() => {
		let projectId = []
		Promise.all(projectUsersItems.map((item) => {
			projectId.push(item?.ProjectName?._id)
		}))
		if (networkStatus) {
			dispatch(projectcards.filter({ entity: 'PDI_Project_Cards', jsonData: { params: { _id: projectId }, page: 1, limit: 200 } }))
		}
	}, [projectUsersItems])

	const handleInspectionBoard = (project) => {
		dispatch(crm.trigger({ activeBoardName: project.ProjectName, activeProjectId: project._id, boardType: "drag", inspectionReload: true, projectItem: project }))
		navigate('/mobile/inspection-items')
	}

	return (
		<>
			<MyAccount />
			{/* <Notification enable={isNotification} /> */}
			<div className="max-h-screen w-full bg-[#F9F9FA]">
				<header className="flex absolute z-30 fixed top-0 left-0 justify-center w-full h-20 items-center bg-[#3C50E0FF] text-white">
					<div className="flex flex-row justify-between items-center w-full">
						<div className="flex flex-row text-white items-center justify-center my-3 mx-3 px-3" >
							<svg fill="#ffffff" version="1.1" id="Capa_1"
								width="24" height="24" viewBox="0 0 956.815 956.815" >
								<path d="M137.621,162.622H20c-11.046,0-20,8.954-20,20v72.919c0,11.046,8.954,20,20,20h117.622L137.621,162.622L137.621,162.622z"
								/>
								<path d="M774.193,956.815c11.046,0,20-8.954,20-20V819.193H681.274v117.621c0,11.046,8.954,20,20,20L774.193,956.815
		L774.193,956.815z"/>
								<path d="M794.193,656.275V182.622c0-11.046-8.954-20-20-20H300.54v112.919h380.734v380.734H794.193z" />
								<path d="M936.814,681.275H794.193H681.274H275.54V275.541V162.622V20c0-11.046-8.954-20-20-20h-72.918c-11.046,0-20,8.954-20,20
		v142.622v112.919v498.653c0,11.046,8.954,20,20,20h498.653h112.918h142.622c11.045,0,20-8.954,20-20v-72.918
		C956.814,690.229,947.86,681.275,936.814,681.275z"/>
							</svg>
							<div className=" text-xl font-medium mx-2">
								PropCheck
							</div>
						</div>
						<div className="flex flex-row items-center justify-center">
							{/* <div className="cursor-pointer  mx-2"
								onClick={() => handleNotification()}>
								<svg fill="#ffffff" height="20px" width="20px" version="1.1" id="Capa_1"
									viewBox="0 0 611.999 611.999" >
									<path d="M570.107,500.254c-65.037-29.371-67.511-155.441-67.559-158.622v-84.578c0-81.402-49.742-151.399-120.427-181.203
				C381.969,34,347.883,0,306.001,0c-41.883,0-75.968,34.002-76.121,75.849c-70.682,29.804-120.425,99.801-120.425,181.203v84.578
				c-0.046,3.181-2.522,129.251-67.561,158.622c-7.409,3.347-11.481,11.412-9.768,19.36c1.711,7.949,8.74,13.626,16.871,13.626
				h164.88c3.38,18.594,12.172,35.892,25.619,49.903c17.86,18.608,41.479,28.856,66.502,28.856
				c25.025,0,48.644-10.248,66.502-28.856c13.449-14.012,22.241-31.311,25.619-49.903h164.88c8.131,0,15.159-5.676,16.872-13.626
				C581.586,511.664,577.516,503.6,570.107,500.254z M484.434,439.859c6.837,20.728,16.518,41.544,30.246,58.866H97.32
				c13.726-17.32,23.407-38.135,30.244-58.866H484.434z M306.001,34.515c18.945,0,34.963,12.73,39.975,30.082
				c-12.912-2.678-26.282-4.09-39.975-4.09s-27.063,1.411-39.975,4.09C271.039,47.246,287.057,34.515,306.001,34.515z
				 M143.97,341.736v-84.685c0-89.343,72.686-162.029,162.031-162.029s162.031,72.686,162.031,162.029v84.826
				c0.023,2.596,0.427,29.879,7.303,63.465H136.663C143.543,371.724,143.949,344.393,143.97,341.736z M306.001,577.485
				c-26.341,0-49.33-18.992-56.709-44.246h113.416C355.329,558.493,332.344,577.485,306.001,577.485z"/>
									<path d="M306.001,119.235c-74.25,0-134.657,60.405-134.657,134.654c0,9.531,7.727,17.258,17.258,17.258
				c9.531,0,17.258-7.727,17.258-17.258c0-55.217,44.923-100.139,100.142-100.139c9.531,0,17.258-7.727,17.258-17.258
				C323.259,126.96,315.532,119.235,306.001,119.235z"/>
								</svg>
							</div> */}
							<div
								onClick={() => handleProfile()}
								className="flex items-center justify-between m-5 ml-2  ">
								<img
									className="h-10 w-10 rounded-full"
									src={url}
								/>
							</div>
						</div>
					</div>
				</header >
				<div className="flex flex-col pt-20 pb-20 px-3 w-screen h-full overflow-auto">
					{/* <form className="m-3">
						<div className="relative flex w-full flex-wrap items-stretch">
							<span className="z-10 h-full leading-snug font-normal absolute text-center absolute rounded text-base items-center justify-center w-8 pl-3 py-2">
								<svg className="h-4 w-5 text-black border-opacity-40 " viewBox="0 0 24 24" fill="#A9A9A9"><path d="M11 2C15.968 2 20 6.032 20 11C20 15.968 15.968 20 11 20C6.032 20 2 15.968 2 11C2 6.032 6.032 2 11 2ZM11 18C14.8675 18 18 14.8675 18 11C18 7.1325 14.8675 4 11 4C7.1325 4 4 7.1325 4 11C4 14.8675 7.1325 18 11 18ZM19.4853 18.0711L22.3137 20.8995L20.8995 22.3137L18.0711 19.4853L19.4853 18.0711Z"></path></svg>
							</span>
							<input
								type="text"
								placeholder="Search here..."
								value={search}
								onChange={(e) => handleSearch(e)}
								className="border-x border-y px-3 py-1 border-black border-opacity-40 text-black text-opacity-60 relative rounded text-sm shadow outline-none focus:outline-none focus:ring w-[300px] pl-10 bg-[#F9F9FAFF]"
							/>
						</div>
					</form> */}
					<div className="flex flex-row justify-between items-center m-2">
						{/* <div className="text-sm">Units</div>
						<div className="flex">
							<div className="flex justify-end w-20 mx-1 bg-[#F9F9FAFF]" >
								<div className="border-x border-y border-black border-opacity-40  py-1  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 bg-[#F9F9FAFF]" >
									<select
										className="w-full bg-[#F9F9FAFF] text-xs"
										onChange={(e) => handleStageFilter(e)} >
										<option value={1} key={1}> &nbsp; Status </option>
										{stageitems.map((item, index) => {
											return <option value={item._id} key={index}> {item.Name}</option>
										})}
									</select>
								</div>
							</div>
							<div className="flex justify-end w-20  mx-1" >
								<div className="border-x border-y border-black border-opacity-40   py-1  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150" >
									<select
										className="w-full bg-[#F9F9FAFF] text-xs"
										onChange={(e) => handleStatusFilter(e)} >
										<option value={1} key={1}> &nbsp; Filter
											<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M7 11h10v2H7zM4 7h16v2H4zm6 8h4v2h-4z" /></svg>
										</option>
										{statusitems.map((item, index) => {
											return <option value={item._id} key={index}> {item.Name}</option>
										})}
									</select>
								</div>
							</div>
						</div> */}
					</div>
					<div className="flex flex-wrap item-center justify-center lg:justify-start">
						{projectItems?.map((project, idx) => {
							return <div key={idx}
								className="cursor-pointer flex  rounded shadow-2xl flex-row w-60 mx-2 justify-center my-5 bg-white p-1 pt-1">
								<div className="flex flex-col w-60 w-max-60 ">
									<img
										className="h-40 w-54 my-3 px-3 rounded-bl-md rounded-tr-none rounded-tr-md rounded-tl-md"
										src={getImage(project._id)}
										alt="image-text"
										onClick={() => handleInspectionBoard(project)}
									/>
									<div className="flex flex-row w-64 px-4">
										<div className="w-64 flex flex-col justify-between">
											<div className="flex flex-col w-64 max-w-64 ">
												<div
													onClick={() => handleInspectionBoard(project)}
													className="flex flex-wrap text-gray-400 text-xs w-48 h-14">
													{project?.ProjectType?.map((projecttype, index) => {
														return <div key={index}> {projecttype[0].label},&nbsp;</div>
													})}
													{project?.TypeOfHouse?.map((typeofhouse, idx1) => {
														return <div key={idx1}> {typeofhouse[0].label},&nbsp;</div>
													})}
												</div>
												<div className="my-3   flex flex-row items-center justify-start w-54 text-left">
													<div
														onClick={() => handleInspectionBoard(project)}
														className="text-gray-700 font-bold mr-3 text-left" >
														{project.ProjectName}<br />
													</div>
												</div>
											</div>
											<div onClick={() => handleInspectionBoard(project)}
												className="flex flex-col space-y-2 justify-start w-64 ">
												<div className="flex flex-row w-64 justify-start">
													<div className=" text-gray-800 text-xs min-w-36">Start Date :</div>
													<div className="text-black text-[9px] font-semibold">{Moment(project.StartDate).format('MMM-DD-YYYY')}</div>
												</div>
												<div className="flex flex-row w-64 justify-start">
													<div className=" text-gray-700 text-xs min-w-36">Completion Date :</div>
													<div className="text-black text-[9px] font-semibold"> {Moment(project.ExpectedCompletionDate).format('MMM-DD-YYYY')}</div>
												</div>
											</div>
											<div className="my-2 w-64 ">
												{project?.ProjectStage?.Name == "Pre Delivery Stage" ? <span className="inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">
													{project?.ProjectStage?.Name}</span> :
													(project?.ProjectStage?.Name == 'Under Construction') ? <span className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">{project?.ProjectStage?.Name}</span> :
														<span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">{project?.ProjectStage?.Name}</span>
												}
											</div>
										</div>
									</div>
								</div>
							</div>
						})}
						{projectItems.length == 0 ? <div className="flex flex-col item-center justify-center h-[500px] overflow-y-hide bg-blue-gray-50">
							<header className="flex justify-between h-24 w-full mx-auto px-5 dark:text-white items-center">
								<div className="flex flex-col justify-start w-full my-10" >
									<nav aria-label="breadcrumb" className="w-max" >
										<ol className="flex flex-wrap items-center w-full px-4 py-1 rounded-md bg-blue-gray-50 bg-opacity-60">
											<li
												className="flex items-center font-sans text-sm antialiased font-normal leading-normal transition-colors duration-300 cursor-pointer text-blue-gray-900 hover:text-light-blue-500">
												<h3 className="text-xl font-bold">Projects</h3>
											</li>
										</ol>
									</nav>
								</div>
							</header >
							<div className="flex flex-col bg-blue-gray-50 h-max ">
								<div className="flex flex-col items-center justify-around">
									<span className="text-3xl font-bold p-3 w-screen text-center">Add Project Details</span>
									<span className="text-xs font-bold w-screen text-center px-10">
										To intiate the project, kindly provide the necessary details by uploading project-releated information
									</span>
									<div className="flex justify-center" >
										<button
											onClick={() => navigate('/inspection-crm/add-project')}
											className="w-auto px-3 button bg-blue-600 text-white rounded ring-1 ring-inset ring-blue-600 text-blue-600 p-2 text-xs font-bold"
										>
											Add Project
										</button>
									</div>
								</div>
							</div>
						</div> : <></>}
					</div>
				</div>
				<footer className="flex fixed bottom-0 left-0 flex-row w-full justify-center min-h-20 pb-2 item-center bg-[#3C50E0FF] text-white">
					<button
						className="w-1/4  flex flex-col justify-center   items-center ">
						<svg xmlns="http://www.w3.org/2000/svg" height="32" width="32" fill="#ffffff" viewBox="0 0 24 24" ><path d="M19 21H5C4.44772 21 4 20.5523 4 20V11L1 11L11.3273 1.6115C11.7087 1.26475 12.2913 1.26475 12.6727 1.6115L23 11L20 11V20C20 20.5523 19.5523 21 19 21ZM13 19H18V9.15745L12 3.7029L6 9.15745V19H11V13H13V19Z"></path></svg>
						<span className="text-xs">Home</span>
					</button>
					<button
						onClick={() => navigate('/mobile/chat')}
						className="w-1/4  flex flex-col justify-center  items-center ">
						<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#ffffff" viewBox="0 0 256 256"><path d="M216,48H40A16,16,0,0,0,24,64V224a15.85,15.85,0,0,0,9.24,14.5A16.13,16.13,0,0,0,40,240a15.89,15.89,0,0,0,10.25-3.78.69.69,0,0,0,.13-.11L82.5,208H216a16,16,0,0,0,16-16V64A16,16,0,0,0,216,48ZM40,224h0ZM216,192H82.5a16,16,0,0,0-10.3,3.75l-.12.11L40,224V64H216ZM88,112a8,8,0,0,1,8-8h64a8,8,0,0,1,0,16H96A8,8,0,0,1,88,112Zm0,32a8,8,0,0,1,8-8h64a8,8,0,1,1,0,16H96A8,8,0,0,1,88,144Z"></path></svg>
						<span className="text-xs">Chats</span>
					</button>
					<button
						onClick={() => navigate('/mobile/notification')}
						className="w-1/4  flex  flex-col justify-center items-center ">
						<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#ffffff" viewBox="0 0 24 24"><path d="M19 13.586V10c0-3.217-2.185-5.927-5.145-6.742C13.562 2.52 12.846 2 12 2s-1.562.52-1.855 1.258C7.185 4.074 5 6.783 5 10v3.586l-1.707 1.707A.996.996 0 0 0 3 16v2a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1v-2a.996.996 0 0 0-.293-.707L19 13.586zM19 17H5v-.586l1.707-1.707A.996.996 0 0 0 7 14v-4c0-2.757 2.243-5 5-5s5 2.243 5 5v4c0 .266.105.52.293.707L19 16.414V17zm-7 5a2.98 2.98 0 0 0 2.818-2H9.182A2.98 2.98 0 0 0 12 22z" /></svg>
						<span className="text-xs">Notifications</span>
					</button>
					<button
						onClick={() => navigate('/mobile/help-center')}
						className="w-1/4  flex flex-col justify-center  items-center ">
						<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#ffffff" viewBox="0 0 256 256"><path d="M201.89,54.66A103.43,103.43,0,0,0,128.79,24H128A104,104,0,0,0,24,128v56a24,24,0,0,0,24,24H64a24,24,0,0,0,24-24V144a24,24,0,0,0-24-24H40.36A88.12,88.12,0,0,1,190.54,65.93,87.39,87.39,0,0,1,215.65,120H192a24,24,0,0,0-24,24v40a24,24,0,0,0,24,24h24a24,24,0,0,1-24,24H136a8,8,0,0,0,0,16h56a40,40,0,0,0,40-40V128A103.41,103.41,0,0,0,201.89,54.66ZM64,136a8,8,0,0,1,8,8v40a8,8,0,0,1-8,8H48a8,8,0,0,1-8-8V136Zm128,56a8,8,0,0,1-8-8V144a8,8,0,0,1,8-8h24v56Z"></path></svg>
						<span className="text-xs">Help</span>
					</button>
				</footer>
			</div>
		</>
	);
}

export default ProjectBoardMobile;
